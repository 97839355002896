import './tabs.scss';
import { Nav } from 'reactstrap';
import { FC } from 'react';
import { Button } from '../form/buttons/Button';
import { useTranslation } from 'react-i18next';

type TabsProps = {
    activeTab: number;
    toggleTab: (index: number) => void;
    tabs: string[];
    onClick?: () => void;
    className?: string;
};

export const Tabs: FC<TabsProps> = ({ activeTab, toggleTab, tabs, onClick, className }) => {
    const { t } = useTranslation();

    return (
        <div className={className ? 'tabs' + ' ' + className : 'tabs'}>
            <Nav pills className="tabs-links mb-2">
                {tabs.map((tab, index) => (
                    <Button
                        key={index}
                        theme={activeTab === index + 1 ? 'primary' : 'none'}
                        onClick={() => {
                            onClick?.();
                            toggleTab(index + 1);
                        }}>
                        <span className="fw-bold tab-link_name">{t(tab)}</span>
                    </Button>
                ))}
            </Nav>
        </div>
    );
};

import { CompanyUniqueField, SelectOption } from '../../models/shared.models';
import { ReactNode } from 'react';
import { TableRow } from 'react-data-table-component';
import { Trash } from 'react-feather';
import { FilterForm } from '../table/Tables.types';
import { CompanyShort } from '../../models/login.models';
import { TFunction } from 'i18next';

export type CompanyContact = {
    HRName: string;
    HRPhone: string;
    HREmail: string;
    accountingName?: string;
    accountingPhone?: string;
    accountingEmail?: string;
};

export type Company = {
    companyID: string;
    name: string;
    registrarCompaniesID: string;
    isFrozen: boolean;
    address: { city: string; street: string };
    companyContact: CompanyContact;
    fixedCostPerMonth: number;
    costPerUser: number;
    mainColor: string;
    secondaryColor: string;
    logo?: string;
    logoUrl?: string;
    whiteLogo?: string;
    whiteLogoUrl?: string;
    squareLogo?: string;
    squareLogoUrl?: string;
    socialCeilingRatio: number;
    defaultGiftGroupAmount: number;
    smallAmountMax: number;
    mediumAmountMax: number;
    usersUniqueFields: CompanyUniqueField[];
    companyUniqueFields: CompanyUniqueField[];
    budget?: number;
    monthlyBudget: MonthlyBudget;
    loginBackgroundURL: string;
    specialEventBackgroundURL: string;
    employeesAmount?: number;
    subordinatesAmount?: number;
    budgetApproved: boolean;
};

export type MonthlyBudget = {
    personal: number;
    social: number;
    perSubordinate?: number;
    other?: number;
};

export type CompaniesFilterForm = FilterForm<CompanyShort> & {
    companyName: SelectOption | null;
};

export const defaultCompaniesValues: CompaniesFilterForm = {
    companyName: null,
    rowsPerPage: { label: 25, value: 25 },
    page: 0,
    orderBy: {
        direction: 'DESC',
        sortedColumn: undefined,
    },
};

export type CompaniesTableData = {
    name: ReactNode;
    companyID: string;
};

export const companyDetailsFormInitialValues: Company = {
    address: { city: '', street: '' },
    companyContact: {
        HREmail: '',
        HRPhone: '',
        HRName: '',
        accountingEmail: '',
        accountingName: '',
        accountingPhone: '',
    },
    companyID: '',
    costPerUser: 0,
    defaultGiftGroupAmount: 0,
    fixedCostPerMonth: 0,
    isFrozen: false,
    loginBackgroundURL: '',
    logo: '',
    logoUrl: '',
    whiteLogo: '',
    whiteLogoUrl: '',
    squareLogo: '',
    squareLogoUrl: '',
    mainColor: '#4833A0',
    secondaryColor: '#1B76F2',
    smallAmountMax: 60,
    mediumAmountMax: 180,
    monthlyBudget: {
        personal: 0,
        social: 0,
        perSubordinate: 0,
        other: undefined,
    },
    name: '',
    registrarCompaniesID: '',
    socialCeilingRatio: 2.5,
    specialEventBackgroundURL: '',
    companyUniqueFields: [],
    usersUniqueFields: [],
    budgetApproved: false,
};

export type HashtagsTableData = {
    name: string;
    id: string;
};

export const hashtagsTableColumns = (handleDeleteHashtag: any, t: TFunction<'translation'>) => [
    {
        name: t('hashtagName'),
        selector: (row: TableRow) => row.name,
        width: '75%',
        sortable: false,
    },
    {
        name: '',
        selector: (row: TableRow) => row.editDelete,
        sortable: false,
        cell: (row: HashtagsTableData) => (
            <span className="text-capitalize table-text">
                <span className={'table-icon'}>
                    <Trash onClick={() => handleDeleteHashtag(row.id)} width={20} />
                </span>
            </span>
        ),
    },
];

export type HashtagsFilterForm = FilterForm & {};

export const defaultHashtagsValues: HashtagsFilterForm = {
    rowsPerPage: { label: 25, value: 25 },
    page: 0,
    orderBy: {
        direction: 'DESC',
        sortedColumn: undefined,
    },
};

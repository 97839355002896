import { FC } from 'react';
import './login.scss';
import { LoginArea } from './LoginArea';

export const Login: FC = () => {
    return (
        <div className={'login-page'}>
            <LoginArea />
            <img className={'login-bg'} src={'/assets/login-background.png'} alt={''} />
        </div>
    );
};

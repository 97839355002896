import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { SuccessToast } from '../toaster/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { InnerModal } from '../modal/InnerModal/InnerModal';
import { Modal } from './Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { Mail } from 'react-feather';
import { AddBudgetForm, AddBudgetFormInitialValues } from '../side-budget-modal/side-budget.models';
import { addSideBudgetSchema } from '../side-budget-modal/budget-validation';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { SideBudgetModal } from '../side-budget-modal/SideBudgetModal';
import { sendOTPByCurrentUser } from '../../api/login.api';
import { addBudgetAPI } from '../../api/companies.api';
import { ModalButton } from '../modal/Modal.utils';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';

type RequestBudgetModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    companyID: string;
};

export const RequestBudgetModal: FC<RequestBudgetModalProps> = ({ isOpen, closeModal, companyID }) => {
    const requestBudgetForm = useForm<AddBudgetForm>({
        defaultValues: AddBudgetFormInitialValues,
        mode: 'onTouched',
        resolver: yupResolver(addSideBudgetSchema),
    });
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const currentUser = useSelector(getCurrentUser);

    useEffect(() => {
        if (currentUser?.userType === 'admin') {
            sendOTPByCurrentUser();
        }
    }, [currentUser?.userType, dispatch, t]);

    const getContent = () => {
        return currentUser?.userType === 'admin' ? (
            <SideBudgetModal
                form={requestBudgetForm}
                sendCode={sendOTPByCurrentUser}
                companyID={companyID}
                closeModal={closeModal}
            />
        ) : (
            <FormProvider {...requestBudgetForm}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <span style={{ textAlign: 'center' }}>
                        {t('yourRequestForBudgetWasSent')}
                        <div>{t('forFurtherDetailsSupport')}</div>
                    </span>
                    <SmartInput
                        name={'budget'}
                        enforceNumbers
                        required
                        label={t('additionalBudgetRequested')}
                    />
                </div>
            </FormProvider>
        );
    };

    const getHeader = () => {
        return currentUser?.userType === 'admin'
            ? { title: t('addToCompanyBudget') }
            : {
                  icon: <Mail height={80} width={80} color={'var(--primary)'} />,
                  title: t('attention'),
              };
    };

    const requestBudget = () => {
        const amount = requestBudgetForm.getValues('budget');

        addBudgetAPI({ companyID, amount })
            .then(() => SuccessToast(t('requestSent')))
            .catch((e) => console.log(e))
            .finally(() => closeModal());
    };

    const getFooter = (): { buttons: ModalButton[] } => {
        return currentUser?.userType === 'admin'
            ? { buttons: [] }
            : {
                  buttons: [
                      {
                          label: t('close'),
                          theme: 'grey',
                          callback: closeModal,
                      },
                      {
                          label: t('sendRequest'),
                          theme: 'primary',
                          callback: requestBudget,
                      },
                  ],
              };
    };

    return (
        <Modal isOpen={isOpen} onClose={closeModal}>
            <InnerModal header={getHeader()} content={getContent()} footer={getFooter()} />
        </Modal>
    );
};

import { FC } from 'react';
import './create-post.scss';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { useTranslation } from 'react-i18next';
import { ImageUpload } from '../image-upload/ImageUpload';
import { getPostImagePath, uploadFile } from '../../api/storage.api';
import { ErrorToast } from '../toaster/Toast';
import { useFormContext, useWatch } from 'react-hook-form';

export const CreatePostContent: FC = () => {
    const { t } = useTranslation();
    const { getValues, setValue, control } = useFormContext();
    const image = useWatch({ control, name: 'image' });

    const uploadPic = async (file: File) => {
        const postID = getValues('postID');

        try {
            const key = Date.now();
            const signedURL = await getPostImagePath(postID, key);
            const path = `posts/${postID}/${key}`;

            await uploadFile(signedURL, file);

            const img = new Image();
            img.src = signedURL ?? '';
            img.onload = () => {
                setValue('imageURL', { url: path, width: img.width, height: img.height });
            };

            setValue('image', signedURL);
        } catch (e) {
            ErrorToast(t('errorUploadingFile'));
        }
    };

    return (
        <div className={'step'} style={{ width: '412px' }}>
            <div className={'step-row'}>
                <SmartInput
                    name={'content'}
                    label={t('writeMessage')}
                    placeholder={t('startWritingHere')}
                    type={'textarea'}
                    required
                />
            </div>
            <div className={'step-row'}>
                <ImageUpload
                    imageURL={image}
                    name={'image'}
                    onChange={(value: string) => {
                        setValue('image', value);

                        const img = new Image();
                        img.src = value ?? '';
                        img.onload = () => {
                            setValue('imageURL', { url: value, width: img.width, height: img.height });
                        };
                    }}
                    onUpload={uploadPic}
                    square
                />
            </div>
        </div>
    );
};

import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, UseFormReturn } from 'react-hook-form';
import { InnerModal } from '../../modal/InnerModal/InnerModal';
import { Modal } from '../Modal';
import { CheckCircle } from 'react-feather';
import { AddCredits } from './AddCredits';
import { Company } from '../../companies/companies.models';
import { AddCreditsRequest, UserDetailsForm } from '../../users/users.models';
import { addCreditsToUserAPI } from '../../../api/transactions.api';
import { SuccessToast } from '../../toaster/Toast';
import { getUsersAPI } from '../../../api/users.api';
import { getCompaniesAPI } from '../../../api/companies.api';
import { yupResolver } from '@hookform/resolvers/yup';
import { addCreditsValidations } from './AddCredits.validations';
import { NotEnoughBudgetModal } from './NotEnoughBudget.modal';

type AddCreditsModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    company: Company;
    userID: string;
    form: UseFormReturn<UserDetailsForm>;
    setCompanyDetails: (company: Company) => void;
};

export type AddCreditsForm = {
    budget: number;
    companyID: string;
    creditsToAdd: number;
    isSocial: boolean;
};

export const AddCreditsModal: FC<AddCreditsModalProps> = ({
    isOpen,
    closeModal,
    setCompanyDetails,
    company,
    userID,
    form,
}) => {
    const [isNotEnoughBudgetOpen, setNotEnoughBudgetOpen] = useState(false);
    const addCreditsForm = useForm<AddCreditsForm>({
        mode: 'onTouched',
        defaultValues: {
            budget: company.budget ?? 0,
            companyID: company.companyID,
            creditsToAdd: 0,
            isSocial: false,
        },
        resolver: yupResolver(addCreditsValidations),
    });
    const { t } = useTranslation();

    useEffect(() => {
        addCreditsForm.setValue('budget', company.budget ?? 0);
        addCreditsForm.setValue('companyID', company.companyID);
    }, [company, addCreditsForm]);

    const confirmAddCredits = async (): Promise<void> => {
        await form.trigger().then(async (value) => {
            if (value) {
                const { creditsToAdd, isSocial, companyID, budget } = addCreditsForm.getValues();

                if (budget < +creditsToAdd) {
                    setNotEnoughBudgetOpen(true);
                    return;
                }
                if (!+creditsToAdd) {
                    addCreditsForm.setError('creditsToAdd', { message: t('amountNotValid') });
                    return;
                }

                const addCreditsRequest: AddCreditsRequest = {
                    amount: +creditsToAdd,
                    isSocial,
                    companyID,
                    userID,
                };

                await addCreditsToUserAPI(addCreditsRequest).then(async () => {
                    await updateCredits(isSocial);
                    SuccessToast(t('creditsAddedSuccessfully'));
                    closeModal();
                });
            }
        });
    };

    const updateCredits = async (isSocial: boolean) => {
        const updatedUser = await getUsersAPI({ userID, isExtended: true });
        await getCompaniesAPI<Company>({ companyID: company.companyID, isExtended: true }).then((res) => {
            if (res?.data?.[0]) {
                setCompanyDetails(res?.data?.[0]);
            }
        });

        if (updatedUser.data?.[0]) {
            if (isSocial) {
                form.setValue('socialCredits', updatedUser.data?.[0]?.socialCredits);
            } else {
                form.setValue('personalCredits', updatedUser.data?.[0]?.personalCredits);
            }
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={closeModal}>
            <InnerModal
                header={{ title: t('addCreditsManually') }}
                content={<AddCredits form={addCreditsForm} />}
                footer={{
                    position: 'left',
                    buttons: [
                        {
                            label: t('close'),
                            theme: 'grey',
                            callback: () => {
                                closeModal();
                                addCreditsForm.reset();
                            },
                        },
                        {
                            label: t('confirm'),
                            icon: <CheckCircle />,
                            callback: () => confirmAddCredits(),
                            theme: 'primary',
                        },
                    ],
                }}
            />
            <NotEnoughBudgetModal
                isOpen={isNotEnoughBudgetOpen}
                closeModal={() => setNotEnoughBudgetOpen(false)}
            />
        </Modal>
    );
};

import { API } from './api';
import axios from 'axios';

export const getUserImagePath = async (userID: string, key: number): Promise<string> => {
    return API.get({ url: `storage/user`, params: { userID, key } });
};

export const getProductImagePath = async (productID: string, key: number): Promise<string> => {
    return API.get({ url: `storage/product`, params: { productID, key } });
};

export const getPostImagePath = async (postID: string, key: number): Promise<string> => {
    return API.get({ url: `storage/post`, params: { postID, key } });
};

export const getGroupImagePath = async (groupID: string, companyID: string, key: number): Promise<string> => {
    return API.get({ url: `storage/group`, params: { groupID, companyID, key } });
};

export const getCompanyImagePath = async (companyID: string, key: number): Promise<string> => {
    return API.get({ url: `storage/company`, params: { companyID, key } });
};

export const getCategoryImagePath = async (categoryID: string, key: number): Promise<string> => {
    return API.get({ url: `storage/category`, params: { categoryID, key } });
};

export const uploadFile = async (path: string, file: File): Promise<string> => {
    const formData = new FormData();
    formData.append('file', file);

    return axios(path, {
        method: 'PUT',
        // headers: { 'Content-Type': '"application/octet-stream"' },
        headers: { 'Content-Type': file.type },
        data: file,
    })
        .then((res) => res.data)
        .catch((e) => console.log(e));
};

export const getCookieApi = async (): Promise<string> => {
    return API.get({ url: `storage/cookie` });
};

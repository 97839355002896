import { FC } from 'react';
import './verify-phone.scss';
import '../../scss/page-auth.scss';
import { Button } from '../form/buttons/Button';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { useTranslation } from 'react-i18next';

interface VerifyPhoneProps {
    sendCode: () => void;
    phone: string;
}

export const VerifyPhone: FC<VerifyPhoneProps> = ({ sendCode, phone }) => {
    const { t } = useTranslation();

    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && phone.length >= 9) {
            sendCode();
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
            }
        }
    };

    return (
        <div className={'verify-phone'}>
            <h3 className={'welcome-title'}>{t('welcomeBack')}</h3>
            <div className={'welcome-description'}>{t('enterPhoneForOTP')}</div>
            <div className={'phone-input'}>
                <SmartInput
                    className={'phone-input'}
                    name={'phone'}
                    label={t('phone')}
                    placeholder={t('phone')}
                    onKeyDown={handleKeyPress}
                />
            </div>
            <Button onClick={sendCode} theme="primary" disabled={phone.length < 9}>
                {t('sendOTP')}
            </Button>
        </div>
    );
};

import { useFieldArray, UseFormReturn, useWatch } from 'react-hook-form';
import { FC, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { SmartSelect } from '../form/select/smart-select';
import { ImageUpload } from '../image-upload/ImageUpload';
import shadow from '../users/person-placeholder.png';
import { Button } from '../form/buttons/Button';
import { Copy, Plus, PlusCircle, Save, Trash, Upload } from 'react-feather';
import { useHandleBack } from '../../hooks/useHandleBack';
import { Product, ProductForm, StoreCategory } from './store.models';
import { ErrorToast, SuccessToast } from '../toaster/Toast';
import { useTranslation } from 'react-i18next';
import { createOrEditProductAPI, getProductsAPI } from '../../api/store.api';
import { closeModal, raiseModal } from '../../store/modal/modal.actions';
import { ModalIcon } from '../modal/ModalIcon';
import { SmartRichText } from '../form/rich-text/SmartRichText';
import { convertEditorStateToHtml, getRanHex, getRecursiveCategories, DigitalInventoryUpload, parseInventory } from './store.utils';
import { getProductImagePath, uploadFile } from '../../api/storage.api';
import { Input } from 'reactstrap';

type StoreProductProps = {
    form: UseFormReturn<ProductForm>;
    onStageReset: () => void;
    storeCategories: StoreCategory[] | undefined;
    variantsOptions: { variantID: string; name: string }[] | undefined;
    handleDuplicateProduct: (originalProduct: Product) => Promise<void>;
};

export const StoreProduct: FC<StoreProductProps> = ({
    form,
    variantsOptions,
    storeCategories,
    onStageReset,
    handleDuplicateProduct,
}) => {
    const currentUser = useSelector(getCurrentUser);
    const additionalImages = useWatch({ control: form.control, name: 'additionalImages' });
    const additionalImagesURLs = useWatch({ control: form.control, name: 'additionalImagesURLs' });
    const mainImageURL = useWatch({ control: form.control, name: 'mainImageURL' });
    const variant = form.watch('variant');
    const variants = form.watch('variant.variants');
    const isDigital = form.watch('isDigital');
    const availableItemsCount = form.watch('availableItemsCount');
    const originalItems = form.getValues('items');
    const originalAvailableItemsCount = form.getValues('availableItemsCount');

    const { remove } = useFieldArray({
        control: form.control,
        name: 'variant.variants',
    });
    const formRef = useRef<HTMLDivElement>(null);
    const {
        formState: { isDirty },
    } = form;
    const handleBack = useHandleBack(isDirty);
    const userType = currentUser?.userType;
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const variantsMapped = useMemo(() => {
        return variantsOptions?.map((variant) => {
            return { label: t(variant.name), value: variant.name };
        });
    }, [variantsOptions, t]);

    const recursiveCategories = useMemo(() => {
        return getRecursiveCategories(storeCategories ?? []);
    }, [storeCategories]);

    const handleSave = async (createNewVariant?: () => void) => {
        await form.trigger().then(async (value) => {
            if (!value) {
                dispatch(
                    raiseModal({
                        isOpen: true,
                        content: t('mustFillMandatoryFields'),
                        header: {
                            title: t('save'),
                            icon: <ModalIcon theme={'ALERT'} />,
                        },
                        footer: {
                            buttons: [
                                { label: t('close'), theme: 'grey', callback: () => dispatch(closeModal()) },
                            ],
                        },
                    })
                );
            } else {
                dispatch(
                    raiseModal({
                        isOpen: true,
                        content: t('areYouSureSaveChanges'),
                        header: {
                            title: t('save'),
                            icon: <ModalIcon theme={'ALERT'} />,
                        },
                        footer: {
                            buttons: [
                                { label: t('close'), theme: 'grey', callback: () => dispatch(closeModal()) },
                                {
                                    label: t('save'),
                                    theme: 'primary',
                                    callback: async () => {
                                        dispatch(closeModal());

                                        await confirmSave();
                                        // createNewVariant?.();
                                    },
                                },
                            ],
                        },
                    })
                );
            }
        });
    };

    const handleSaveAndCreateANewVariant = async () => {
        handleSave(createNewVariant);
    };

    const createNewVariant = async () => {
        const product = await getProductsAPI({ catalogNumberID: form.getValues('catalogNumberID') });

        if (product?.data?.[0]) {
            await handleDuplicateProduct(product?.data?.[0]);
            if (formRef?.current) {
                formRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
    };

    const buildProduct = () => {
        const productForm = form.getValues();

        const product: Product = {
            ...productForm,
            availabilitySupplier: productForm.availabilitySupplier ? 'available' : 'not_available',
            categoryID: productForm.category?.value ?? '',
            categoryName: productForm.category?.label ?? '',
            additionalImages: productForm.additionalImages.filter((ai) => !!ai),
            shownByCompany: true,
            enteredCatalogTime: Date.now(),
            isShown: true,
            isForSale: true,
            variant: productForm.variant?.id
                ? {
                      id: productForm.variant.id,
                      isMain: productForm.variant?.isMain,
                      variants: productForm.variant?.variants?.map((variant) => ({
                          type: variant.type.value,
                          value: variant.value,
                      })),
                  }
                : null,
            isDigital: productForm.isDigital,
            items: productForm.items,
            availableItemsCount: productForm.availableItemsCount,
        };

        product.description = convertEditorStateToHtml(product.description ?? '');
        product.warranty = convertEditorStateToHtml(product.warranty ?? '');
        product.specifications = convertEditorStateToHtml(product.specifications ?? '');

        return product;
    };

    const confirmSave = () => {
        const productForm = form.getValues();
        const product = buildProduct();
        return createOrEditProductAPI(product).then((res) => {
            SuccessToast(t('actionSucceeded'));
            form.reset(productForm);

            return res;
        });
    };

    const uploadPic = async (
        file: File,
        name: keyof Product | `additionalImages.${number}`,
        urlName: keyof Product | `additionalImagesURLs.${number}.url`
    ) => {
        try {
            const productID = form.getValues('productID');
            const key = Date.now();
            const signedURL = await getProductImagePath(productID, key);
            const path = `products/${productID}/${key}`;

            await uploadFile(signedURL, file).then(() => {
                form.setValue(name, path);
                form.setValue(urlName, signedURL);
            });
        } catch (e) {
            ErrorToast(t('errorUploadingFile'));
        }
    };

    const renderButtons = () => {
        return (
            userType === 'admin' && (
                <div className={'step-row'}>
                    <div className={'step-footer'}>
                        <div className={'save-and-cancel'}>
                            <Button theme={'grey'} onClick={() => handleBack(onStageReset, onStageReset)}>
                                {t('cancel')}
                            </Button>
                            <Button
                                disabled={userType !== 'admin'}
                                theme={'primary'}
                                onClick={handleSave}
                                icon={<Save size={14} />}>
                                {t('save')}
                            </Button>
                            <Button
                                disabled={userType !== 'admin'}
                                theme={'primary'}
                                onClick={handleSaveAndCreateANewVariant}
                                icon={<Plus size={14} />}>
                                {t('saveAndCreateANewVariant')}
                            </Button>
                        </div>
                    </div>
                </div>
            )
        );
    };

    const updateInventory = async (file: File) => {
        const newItems = await parseInventory(file);
        const items = form.getValues('items').concat(newItems);
        let availableItemsCount = 0;
        for (let index = 0; index <items.length; index++) {
            if (items[index].isActive){
                availableItemsCount++;
            }
        }
        form.setValue('items', items);
        form.setValue('availableItemsCount', availableItemsCount)
    };

    return (
        <div className={'step'} ref={formRef}>
            <div className={'step-title'}>{t('productDetails')}</div>
            <SmartInput
                disabled={userType !== 'admin'}
                required
                name={'catalogNumberID'}
                label={t('makat')}
            />
            <SmartInput required name={'productID'} label={t('internalCatalogID')} disabled />
            <SmartInput disabled={userType !== 'admin'} required name={'title'} label={t('productName')} />
            <SmartSelect
                isDisabled={userType !== 'admin'}
                required
                name={'category'}
                options={recursiveCategories}
                label={t('category')}
            />
            <div className={'checkbox-with-label step-row'}>
                <Input
                    type={'checkbox'}
                    checked={!!variant?.id}
                    onClick={(e: any) => {
                        if (!e.target?.checked) {
                            form.setValue('variant.isMain', false);
                            form.setValue('variant.id', '');
                            form.setValue('variant', undefined);
                        } else {
                            form.setValue('variant', { variants: [], id: getRanHex(), isMain: true });
                        }
                    }}
                    disabled={currentUser?.userType !== 'admin'}
                />
                <span className={'checkbox-label'}>{t('isThisProductAVariant')}</span>
            </div>

            {!!variant?.id && (
                <div style={{ display: 'flex', gap: '12px', alignItems: 'center', position: 'relative' }}>
                    <SmartInput
                        name={'variant.id'}
                        label={t('variantID')}
                        placeholder={t('pasteVariantCode')}
                        disabled={userType !== 'admin' || !variant?.id}
                    />
                    <div className={'checkbox-with-label step-row'}>
                        <Copy
                            width={16}
                            height={16}
                            style={{ cursor: variant?.id ? 'pointer' : 'auto' }}
                            onClick={() => {
                                const variantID = form.getValues('variant.id');
                                if (variantID) {
                                    navigator.clipboard.writeText(variantID ?? '').then(() => {
                                        SuccessToast(t('copiedToClipboard'));
                                    });
                                }
                            }}
                        />
                        <SmartInput
                            name={'variant.isMain'}
                            type={'checkbox'}
                            onClick={(e: any) => {
                                const currentVariantID = variant?.id;

                                if (e.target?.checked && !currentVariantID) {
                                    form.setValue('variant.id', getRanHex());
                                }
                            }}
                            disabled={currentUser?.userType !== 'admin' || !variant?.id}
                        />
                        <span className={'checkbox-label'}>{t('mainVariant')}</span>
                    </div>
                </div>
            )}

            {variants?.length > 0 && (
                <div className={'step-row'}>
                    {variants?.map((v, i) => {
                        return (
                            <div className={'variant'} key={`${v.type?.value}-${i}`}>
                                <SmartSelect
                                    required
                                    options={variantsMapped?.filter(
                                        (vm) => !variants.find((v) => v.type?.label === vm.label)
                                    )}
                                    name={`variant.variants.${i}.type`}
                                    label={t('variantType')}
                                    isDisabled={userType !== 'admin'}
                                />
                                <SmartInput
                                    required
                                    name={`variant.variants.${i}.value`}
                                    label={t('variantDescription')}
                                    disabled={userType !== 'admin'}
                                />
                                <Button
                                    theme={'secondary'}
                                    onClick={() => remove(i)}
                                    disabled={userType !== 'admin'}
                                    className={'float-button'}>
                                    <Trash width={16} height={16} />
                                </Button>
                            </div>
                        );
                    })}
                </div>
            )}

            {!!variant?.id && (
                <div className={'step-row'}>
                    <Button
                        disabled={userType !== 'admin' || !!variants?.find((va) => !va.value) || !variant?.id}
                        onClick={() => {
                            form.setValue('variant.variants', [
                                ...variants,
                                { value: '', type: { label: '', value: '' } },
                            ]);
                        }}
                        style={{ width: '182px', marginBottom: '16px' }}
                        theme={'outline-primary'}>
                        <span className={'button-center'}>
                            <Plus width={16} height={16} />
                            {t('addVariant')}
                        </span>
                    </Button>
                </div>
            )}

            <div className={'checkbox-with-label step-row'}>
                <Input
                    type={'checkbox'}
                    checked={isDigital}
                    onClick={(e: any) => {
                        if (!e.target?.checked) {
                            form.setValue('isDigital', false);
                            form.setValue('items', originalItems); // TODO use reset field?
                            form.setValue('availableItemsCount', originalAvailableItemsCount); // TODO use reset field?
                        } else {
                            form.setValue('isDigital', true);
                        }
                    }}
                    disabled={currentUser?.userType !== 'admin'}
                />
                <span className={'checkbox-label'}>{`${t('isThisADigitalProduct')} (${availableItemsCount.toString()})`}</span>
            </div>
            
            {!!isDigital && (
                <div className={'step-row'}>
                    <DigitalInventoryUpload
                    disabled={userType !== 'admin'}
                    onChange={(value: string) => {
                        form.setValue('isDigital', false);
                        form.setValue('items', originalItems);  // TODO use reset field?
                        form.setValue('availableItemsCount', originalAvailableItemsCount);  // TODO use reset field?
                    }}
                    onUpload={(file) => updateInventory(file)}
                />
                </div>
            )}

            <SmartInput disabled={userType !== 'admin'} required name={'brand_he'} label={t('brand')} />
            <SmartInput disabled={userType !== 'admin'} required name={'subtitle'} label={t('model')} />
            <div className={'step-row'}>
                <SmartInput
                    disabled={userType !== 'admin'}
                    type={'textarea'}
                    placeholder={t('productDescription')}
                    required
                    name={'summary'}
                    label={t('shortProductDescription')}
                />
            </div>
            <div className={'step-row'}>
                <ImageUpload
                    disabled={userType !== 'admin'}
                    imageURL={mainImageURL}
                    name={'mainImageURL'}
                    required
                    onChange={(value: string) => {
                        form.setValue('mainImage', value);
                        form.setValue('mainImageURL', value);
                    }}
                    defaultImage={shadow}
                    onUpload={(file) => uploadPic(file, 'mainImage', 'mainImageURL')}
                    label={t('mainImage')}
                    square
                />
            </div>
            {additionalImagesURLs?.map((ai, key) => {
                return (
                    <div key={key} className={'step-row'}>
                        <ImageUpload
                            disabled={userType !== 'admin'}
                            imageURL={additionalImagesURLs[key]?.url}
                            name={`additionalImagesURLs.${key}.url`}
                            onChange={(value: string) => {
                                if (!value) {
                                    const newImagesURLs = [...additionalImagesURLs];
                                    newImagesURLs.splice(key, 1);
                                    form.setValue(`additionalImagesURLs`, newImagesURLs);

                                    const newImages = [...additionalImages];
                                    newImages.splice(key, 1);
                                    form.setValue(`additionalImages`, newImages);
                                } else {
                                    form.setValue(`additionalImagesURLs.${key}.url`, value);
                                    form.setValue(`additionalImages.${key}`, value);
                                }
                            }}
                            defaultImage={shadow}
                            onUpload={(file) =>
                                uploadPic(file, `additionalImages.${key}`, `additionalImagesURLs.${key}.url`)
                            }
                            label={t('secondaryImage')}
                            square
                        />
                    </div>
                );
            })}
            <Button
                disabled={userType !== 'admin'}
                onClick={() => {
                    if (additionalImagesURLs.every((ai) => !!ai.url)) {
                        const images = [...additionalImagesURLs];
                        images.push({ url: '', imageURL: '' });
                        form.setValue('additionalImagesURLs', images);
                        form.setValue('additionalImages', [...additionalImages, '']);
                    }
                }}
                style={{ width: '182px' }}
                theme={'outline-primary'}>
                <span className={'button-center'}>
                    <PlusCircle width={13} height={13} />
                    {t('addImage')}
                </span>
            </Button>

            <div className={'step-row'}>
                <SmartInput disabled={userType !== 'admin'} name={'youtube'} label={t('linkToVideo')} />
            </div>

            <div className={'step-title'}>{t('priceAndDelivery')}</div>

            <SmartInput
                disabled={userType !== 'admin'}
                enforceNumbers
                required
                name={'price'}
                label={t('price')}
            />
            <SmartInput
                disabled={userType !== 'admin'}
                enforceNumbers
                name={'originalPrice'}
                label={t('prevPrice')}
            />
            <SmartInput
                disabled={userType !== 'admin'}
                enforceNumbers
                required
                name={'delivery_price'}
                label={t('deliveryPrice')}
            />

            <div className={'step-title'}>{t('specification')}</div>
            <div className={'step-row'}>
                <SmartRichText
                    required
                    disabled={userType !== 'admin'}
                    name={'specifications'}
                    maxCharacters={4000}
                />
            </div>

            <div className={'step-title'}>{t('salesConditions')}</div>
            <div className={'step-row'}>
                <SmartRichText
                    required
                    disabled={userType !== 'admin'}
                    name={'warranty'}
                    maxCharacters={4000}
                />
            </div>

            <div className={'step-title'}>{t('moreInfo')}</div>
            <div className={'step-row'}>
                <SmartRichText
                    required
                    disabled={userType !== 'admin'}
                    name={'description'}
                    maxCharacters={4000}
                />
            </div>

            <SmartInput disabled={userType !== 'admin'} enforceNumbers name={'rating'} label={t('rating')} />
            <SmartInput
                disabled={userType !== 'admin'}
                enforceNumbers
                name={'ratingCount'}
                label={t('ratersCount')}
            />
            <div style={{ display: 'flex' }} className={'step-row checkbox-with-label'}>
                <SmartInput disabled={userType !== 'admin'} name={'availabilitySupplier'} type={'checkbox'} />
                <span className={'checkbox-label'}>{t('availability')}</span>
            </div>

            <div className={'inputs-separator'} />

            {renderButtons()}
        </div>
    );
};

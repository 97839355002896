import { FC } from 'react';
import { AlertCircle, ArrowLeft, ArrowRight, CheckCircle, Mail, Save } from 'react-feather';

export type IconTheme = 'ALERT' | 'SAVE' | 'CHECK' | 'ARROW_LEFT' | 'ARROW_RIGHT' | 'MAIL';

export type ModalIconProps = {
    theme: IconTheme;
    size?: number;
    color?: string;
};

export const ModalIcon: FC<ModalIconProps> = ({ theme, size, color }) => {
    switch (theme) {
        case 'ALERT':
            return <AlertCircle color={color ?? 'var(--red)'} height={size ?? 72} width={size ?? 72} />;
        case 'MAIL':
            return <Mail color={color ?? 'var(--primary)'} height={size ?? 72} width={size ?? 72} />;
        case 'SAVE':
            return <Save color={color ?? 'var(--white)'} height={size ?? 14} width={size ?? 14} />;
        case 'CHECK':
            return <CheckCircle color={color ?? 'var(--white)'} height={size ?? 14} width={size ?? 14} />;
        case 'ARROW_LEFT':
            return <ArrowLeft color={color ?? 'var(--white)'} height={size ?? 14} width={size ?? 14} />;
        case 'ARROW_RIGHT':
            return <ArrowRight color={color ?? 'var(--white)'} height={size ?? 14} width={size ?? 14} />;
        default:
            return null;
    }
};

export const formatUnixTimestamp = (timestamp: string | number | undefined | null): string => {
    if (!timestamp) return '';

    const date = new Date(+timestamp);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();

    const pad = (num: number): string => num.toString().padStart(2, '0');

    return `${pad(day)}/${pad(month)}/${year}`;
};

export const formatTimestamp = (timestamp: number): string => {
    const formattedDate = formatUnixTimestamp(timestamp);
    const formattedTime = getCurrentTime(timestamp);

    return `${formattedDate}, ${formattedTime}`;
};

export const getCurrentTime = (timestamp: number) => {
    const date = new Date(timestamp);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${hours}:${minutes}`;
};

export const getCurrentDate = (timestamp: number) => {
    return formatUnixTimestamp(timestamp);
};

export const getToBePublishedAtTimestamp = (date: Date | undefined, time: string | undefined) => {
    if (!date) return undefined;

    if (time) {
        const hoursAndMinutes = time?.split(':').map(Number);

        if (hoursAndMinutes) {
            date.setHours(+hoursAndMinutes[0]);
            date.setMinutes(+hoursAndMinutes[1]);
        }
    }

    return date.getTime();
};

export const formatter = new Intl.NumberFormat('he-IL', {
    style: 'currency',
    currency: 'ILS',
    maximumFractionDigits: 0,
});

export const getImageURL = (imagePath: string | undefined) => {
    if (!imagePath) return undefined;

    if (isValidUrl(imagePath)) {
        return imagePath;
    } else {
        const urlPrefix = process.env.REACT_APP_ASSETS;
        return `${urlPrefix}/sm/${imagePath}`;
    }
};

export const isValidUrl = (urlString: string) => {
    const urlPattern = /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,}(\/.*)?$/i;

    return urlPattern.test(urlString);
};

import { Button } from '../form/buttons/Button';
import { imageFormats } from '../users/users.models';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { ChangeEvent, CSSProperties, FC, useRef, useState } from 'react';
import './image-upload.scss';
import { ErrorToast } from '../toaster/Toast';
import { useTranslation } from 'react-i18next';
import { getImageURL } from '../../utils/utils';

export const DEFAULT_FILE_SIZE = 10; // MB
export const DEFAULT_ACCEPTED_FILES_TYPES = '.JPEG, .PNG, .JPG';

export type ImageUploadProps = {
    imageURL: string | undefined;
    name: string;
    onChange: (url: string) => void;
    label?: string;
    defaultImage?: string;
    onUpload?: (file: File) => void;
    required?: boolean;
    direction?: 'row' | 'column';
    disabled?: boolean;
    square?: boolean;
    style?: CSSProperties;
};

export const ImageUpload: FC<ImageUploadProps> = ({
    onChange,
    imageURL,
    label,
    onUpload,
    name,
    defaultImage,
    required,
    disabled,
    square,
    direction = 'row',
    style,
}) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [key, setKey] = useState(0);
    const { t } = useTranslation();

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const imageSizeIsValid = (selectedFileSize: number) => {
        return selectedFileSize <= DEFAULT_FILE_SIZE;
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setKey(key + 1);
        const file = event?.target?.files?.[0];
        const fileName = file?.name.toLowerCase();

        const selectedFileSize = (file?.size ?? 0) / 1024 / 1024; // MB
        if (
            !!file &&
            imageSizeIsValid(selectedFileSize) &&
            imageFormats.some((format) => fileName?.endsWith(format))
        ) {
            onUpload?.(file);
        } else {
            ErrorToast(
                `There was an error uploading the picture. Make sure it's of the right format and not too big`
            );
        }
    };

    return (
        <div className={'image-section'}>
            {(imageURL || required) && (
                <span style={{ display: 'flex' }}>
                    <span style={{ ...style, padding: '10px' }}>
                        {imageURL && (
                            <img
                                src={getImageURL(imageURL) ?? defaultImage ?? ''}
                                alt={''}
                                className={`img-profile ${square ? 'square-image' : ''}`}
                            />
                        )}
                    </span>
                    {required && <span className={'required'}>*</span>}
                </span>
            )}
            <div className={'image-upload'}>
                <div className={'upload-buttons'}>
                    {label ?? ''}
                    <input
                        disabled={disabled}
                        key={key}
                        type="file"
                        accept={DEFAULT_ACCEPTED_FILES_TYPES}
                        onChange={(e) => handleChange(e)}
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                    />
                    <span
                        style={{
                            display: 'flex',
                            flexDirection: direction,
                            gap: '8px',
                            alignItems: 'center',
                        }}>
                        <span style={{ display: 'flex', gap: '16px' }}>
                            <Button
                                disabled={disabled}
                                theme={'primary'}
                                onClick={handleUploadClick}
                                className={'mini'}>
                                {t('upload')}
                            </Button>
                            <Button
                                disabled={disabled}
                                theme={'secondary'}
                                onClick={() => onChange('')}
                                className={'mini'}>
                                {t('delete')}
                            </Button>
                        </span>
                        {`${t('imagesInFormat')} ${imageFormats.join(', ')}`}
                    </span>
                </div>
                <SmartInput
                    disabled={disabled}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                    label={''}
                    name={name}
                    placeholder={t('orLinkURLToImage')}
                    className="w-100"
                />
            </div>
        </div>
    );
};

import { FC } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { useTranslation } from 'react-i18next';

type CreateListItemProps = {
    form: UseFormReturn<{ createdListItemName: string }>;
    name: string;
    onKeyDown?: (e: KeyboardEvent) => void;
};

export const CreateListItem: FC<CreateListItemProps> = ({ form, name, onKeyDown }) => {
    const { t } = useTranslation();

    return (
        <FormProvider {...form}>
            <SmartInput
                required
                label={t('listItemName')}
                placeholder={t('listItemName')}
                name={name}
                onKeyDown={onKeyDown}
                containerClass={`position-relative size-default container-input`}
            />
        </FormProvider>
    );
};

import { FC, useEffect, useState } from 'react';
import './orders.scss';
import { useSelector } from 'react-redux';
import { Home } from 'react-feather';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { useNavigate } from 'react-router-dom';
import { OrderPage } from './OrderPage';
import { useHandleBack } from '../../hooks/useHandleBack';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getInternalOrderStatusesAPI, getOrderStatusesAPI } from '../../api/store.api';
import { OpenTransactions } from './OpenTransactions';
import { OrdersManagement } from './OrderManagement';
import { Tabs } from '../tabs/Tabs';
import { useParams } from 'react-router';

export const Orders: FC = () => {
    const { t } = useTranslation();
    const handleBack = useHandleBack(false);
    const [tabs] = useState(['allOrders', 'openOrders']);
    const currentUser = useSelector(getCurrentUser);
    const [breadCrumbs, setBreadCrumbs] = useState([t('ordersManagement')]);
    const [currentStep, setCurrentStep] = useState(1);
    const navigate = useNavigate();
    const { order } = useParams();
    const [purchaseID, setPurchaseID] = useState<string>();
    const { data: orderStatuses } = useQuery({
        queryKey: ['orderStatusesOptions'],
        queryFn: getOrderStatusesAPI,
    });
    const { data: internalOrderStatuses } = useQuery({
        queryKey: ['internalOrderStatusesOptions'],
        queryFn: getInternalOrderStatusesAPI,
    });

    // useEffect(() => {
    //     console.log(searchParams.get('tab'));
    // }, [searchParams]);

    useEffect(() => {
        if (currentUser?.userType !== 'admin') {
            navigate('/');
        }
    }, [currentUser?.userType, navigate]);

    // useEffect(() => {
    //     console.log(type);
    //     if (!type || type === 'all') {
    //         setBreadCrumbs([t('orderManagement')]);
    //         setCurrentStep(1);
    //     } else if (type === 'open') {
    //         setBreadCrumbs([t('openTransactions')]);
    //         setCurrentStep(2);
    //     } else if (type === 'order') {
    //         setBreadCrumbs([t('editOrder')]);
    //         setCurrentStep(3);
    //     }
    // }, [type]);

    const onEditOrder = (orderID: string) => {
        setPurchaseID(orderID);
        setCurrentStep(3);
        setBreadCrumbs([t('orderManagement')]);
    };

    useEffect(() => {
        if (order) {
            onEditOrder(order);
        }
    }, [order]);

    const onTabChange = (tabIndex: number) => {
        // console.log(tabIndex);
        // if (tabIndex === 1) {
        //     setSearchParam({ tab: 'all' });
        // } else if (tabIndex === 2) {
        //     setSearchParam({ tab: 'open' });
        // } else {
        //     setSearchParam({ tab: 'order' });
        // }
        setCurrentStep(tabIndex);
    };

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <OrdersManagement
                        orderStatuses={orderStatuses ?? []}
                        internalOrderStatuses={internalOrderStatuses ?? []}
                    />
                );
            case 2:
                return <OpenTransactions />;
            case 3:
                return (
                    <OrderPage
                        // purchaseID={purchaseID}
                        onStageReset={onStageReset}
                        orderStatuses={orderStatuses ?? []}
                        internalOrderStatuses={internalOrderStatuses ?? []}
                    />
                );
        }
    };

    const onStageReset = () => {
        setCurrentStep(1);
        setBreadCrumbs([t('ordersManagement')]);
        setPurchaseID(undefined);
        navigate('/orders');
    };

    return (
        <div className={'orders'}>
            <BreadCrumbs
                breadCrumbTitle={t('ordersManagement')}
                icon={<Home width={13} height={13} color={'var(--primary)'} />}
                breadCrumbs={breadCrumbs}
                onBack={() => handleBack(onStageReset, onStageReset)}
            />
            {currentStep <= 2 && (
                <div className={'steps-tabs'}>
                    <Tabs activeTab={currentStep} toggleTab={onTabChange} tabs={tabs} />
                </div>
            )}
            {renderStep()}
        </div>
    );
};

import { Label, Input as InputStrap, InputProps as ScrapInputProps } from 'reactstrap';
import './input.scss';
import { ForwardedRef, FC } from 'react';

export type InputProps = ScrapInputProps & {
    inputRef?: ForwardedRef<HTMLInputElement | HTMLTextAreaElement>;
    containerClass?: string;
    errorMessage?: string;
    hideError?: boolean;
};

export const Input: FC<InputProps> = ({
    type = 'text',
    label,
    inputRef,
    containerClass,
    className,
    children,
    errorMessage,
    hideError,
    ...rest
}) => {
    return (
        <div className={`${containerClass} ${type === 'checkbox' ? 'checkbox-wrapper' : 'input-wrapper'}`}>
            {label && (
                <Label className="form-label" for="basicInput">
                    {label}
                    {rest.required && <span className={'required'}>*</span>}
                </Label>
            )}
            <InputStrap
                {...rest}
                innerRef={inputRef}
                className={`input ${className} ${type === 'switch' && 'form-switch'} ${
                    type === 'checkbox' && 'form-checkbox'
                } ${type === 'number' && 'input-number'} ${type === 'textarea' && 'textarea'}`}
                type={type}
                rows={4}
                autoComplete={'off'}
                id="basicInput">
                {children}
            </InputStrap>
            {!hideError && <label className="error-label">{errorMessage}</label>}
        </div>
    );
};

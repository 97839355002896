import { API } from './api';
import { GetInstances, GetResponse } from './api.models';
import { User } from '../components/users/users.models';
import { Group, GroupMemberStatus } from '../models/groups.models';

export const getGroupsAPI = async <T>(getBy: GetInstances<T>): Promise<GetResponse & { data: Group[] }> => {
    return API.get({ url: `groups`, params: getBy });
};

export const getCoreGroupAPI = async (companyID: string): Promise<Group> => {
    return API.get({ url: `groups/default/${companyID}` });
};

export const createCompanyGroupAPI = async (group: Group, members: string[]): Promise<Group> => {
    return API.post({
        url: `groups/company`,
        body: { group, members },
    });
};

export const editCompanyGroupAPI = async (group: Group, members: string[]): Promise<Group> => {
    return API.put({
        url: `groups/company`,
        body: { group, members },
    });
};

export const getGroupMembersAPI = async (groupID: string): Promise<GetResponse & { data: User[] }> => {
    return API.get({ url: `groups/members/${groupID}` });
};

export const getMemberGroups = async (userID: string): Promise<GroupMemberStatus[]> => {
    return API.get({ url: `groups/members/groups/${userID}` });
};

export const deleteGroup = async (groupID: string): Promise<void> => {
    return API.delete({ url: `groups/${groupID}` });
};

export const getGroupsCategoriesAPI = async (): Promise<{ categories: string[] }> => {
    return API.get({ url: 'groups/interests' });
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { SuccessToast } from '../toaster/Toast';
import { CreateHashtag } from './CreateHashtag';
import { InnerModal } from '../modal/InnerModal/InnerModal';
import { Modal } from './Modal';
import { createHashtagAPI } from '../../api/hashtags.api';
import { useHandleBack } from '../../hooks/useHandleBack';

type CreateHashtagModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    companyID: string;
    refetchHashtags: () => void;
};

export const CreateHashtagModal: FC<CreateHashtagModalProps> = ({
    isOpen,
    closeModal,
    companyID,
    refetchHashtags,
}) => {
    const createHashtagForm = useForm<{ createdHashTagName: string }>({
        mode: 'onTouched',
        defaultValues: { createdHashTagName: '' },
    });
    const { isDirty } = createHashtagForm.formState;
    const handleBack = useHandleBack(isDirty);
    const { t } = useTranslation();

    const confirmCreateHashtag = async (companyID: string): Promise<void> => {
        const createdHashTagName = createHashtagForm.getValues('createdHashTagName');

        if (!createdHashTagName) return;

        await createHashtagAPI(
            createdHashTagName.trim().replaceAll(/\s+/g, ' ').replaceAll(' ', '_').replaceAll('#', ''),
            companyID
        ).then(() => {
            SuccessToast(t('hashtagSuccessfullyCreated'));
            closeModal();
            refetchHashtags();
        });
    };

    const handleClose = () => {
        handleBack(onClose, onClose);
    };

    const onClose = () => {
        closeModal();
        createHashtagForm.reset({ createdHashTagName: '' });
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <InnerModal
                header={{ title: t('creationOfNewHashtag') }}
                content={
                    <CreateHashtag
                        form={createHashtagForm}
                        name={'createdHashTagName'}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                confirmCreateHashtag(companyID);
                                createHashtagForm.reset();
                            }
                        }}
                    />
                }
                footer={{
                    position: 'left',
                    buttons: [
                        {
                            label: t('close'),
                            theme: 'grey',
                            callback: handleClose,
                        },
                        {
                            label: t('save'),
                            callback: () => {
                                confirmCreateHashtag(companyID);
                                createHashtagForm.reset();
                            },
                            theme: 'primary',
                        },
                    ],
                }}
            />
        </Modal>
    );
};

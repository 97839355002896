import { FC } from 'react';
import './chip.scss';
import { Badge } from 'reactstrap';

type ChipProps = {
    variant: ChipVariant;
    label: string;
};

export type ChipVariant = 'success' | 'danger' | 'warning';

export const Chip: FC<ChipProps> = ({ variant, label }) => {
    return (
        <Badge className={`chip ${variant}`} color={variant} pill>
            {label}
        </Badge>
    );
};

import { FC, ReactNode } from 'react';
import ReactModal from 'react-modal';
import './modals.scss';

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
};

export const Modal: FC<ModalProps> = ({ isOpen, onClose, children }) => {
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="modal"
            ariaHideApp={false}
            overlayClassName="modal-overlay">
            {children}
        </ReactModal>
    );
};

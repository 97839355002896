import { Post } from '../components/home/home.models';
import { API } from './api';
import { BoostsAndGiftsReport, Transaction } from '../components/reports/reports.models';

export const getPostsReportAPI = async (getBy: any): Promise<Post[]> => {
    return API.get({ url: `reports/posts`, params: getBy });
};

export const getPurchasesReportAPI = async (getBy: any): Promise<Post[]> => {
    return API.get({ url: `reports/purchases`, params: getBy });
};

export const getBoostsAndGiftsReportAPI = async (getBy: any): Promise<BoostsAndGiftsReport[]> => {
    return API.get({ url: `reports/boosts-and-gifts`, params: getBy });
};

export const getTransactionsReportAPI = async (getBy: any): Promise<Transaction[]> => {
    return API.get({ url: `reports/transactions`, params: getBy });
};

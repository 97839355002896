import { FC, ReactNode } from 'react';
import { SmartSelect } from '../form/select/smart-select';
import './table.scss';
import { POSSIBLE_PER_PAGE_VALUES } from '../users/users.models';
import { Select } from '../form/select/select';
import { useTranslation } from 'react-i18next';

interface TableHeaderProps {
    leftSideComponents?: ReactNode[];
    rightSideComponents?: ReactNode[];
    rowsPerPage?: number;
    onChangeRowsPerPage?: (val: { value: number }) => void;
}

export const ROWS_PER_PAGE: POSSIBLE_PER_PAGE_VALUES[] = [25, 50, 100];

export const TableHeader: FC<TableHeaderProps> = ({
    rowsPerPage,
    rightSideComponents,
    leftSideComponents,
    onChangeRowsPerPage,
}) => {
    const { t } = useTranslation();

    return (
        <div className="table-header">
            <div className={'rows-per-page'} style={{ gap: '26px' }}>
                {rowsPerPage !== undefined && (
                    <span style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                        {t('show')}
                        {onChangeRowsPerPage ? (
                            <Select
                                label={''}
                                onChange={(e) => onChangeRowsPerPage(e as { value: number })}
                                value={rowsPerPage}
                                placeholder={rowsPerPage}
                                options={ROWS_PER_PAGE.map((r) => ({ label: r, value: r }))}
                            />
                        ) : (
                            <SmartSelect
                                label={''}
                                name="rowsPerPage"
                                placeholder={rowsPerPage}
                                hideError={true}
                                options={ROWS_PER_PAGE.map((r) => ({ label: r, value: r }))}
                            />
                        )}
                    </span>
                )}
                <div className={'components'}>{rightSideComponents}</div>
            </div>
            <div className={'components'}>{leftSideComponents}</div>
        </div>
    );
};

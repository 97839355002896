import { FC } from 'react';
import { RadioOption } from '../form/radio/smart-radio-button';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { Button } from '../form/buttons/Button';
import { Trash } from 'react-feather';
import './unique-fields.scss';
import { useTranslation } from 'react-i18next';

type UniqueFieldProps = {
    fieldNames: { fieldName: string; isDisabled?: boolean; label?: string }[];
    publicityName: string;
    onRemove: () => void;
};

export const UniqueField: FC<UniqueFieldProps> = ({ fieldNames, publicityName, onRemove }) => {
    const { t } = useTranslation();

    return (
        <div className={'step'}>
            {fieldNames.map((field, i) => {
                return (
                    <div key={i} className={'unique-field-with-publicity'} style={{ position: 'relative' }}>
                        <SmartInput
                            label={field.label ?? t('fieldName')}
                            name={field.fieldName}
                            disabled={field.isDisabled}
                        />
                        {i === 0 ? (
                            <RadioOption name={publicityName} option1={t('private')} option2={t('public')} />
                        ) : (
                            i === fieldNames.length - 1 && (
                                <Button theme={'secondary'} onClick={onRemove} className={'delete-field'}>
                                    <Trash width={16} height={16} />
                                </Button>
                            )
                        )}
                    </div>
                );
            })}
        </div>
    );
};

import { formatTimestamp, formatUnixTimestamp, getImageURL } from '../../utils/utils';
import { Edit2, RotateCw, XCircle } from 'react-feather';
import { ExcelPurchase, OpenTransactionTableData, OrderTableData, Purchase } from './order.models';
import { Link } from 'react-router-dom';
import { Chip, ChipVariant } from '../chip/Chip';
import { TFunction } from 'i18next';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { GetInstances } from '../../api/api.models';
import { getPurchasesAPI } from '../../api/store.api';
import { GiftDetails } from '../home/home.models';

export const populatePurchasesData = (
    purchases: Purchase[],
    handleRefundPurchase: (orderID: string) => void,
    t: TFunction<'translation'>
) => {
    return purchases?.map<OrderTableData>((purchase) => {
        const {
            purchaseID,
            userID,
            companyID,
            purchaseStatus,
            internalPurchaseStatus,
            statusUpdateTimeStamp,
            timestamp,
            moneyAmount,
            creditsAmount,
            companyName,
            userFullName,
            userPhone,
            product,
            hasBeenRefunded,
        } = purchase;

        return {
            image: (
                <Link to={`/store/${product.productID}`} style={{ textDecoration: 'none' }}>
                    <img
                        src={getImageURL(product.mainImage)}
                        style={{ cursor: 'pointer' }}
                        width={32}
                        alt={''}
                    />
                </Link>
            ),
            id: purchaseID,
            fullName: (
                <Link to={`/users/${userID}`} style={{ textDecoration: 'none' }}>
                    {userFullName ?? ''}
                </Link>
            ),
            phone: userPhone ?? '',
            productName: product.productName ?? '',
            companyName: (
                <Link to={`/companies/${companyID}`} style={{ textDecoration: 'none' }}>
                    {companyName ?? ''}
                </Link>
            ),
            credits: `${moneyAmount ?? 0} ${t('ILS')}, ${creditsAmount ?? 0} ${t('points')}`,
            timestamp: formatUnixTimestamp(timestamp) ?? '',
            statusUpdateTimeStamp: formatUnixTimestamp(statusUpdateTimeStamp) ?? '',
            status: <Chip variant={statusVariant[purchaseStatus]} label={t(purchaseStatus)} />,
            internalStatus: (
                <Chip variant={statusVariant[internalPurchaseStatus]} label={t(internalPurchaseStatus)} />
            ),
            edit: (
                <span className="text-capitalize table-text-icon" style={{ textAlign: 'left' }}>
                    {!hasBeenRefunded && !['delivered', 'returned'].includes(purchaseStatus) && (
                        <RotateCw onClick={() => handleRefundPurchase(purchaseID)} width={20} />
                    )}
                    <Edit2 width={20} />
                </span>
            ),
        };
    });
};

export const statusVariant: Record<string, ChipVariant> = {
    error: 'danger',
    delivered: 'success',
    placed: 'success',
    pending: 'warning',
    notInStock: 'danger',
    passedToSupplier: 'success',
    onDelivery: 'success',
    returned: 'warning',
    refunded: 'warning',
    closedWithSupplier: 'success',
    closedWithEmployer: 'success',
};

export const exportOrders = async (params: GetInstances<Purchase>) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');
    worksheet.views = [{ rightToLeft: true }];

    const purchases = await getPurchasesAPI(params);
    const excelPurchases: Partial<ExcelPurchase>[] = transformPurchasesToExcelPurchases(purchases?.data);

    if (excelPurchases?.[0]) {
        worksheet.addRow(Object.keys(excelPurchases[0]));
    }

    excelPurchases.forEach((item) => worksheet.addRow(Object.values(item)));

    worksheet.columns.forEach((column) => {
        column.width = 20;
    });

    const csvData = await workbook.csv.writeBuffer();
    const csvBlob = new Blob(['\uFEFF' + csvData], {
        type: 'text/csv; charset=utf-8',
    });
    saveAs(csvBlob, `orders.csv`);
};

export const transformPurchasesToExcelPurchases = (purchases: Purchase[]): Partial<ExcelPurchase>[] => {
    return purchases.map<ExcelPurchase>((purchase) => {
        return {
            Purchase_ID: purchase.purchaseID,
            Company_Name: purchase.companyName ?? '',
            User_Name: purchase.userFullName ?? '',
            Product_Name: purchase.product?.productName,
            Product_ID: purchase.product?.productID,
            Purchase_Time: formatTimestamp(purchase.timestamp),
            Status_Update_Time: formatTimestamp(purchase.statusUpdateTimeStamp),
            Money_Amount: purchase.moneyAmount,
            Credits_Amount: purchase.creditsAmount,
            Purchase_Status: purchase.purchaseStatus,
            Internal_Purchase_Status: purchase.internalPurchaseStatus,
            Address: purchase.address,
            Company_ID: purchase.companyID,
            User_ID: purchase.userID,
        };
    });
};

export const populateOpenTransactionsData = (
    openTransactions: GiftDetails[],
    handleCancelTransaction: (giftDetailsID: string[]) => void,
    t: TFunction<'translation'>
) => {
    return openTransactions?.map<OpenTransactionTableData>((giftDetails) => {
        const { giftDetailsID, fromUser, toUser, productDetails, creditsAmount, timestamp, boostType } =
            giftDetails;

        return {
            image: productDetails?.productImageURL ? (
                // <Link to={`/store/${product.productID}`} style={{ textDecoration: 'none' }}>
                <img
                    src={getImageURL(productDetails?.productImageURL)}
                    // style={{ cursor: 'pointer' }}
                    width={32}
                    alt={''}
                />
            ) : (
                <div></div>
            ),
            productName: productDetails?.productID
                ? productDetails?.productName
                : creditsAmount
                ? `${creditsAmount} קרדיטים `
                : boostType === 'compliment'
                ? 'מילה טובה'
                : '',
            id: giftDetailsID,
            boostType: boostType ? 'בוסט' : 'מתנה',
            from: fromUser
                ? `${fromUser.firstName ?? ''} ${fromUser.lastName} - ${fromUser.phone?.value ?? ''}`
                : '',
            to: toUser
                ? `${toUser.firstName ?? ''} ${toUser.lastName ?? ''} - ${toUser.phone?.value ?? ''}`
                : '',
            companyName: toUser?.companyName ?? fromUser?.companyName ?? '',
            credits: `${creditsAmount ?? 0} ${t('points')}`,
            timestamp: formatUnixTimestamp(timestamp) ?? '',
            edit: (
                <span className="text-capitalize table-text-icon" style={{ textAlign: 'left' }}>
                    <XCircle onClick={() => handleCancelTransaction([giftDetailsID])} width={20} />
                </span>
            ),
        };
    });
};

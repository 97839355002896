import {  useFormContext } from 'react-hook-form';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { useHandleBack } from '../../hooks/useHandleBack';
import { Supplier, SupplierForm } from './store.models';
import { useTranslation } from 'react-i18next';
import { Button } from '../form/buttons/Button';
import { Save } from 'react-feather';
import { closeModal, raiseModal } from '../../store/modal/modal.actions';
import { ModalIcon } from '../modal/ModalIcon';
import { SuccessToast } from '../toaster/Toast';
import { createOrEditSupplierAPI, getSuppliersAPIServicesAPI } from '../../api/store.api';
import { useQuery } from 'react-query';
import { SmartSelect } from '../form/select/smart-select';

type StoreSuppliersStepProps = {
    onStageReset: () => void;
};

export const StoreSuppliersStep: FC<StoreSuppliersStepProps> = ({ onStageReset }) => {
    const { formState, trigger, getValues } = useFormContext<SupplierForm>();
    const { isDirty } = formState;
    const handleBack = useHandleBack(isDirty);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { data: services } = useQuery({
        queryKey: ['suppliersApiServices'],
        queryFn: async () => {
            const data = await getSuppliersAPIServicesAPI();
            return data?.map((s) => s.name);
        },
        keepPreviousData: true,
    });

    const handleSave = async () => {
        const supplierForm = getValues();
        const supplier: Supplier = {
            ...supplierForm,
            APIService: supplierForm.APIService?.value,
        };

        await trigger().then((value) => {
            if (!value) {
                dispatch(
                    raiseModal({
                        isOpen: true,
                        content: t('mustFillMandatoryFields'),
                        header: {
                            icon: <ModalIcon theme={'ALERT'} />,
                            title: t('save'),
                        },
                        footer: {
                            buttons: [
                                { label: t('close'), theme: 'grey', callback: () => dispatch(closeModal()) },
                            ],
                        },
                    })
                );

                return;
            } else {
                createOrEditSupplierAPI(supplier)
                    .then(() => {
                        SuccessToast(t('actionSucceeded'));
                        onStageReset();
                    })
                    .catch((e) => console.log(e));
            }
        });
    };

    return (
        <div className={'step'}>
            <div className={'step-title'}>{t('supplierDetails')}</div>
            <div className={'step-row'}>
                <SmartInput
                    required
                    name={'supplierName'}
                    label={t('supplierName')}
                    placeholder={t('supplierName')}
                />
            </div>
            <SmartInput required name={'APILink'} label={t('APILink')} placeholder={t('APILink')} />
            <SmartSelect
                options={services?.map((s) => ({ label: s, value: s }))}
                required
                name={'APIService'}
                placeholder={t('showAll')}
                label={t('APIService')}
            />
            <SmartInput
                required
                name={'contactName'}
                label={t('supplierContact')}
                placeholder={t('supplierContact')}
            />
            <SmartInput
                required
                name={'contactPhone'}
                enforceNumbers
                label={t('supplierPhone')}
                placeholder={t('supplierPhone')}
            />
            <SmartInput
                required
                name={'contactEmail'}
                label={t('contactEmail')}
                placeholder={t('contactEmail')}
            />

            <div className={'step-row'}>
                <div className={'step-footer'}>
                    <div className={'inputs-separator'} />
                    <div className={'save-and-cancel'}>
                        <Button theme={'grey'} onClick={() => handleBack(onStageReset, onStageReset)}>
                            {t('cancel')}
                        </Button>
                        <Button theme={'primary'} onClick={handleSave} icon={<Save size={14} />}>
                            {t('save')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

import { TFunction } from 'i18next';
import { FiltersModels } from '../filters/filters.models';
import { getImageURL } from '../../utils/utils';
import { Tabs } from '../tabs/Tabs';
import { FilterWithSteps } from './dashboard.models';

export const getDashboardFilters = (
    onFilter: () => void,
    step: number,
    selectedTab: number,
    setSelectedTab: (tabIndex: number) => void,
    t: TFunction<'translation'>
): FilterWithSteps[] => {
    const filters: FilterWithSteps[] = [
        {
            type: FiltersModels.CUSTOM,
            component: (
                <Tabs
                    activeTab={selectedTab + 1}
                    toggleTab={(newTab: number) => setSelectedTab(newTab - 1)}
                    tabs={['7 ימים אחרונים', '30 ימים אחרונים', '12 חודשים אחרונים']}
                />
            ),
            steps: [0, 1, 2, 3],
        },
        {
            type: FiltersModels.DATE,
            name: 'fromTimestamp',
            label: t('fromDate'),
            placeholder: t('fromDate'),
            steps: [0, 1, 2, 3],
        },
        {
            type: FiltersModels.DATE,
            name: 'toTimestamp',
            label: t('toDate'),
            placeholder: t('toDate'),
            steps: [0, 1, 2, 3],
        },
        // {
        //     type: FiltersModels.SELECT,
        //     name: 'gender',
        //     placeholder: t('gender'),
        //     label: t('gender'),
        //     options: [
        //         { label: t('male'), value: 'male' },
        //         { label: t('female'), value: 'female' },
        //     ],
        //     steps: [0, 1, 2, 3],
        // },
        // {
        //     type: FiltersModels.DATE,
        //     name: 'ageFromTimestamp',
        //     label: t('ageFromDate'),
        //     placeholder: t('fromDate'),
        //     steps: [0, 1, 2, 3],
        // },
        // {
        //     type: FiltersModels.DATE,
        //     name: 'ageToTimestamp',
        //     label: t('ageToDate'),
        //     placeholder: t('toDate'),
        //     steps: [0, 1, 2, 3],
        // },
        // {
        //     type: FiltersModels.SEPARATOR,
        //     steps: [0, 1, 2, 3],
        // },
        // {
        //     type: FiltersModels.DATE,
        //     name: 'fromTimestampRef',
        //     label: t('fromDate'),
        //     placeholder: t('fromDate'),
        //     steps: [0, 1, 2, 3],
        // },
        // {
        //     type: FiltersModels.DATE,
        //     name: 'toTimestampRef',
        //     label: t('toDate'),
        //     placeholder: t('toDate'),
        //     steps: [0, 1, 2, 3],
        // },
        {
            type: FiltersModels.BUTTON,
            label: t('filter'),
            onClick: onFilter,
            steps: [0, 1, 2, 3],
            //icon: <Download width={14} />,
            // steps: [0, 1, 2, 3],
        },
    ];

    return filters.filter((a) => (a.steps ?? [])?.includes(step)) ?? [];
};

export function summarizePostsByDate(posts: any[]) {
    const statsByDate: any = {};

    posts.forEach((post: { timestamp: any; likes: any[]; replies: { data: any[] } }) => {
        const postDate = formatDate(post.timestamp);

        if (!statsByDate[postDate]) {
            statsByDate[postDate] = { date: postDate, posts: 0, likes: 0, replies: 0 };
        }

        statsByDate[postDate].posts++;

        post.likes.forEach((like: { timestamp: any }) => {
            const likeDate = formatDate(like.timestamp || post.timestamp);
            if (!statsByDate[likeDate]) {
                statsByDate[likeDate] = { date: likeDate, posts: 0, likes: 0, replies: 0 };
            }
            statsByDate[likeDate].likes++;
        });

        post.replies.data.forEach((reply: { timestamp: any }) => {
            const replyDate = formatDate(reply.timestamp || post.timestamp);
            if (!statsByDate[replyDate]) {
                statsByDate[replyDate] = { date: replyDate, posts: 0, likes: 0, replies: 0 };
            }
            statsByDate[replyDate].replies++;
        });
    });

    return Object.values(statsByDate);
}

export function sumPostsLikesReplies(posts: any[]) {
    let totalPosts = posts.length;
    let totalLikes = 0;
    let totalReplies = 0;

    posts.forEach((post) => {
        totalLikes += post.likes.length;
        totalReplies += post.replies.data.length;
    });

    return [
        { name: 'posts', value: totalPosts },
        { name: 'likes', value: totalLikes },
        { name: 'replies', value: totalReplies },
    ];
}

export function aggregateByMonth(filledData: any[], maxEntries = 60) {
    if (filledData.length <= maxEntries) {
        return filledData;
    }

    const monthlyData: any = {};

    filledData.forEach(
        (entry: {
            date: { split: (arg0: string) => [any, any, any] };
            posts: any;
            likes: any;
            replies: any;
        }) => {
            const [day, month, year] = entry.date.split('/');
            const monthKey = `${month}/${year}`;

            if (!monthlyData[monthKey]) {
                monthlyData[monthKey] = { date: monthKey, posts: 0, likes: 0, replies: 0 };
            }

            monthlyData[monthKey].posts += entry.posts;
            monthlyData[monthKey].likes += entry.likes;
            monthlyData[monthKey].replies += entry.replies;
        }
    );

    return Object.values(monthlyData);
}

export const radiusForTopVisibleBar = (data: any, stackKeys: any) => {
    const radiusConfig = data.map((entry: any) => {
        let config = stackKeys.map(() => [0, 0, 0, 0]);

        // Determine the topmost visible stack item
        for (let i = stackKeys.length - 1; i >= 0; i--) {
            if (entry[stackKeys[i]] > 0) {
                config[i] = [10, 10, 0, 0];
                break; // Stop at the first visible from the top
            }
        }
        return config;
    });

    return radiusConfig;
};

export function getTopProducts(products: any[], topN?: number) {
    const productMap = new Map();

    products.forEach((item: { product: { productName: any; quantity: any; mainImage: any } }) => {
        const productName = item.product.productName;
        const quantity = item.product.quantity;
        const mainImage = getImageURL(item.product.mainImage);

        if (productMap.has(productName)) {
            productMap.set(productName, {
                quantity: productMap.get(productName).quantity + quantity,
                mainImage: productMap.get(productName).mainImage,
            });
        } else {
            productMap.set(productName, { quantity, mainImage });
        }
    });

    const sortedProducts = Array.from(productMap, ([name, data]) => ({
        name,
        amount: data.quantity,
        mainImage: data.mainImage,
    })).sort((a, b) => b.amount - a.amount);

    if (topN) {
        return sortedProducts.slice(0, topN);
    }

    return sortedProducts;
}

export function formatDate(timestamp: number | string | Date): string {
    const date = new Date(timestamp);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${date.getFullYear()}`;
}

export const getDateRange = (tabIndex: number): { fromTimestamp: number; toTimestamp: number } => {
    const toTimestamp = new Date();
    const fromTimestamp = new Date();

    switch (tabIndex) {
        case 0: // Last 7 days
            fromTimestamp.setDate(toTimestamp.getDate() - 7);
            break;
        case 1: // Last 30 days
            fromTimestamp.setDate(toTimestamp.getDate() - 30);
            break;
        case 2: // Last year
            fromTimestamp.setFullYear(toTimestamp.getFullYear() - 1);
            break;
        default:
            break;
    }
    return {
        fromTimestamp: fromTimestamp.getTime(),
        toTimestamp: toTimestamp.getTime(),
    };
};

export function fillInMissingDates(
    summary: any[],
    fromTimestamp: string | number | Date,
    toTimestamp: string | number | Date
) {
    const fromDate = new Date(fromTimestamp);
    const toDate = new Date(toTimestamp);
    const filledSummary = [];

    const summaryMap: any = {};
    summary.forEach((item: { date: string | number }) => {
        summaryMap[item.date] = item;
    });

    for (let date = new Date(fromDate); date <= toDate; date.setDate(date.getDate() + 1)) {
        const formattedDate = formatDate(date);

        if (summaryMap[formattedDate]) {
            filledSummary.push(summaryMap[formattedDate]);
        } else {
            filledSummary.push({ date: formattedDate, posts: 0, likes: 0, replies: 0 });
        }
    }

    return filledSummary;
}

import { FC } from 'react';
import './user-icon.scss';
import { getImageURL } from '../../utils/utils';

export const UserIcon: FC<{ imageURL?: string; initials: string }> = ({ imageURL, initials }) => {
    return imageURL ? (
        <img className="user-image" width={38} height={38} src={getImageURL(imageURL)} alt={initials} />
    ) : (
        <div className="user-icon">{initials}</div>
    );
};

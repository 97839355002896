import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Preloader } from './Preloader';
import { FC } from 'react';

export const LoadingContainer: FC = () => {
    const { numActiveRequests } = useSelector((state: RootState) => state.loading);

    return <Preloader isLoading={numActiveRequests > 0} type={'transparentOverlay'} />;
};

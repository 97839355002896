import { FilterForm } from '../table/Tables.types';
import { TableRow } from 'react-data-table-component';
import { ReactElement, ReactNode } from 'react';
import { SelectOption } from '../../models/shared.models';
import { Variant } from '../store/store.models';

export type PurchaseStatus = {
    statusID: string;
    name: string;
    statusType: 'regular' | 'internal';
};

export type PurchaseHistory = {
    position?: 'left' | 'right';
    status: string;
    timestamp: number;
    userFullName: string;
    note: string;
    type: string;
};

export type Purchase = {
    appName?: string;
    purchaseID: string;
    userID: string;
    companyID: string;
    companyName: string;
    purchaseStatus: string;
    internalPurchaseStatus: string;
    timestamp: number;
    statusUpdateTimeStamp: number;
    address: string;
    creditsAmount: number;
    moneyAmount: number;
    totalAmount: number;
    userFullName?: string;
    userPhone?: string;
    creditCardLastDigits?: string;
    creditCardCompany?: string;
    hasBeenRefunded: boolean;
    product: PurchasedProduct;
    purchaseHistory?: PurchaseHistory[];
};

export type PurchasedProduct = {
    purchasedProductID: string;
    purchaseID: string;
    supplierID: string;
    productID: string;
    productName: string;
    subtitle: string;
    catalogNumberID: string;
    mainImage: string;
    variant?: Variant;
    originalPrice: string;
    summary: string;
    description: string;
    warranty: string;
    specifications?: string;
    price: number;
    quantity: number;
    discountAmount: number;
    subTotal: number; //total without VAT
    VAT: number;
    total: number;
};

export type Tags = {
    userFullName?: string;
    companyName?: string;
    userPhone?: string;
    productImageURL?: string;
    supplier?: string;
    productID?: string;
    productName?: string;
    creditsUsed?: number;
    creditCardLastDigits?: string;
    creditCardCompany?: string;
};

export type PurchaseFilterForm = FilterForm<Purchase> & {
    companiesIDs?: SelectOption[];
    userID?: SelectOption | null;
    supplierIDs?: SelectOption[];
    purchaseStatus?: SelectOption[];
    internalPurchaseStatus?: SelectOption[];
    productID?: SelectOption | null;
    fromTimestamp?: number;
    toTimestamp?: number;
};

export const defaultPurchaseFilterForm: PurchaseFilterForm = {
    companiesIDs: [],
    userID: null,
    supplierIDs: [],
    purchaseStatus: [],
    internalPurchaseStatus: [],
    productID: null,
    orderBy: {
        direction: 'DESC',
        sortedColumn: 'statusUpdateTimeStamp',
    },
    page: 0,
    rowsPerPage: { label: 25, value: 25 },
};

export const purchasesManagementTableColumns = () => {
    return [
        {
            name: 'תמונה',
            selector: (row: TableRow) => row.image,
            sortable: false,
            field: 'image',
            width: '5%',
        },
        {
            name: 'שם מזמין',
            selector: (row: TableRow) => row.fullName,
            sortable: true,
            field: 'tags.userFullName',
            width: '9%',
        },
        {
            name: 'מוצר מוזמן',
            selector: (row: TableRow) => row.productName,
            sortable: true,
            field: 'productName',
            width: '10%',
        },
        {
            name: 'חברה מזמינה',
            selector: (row: TableRow) => row.companyName,
            sortable: true,
            field: 'companyName',
            width: '9%',
        },
        {
            name: 'נקודות',
            selector: (row: TableRow) => row.credits,
            sortable: true,
            field: 'credits',
            width: '9%',
        },
        {
            name: 'תאריך הזמנה',
            selector: (row: TableRow) => row.timestamp,
            sortable: true,
            field: 'timestamp',
            width: '9%',
        },
        {
            name: 'עדכון אחרון',
            selector: (row: TableRow) => row.statusUpdateTimeStamp,
            sortable: true,
            field: 'statusUpdateTimeStamp',
            width: '9%',
        },
        {
            name: 'סטטוס הזמנה',
            selector: (row: TableRow) => row.status,
            sortable: true,
            field: 'status',
            width: '10%',
        },
        {
            name: 'סטטוס פנימי',
            selector: (row: TableRow) => row.internalStatus,
            sortable: true,
            field: 'internalStatus',
            width: '12%',
        },
        {
            name: '',
            selector: (row: TableRow) => row.edit,
        },
    ];
};

export type OrderTableData = {
    id: string;
    image: ReactElement;
    fullName: ReactNode;
    phone: string;
    productName: string;
    companyName: ReactNode;
    credits: string;
    timestamp: string;
    statusUpdateTimeStamp: string;
    status: ReactElement;
    internalStatus: ReactElement;
};

export type OpenTransactionTableData = {
    id: string;
    image: ReactElement;
    productName: string;
    companyName: ReactNode;
    credits: string;
    timestamp: string;
    boostType: string;
    from: string;
    to: string;
};

export const openTransactionsManagementTableColumns = () => {
    return [
        {
            name: 'תמונה',
            selector: (row: TableRow) => row.image,
            sortable: false,
            field: 'image',
            maxWidth: '5%',
        },
        {
            name: 'מוצר מוזמן',
            selector: (row: TableRow) => row.productName,
            sortable: false,
            field: 'productDetails.productID',
            minWidth: '15%',
        },
        {
            name: 'סוג',
            selector: (row: TableRow) => row.boostType,
            sortable: true,
            field: 'boostType',
            maxWidth: '5%',
        },
        {
            name: 'חברה',
            selector: (row: TableRow) => row.companyName,
            sortable: true,
            field: 'companyID',
            // width: '9%',
        },
        {
            name: 'שולח',
            selector: (row: TableRow) => row.from,
            sortable: true,
            field: 'fromUserID',
            minWidth: '15%',
        },
        {
            name: 'אל',
            selector: (row: TableRow) => row.to,
            sortable: true,
            field: 'toUserID',
            minWidth: '15%',
        },
        {
            name: 'קרדיטים',
            selector: (row: TableRow) => row.credits,
            sortable: true,
            field: 'creditsAmount',
            // width: '6%',
        },
        {
            name: 'תאריך שליחה',
            selector: (row: TableRow) => row.timestamp,
            sortable: true,
            field: 'timestamp',
            // width: '9%',
        },
        {
            name: '',
            selector: (row: TableRow) => row.edit,
        },
    ];
};

export type OrderForm = {
    purchaseID: string;
    userID: string;
    companyID: string;
    purchaseStatus?: { label: string; value: string };
    internalPurchaseStatus?: { label: string; value: string };
    timestamp: string;
    statusUpdateTimeStamp: string;
    userFullName: string;
    // companyName: string;
    userPhone: string;
    address: string;
    productImageURL: string;
    supplier: string;
    productID: string;
    productName: string;
    originalPrice: number | '';
    price: number | '';
    title: string;
    subtitle: string;
    description: string;
    summary: string;
    variants: SelectOption[];
    creditsAmount: number;
    moneyAmount: number;
    totalAmount: number;
    paymentMethod?: string;
    hasBeenRefunded: boolean;
    note?: string;
    purchaseHistory?: HistoryUpdate[];
};

export const orderFormInitialValue: OrderForm = {
    address: '',
    variants: [],
    description: '',
    summary: '',
    paymentMethod: '',
    originalPrice: '',
    price: '',
    companyID: '',
    userID: '',
    title: '',
    subtitle: '',
    internalPurchaseStatus: undefined,
    productID: '',
    productImageURL: '',
    productName: '',
    purchaseID: '',
    purchaseStatus: undefined,
    supplier: '',
    timestamp: '',
    statusUpdateTimeStamp: '',
    creditsAmount: 0,
    moneyAmount: 0,
    totalAmount: 0,
    hasBeenRefunded: false,
    userFullName: '',
    userPhone: '',
    note: '',
    purchaseHistory: [],
};

export type HistoryUpdate = {
    status: string;
    timestamp: number;
    userFullName: string;
    note: string;
    type: 'regular' | 'internal';
};

export type UpdatePurchaseStatus = {
    purchaseID: string;
    purchaseStatus: string;
    internalPurchaseStatus: string;
    historyUpdates?: HistoryUpdate[];
};

export type ExcelPurchase = {
    Purchase_ID: string;
    User_Name: string;
    User_ID: string;
    Company_Name: string;
    Company_ID: string;
    Purchase_Status: string;
    Internal_Purchase_Status: string;
    Purchase_Time: string;
    Address: string;
    Status_Update_Time: string;
    Credits_Amount: number;
    Money_Amount: number;
    Product_Name: string;
    Product_ID: string;
};

export type OpenTransactionsFilterForm = FilterForm<Purchase> & {
    company?: SelectOption | null;
    name?: string;
    fromTimestamp?: number;
    toTimestamp?: number;
};

export const defaultOpenTransactionsFilterForm: OpenTransactionsFilterForm = {
    company: null,
    name: '',
    orderBy: {
        direction: 'DESC',
        sortedColumn: 'timestamp',
    },
    page: 0,
    rowsPerPage: { label: 25, value: 25 },
}; //import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
export type CustomTimelineItemData = {
    position?: 'left' | 'right';
    status: string;
    timestamp: number;
    userFullName: string;
    note: string;
    type: string;
};
export type CustomTimelineProps = {
    items: CustomTimelineItemData[];
    renderContent: (item: CustomTimelineItemData) => React.ReactNode;
};

import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Modal } from './Modal';
import { FC } from 'react';

export const ModalContainer: FC = () => {
    const modalProps = useSelector((state: RootState) => state.modals);

    return <Modal {...modalProps} />;
};

import { API } from './api';
import { GetInstances, GetResponse } from './api.models';
import { Company } from '../components/companies/companies.models';

export const getCompaniesAPI = async <T>(
    getBy: GetInstances<T>
): Promise<GetResponse & { data: Company[] }> => {
    return API.get({ params: getBy, url: `companies` });
};

export const CreateOrEditCompanyAPI = async (company: Company): Promise<Company> => {
    return API.post({ url: `companies`, body: company });
};

export const toggleFreezeCompanyAPI = async (id: string): Promise<void> => {
    return API.put({ url: `companies/freeze/${id}` });
};

export const deleteCompanyAPI = async (id: string): Promise<void> => {
    return API.delete({ url: `companies/${id}` });
};

export const addBudgetAPI = async (body: { companyID?: string; amount?: number }): Promise<void> => {
    return API.post({ url: `companies/add-budget`, body });
};

//TEMP
export const addMonthlyBudgetAPI = async (): Promise<void> => {
    return API.post({ url: `transactions/test` });
};

import { FC, useCallback, useEffect, useMemo } from 'react';
import './create-post.scss';
import { useTranslation } from 'react-i18next';
import { SmartAutoComplete } from '../form/select/auto-complete';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { SmartDatePicker } from '../form/date-picker/smart-date-picker';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { getCoreGroupAPI, getGroupsAPI } from '../../api/groups.api';
import { Group, GroupOption } from '../../models/groups.models';
import { companyLoadOptions, userLoadOptions } from '../../utils/loadOptions';
import { useFormContext, useWatch } from 'react-hook-form';

type CreatePostGroupsAndUsersProps = {
    isEventable?: boolean;
    isGift?: boolean;
};

export const CreatePostGroupsAndUsers: FC<CreatePostGroupsAndUsersProps> = ({ isEventable, isGift }) => {
    const currentUser = useSelector(getCurrentUser);
    const { t } = useTranslation();
    const { control, setValue } = useFormContext();
    const company = useWatch({ control, name: 'company' });
    const groups = useWatch({ control, name: 'groups' });
    const users = useWatch({ control, name: 'users' });
    const isEvent = useWatch({ control, name: 'isEvent' });
    const publishAtDate = useWatch({ control, name: 'publishAtDate' });
    const publishAtTime = useWatch({ control, name: 'publishAtTime' });
    const usersAmountLessThenOne = useMemo(() => {
        return users.length <= 1 && groups.length === 0;
    }, [users, groups]);

    useEffect(() => {
        if (users.length > 1 || groups.length > 0) {
            setValue('isEvent', false);
            setValue('eventName', '');
        }
    }, [users, groups]);

    useEffect(() => {
        if (company?.value && !isGift) {
            getCoreGroupAPI(company?.value).then((res) => {
                if (res) {
                    const value = {
                        label: res.name,
                        value: { groupID: res.groupID },
                    };
                    setValue('groups', [value]);
                }
            });
        }
    }, [company]);

    const groupsLoadOptions = async (inputValue: string): Promise<GroupOption[]> => {
        const results = await getGroupsAPI<Group>({
            companyID: company?.value,
            name: inputValue,
        });

        if (!results?.data) return [];

        return results.data
            .filter((g) => g.type !== 'private')
            .map((u) => ({
                label: `${u.name}`,
                value: { groupID: u.groupID },
            }));
    };

    const renderEvent = useCallback(() => {
        return (
            <div className={'step-row'}>
                <div className={'event-gift'}>
                    <div className={'step-row'}>
                        <div style={{ display: 'flex' }} className={'checkbox-with-label'}>
                            <SmartInput
                                name={'isEvent'}
                                type={'checkbox'}
                                disabled={!usersAmountLessThenOne}
                            />
                            <span className={'checkbox-label'}>
                                {t('makeEvent')}
                                <span className={'checkbox-subtext'}>
                                    {t('canMakeEventOnlyIfSingleUserChosen')}
                                </span>
                            </span>
                        </div>
                    </div>

                    <SmartInput
                        name={'eventName'}
                        label={t('eventName')}
                        disabled={!isEvent || !usersAmountLessThenOne}
                    />
                </div>
            </div>
        );
    }, [usersAmountLessThenOne, isEvent]);

    return (
        <div className={'step'} style={{ width: '412px' }}>
            <SmartAutoComplete
                placeholder={t('choose')}
                name={'company'}
                label={t('company')}
                getLoadOptionsData={companyLoadOptions}
                isDisabled={currentUser?.userType !== 'admin'}
                isClearable
                required
                onChange={() => {
                    setValue('groups', []);
                    setValue('users', []);
                    setValue('behalfOf', { value: '', label: '' });
                }}
            />
            <SmartAutoComplete
                name={'behalfOf'}
                required
                placeholder={t('choose')}
                getLoadOptionsData={(inputValue) =>
                    userLoadOptions({
                        inputValue,
                        companyID: company?.value,
                        excludeAdmins: true,
                        userType: 'HR',
                    })
                }
                label={t('onBehalfOfName')}
                isDisabled={!company?.value}
                keyValue={company?.value}
            />

            <SmartAutoComplete
                name={'groups'}
                placeholder={t('choose')}
                getLoadOptionsData={groupsLoadOptions}
                label={t('groups')}
                isMulti
                keyValue={company?.value}
                isDisabled={!company}
            />
            <SmartAutoComplete
                name={'users'}
                placeholder={t('choose')}
                getLoadOptionsData={(inputValue) =>
                    userLoadOptions({
                        inputValue,
                        companyID: company?.value,
                        excludeAdmins: true,
                    })
                }
                label={t('users')}
                isDisabled={!company}
                isMulti
                keyValue={company?.value}
            />

            <SmartDatePicker
                label={t('publishAtDate')}
                dateFormat={'dd/MM'}
                name={'publishAtDate'}
                placeholder="DD/MM"
                futureOnly
                required={!!publishAtTime}
            />
            <SmartInput
                label={t('publishAtTime')}
                type="time"
                name={'publishAtTime'}
                required={!!publishAtDate}
            />
            <SmartInput name={'campaign'} label={t('campaign')} placeholder={t('campaign')} />

            {isEventable && renderEvent()}
        </div>
    );
};

import { useFormContext, useWatch } from 'react-hook-form';
import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { useHandleBack } from '../../hooks/useHandleBack';
import { CategoryForm, StoreCategory } from './store.models';
import { useTranslation } from 'react-i18next';
import { Button } from '../form/buttons/Button';
import { Save } from 'react-feather';
import { ImageUpload } from '../image-upload/ImageUpload';
import shadow from '../users/person-placeholder.png';
import { getCategoryImagePath, uploadFile } from '../../api/storage.api';
import { ErrorToast, SuccessToast } from '../toaster/Toast';
import { SmartAutoComplete } from '../form/select/auto-complete';
import { categoriesLoadOptions, getRecursiveCategories } from './store.utils';
import { closeModal, raiseModal } from '../../store/modal/modal.actions';
import { ModalIcon } from '../modal/ModalIcon';
import { createOrEditCategoryAPI } from '../../api/store.api';

type StoreCategoryStepProps = {
    allStoreCategories: StoreCategory[] | undefined;
    onStageReset: () => void;
};

export const StoreCategoryStep: FC<StoreCategoryStepProps> = ({ onStageReset, allStoreCategories }) => {
    const { control, formState, trigger, getValues, setValue } = useFormContext<CategoryForm>();
    const { isDirty } = formState;
    const handleBack = useHandleBack(isDirty);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const url = useWatch({ name: 'url', control });
    const recursiveCategories = useMemo(() => {
        return getRecursiveCategories(allStoreCategories ?? []);
    }, [allStoreCategories]);

    const handleSave = async () => {
        const categoryForm = getValues();

        const category: StoreCategory = {
            categoryID: categoryForm.categoryID,
            categoryName: categoryForm.categoryName,
            categoryImageURL: categoryForm?.categoryImageURL,
            supplierID: categoryForm?.supplierID,
            order: categoryForm?.order,
            supplierCategoryID: categoryForm?.supplierCategoryID,
            supplierCategoryName: categoryForm?.supplierCategoryName,
            parentCategoryID: categoryForm.parentCategories?.map((pc) => pc.value),
        };

        await trigger().then((value) => {
            if (!value) {
                dispatch(
                    raiseModal({
                        isOpen: true,
                        content: t('mustFillMandatoryFields'),
                        header: {
                            icon: <ModalIcon theme={'ALERT'} />,
                            title: t('save'),
                        },
                        footer: {
                            buttons: [
                                { label: t('close'), theme: 'grey', callback: () => dispatch(closeModal()) },
                            ],
                        },
                    })
                );

                return;
            } else {
                createOrEditCategoryAPI(category)
                    .then(() => {
                        SuccessToast(t('actionSucceeded'));
                        onStageReset();
                    })
                    .catch((e) => console.log(e));
            }
        });
    };

    const uploadPic = async (file: File) => {
        try {
            const categoryID = getValues('categoryID');
            const key = Date.now();
            const signedURL = await getCategoryImagePath(categoryID, key);
            const path = `categories/${categoryID}/${key}`;

            await uploadFile(signedURL, file).then(() => {
                setValue('categoryImageURL', path);
                setValue('url', signedURL);
            });
        } catch (e) {
            ErrorToast(t('errorUploadingFile'));
        }
    };

    return (
        <div className={'step'}>
            <div className={'step-title'}>{t('categoryDetails')}</div>
            <SmartInput
                required
                name={'categoryID'}
                label={t('internalID')}
                placeholder={t('internalID')}
                disabled
            />
            <SmartInput
                required
                name={'supplierCategoryID'}
                label={t('supplierCategoryID')}
                placeholder={t('supplierCategoryID')}
                disabled
            />

            <SmartInput
                required
                name={'categoryName'}
                label={t('categoryName')}
                placeholder={t('categoryName')}
            />
            <SmartInput name={'order'} label={t('order')} placeholder={t('order')} enforceNumbers />

            <div className={'step-row'}>
                <ImageUpload
                    imageURL={url ?? ''}
                    name={'url'}
                    onChange={(value) => {
                        setValue('categoryImageURL', value);
                        setValue('url', value);
                    }}
                    defaultImage={shadow}
                    onUpload={uploadPic}
                />
            </div>

            <SmartInput
                required
                name={'supplierID'}
                label={t('supplier')}
                placeholder={t('supplier')}
                disabled
            />
            <SmartInput
                name={'supplierCategoryName'}
                label={t('supplierCategoryName')}
                placeholder={t('supplierCategoryName')}
                disabled
            />

            <div className={'step-row'}>
                <SmartAutoComplete
                    getLoadOptionsData={(input) => categoriesLoadOptions(recursiveCategories, input)}
                    name={'parentCategories'}
                    label={t('parentCategories')}
                    placeholder={t('parentCategories')}
                    isMulti
                />
            </div>
            <div className={'step-row'}>
                <SmartAutoComplete
                    getLoadOptionsData={(input) => categoriesLoadOptions(recursiveCategories, input)}
                    name={'childrenCategories'}
                    label={t('childrenCategories')}
                    isMulti
                    placeholder={t('')}
                    isDisabled
                />
            </div>

            <div className={'step-row'}>
                <div className={'step-footer'}>
                    <div className={'inputs-separator'} />
                    <div className={'save-and-cancel'}>
                        <Button theme={'grey'} onClick={() => handleBack(onStageReset, onStageReset)}>
                            {t('cancel')}
                        </Button>
                        <Button theme={'primary'} onClick={handleSave} icon={<Save size={14} />}>
                            {t('save')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

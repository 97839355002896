import * as Yup from 'yup';
import { ERROR_MESSAGES } from '../../../utils/validations';

export const addCreditsValidations = Yup.object().shape({
    creditsToAdd: Yup.mixed()
        .nullable()
        .required(ERROR_MESSAGES.required)
        .test('min-1', 'סכום לא תקין', function (value) {
            if (!value) {
                return false;
            }

            return +value > 0;
        }),
    isSocial: Yup.boolean().nullable().required(ERROR_MESSAGES.required),
});

import { PreloaderProps } from './Preloader.models';
import { MAP_TYPE_TO_STYLE } from './Preloader.utils';
import { Loader } from 'react-feather';
import './Preloader.scss';
import { FC } from 'react';

export const Preloader: FC<PreloaderProps> = ({ isLoading, type = 'inline', extraStyle }) => {
    if (!isLoading) return null;

    const style = MAP_TYPE_TO_STYLE[type];

    return (
        <div className={'preloader'} style={{ ...style, ...extraStyle }}>
            <Loader className="rolling" size="50" color={'var(--primary)'} />
        </div>
    );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InnerModal } from '../../modal/InnerModal/InnerModal';
import { Modal } from '../Modal';
import { ModalIcon } from '../../modal/ModalIcon';

type NotEnoughBudgetModalProps = {
    isOpen: boolean;
    closeModal: () => void;
};

export const NotEnoughBudgetModal: FC<NotEnoughBudgetModalProps> = ({ isOpen, closeModal }) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} onClose={closeModal}>
            <InnerModal
                header={{ title: t('error'), icon: <ModalIcon theme={'ALERT'} /> }}
                content={
                    <div style={{ fontSize: '14px', textAlign: 'center' }}>
                        {t('notEnoughCreditsMessage')}
                    </div>
                }
                footer={{
                    position: 'center',
                    buttons: [{ label: t('close'), theme: 'grey', callback: closeModal }],
                }}
            />
        </Modal>
    );
};

import { FC } from 'react';
import './timeline.scss';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { styled } from '@mui/material/styles';
import { CustomTimelineProps } from '../orders/order.models';
// const CustomTimelineContent = styled(TimelineContent)(({ theme }) => ({
//     margin: 0,
//     fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//     fontWeight: 400,
//     fontSize: '1rem',
//     lineHeight: 1.5,
//     letterSpacing: '0.00938em',
//     flex: 1,
//     padding: '6px 16px',
//     textAlign: 'right',
// }));

// const CustomTimelineContent = styled(TimelineContent, {
//     shouldForwardProp: (prop) => prop !== 'position',
// })<{ position?: string }>(({ position }) => ({
//     margin: 0,
//     fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//     fontWeight: 400,
//     fontSize: '1rem',
//     lineHeight: 1.5,
//     letterSpacing: '0.00938em',
//     flex: 1,
//     padding: '6px 16px',
//     textAlign: position === 'right' ? 'right' : 'right',
// }));

// const CustomTimelineContent = styled(TimelineContent)<{ position?: string }>(({ position }) => ({
//     margin: 0,
//     fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//     fontWeight: 400,
//     fontSize: '1rem',
//     lineHeight: 1.5,
//     letterSpacing: '0.00938em',
//     flex: 1,
//     padding: '6px 16px',
//     textAlign: position === 'right' ? 'left' : 'right',
// }));
interface CustomTimelineContentProps {
    srcPosition?: 'right' | 'left';
}

const CustomTimelineContent = styled(TimelineContent, {
    shouldForwardProp: (prop: any) => prop !== 'srcPosition',
})<CustomTimelineContentProps>(({ srcPosition = 'left' }) => ({
    // margin: 0,
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    // fontWeight: 400,
    // fontSize: '1rem',
    // lineHeight: 1.5,
    // letterSpacing: '0.00938em',
    // flex: 1,
    // padding: '6px 16px',
    // textAlign: srcPosition === 'right' ? 'right' : 'left',
    // wordBreak: 'break-word',
    // overflowWrap: 'break-word',
    // '@media (max-width: 600px)': {
    //     textAlign: 'center',
    //     padding: '6px 8px',
    // },
    //seperator
    // margin: 0,
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    // fontWeight: 400,
    // fontSize: '1rem',
    // lineHeight: 1.5,
    // letterSpacing: '0.00938em',
    // flex: 1,
    // padding: '6px 16px',
    // textAlign: srcPosition === 'right' ? 'right' : 'left',
    // wordBreak: 'break-word',
    // overflowWrap: 'break-word',
    // '@media (max-width: 600px)': {
    //     textAlign: 'center',
    //     padding: '6px 8px',
    //     flexBasis: '100%',
    //     maxWidth: '100%',
    // },
    //seperator
    // margin: 0,
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    // fontWeight: 400,
    // fontSize: '1rem',
    // lineHeight: 1.5,
    // letterSpacing: '0.00938em',
    // flex: 1,
    // padding: '6px 16px',
    // textAlign: srcPosition === 'right' ? 'right' : 'left',
    //!!!!!!!!!!!!
    margin: 0,
    fontFamily: 'Rubik-Regular',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 0.7,
    letterSpacing: '0.00938em',
    flex: 1,
    padding: '6px 16px',
    textAlign: srcPosition === 'right' ? 'right' : 'left',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    '@media (max-width: 600px)': {
        textAlign: 'center',
        padding: '6px 8px',
        flexBasis: '100%',
        maxWidth: '100%',
    },
}));

const CustomTimeline: React.FC<CustomTimelineProps> = ({ items, renderContent }) => {
    return (
        <Timeline>
            {items.map((item, index) => (
                <TimelineItem key={index} position={item.position || 'alternate'}>
                    <TimelineSeparator>
                        <TimelineDot />
                        {index < items.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <CustomTimelineContent srcPosition={item.position!}>
                        {renderContent(item)}
                    </CustomTimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
};

export default CustomTimeline;

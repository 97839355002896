import { timeAgo } from './post.utils';
import { boostGiftRedeemType, GiftDetails, PostType } from '../home/home.models';
import { useTranslation } from 'react-i18next';
import { getImageURL } from '../../utils/utils';

type PostHeaderProps = {
    imageURL: string;
    timestamp: number;
    userRole: string;
    fullName: string;
    postType: PostType;
    target?: string;
    publisherPronoun?: 'he' | 'she';
    giftDetails: GiftDetails | undefined;
};

export const PostHeader = ({
    fullName,
    imageURL,
    userRole,
    timestamp,
    postType,
    publisherPronoun,
    target,
    giftDetails,
}: PostHeaderProps) => {
    const { t } = useTranslation();

    const getProductNameText = () => {
        return (
            giftDetails && (
                <h6 className={'gift-details'}>{`${t(postType)} מסוג ${
                    giftDetails?.productDetails?.productID ? t('product') : t('credit')
                } בשווי ${giftDetails?.creditsAmount} ש"ח ${
                    giftDetails?.productDetails?.productName
                        ? `- ${giftDetails?.productDetails?.productName}`
                        : ''
                }`}</h6>
            )
        );
    };

    return (
        <div className={'post-header'}>
            <div className={'publisher-details'}>
                <img src={getImageURL(imageURL)} alt={''} className={'publisher-image'} />
                <div className={'publisher-and-role'}>
                    {postType === 'gift' ? (
                        <h5 className={'publisher'}>{`${fullName} ${
                            publisherPronoun === 'he' ? t('sentMale') : t('sentFemale')
                        } ${t(boostGiftRedeemType[postType as 'gift' | 'giftRedeem'])} ל${target}`}</h5>
                    ) : postType === 'giftRedeem' ? (
                        <h5 className={'publisher'}>{`${target} ${
                            publisherPronoun === 'he' ? t('receivedMale') : t('receivedFemale')
                        } ${t('gift')} מ${fullName}`}</h5>
                    ) : (
                        <h5 className={'publisher'}>{fullName}</h5>
                    )}
                    <h6 className={'user-role'}>{userRole}</h6>

                    {getProductNameText()}
                </div>
            </div>
            <span className={'published-at'}>{timeAgo(timestamp)}</span>
        </div>
    );
};

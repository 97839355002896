type LocalStorageValue = {
    token: string;
};

type LocalStorageKeys = keyof LocalStorageValue;

type GetItem = <T extends LocalStorageKeys>(keyName: T) => LocalStorageValue[T] | null;

type SetItem = <T extends LocalStorageKeys>(keyName: T, keyValue: LocalStorageValue[T]) => void;

export class LocalStorage {
    static getItem: GetItem = (keyName) => {
        const localStorageItem = localStorage.getItem(keyName);
        if (localStorageItem === null) {
            return null;
        } else {
            //In case the localStorageItem can't be parsed we return the item value without parsing it.
            try {
                return JSON.parse(localStorageItem);
            } catch {
                return localStorageItem;
            }
        }
    };

    static setItem: SetItem = (keyName, keyValue) => {
        const keyValueString = JSON.stringify(keyValue);
        localStorage.setItem(keyName, keyValueString);
    };

    static removeItem = (keyName: LocalStorageKeys): void => {
        localStorage.removeItem(keyName);
    };

    static clearAll = (): void => {
        localStorage.clear();
    };
}

import * as Yup from 'yup';
import { Gender, MaritalStatus, UserType } from './users.models';
import { ERROR_MESSAGES } from '../../utils/validations';

const addressSchema = Yup.object()
    .shape({
        city: Yup.object()
            .shape({
                label: Yup.string().nullable(),
                value: Yup.string().nullable(),
            })
            .nullable(),
        isCityPublic: Yup.boolean().nullable(),
        street: Yup.object()
            .shape({
                label: Yup.string().nullable(),
                value: Yup.string().nullable(),
            })
            .nullable(),
        isStreetPublic: Yup.boolean().nullable(),
    })
    .nullable();

export const usersSchema = Yup.object().shape({
    firstName: Yup.string().required(ERROR_MESSAGES.required),
    lastName: Yup.string().required(ERROR_MESSAGES.required),
    userType: Yup.object().shape({
        label: Yup.string().required(ERROR_MESSAGES.required),
        value: Yup.string().required(ERROR_MESSAGES.required),
    }),
    company: Yup.object().when('userType', {
        is: (userType: { value: UserType; label: string }) => userType.value === 'admin',
        then: Yup.object({
            label: Yup.string().nullable(),
            value: Yup.string().nullable(),
        }).nullable(),
        otherwise: Yup.object({
            label: Yup.string().nullable().required(ERROR_MESSAGES.required),
            value: Yup.string().nullable().required(ERROR_MESSAGES.required),
        })
            .nullable()
            .required(ERROR_MESSAGES.required),
    }),
    pronoun: Yup.object().shape({
        isPublic: Yup.boolean().required(ERROR_MESSAGES.required),
        value: Yup.object().shape({
            label: Yup.string().required(ERROR_MESSAGES.required),
            value: Yup.string().required(ERROR_MESSAGES.required),
        }),
    }),
    phone: Yup.object().shape({
        isPublic: Yup.boolean().required(ERROR_MESSAGES.required),
        value: Yup.string()
            .nullable()
            .min(9, 'מינימום 9 תווים')
            .max(10, 'מקסימום 10 תווים')
            .required(ERROR_MESSAGES.required),
    }),
    birthDate: Yup.object().shape({
        isPublic: Yup.boolean().required(ERROR_MESSAGES.required),
        value: Yup.string().nullable(),
    }),
    birthYear: Yup.object().shape({
        isPublic: Yup.boolean().required(ERROR_MESSAGES.required),
        value: Yup.string().nullable(),
    }),
    uniqueFields: Yup.array().of(
        Yup.object().shape({
            label: Yup.string().required(ERROR_MESSAGES.required),
            value: Yup.string().required(ERROR_MESSAGES.required),
        })
    ),
    companyUniqueFields: Yup.array().of(
        Yup.object().shape({
            label: Yup.string().required(ERROR_MESSAGES.required),
            value: Yup.string().required(ERROR_MESSAGES.required),
        })
    ),
    role: Yup.object().shape({
        isPublic: Yup.boolean().required(ERROR_MESSAGES.required),
        value: Yup.string().nullable().required(ERROR_MESSAGES.required),
    }),
    department: Yup.object().shape({
        isPublic: Yup.boolean().required(ERROR_MESSAGES.required),
        value: Yup.string().nullable().required(ERROR_MESSAGES.required),
    }),
    email: Yup.object().shape({
        isPublic: Yup.boolean(),
        value: Yup.string().email('אימייל לא תקין').nullable(),
    }),
    maritalStatus: Yup.object()
        .nullable()
        .shape({
            isPublic: Yup.boolean().nullable(),
            value: Yup.object().shape({
                label: Yup.string().nullable(),
                value: Yup.string().nullable(),
            }),
        }),
    children: Yup.array()
        .of(
            Yup.object().shape({
                gender: Yup.object({
                    value: Yup.object({
                        value: Yup.string().required(ERROR_MESSAGES.required).nullable(),
                        label: Yup.string().required(ERROR_MESSAGES.required).nullable(),
                    }),
                    isPublic: Yup.boolean().required(ERROR_MESSAGES.required),
                }),
                name: Yup.object({
                    value: Yup.string().required(ERROR_MESSAGES.required),
                    isPublic: Yup.boolean().required(ERROR_MESSAGES.required),
                }),
                birthDate: Yup.object({
                    value: Yup.string().required(ERROR_MESSAGES.required).nullable(),
                    isPublic: Yup.boolean().required(ERROR_MESSAGES.required),
                }),
            })
        )
        .nullable(),
    spouse: Yup.object().shape({
        gender: Yup.object({ value: Yup.string().nullable() }).nullable(),
        name: Yup.object().when('gender', {
            is: (gender: { value: Gender }) => gender?.value !== 'none',
            then: Yup.object({
                value: Yup.string().required(ERROR_MESSAGES.required),
                isPublic: Yup.boolean().required(ERROR_MESSAGES.required),
            }),
        }),
        birthDate: Yup.mixed().when('gender', {
            is: (gender: { value: Gender }) => gender?.value !== 'none',
            then: Yup.object({
                value: Yup.string().required(ERROR_MESSAGES.required),
                isPublic: Yup.boolean().required(ERROR_MESSAGES.required),
            }),
        }),
    }),
    imageURL: Yup.string().nullable(),
    officePhone: Yup.object()
        .shape({
            isPublic: Yup.boolean().required(ERROR_MESSAGES.required),
            value: Yup.string()
                .test('min-length', 'מינימום 9 תווים', function (value) {
                    if (!value) {
                        return true;
                    }

                    return value.length >= 9;
                })
                .max(10, 'מקסימום 10 תווים')
                .nullable(),
        })
        .nullable(),
    jobStartDate: Yup.object()
        .shape({
            isPublic: Yup.boolean().required(ERROR_MESSAGES.required),
            value: Yup.string().nullable(),
        })
        .nullable(),
    address: addressSchema,
    officeAddress: addressSchema,
    userID: Yup.string(),
    marriageDate: Yup.mixed().when('maritalStatus', {
        is: (maritalStatus: { value: MaritalStatus }) => {
            return maritalStatus.value === 'married';
        },
        then: Yup.object()
            .shape({
                isPublic: Yup.boolean().required(ERROR_MESSAGES.required),
                value: Yup.string().required(ERROR_MESSAGES.required),
            })
            .required(),
    }),
    roleStartDate: Yup.object({
        isPublic: Yup.boolean().required(ERROR_MESSAGES.required),
        value: Yup.string().nullable(),
    }).nullable(),
    companyGroups: Yup.array().of(
        Yup.object().shape({
            label: Yup.string(),
            value: Yup.object().shape({
                groupID: Yup.string(),
            }),
        })
    ),
    isFrozen: Yup.boolean(),
    managerID: Yup.object({
        value: Yup.object()
            .shape({
                label: Yup.string().nullable(),
                value: Yup.string().nullable(),
            })
            .nullable(),
        isPublic: Yup.boolean().when('value', {
            is: (value: { label: string; value: string }) => {
                return value?.value !== undefined;
            },
            then: Yup.boolean().required(ERROR_MESSAGES.required),
            otherwise: Yup.boolean().nullable(),
        }),
    }).nullable(),
});

export const chooseCompanySchema = Yup.object().shape({
    chosenUploadCompany: Yup.object().required(ERROR_MESSAGES.required).nullable(),
});

export const getInitials = (firstWord?: string, secondWord?: string): string => {
    const first = firstWord ? firstWord.charAt(0) : '';
    const second = secondWord ? `.${secondWord.charAt(0)}` : '';
    return `${first}${second}`;
};

export const getInitialsByName = (name: string): string => {
    const parts = name.split(' ');
    return getInitials(...parts);
};

import { API } from './api';
import { GetInstances, GetResponse } from './api.models';

export const createHashtagAPI = async (name: string, companyID: string) => {
    return API.post({ url: 'hashtags', body: { companyID, name } });
};

export const getHashtagsAPI = async (
    getBy: GetInstances<{ companyID: string; name: string }>
): Promise<GetResponse & { data: string[] }> => {
    return API.get({ url: `hashtags/${getBy.companyID}`, params: getBy });
};

export const deleteHashtagsAPI = async (companyID: string, hashtags: string[]): Promise<string[]> => {
    return API.post({ url: `hashtags/delete`, body: { companyID, hashtags } });
};

import { Filter, FiltersModels } from '../filters/filters.models';
import { SelectOption } from '../../models/shared.models';
import { GroupOption } from '../../models/groups.models';
import { User } from '../users/users.models';
import { TFunction } from 'i18next';

export const getHomeFilters = (
    currentUser: User | undefined,
    companyLoadOptions: (inputValue: string) => Promise<SelectOption[]>,
    groupsLoadOptions: (inputValue: string) => Promise<GroupOption[]>,
    userLoadOptions: ({
        inputValue,
        companyID,
        companiesIDs,
        excludeUserID,
        excludeAdmins,
    }: {
        inputValue: string;
        companyID?: string;
        companiesIDs?: string[];
        excludeUserID?: string;
        excludeAdmins?: boolean;
    }) => Promise<SelectOption[]>,
    onClear: () => void,
    onKeyDown: (e: KeyboardEvent) => void,
    t: TFunction<'translation'>
): Filter[] => {
    return [
        {
            type: FiltersModels.AUTO_COMPLETE,
            getLoadOptionsData: companyLoadOptions,
            name: 'company',
            placeholder: t('showAll'),
            label: t('company'),
            onKeyDown,
            isDisabled: currentUser?.userType !== 'admin',
        },
        {
            type: FiltersModels.AUTO_COMPLETE,
            name: 'group',
            placeholder: t('showAll'),
            label: t('group'),
            onKeyDown,
            getLoadOptionsData: groupsLoadOptions,
        },
        {
            type: FiltersModels.AUTO_COMPLETE,
            name: 'user',
            onKeyDown,
            placeholder: t('showAll'),
            getLoadOptionsData: (inputValue: string) => userLoadOptions({ inputValue }),
            label: t('user'),
        },
        {
            type: FiltersModels.INPUT,
            name: 'campaign',
            onKeyDown,
            placeholder: t('campaignName'),
            label: t('campaign'),
        },
        {
            type: FiltersModels.DATE,
            name: 'fromTimestamp',
            onKeyDown,
            label: t('fromDate'),
            placeholder: t('fromDate'),
        },
        {
            type: FiltersModels.DATE,
            name: 'toTimestamp',
            onKeyDown,
            label: t('toDate'),
            placeholder: t('toDate'),
        },
        {
            type: FiltersModels.CHECK_BOX,
            name: 'futurePostsOnly',
            label: t('futurePostsOnly'),
        },
        {
            type: FiltersModels.BUTTON,
            sizeBootstrap: 2,
            label: t('filter'),
            onClick: onClear,
        },
    ];
};

import classNames from 'classnames';
import ReactSelect, { StylesConfig } from 'react-select';
import { FC, useState } from 'react';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import './select.scss';
import { Label } from 'reactstrap';

export const Select: FC<StateManagerProps & { containerClass?: string; label: string }> = ({
    containerClass,
    label,
    ...rest
}) => {
    const [searchValue, setSearchValue] = useState('');

    const handleInputChange = (newValue: string) => {
        setSearchValue(newValue);
    };

    const filteredOptions = rest.options?.filter((option) =>
        String((option as { label: string }).label)
            ?.toLowerCase()
            .includes(searchValue.toLowerCase())
    );

    return (
        <div className={containerClass}>
            {label && (
                <Label for={`${label}-input`} className="form-label">
                    {label}
                </Label>
            )}
            <ReactSelect
                {...rest}
                styles={customStyles}
                className={classNames('react-select', { 'react-select-disabled': rest.isDisabled })}
                onInputChange={handleInputChange}
                filterOption={() => true}
                isSearchable={true}
                options={filteredOptions}
            />
        </div>
    );
};

export const customStyles: StylesConfig = {
    option: (base, { isFocused, isSelected }) => {
        return {
            ...base,
            backgroundColor: isSelected ? 'var(--primary)' : 'none',
            lineHeight: 1.5,
            fontSize: '12px',
            minHeight: '38px',
            ':hover': {
                backgroundColor: isFocused ? 'lightblue' : 'auto',
                color: 'var(--white)',
            },
            color: isSelected ? 'var(--white)' : 'auto',
            // maxHeight: '38px',
        };
    },
    valueContainer: (provided) => ({
        ...provided,
        lineHeight: 1.5,
        fontSize: '12px',
        maxHeight: '38px',
        overflowY: 'auto',
    }),
    control: (provided, { selectProps }) => ({
        ...provided,
        borderRadius: '99px',
        maxHeight: '38px',
        overflow: 'hidden',
        border: selectProps.className?.includes('error') ? `red 2px solid` : 'auto',
    }),
    multiValue: (provided) => ({
        ...provided,
        borderRadius: '99px',
        backgroundColor: 'var(--primary)',
        lineHeight: 1.5,
        fontSize: '12px',
        padding: '3px',
        color: 'var(--white)',
        height: '20px',
        display: 'flex',
        alignItems: 'center',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: 'var(--white)',
    }),
};

import { FC, useState } from 'react';
import { FormProvider, useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { Company } from './companies.models';
import { useUpdateEffect } from '../../hooks/useUpdateEffect';
import { TableHeader } from '../table/TableHeader';
import { Table } from '../table/Table';
import { Button } from '../form/buttons/Button';
import { PlusCircle } from 'react-feather';
import { useDispatch } from 'react-redux';
import { GroupsTableColumns, populateGroupsData } from './companies.utils';
import { ErrorToast, SuccessToast } from '../toaster/Toast';
import { closeModal, raiseModal } from '../../store/modal/modal.actions';
import { useQuery } from 'react-query';
import { deleteGroup, getGroupMembersAPI, getGroupsAPI } from '../../api/groups.api';
import { Column } from '../table/Tables.types';
import { Order } from '../../hooks/useSort';
import { ModalIcon } from '../modal/ModalIcon';
import { defaultGroupsTableValues, GroupsFilterForm, GroupWithMembers } from '../../models/groups.models';
import { useTranslation } from 'react-i18next';
import { CreateGroupModal } from '../modals/CreateGroup.modal';

type CompaniesStep3Props = {
    form: UseFormReturn<Company>;
};

export const CompaniesStep3: FC<CompaniesStep3Props> = ({ form }) => {
    const dispatch = useDispatch();
    const companyID = useWatch({ name: 'companyID', control: form.control });
    const [editedGroupID, setEditedGroupID] = useState<string>();
    const { t } = useTranslation();
    const filterForm = useForm<GroupsFilterForm>({
        defaultValues: defaultGroupsTableValues,
        mode: 'onTouched',
    });
    const orderBy = useWatch({ name: 'orderBy', control: filterForm.control });
    const rowsPerPage = useWatch({ name: 'rowsPerPage', control: filterForm.control });
    const page = useWatch({ name: 'page', control: filterForm.control });
    const [isGroupModalOpen, setGroupModalOpen] = useState(false);

    const { refetch, data: { data: groups = [], total = 0 } = {} } = useQuery({
        queryKey: ['getGroups'],
        queryFn: async () => {
            const companyID = form.getValues('companyID');
            return getGroupsAPI({
                companyID,
                groupType: 'company',
                offset: page * rowsPerPage.value,
                count: rowsPerPage.value,
                sortBy: orderBy.direction,
                sortField: orderBy.sortedColumn,
            }).then(async (res) => {
                const groups = res?.data;

                const members = await Promise.all(
                    groups.map(async (group) => {
                        return {
                            groupID: group.groupID,
                            members: await getGroupMembersAPI(group.groupID),
                        };
                    })
                );

                (groups as GroupWithMembers[]).forEach((group) => {
                    group.members = members.find((m) => m.groupID === group.groupID)?.members.data ?? [];
                });

                return { data: groups as GroupWithMembers[], total: res?.total };
            });
        },
        enabled: true,
    });

    useUpdateEffect(() => {
        if (page !== 0) {
            filterForm.setValue('page', 0);
        } else {
            refetch();
        }
    }, [rowsPerPage.value]);

    useUpdateEffect(() => {
        refetch();
    }, [dispatch, form, page, orderBy]);

    const CustomHeader = () => {
        return (
            <TableHeader
                rowsPerPage={rowsPerPage.value}
                leftSideComponents={[
                    <Button
                        theme={'primary'}
                        onClick={() => {
                            setEditedGroupID(undefined);
                            setGroupModalOpen(true);
                        }}
                        icon={<PlusCircle width={13} />}>
                        {t('addNewCompanyGroup')}
                    </Button>,
                ]}
                rightSideComponents={[]}
            />
        );
    };

    const handleDeleteGroup = (name: string) => {
        const groupID = groups?.find((g) => g.name === name)?.groupID;

        if (!groupID) {
            ErrorToast(t(`errorDeletingGroup`));
            return;
        }

        dispatch(
            raiseModal({
                isOpen: true,
                content: t(`areYouSureDeleteCompanyGroup`),
                header: {
                    title: t('deleteCompanyGroup'),
                    icon: <ModalIcon theme={'ALERT'} />,
                },
                footer: {
                    buttons: [
                        {
                            label: 'כן, מחק',
                            theme: 'danger',
                            callback: async () => {
                                deleteGroup(groupID).then(() => {
                                    SuccessToast(t('groupSuccessfullyDeleted'));
                                    dispatch(closeModal());
                                    refetch();
                                });
                            },
                        },
                        { label: t('cancel'), theme: 'grey', callback: () => dispatch(closeModal()) },
                    ],
                },
            })
        );
    };

    return (
        <FormProvider {...filterForm}>
            <Table
                columns={GroupsTableColumns(handleDeleteGroup)}
                data={populateGroupsData(groups)}
                header={CustomHeader()}
                onRowClicked={(row) => {
                    setEditedGroupID(row.groupID);
                    setGroupModalOpen(true);
                }}
                pagination={true}
                rowsPerPage={rowsPerPage.value}
                total={total}
                page={page}
                selectableRows={false}
                onSort={(column: Column<GroupWithMembers>, direction: Order) => {
                    filterForm.setValue('orderBy', {
                        direction,
                        sortedColumn: column.field,
                    });
                }}
            />
            <CreateGroupModal
                companyID={companyID}
                isOpen={isGroupModalOpen}
                refetch={refetch}
                closeModal={() => {
                    setEditedGroupID(undefined);
                    setGroupModalOpen(false);
                }}
                group={groups.find((g) => g.groupID === editedGroupID)}
            />
        </FormProvider>
    );
};

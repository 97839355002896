import { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SidebarProps } from './Sidebar.models';
import { ReactComponent as LogoIcon } from './elementsLogoCommuni.svg';
import { LocalStorage } from '../../utils/LocalStorage.utils';
import { setCurrentUser, setToken } from '../../store/users/UsersActions';
import { useDispatch } from 'react-redux';
import { LogOut } from 'react-feather';
import './Sidebar.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/users/UsersSelectors';


const PRIMARY_COLOR = 'var(--primary)';
const SECONDARY_COLOR = 'var(--white)';
const FONT_COLOR = 'var(--title-grey-color)';

export const Sidebar: FC<SidebarProps> = ({ items }) => {
    const [hoveredItemID, setHoveredItemID] = useState<number>();
    const [selectedItemID, setSelectedItemID] = useState<number>();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const currentUser = useSelector(getCurrentUser);

    useEffect(() => {
        if (pathname) {
            const path = getStringBetweenSlashes(pathname);
            const foundItemIndex = items.findIndex((item) => item.path === path);

            if (foundItemIndex > -1) {
                setSelectedItemID(foundItemIndex);
            } else {
                setSelectedItemID(0);
            }
        }
    }, [items, pathname]);

    const getStringBetweenSlashes = (path: string): string | null => {
        const parts = path.split('/');
        return parts?.[1];
    };

    const handleItemMouse = (itemID?: number) => {
        setHoveredItemID(itemID);
    };

    const getBackground = (index: number) => {
        return hoveredItemID === index || selectedItemID === index ? PRIMARY_COLOR : SECONDARY_COLOR;
    };

    const getColor = (index: number) => {
        return hoveredItemID === index || selectedItemID === index ? SECONDARY_COLOR : FONT_COLOR;
    };

    // const isCurrentRoute = (route: string) => getStringBetweenSlashes(pathname) === route;

    return (
        <header className={`side-bar`}>
            <div className={'logo'}>
                <LogoIcon />
            </div>
            {items
                .filter((i) => i.roles?.includes(currentUser?.userType) && i.description)
                .map((item, index) => {
                    return (
                        <div key={item.path} className={`nav`}>
                            <Link
                                className="link"
                                // reloadDocument={isCurrentRoute(item.path)}
                                to={`/${item.path}`}
                                style={{ backgroundColor: getBackground(index) }}
                                // onClick={() => setSelectedItemID(index)}
                                onMouseEnter={() => handleItemMouse(index)}
                                onMouseLeave={() => handleItemMouse()}>
                                {item.description?.icon && (
                                    <item.description.icon width={20} height={20} size={20} color={getColor(index)} />
                                )}
                                {item.description?.name && (
                                    <span className={`desc`}>{t(item.description?.name)}</span>
                                )}
                            </Link>
                        </div>
                    );
                })}
            <div
                className={`nav`}
                onClick={() => {
                    LocalStorage.removeItem('token');
                    dispatch(setCurrentUser(undefined));
                    dispatch(setToken(null));
                }}>
                <Link
                    to={''}
                    className="link"
                    onMouseEnter={() => handleItemMouse(items.length + 1)}
                    onMouseLeave={() => handleItemMouse()}
                    style={{ backgroundColor: getBackground(items.length + 1) }}>
                    <LogOut width={20} height={20} color={getColor(items.length + 1)} />
                    <span className={`desc`}>{t('disconnect')}</span>
                </Link>
            </div>
        </header>
    );
};

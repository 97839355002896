import { FC, useCallback, useEffect, useState } from 'react';
import './store.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { StoreManagement } from './StoreManagement';
import { Home } from 'react-feather';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';
import { Tabs } from '../tabs/Tabs';
import {
    CategoryForm,
    defaultStoreFilterForm,
    initialCategory,
    initialProduct,
    initialSupplier,
    Product,
    ProductForm,
    StoreFilterForm,
    storeTabsNames,
    SupplierForm,
} from './store.models';
import { StoreProduct } from './StoreProduct';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { useQuery } from 'react-query';
import {
    getCategoryByIDAPI,
    getProductsAPI,
    getSupplierByIDAPI,
    getSuppliersAPI,
    variantsOptionsAPI,
} from '../../api/store.api';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { productSchema } from './product-validation';
import { StoreSuppliers } from './StoreSuppliers';
import { useUpdateEffect } from '../../hooks/useUpdateEffect';
import { convertHtmlToEditState, duplicateProduct } from './store.utils';
import { StoreSuppliersStep } from './StoreSuppliersStep';
import { supplierSchema } from './supplier-validation';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { EditorState } from 'draft-js';
import { v4 } from 'uuid';
import { ErrorToast } from '../toaster/Toast';
import { CategoryManagement } from './CategoryManagement';
import { StoreCategoryStep } from './StoreCategoryStep';
import { categorySchema } from './category-validation';
import { getAllCategories } from '../../store/store/StoreSelectors';
import { closeModal, raiseModal } from '../../store/modal/modal.actions';

export type StoreMode =
    | 'management'
    | 'product'
    | 'categories'
    | 'createCategory'
    | 'editCategory'
    | 'suppliers'
    | 'createSupplier'
    | 'editSupplier';

export const Store: FC = () => {
    const { t } = useTranslation();
    const productForm = useForm<ProductForm>({
        defaultValues: initialProduct(),
        mode: 'onTouched',
        resolver: yupResolver(productSchema),
    });
    const categoryForm = useForm<CategoryForm>({
        defaultValues: initialCategory(),
        mode: 'onTouched',
        resolver: yupResolver(categorySchema),
    });
    const supplierForm = useForm<SupplierForm>({
        defaultValues: initialSupplier,
        mode: 'onTouched',
        resolver: yupResolver(supplierSchema),
    });
    const productManagementForm = useForm<StoreFilterForm>({
        defaultValues: defaultStoreFilterForm,
        mode: 'onTouched',
    });
    const currentUser = useSelector(getCurrentUser);
    const [breadCrumbs, setBreadCrumbs] = useState([t('store')]);
    const [currentStep, setCurrentStep] = useState(0);
    const [currentMode, setCurrentMode] = useState<StoreMode>('management');
    const {
        formState: { isDirty: productFormDirty },
    } = productForm;
    const {
        formState: { isDirty: supplierFormDirty },
    } = supplierForm;
    const allCategories = useSelector(getAllCategories);
    const { data: variantsOptions } = useQuery({
        queryKey: ['variantsOptions'],
        queryFn: variantsOptionsAPI,
    });
    const { data: { data: suppliers } = {} } = useQuery({
        queryKey: ['getSuppliers'],
        queryFn: async () => {
            return getSuppliersAPI();
        },
        enabled: true,
    });
    const dispatch = useDispatch();
    const [productID, setProductID] = useState<string>();
    const { refetch } = useQuery({
        queryKey: ['product', productID],
        queryFn: async () => {
            if (!productID) return;

            return getProductsAPI<Product>({ productID, all: true }).then((res) => {
                const product: Product = res?.data?.[0];
                if (product) {
                    onProductEdit(product);
                } else {
                    ErrorToast(t('productNotFound'));
                }
            });
        },
        enabled: true,
    });
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (id === 'new') {
            onCreateOrEditProduct(undefined);
        } else if (id) {
            setProductID(id);
        } else {
            setCurrentStep(0);
            setBreadCrumbs([t('store')]);
            setCurrentMode('management');
        }
    }, [id]);

    useEffect(() => {
        if (productID) {
            refetch();
        }
    }, [productID, refetch]);

    const onProductEdit = async (product: Product, keepImages?: boolean, originalProduct?: Product) => {
        setCurrentMode('product');
        const productTitle = product?.productID ? 'עריכת מוצר' : 'הוספת מוצר חדש';

        if (breadCrumbs[breadCrumbs.length - 1] !== productTitle) {
            setBreadCrumbs([...breadCrumbs, productTitle]);
        }

        const productFormValues: ProductForm = {
            ...product,
            availabilitySupplier: product.availabilitySupplier !== 'not_available',
            variant: product.variant?.id
                ? {
                      id: product.variant?.id,
                      isMain: product.variant?.isMain,
                      variants: (product.variant?.variants ?? [])?.map((variant) => {
                          return {
                              type: { label: t(variant.type), value: variant.type },
                              value: variant.value,
                          };
                      }),
                  }
                : undefined,
            isDigital: product.isDigital? true : false,
            items: product.items? product.items : [],
            availableItemsCount: product.availableItemsCount? product.availableItemsCount : 0,
        };

        if (product) {
            productFormValues.category = {
                label: product.categoryName,
                value: product.categoryID,
            };

            productFormValues.description = product.description
                ? convertHtmlToEditState(product.description)
                : EditorState.createEmpty();

            productFormValues.specifications = product.specifications
                ? convertHtmlToEditState(product.specifications)
                : EditorState.createEmpty();

            productFormValues.warranty = product.warranty
                ? convertHtmlToEditState(product.warranty)
                : EditorState.createEmpty();

            if (!keepImages) {
                productFormValues.mainImageURL = productFormValues.mainImage;
                productFormValues.additionalImagesURLs = productFormValues.additionalImages.map((ai) => {
                    return { url: ai, imageURL: ai };
                });
            }

            if (originalProduct) {
                productFormValues.mainImageURL = originalProduct?.mainImage ?? '';
                productFormValues.additionalImagesURLs =
                    originalProduct?.additionalImages.map((ai) => {
                        return { url: ai, imageURL: ai };
                    }) ?? [];
            }
        }

        productForm.reset(productFormValues);
    };

    const handleDuplicateProduct = async (originalProduct: Product) => {
        const duplicatedProduct = await duplicateProduct(originalProduct, t);

        if (duplicatedProduct) {
            onProductEdit(duplicatedProduct, true, originalProduct);
        }
    };

    useUpdateEffect(() => {
        if (currentStep === 0) {
            setCurrentMode('management');
            setBreadCrumbs([t('store')]);
        } else if (currentStep === 1) {
            setCurrentMode('categories');
            setBreadCrumbs([t('categories')]);
        } else {
            setCurrentMode('suppliers');
            setBreadCrumbs([t('suppliers')]);
        }
    }, [currentStep]);

    const onStageReset = () => {
        const mode =
            currentMode === 'management' || currentMode === 'product'
                ? '0'
                : currentMode === 'categories' ||
                  currentMode === 'createCategory' ||
                  currentMode === 'editCategory'
                ? '1'
                : '2';

        switch (mode) {
            case '0': {
                setCurrentStep(0);
                setBreadCrumbs([t('store')]);
                setCurrentMode('management');
                break;
            }
            case '1': {
                setCurrentStep(1);
                setBreadCrumbs([t('categories')]);
                setCurrentMode('categories');
                break;
            }
            case '2': {
                setCurrentStep(2);
                setBreadCrumbs([t('suppliers')]);
                setCurrentMode('suppliers');
                break;
            }
        }

        navigate('/store');
        setProductID(undefined);
    };

    const renderStep = () => {
        switch (currentMode) {
            case 'management':
                return (
                    <StoreManagement
                        duplicateProduct={handleDuplicateProduct}
                        onCreateOrEdit={onCreateOrEditProduct}
                        storeCategories={allCategories}
                        productManagementForm={productManagementForm}
                        suppliers={suppliers}
                    />
                );
            case 'product':
                return (
                    <StoreProduct
                        form={productForm}
                        onStageReset={() => {
                            setCurrentMode('management');
                            setBreadCrumbs([t('store')]);
                            setProductID(undefined);
                        }}
                        storeCategories={allCategories}
                        variantsOptions={variantsOptions}
                        handleDuplicateProduct={handleDuplicateProduct}
                    />
                );
            case 'suppliers':
                return <StoreSuppliers onCreateSupplier={onCreateSupplier} onEditSupplier={onEditSupplier} />;
            case 'createSupplier':
            case 'editSupplier':
                return (
                    <FormProvider {...supplierForm}>
                        <StoreSuppliersStep
                            onStageReset={() => {
                                setCurrentMode('suppliers');
                                setBreadCrumbs([t('suppliers')]);
                            }}
                        />
                    </FormProvider>
                );
            case 'categories':
                return (
                    <CategoryManagement
                        onCreateOrEditCategory={onCreateOrEditCategory}
                        suppliers={suppliers}
                        allStoreCategories={allCategories}
                    />
                );
            case 'createCategory':
            case 'editCategory':
                return (
                    <FormProvider {...categoryForm}>
                        <StoreCategoryStep
                            allStoreCategories={allCategories}
                            onStageReset={() => {
                                setCurrentMode('categories');
                                setBreadCrumbs([t('categories')]);
                            }}
                        />
                    </FormProvider>
                );
        }
    };

    const onCreateSupplier = async () => {
        supplierForm.reset({ ...initialSupplier, supplierID: v4() });
        setCurrentMode('createSupplier');
        setBreadCrumbs([...breadCrumbs, t('addNewSupplier')]);
    };

    const onEditSupplier = async (supplierID: string) => {
        const supplier = await getSupplierByIDAPI(supplierID);
        supplierForm.reset({
            ...supplier,
            APIService: { label: supplier.APIService, value: supplier.APIService },
        });
        setCurrentMode('editSupplier');
        setBreadCrumbs([...breadCrumbs, t('editSupplier')]);
    };

    const onCreateOrEditProduct = async (chosenProductID: string | undefined) => {
        if (chosenProductID === productID) {
            if (!chosenProductID) {
                productForm.reset(initialProduct());
            }

            setCurrentMode('product');
            const productTitle = chosenProductID ? t('editProduct') : t('addNewProduct');
            setBreadCrumbs([...breadCrumbs, productTitle]);
        } else {
            productForm.reset({ ...initialProduct() });
            setProductID(chosenProductID);
        }
    };

    const onCreateOrEditCategory = async (chosenCategoryID: string | undefined) => {
        if (!chosenCategoryID) {
            const categoryID = v4();
            categoryForm.reset({
                ...initialCategory(),
                categoryID,
                supplierCategoryID: categoryID,
            });
            setCurrentMode('createCategory');
            setBreadCrumbs([...breadCrumbs, t('addNewCategory')]);
        } else {
            const category = await getCategoryByIDAPI(chosenCategoryID);
            categoryForm.reset({
                ...category,
                url: category.categoryImageURL,
            });
            setCurrentMode('editCategory');
            setBreadCrumbs([...breadCrumbs, t('editCategory')]);
        }
    };

    const handleBack = useCallback(
        (onBack: () => void, onStageReset: () => void) => {
            if (currentMode === 'product' ? productFormDirty : supplierFormDirty) {
                dispatch(
                    raiseModal({
                        isOpen: true,
                        header: { title: t('back') },
                        content: t('areYouSureChangesWillNotBeSaved'),
                        footer: {
                            buttons: [
                                {
                                    label: t('close'),
                                    theme: 'secondary',
                                    callback: () => dispatch(closeModal()),
                                },
                                {
                                    label: t('continueWithoutSave'),
                                    theme: 'primary',
                                    callback: () => {
                                        onBack();
                                        dispatch(closeModal());
                                    },
                                },
                            ],
                        },
                    })
                );
            } else {
                onStageReset();
            }
        },
        [dispatch, currentMode, productFormDirty, supplierFormDirty]
    );

    return (
        <div className={'store'}>
            <BreadCrumbs
                breadCrumbTitle={t('store')}
                icon={<Home width={13} height={13} color={'var(--primary)'} />}
                breadCrumbs={breadCrumbs}
                onBack={() => {
                    handleBack(() => {
                        onStageReset();
                        navigate('/store');
                    }, onStageReset);
                }}
            />
            {currentUser?.userType === 'admin' &&
                (currentMode === 'management' ||
                    currentMode === 'categories' ||
                    currentMode === 'suppliers') && (
                    <div className={'steps-tabs'}>
                        <Tabs
                            activeTab={currentStep + 1}
                            toggleTab={(step) => setCurrentStep(step - 1)}
                            tabs={storeTabsNames}
                        />
                    </div>
                )}
            <FormProvider {...productForm}>{renderStep()}</FormProvider>
        </div>
    );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type UserRowReport = {
    firstName: string;
    lastName: string;
    phone: string;
    reason?: Record<string, string>[];
};

type UploadUserReportTableProps = {
    data: UserRowReport[];
};

export const UploadUserReportTable: FC<UploadUserReportTableProps> = ({ data }) => {
    const { t } = useTranslation();

    return (
        <table className={'report-table'}>
            <tbody>
                <tr>
                    <th>{t('firstName')}</th>
                    <th>{t('lastName')}</th>
                    <th>{t('phone')}</th>
                    {data?.[0]?.reason && <th>{t('errors')}</th>}
                </tr>
            </tbody>

            {data?.map((e, i) => (
                <tr key={i}>
                    <td>{e.firstName}</td>
                    <td>{e.lastName}</td>
                    <td>{e.phone}</td>
                    {e?.reason && (
                        <td style={{ display: 'flex', flexDirection: 'column', border: '1px' }}>
                            {e?.reason?.map((a: any, i) => (
                                <span key={i}>{a && Object.values(a)}</span>
                            ))}
                        </td>
                    )}
                </tr>
            ))}
        </table>
    );
};

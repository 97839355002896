import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useUpdateEffect } from '../../hooks/useUpdateEffect';
import { PlusCircle } from 'react-feather';
import { TableHeader } from '../table/TableHeader';
import { Button } from '../form/buttons/Button';
import { Table } from '../table/Table';
import {
    addMonthlyBudgetAPI,
    deleteCompanyAPI,
    getCompaniesAPI,
    toggleFreezeCompanyAPI,
} from '../../api/companies.api';
import { CompanyShort } from '../../models/login.models';
import { companiesManagementTableColumns, populateCompaniesData } from './companies.utils';
import { CompaniesFilterForm, CompaniesTableData, defaultCompaniesValues } from './companies.models';
import 'react-toastify/dist/ReactToastify.css';
import { SuccessToast } from '../toaster/Toast';
import { Column } from '../table/Tables.types';
import { Order } from '../../hooks/useSort';
import { useTranslation } from 'react-i18next';
import { ChooseCompany } from '../choose-company/ChooseCompany';
import { ModalIcon } from '../modal/ModalIcon';
import { AreYouSureModal } from '../modals/AreYouSure.modal';
import { useNavigate } from 'react-router-dom';
import { useFormWithSession } from '../../hooks/useFormSession';
import { GetInstances } from '../../api/api.models';

type CompaniesManagementProps = {
    onCreate0rEdit: (id: string | undefined) => void;
};

export const CompaniesManagement: FC<CompaniesManagementProps> = () => {
    const currentUser = useSelector(getCurrentUser);
    const form = useForm<CompaniesFilterForm>({
        defaultValues: defaultCompaniesValues,
        mode: 'onTouched',
    });
    const { t } = useTranslation();
    const rowsPerPage = useWatch({ name: 'rowsPerPage', control: form.control });
    const page = useWatch({ name: 'page', control: form.control });
    const orderBy = useWatch({ name: 'orderBy', control: form.control });
    const companyName = useWatch({ name: 'companyName', control: form.control });
    const [areYouSureDeleteID, setAreYouSureDeleteID] = useState<string>();
    const navigate = useNavigate();
    const [filters, setFilters] = useState<GetInstances<CompanyShort>>(buildFiltersFromForm());

    const { data: { data: companies = [], total = 0 } = {}, refetch: forceRefetch } = useQuery({
        queryKey: ['getCompanies', filters],
        queryFn: async () => {
            return getCompaniesAPI<CompanyShort>(filters);
        },
        enabled: true,
    });
    useFormWithSession<CompaniesFilterForm>(form, (values: CompaniesFilterForm) =>
        setFilters(buildFiltersFromSession(values))
    );

    function buildFiltersFromForm() {
        return {
            companyID: currentUser?.companyID,
            name: companyName?.label,
            offset: page * rowsPerPage.value,
            count: rowsPerPage.value,
            sortBy: orderBy.direction,
            sortField: orderBy.sortedColumn,
            isExtended: true,
        };
    }

    function buildFiltersFromSession(values: CompaniesFilterForm): GetInstances<CompanyShort> {
        const { companyName, page, rowsPerPage, orderBy } = values;

        return {
            companyID: currentUser?.companyID,
            name: companyName?.label,
            offset: page * rowsPerPage.value,
            count: rowsPerPage.value,
            sortBy: orderBy.direction,
            sortField: orderBy.sortedColumn,
            isExtended: true,
        };
    }

    const refetch = () => {
        setFilters(buildFiltersFromForm());
    };

    const refetchOrResetPage = () => {
        if (page !== 0) {
            form.setValue('page', 0);
        } else {
            refetch();
        }
    };

    useUpdateEffect(() => {
        refetchOrResetPage();
    }, [rowsPerPage.value, orderBy]);

    useUpdateEffect(() => {
        refetch();
    }, [page]);

    const addOrEditCompany = (id: string | undefined) => {
        // onCreate0rEdit(id);
        navigate(`${id ?? 'new'}`);
    };

    const handleOnDeleteCompany = (id: string) => {
        setAreYouSureDeleteID(id);
    };

    const confirmDelete = (id: string) => {
        deleteCompanyAPI(id).then(() => {
            SuccessToast(t('operationSuccess'));
            setAreYouSureDeleteID(undefined);
            refetch();
        });
    };

    const handleFreezeCompany = (id: string) => {
        toggleFreezeCompanyAPI(id).then(() => {
            SuccessToast(t('operationSuccess'));
            forceRefetch();
        });
    };

    const addMonthlyBudget = () => {
        addMonthlyBudgetAPI().then(() => {
            SuccessToast(t('operationSuccess'));
            refetch();
        });
    };

    const CustomHeader = () => {
        return (
            <TableHeader
                rowsPerPage={rowsPerPage.value}
                leftSideComponents={[
                    <Button
                        key={0}
                        theme={'primary'}
                        onClick={() => addOrEditCompany(undefined)}
                        icon={<PlusCircle width={14} />}>
                        {t('addNewCompany')}
                    </Button>,
                    currentUser?.userType === 'admin' && (
                        <Button key={1} theme={'secondary'} onClick={addMonthlyBudget}>
                            {t('addMonthlyBudget')}
                        </Button>
                    ),
                ]}
                rightSideComponents={[
                    <ChooseCompany name={'companyName'} isDisabled={currentUser?.userType !== 'admin'} />,
                ]}
            />
        );
    };

    return (
        <FormProvider {...form}>
            <div className={'management'}>
                <Table
                    columns={companiesManagementTableColumns(
                        currentUser?.userType,
                        (row: CompaniesTableData) => handleOnDeleteCompany(row.companyID)
                    )}
                    data={populateCompaniesData(companies, handleFreezeCompany, addOrEditCompany)}
                    header={CustomHeader()}
                    onRowClicked={(row: CompaniesTableData) => addOrEditCompany(row.companyID)}
                    pagination={true}
                    rowsPerPage={rowsPerPage.value}
                    total={total}
                    selectableRows={false}
                    onSort={(column: Column<CompanyShort>, direction: Order) => {
                        let actualDirection = direction;
                        if (column.field === 'isFrozen') {
                            actualDirection = actualDirection === 'ASC' ? 'DESC' : 'ASC';
                        }

                        form.setValue('orderBy', { direction: actualDirection, sortedColumn: column.field });
                    }}
                />
            </div>
            <AreYouSureModal
                onConfirm={() => {
                    if (areYouSureDeleteID) {
                        confirmDelete(areYouSureDeleteID);
                    }
                }}
                icon={<ModalIcon theme={'ALERT'} />}
                closeModal={() => setAreYouSureDeleteID(undefined)}
                title={t('deleteCompany')}
                text={t('areYouSureYouWantToDeleteCompany')}
                isOpen={!!areYouSureDeleteID}
            />
        </FormProvider>
    );
};

import {
    summarizePostsByDate,
    getTopProducts,
    sumPostsLikesReplies,
    fillInMissingDates,
    aggregateByMonth,
} from './dashboard.utils';
import { ChartComponentProps } from '../chart/chart.models';
import React from 'react';
import { Filter } from '../filters/filters.models';

export interface StatisticsCardProps {
    title: string | React.ReactNode;
    data: any;
    chartType: 'bar' | 'line' | 'pie' | 'table';
    transformData: (data: any, fromTimestamp?: number, toTimestamp?: number) => any[];
    chartProps?: ChartComponentProps;
}

interface RowConfig {
    cards: (Omit<StatisticsCardProps, 'title'> & { title: string })[];
    columnSizes: string[];
}

interface Product {
    name: string;
    mainImage: string;
    amount: number;
}

export type DashboardForm = {
    // companyID: SelectOption | null;
    fromTimestamp: number | undefined;
    toTimestamp: number | undefined;
    fromTimestampRef: number | undefined;
    toTimestampRef: number | undefined;
    // redeemedFromTimestamp: number | null;
    // redeemedToTimestamp: number | null;
    // ageFromTimestamp: number | null;
    // ageToTimestamp: number | null;
    // // userID: SelectOption | null;
    // // targetID: SelectOption | null;
    // // managerID: SelectOption | null;
    // gender: SelectOptionT<'male'> | SelectOptionT<'female'> | null;
    // postType: SelectOptionT<PostType> | null;
    // isStyled: SelectOptionT<'yes'> | SelectOptionT<'no'> | null;
    // boostType: SelectOptionT<'gift'> | SelectOptionT<'boost'> | null;
    // giftOrBoostType: SelectOptionT<'product'> | SelectOptionT<'credits'> | SelectOptionT<'compliment'> | null;
    // redeemedGiftOrBoostType:
    //     | SelectOptionT<'product'>
    //     | SelectOptionT<'credits'>
    //     | SelectOptionT<'compliment'>
    //     | null;
};

export const dashboardDefaultValues: DashboardForm = {
    fromTimestamp: new Date(new Date().setDate(new Date().getDate() - 7)).getTime(),
    toTimestamp: new Date().getTime(),
    fromTimestampRef: new Date(new Date().setDate(new Date().getDate() - 15)).getTime(),
    toTimestampRef: new Date(new Date().setDate(new Date().getDate() - 8)).getTime(),
    // ageFromTimestamp: null,
    // ageToTimestamp: null,
    // gender: null,
};

export const dashboardRows: RowConfig[] = [
    {
        cards: [
            {
                title: 'postsLikesRepliesMetric',
                data: {},
                chartType: 'bar',
                transformData: (data, fromTimestamp?, toTimestamp?) => {
                    // Set default timestamps if undefined
                    const safeFromTimestamp = fromTimestamp ?? new Date().getTime() - 7 * 24 * 60 * 60 * 1000;
                    const safeToTimestamp = toTimestamp ?? new Date().getTime();

                    const summarized: any = summarizePostsByDate(data.data.posts.data);
                    console.log(JSON.stringify(summarized));

                    const summarizedWithMissingDates: any = fillInMissingDates(
                        summarized,
                        safeFromTimestamp,
                        safeToTimestamp
                    );

                    const aggregated = aggregateByMonth(summarizedWithMissingDates, 60);
                    return aggregated;
                },
                chartProps: {
                    width: '100%',
                    height: 500,
                    xAxisProps: [
                        {
                            dataKey: 'date',
                            reversed: false,
                        },
                    ],
                    yAxisProps: [
                        {
                            dataKey: 'posts',

                            fill: '#5B45AF',
                            stackId: 'a',
                        },
                        {
                            dataKey: 'replies',

                            fill: '#1B76F2', //1B76F2 //#AEA2D8
                            stackId: 'a',
                        },
                        {
                            dataKey: 'likes',

                            fill: '#FF1F8B',
                            stackId: 'a',
                        },
                    ],
                    formatTickFunction: (dateString: string) => {
                        const parts = dateString.split('/');

                        const day = parts[0];
                        const month = parts[1];

                        const dayNumber = parseInt(day, 10);
                        const monthNumber = parseInt(month, 10);

                        return `${dayNumber}.${monthNumber}`;
                    },
                },
            },
            {
                title: 'totalPostsLikesReplies',
                data: {},
                chartType: 'pie',
                transformData: (data) => {
                    const summerized: any = sumPostsLikesReplies(data.data.posts.data);
                    return summerized;
                },
                chartProps: {
                    width: '100%',
                    height: 500,
                },
            },
        ],
        columnSizes: ['8', '4'],
    },
    {
        cards: [
            {
                title: 'topProducts',
                data: {},
                chartType: 'table',
                transformData: (data) => {
                    const topProducts: any = getTopProducts(data.data.purchases.data, 10);
                    console.log(JSON.stringify(topProducts));
                    return topProducts;
                },

                chartProps: {
                    width: '100%',
                    height: 700,
                    xAxisProps: [
                        {
                            dataKey: 'name',
                            reversed: false,
                        },
                    ],
                    yAxisProps: [
                        {
                            dataKey: 'amount',
                            fill: '#4833a0',
                        },
                    ],
                },
            },
            {
                title: 'topProductsVSRest',
                data: {},
                chartType: 'pie',
                transformData: (data) => {
                    const topProducts: any = getTopProducts(data.data.purchases.data);

                    const topTenProducts: any = topProducts.slice(0, 9);
                    const otherProducts: any = topProducts.slice(9);
                    let totalotherProducts = 0;
                    for (const product of otherProducts) {
                        totalotherProducts += product.amount;
                    }

                    return [
                        ...topTenProducts.map((product: any) => {
                            return { name: product.name, value: product.amount };
                        }),
                        { name: 'otherProducts', value: totalotherProducts },
                    ];
                },
                chartProps: {
                    width: '100%',
                    height: 500,
                },
            },
        ],
        columnSizes: ['8', '4'],
    },
];

export const productsColumns = [
    {
        name: 'Image',
        selector: (row: Product) =>
            React.createElement('img', {
                src: row.mainImage,

                width: 32,
                alt: '',
            }),
        sortable: false,
        field: 'image',
        width: '10%',
    },
    {
        name: 'Product Name',
        selector: (row: Product) => row.name,
        sortable: true,
    },
    {
        name: 'Number of Purchases',
        selector: (row: Product) => row.amount,
        sortable: true,
        width: '20%',
    },
];
export const dashboardTabs = ['פוסטים', 'מוצרים', 'בוסטים', 'נתוני שימוש'];
export type DashboardMetrics = {};
export type FilterWithSteps = Filter & { steps?: number[]; component?: JSX.Element };

import { initReactI18next } from 'react-i18next';
import i18next, { Resource } from 'i18next';
import en from './locales/en/en.common.json';

export const resources: Resource = {
    en: {
        common: en,
    },
} as const;

export const defaultNS = 'common';

i18next.use(initReactI18next).init({
    lng: 'en',
    ns: [defaultNS],
    defaultNS,
    resources,
});

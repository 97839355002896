import { TableRow } from 'react-data-table-component';
import { CompaniesTableData, Company } from './companies.models';
import { Check, Edit2, Trash, X } from 'react-feather';
import { v4 } from 'uuid';
import { UserIcon } from '../user-icon/UserIcon';
import { AvatarGroup } from '../avatar/AvatarGroup';
import { Group, GroupForm, GroupsTableData, GroupWithMembers } from '../../models/groups.models';
import { getInitialsByName } from '../../utils/initials.utils';
import { SmartSwitch } from '../form/switch/SmartSwitch';
import { UserType } from '../users/users.models';

export const populateCompaniesData = (
    companies: Company[],
    handleFreezeCompany: (companyID: string) => void,
    onCompanyEdit: (companyID: string) => void
): CompaniesTableData[] => {
    return companies.map<CompaniesTableData>((company) => {
        const { name, companyContact, companyID, budget, isFrozen, employeesAmount, budgetApproved } =
            company;
        const budgetApprovedIcon = budgetApproved ? <Check width={19} /> : <X width={19} />;

        return {
            name: (
                <div onClick={() => onCompanyEdit(companyID)} style={{ cursor: 'pointer' }}>
                    {name ?? ''}
                </div>
            ),
            companyID,
            employeesAmount: employeesAmount,
            contactFullName: companyContact?.HRName,
            phone: companyContact?.HRPhone,
            email: companyContact?.HREmail,
            budget: `${budget ?? 0}`,
            budgetApproved: budgetApprovedIcon,
            isActive: (
                <SmartSwitch
                    type="switch"
                    name={`switch-${companyID}`}
                    checked={!isFrozen}
                    onClick={() => handleFreezeCompany(companyID)}
                />
            ),
        };
    });
};

export const companiesManagementTableColumns = (
    currentUserType: UserType | undefined,
    handleDeleteCompany: (company: CompaniesTableData) => void
) => [
    {
        name: 'שם חברה',
        selector: (row: TableRow) => row.name,
        sortable: true,
        field: 'name',
    },
    {
        name: 'מספר עובדים',
        selector: (row: TableRow) => row.employeesAmount,
        sortable: false,
        field: 'employeesAmount',
    },
    {
        name: 'איש קשר',
        selector: (row: TableRow) => row.contactFullName,
        sortable: false,
        field: 'companyContact.HRName',
    },
    {
        name: 'טלפון',
        selector: (row: TableRow) => row.phone,
        sortable: false,
        field: 'companyContact.HRPhone',
    },
    {
        name: 'אימייל',
        selector: (row: TableRow) => row.email,
        sortable: false,
        field: 'companyContact.HREmail',
    },
    {
        name: 'תקציב סל',
        selector: (row: TableRow) => row.budget,
        sortable: true,
        field: 'budget',
    },
    {
        name: 'אושר תקציבית',
        selector: (row: TableRow) => row.budgetApproved,
        sortable: true,
        field: 'budgetApproved',
    },
    {
        name: 'פעילה',
        selector: (row: TableRow) => row.isActive,
        sortable: true,
        field: 'isFrozen',
    },
    {
        name: '',
        selector: (row: TableRow) => row.editDelete,
        sortable: false,
        cell: (row: CompaniesTableData) => (
            <span className="text-capitalize table-text">
                {currentUserType === 'admin' && (
                    <span className={'table-icon'}>
                        <Trash onClick={() => handleDeleteCompany(row)} width={20} />
                    </span>
                )}
                <span className={'table-icon'}>
                    <Edit2 width={20} />
                </span>
            </span>
        ),
    },
];

export const buildFormGroup = (groupForm: GroupForm): Group => {
    const groupID = groupForm.groupID.length ? groupForm.groupID : v4();
    const ownerID = groupForm.ownerID;

    return {
        groupID,
        companyID: groupForm.companyID,
        ownerID,
        name: groupForm.name,
        type: 'company',
        imageURL: groupForm.imageURL,
    };
};

export const GroupsTableColumns = (handleDeleteGroup: (id: string) => void) => [
    {
        name: 'שם קבוצה',
        selector: (row: TableRow) => row.name,
        width: '15%',
        sortable: true,
    },
    {
        name: 'תמונה',
        selector: (row: TableRow) => row.image,
        width: '15%',
        sortable: false,
    },
    {
        name: 'חברים בקבוצה',
        selector: (row: TableRow) => row.members,
        width: '30%',
        sortable: false,
    },
    {
        name: '',
        selector: (row: TableRow) => row.editDelete,
        sortable: false,
        cell: (row: GroupsTableData) => (
            <span className="text-capitalize table-text">
                <span className={'table-icon'}>
                    <Trash onClick={() => handleDeleteGroup(row?.groupName)} width={20} />
                </span>
                <span className={'table-icon'}>
                    <Edit2 width={20} />
                </span>
            </span>
        ),
    },
];

export const populateGroupsData = (groups: GroupWithMembers[]): GroupsTableData[] => {
    return groups.map((group) => {
        const images = group.members.map((user) => {
            const initials = getInitialsByName(`${user.firstName} ${user.lastName}`);
            return { imageURL: user.imageURL, initials };
        });

        return {
            groupID: group.groupID,
            name: group.name ?? '',
            groupName: group.name,
            image: <UserIcon initials={getInitialsByName(group.name)} imageURL={group.imageURL} />,
            members: <AvatarGroup data={images} />,
        };
    });
};

import { useController, useFormContext } from 'react-hook-form';
import { FC } from 'react';
import './select.scss';
import { Label } from 'reactstrap';
import classNames from 'classnames';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { SingleValue } from 'react-select';
import { Option } from 'react-google-places-autocomplete/build/types';
import { customStyles } from './select';
import { CustomLoadingMessage, CustomNoOptionsMessage } from './auto-complete';

type SmartPlacesAutoCompleteProps = {
    name: string;
    containerClass?: string;
    label: string;
    isDisabled?: boolean;
    placeholder?: string;
    type?: 'address' | '(cities)';
    prefix?: string;
    required?: boolean;
};

export const SmartPlacesAutoComplete: FC<SmartPlacesAutoCompleteProps> = ({
    name,
    type,
    isDisabled,
    placeholder,
    containerClass,
    label,
    prefix,
    required,
}) => {
    const { control } = useFormContext();
    const { field, fieldState } = useController({ name, control });

    const formatPlace = async (place: SingleValue<Option>) => {
        if (type) {
            const placeName = (await place?.value?.structured_formatting?.main_text) ?? '';
            await field.onChange(placeName);
        } else {
            await field.onChange(place?.label);
        }
    };

    return (
        <div className={containerClass} style={{ width: '100%' }}>
            {label && (
                <Label className="form-label" for={`${label}-input`}>
                    {label}
                    {required && <span className={'required'}>*</span>}
                </Label>
            )}
            <GooglePlacesAutocomplete
                selectProps={{
                    // @ts-ignore
                    styles: customStyles,
                    className: classNames(
                        'react-select',
                        { 'react-select-disabled': isDisabled },
                        `${fieldState.error ? 'control error' : ''}`
                    ),
                    placeholder,
                    value: { label: field?.value ?? '', value: field?.value ?? '' },
                    onChange: (newValue) => formatPlace(newValue),
                    isDisabled,
                    onInputChange: (newValue) => `${prefix ?? ''} ${newValue}`.trim(),
                    onBlur: (value) => {
                        if (value?.target?.value) {
                            field.onChange(`${prefix ?? ''} ${value.target?.value}`.trim());
                        }
                    },
                    onFocus: () => field.onChange(''),
                    isClearable: true,
                    noOptionsMessage: ({ inputValue }) => <CustomNoOptionsMessage inputValue={inputValue} />,
                    loadingMessage: ({ inputValue }) => <CustomLoadingMessage inputValue={inputValue} />,
                }}
                apiOptions={{ region: 'IL', language: 'he-IL' }}
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}
                autocompletionRequest={type ? { types: [type] } : undefined}
            />

            <label className="error-label">{fieldState?.error?.message}</label>
        </div>
    );
};

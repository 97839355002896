import * as Yup from 'yup';
import { ERROR_MESSAGES } from '../../utils/validations';

export const createPostSchema = Yup.object().shape({
    content: Yup.string().required(ERROR_MESSAGES.required),
    image: Yup.string().nullable(),
    company: Yup.object().nullable().required(ERROR_MESSAGES.required),
    behalfOf: Yup.object({
        label: Yup.string().nullable().required(ERROR_MESSAGES.required),
        value: Yup.string().nullable().required(ERROR_MESSAGES.required),
    })
        .nullable()
        .required(ERROR_MESSAGES.required),
    campaign: Yup.string().nullable(),
    groups: Yup.lazy(() =>
        Yup.array()
            .nullable()
            .when(['users', 'groups'], {
                is: (users: string[], groups: string[]) =>
                    (!users || users.length === 0) && (!groups || groups.length === 0),
                then: Yup.array()
                    .nullable()
                    .min(1, 'יש לבחור לפחות קבוצה או משתמש אחד')
                    .required(ERROR_MESSAGES.required),
            })
    ),
    users: Yup.lazy(() =>
        Yup.array()
            .nullable()
            .when(['users', 'groups'], {
                is: (users: string[], groups: string[]) =>
                    (!users || users.length === 0) && (!groups || groups.length === 0),
                then: Yup.array()
                    .nullable()
                    .min(1, 'יש לבחור לפחות קבוצה או משתמש אחד')
                    .required(ERROR_MESSAGES.required),
            })
    ),
    publishAtDate: Yup.lazy(() =>
        Yup.date()
            .nullable()
            .when(['publishAtDate', 'publishAtTime'], {
                is: (publishAtDate: number | null | undefined, publishAtTime: number | null | undefined) =>
                    !!publishAtDate || !!publishAtTime,
                then: Yup.date().nullable().required(ERROR_MESSAGES.required),
            })
    ),
    publishAtTime: Yup.lazy(() =>
        Yup.string()
            .nullable()
            .when(['publishAtDate', 'publishAtTime'], {
                is: (publishAtDate: number | null | undefined, publishAtTime: number | null | undefined) =>
                    !!publishAtDate || !!publishAtTime,
                then: Yup.string().required(ERROR_MESSAGES.required),
            })
    ),
});

import { TableRow } from 'react-data-table-component';
import { Copy, Edit2, Trash } from 'react-feather';
import {
    CategoriesForm,
    CategoriesTableData,
    communiSupplierName,
    Product,
    StoreCategory,
    StoreTableData,
    Supplier,
    SupplierTableData,
} from './store.models';
import { formatUnixTimestamp, getImageURL } from '../../utils/utils';
import { SmartSwitch } from '../form/switch/SmartSwitch';
import { UserType } from '../users/users.models';
import { Chip } from '../chip/Chip';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import he from 'he';
import { TFunction } from 'i18next';
import { getCategoriesAPI, getDuplicateProductImagesAPI } from '../../api/store.api';
import { v4 } from 'uuid';
import { SelectOption } from '../../models/shared.models';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { ChangeEvent, FC, useMemo, useState, Dispatch, useRef } from 'react';
import { Button } from '../form/buttons/Button';
import { ErrorToast } from '../toaster/Toast';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import { fixQuotes } from '../users/users.utils';


export const storeManagementTableColumns = (t: TFunction<'translation'>, userType: UserType) => {
    return [
        {
            name: t('image'),
            selector: (row: TableRow) => row.image,
            sortable: false,
            field: 'image',
        },
        {
            name: t('productName'),
            selector: (row: TableRow) => row.title,
            sortable: true,
            field: 'title',
            minWidth: '12%',
        },
        {
            name: t('brand'),
            selector: (row: TableRow) => row.brand,
            sortable: true,
            field: 'brand',
        },
        {
            name: t('price'),
            selector: (row: TableRow) => row.price,
            sortable: true,
            field: 'price',
            maxWidth: '3%',
        },
        {
            name: t('category'),
            selector: (row: TableRow) => row.categoryID,
            sortable: true,
            field: 'categoryID',
        },
        {
            name: t('supplier'),
            selector: (row: TableRow) => row.supplier,
            sortable: true,
            field: 'supplier',
        },
        {
            name: t('statusInventory'),
            selector: (row: TableRow) => row.availabilitySupplier,
            sortable: true,
            field: 'availabilitySupplier',
        },
        userType === 'admin' && {
            name: t('isForSale'),
            selector: (row: TableRow) => row.isForSale,
            sortable: true,
            field: 'isForSale',
        },
        {
            name: t('showInStore'),
            selector: (row: TableRow) => row.showInStore,
            sortable: true,
            field: 'showInStore',
        },
        userType === 'admin' && {
            name: t('hotSale'),
            selector: (row: TableRow) => row.hotSale,
            sortable: true,
            field: 'hotSale',
        },
        {
            name: t('enteredCatalogTime'),
            selector: (row: TableRow) => row.enteredCatalogTime,
            sortable: true,
            field: 'enteredCatalogTime',
        },
        {
            name: '',
            selector: (row: TableRow) => row.edit,
            sortable: false,
            minWidth: '10%',
        },
    ].filter((a) => !!a);
};

export const populateProductsData = (
    t: TFunction<'translation'>,
    userType: UserType,
    products: Product[],
    categories: StoreCategory[],
    handleToggleHotSale: (productID: string) => void,
    handleToggleHideOrShow: (productID: string) => void,
    handleToggleForSale: (productID: string) => void,
    handleDuplicateProduct: (productID: string) => void,
    handleDeleteProduct: (productID: string) => void
): StoreTableData[] => {
    return products?.map<StoreTableData>((product) => {
        const {
            productID,
            availabilitySupplier,
            supplier,
            brand_he,
            price,
            categoryID,
            enteredCatalogTime,
            hotSale,
            isShown,
            shownByCompany,
            mainImage,
            isForSale,
            title,
            variant,
        } = product;

        return {
            image: <img src={getImageURL(mainImage)} width={32} alt={title} />,
            productID,
            title,
            brand: brand_he,
            price,
            categoryID: categories?.find((c) => c.categoryID === categoryID)?.categoryName ?? '',
            supplier: supplier,
            availabilitySupplier: (
                <Chip
                    variant={availabilitySupplier !== 'not_available' ? 'success' : 'danger'}
                    label={t(availabilitySupplier)}
                />
            ),
            hotSale: (
                <SmartSwitch
                    type="switch"
                    name={`switch-hotSale-${productID}`}
                    checked={hotSale}
                    onClick={() => handleToggleHotSale(productID)}
                />
            ),
            showInStore: (
                <SmartSwitch
                    type="switch"
                    name={`switch-isShown-${productID}`}
                    disabled={userType === 'HR' && !isShown}
                    checked={userType === 'admin' ? isShown : userType === 'HR' ? !!shownByCompany : false}
                    onClick={() => handleToggleHideOrShow(productID)}
                />
            ),
            isForSale: (
                <SmartSwitch
                    type="switch"
                    name={`switch-forSale-${productID}`}
                    disabled={supplier !== 'COMMUNi'}
                    checked={isForSale}
                    onClick={() => handleToggleForSale(productID)}
                />
            ),
            enteredCatalogTime: formatUnixTimestamp(enteredCatalogTime),
            edit: (
                <span className="text-capitalize table-text-icon" style={{ textAlign: 'left' }}>
                    {userType === 'admin' && variant && (
                        <Copy
                            onClick={() => handleDuplicateProduct(productID)}
                            width={20}
                            fill={variant?.isMain ? 'var(--black)' : 'none'}
                        />
                    )}
                    <Edit2 width={20} />
                    {userType === 'admin' && (
                        <Trash onClick={() => handleDeleteProduct(productID)} width={20} />
                    )}
                </span>
            ),
        };
    });
};

export const populateSuppliersData = (
    suppliers: Supplier[],
    onActiveClick: (supplierID: string) => void
): SupplierTableData[] => {
    return suppliers?.map<SupplierTableData>((supplier) => {
        const {
            supplierID,
            APIService,
            supplierName,
            contactName,
            contactPhone,
            contactEmail,
            APILink,
            isActive,
        } = supplier;

        return {
            supplierName,
            APILink,
            contactEmail,
            contactName,
            contactPhone,
            supplierID,
            APIService,
            isActive: (
                <SmartSwitch
                    type="switch"
                    name={`switch-hotSale-${supplierID}`}
                    checked={isActive}
                    disabled={supplierName === communiSupplierName}
                    onClick={() => onActiveClick(supplierID)}
                />
            ),
        };
    });
};

export const suppliersTableColumns = (
    t: TFunction<'translation'>,
    onDeleteClick: (supplierID: string) => void
) => {
    return [
        {
            name: t('supplierName'),
            selector: (row: TableRow) => row.supplierName,
            sortable: true,
            field: 'supplierName',
            width: '10%',
        },
        {
            name: t('APILink'),
            selector: (row: TableRow) => row.APILink,
            sortable: false,
            field: 'APILink',
            width: '25%',
        },
        {
            name: t('contact'),
            selector: (row: TableRow) => row.contactName,
            sortable: true,
            field: 'contactName',
            width: '10%',
        },
        {
            name: t('phone'),
            selector: (row: TableRow) => row.contactPhone,
            sortable: true,
            field: 'contactPhone',
            width: '10%',
        },
        {
            name: t('contactEmail'),
            selector: (row: TableRow) => row.contactEmail,
            sortable: true,
            field: 'contactEmail',
            width: '12%',
        },

        {
            name: t('APIService'),
            selector: (row: TableRow) => row.APIService,
            sortable: false,
            field: 'APIService',
            width: '10%',
        },
        {
            name: t('isActive'),
            selector: (row: TableRow) => row.isActive,
            sortable: false,
            field: 'isActive',
            width: '8%',
        },
        {
            name: '',
            sortable: false,
            cell: (row: SupplierTableData) => (
                <span className="text-capitalize table-text-icon">
                    {row.supplierName !== communiSupplierName && (
                        <Trash onClick={() => onDeleteClick(row.supplierID)} width={20} />
                    )}
                    <Edit2 width={20} />
                </span>
            ),
        },
    ];
};

export const convertEditorStateToHtml = (data: EditorState): string => {
    try {
        return draftToHtml(convertToRaw(data.getCurrentContent()));
    } catch (e) {
        console.error('convert EditState to html failed');
        return '';
    }
};

export const convertHtmlToEditState = (data?: string): EditorState => {
    if (!data) {
        return EditorState.createEmpty();
    }

    try {
        const blocksFromHTML = convertFromHTML(
            he.decode(replaceReactToHtml(data.replace(/<img[^>]*>/g, '')))
        );
        const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        );
        return EditorState.createWithContent(state);
    } catch (e) {
        console.error('convert html to EditState failed');
        return EditorState.createEmpty();
    }
};

export const replaceReactToHtml = (data: string): string => {
    return replaceReactImageToHtmlImage(data);
};

export const replaceReactImageToHtmlImage = (data: string): string => {
    return data.replaceAll(RegExp(/<img.*?alt="(.*?)"[^]+>/g), (e) => {
        return e.replace('style="', '').replaceAll(': ', '="').replace(';', '" ');
    });
};

export const getRanHex = (size = 8) => {
    let result = [];
    let hexRef = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'];

    for (let n = 0; n < size; n++) {
        result.push(hexRef[Math.floor(Math.random() * 16)]);
    }
    return result.join('');
};

export const duplicateProduct = async (originalProduct: Product | undefined, t: TFunction<'translation'>) => {
    if (!originalProduct) return;

    const productImages = await getDuplicateProductImagesAPI(originalProduct.productID);
    const duplicateProductID = v4();
    const duplicateProduct: Product = {
        ...originalProduct,
        ...productImages,
        productID: duplicateProductID,
        catalogNumberID: duplicateProductID,
        shownByCompany: true,
        isNew: true,
        title: `${t('cloneOf')} ${originalProduct.title}`,
        enteredCatalogTime: Date.now(),
        supplier: communiSupplierName,
        variant: originalProduct.variant && { ...originalProduct.variant, isMain: false },
        isShown: false,
    };

    // @ts-ignore
    delete duplicateProduct._id;

    return duplicateProduct;
};

export const categoriesTableColumns = (
    t: TFunction<'translation'>,
    onCategoryDelete: (categoryID: string) => void
) => {
    return [
        {
            name: t('categoryName'),
            selector: (row: TableRow) => row.categoryName,
            sortable: true,
            field: 'categoryName',
            width: '20%',
        },
        {
            name: t('supplierName'),
            selector: (row: TableRow) => row.supplierID,
            sortable: true,
            field: 'supplierID',
            width: '12%',
        },
        {
            name: t('parentsCategories'),
            selector: (row: TableRow) => row.parentCategoriesNames,
            sortable: false,
            field: 'parentCategoriesNames',
            width: '30%',
        },
        {
            name: t('childrenCategories'),
            selector: (row: TableRow) => row.childrenCategoriesNames,
            sortable: false,
            field: 'childrenCategoriesNames',
            width: '30%',
        },
        {
            name: '',
            selector: (row: TableRow) => row.editDelete,
            sortable: false,
            cell: (row: CategoriesTableData) => (
                <span className="text-capitalize table-text">
                    <span className={'table-icon'}>
                        <Edit2 width={20} />
                    </span>
                    <span className={'table-icon'}>
                        <Trash onClick={() => onCategoryDelete(row.categoryID)} width={20} />
                    </span>
                </span>
            ),
        },
    ];
};

export const populateCategoriesData = (categories: StoreCategory[]): Partial<CategoriesTableData>[] => {
    return categories?.map((category) => {
        const { categoryID, parentCategories, childrenCategories, categoryName, supplierID } = category;

        return {
            categoryID,
            categoryName,
            supplierID,
            parentCategoriesNames: parentCategories?.map((pc) => pc.label)?.join(', '),
            childrenCategoriesNames: childrenCategories?.map((pc) => pc.label)?.join(', '),
        };
    });
};

// export const getRecursiveCategories = (storeCategories: StoreCategory[]) => {
//     return storeCategories
//         ?.map<SelectOption>((category) => {
//             let name = category.categoryName;
//             let currentCategory: StoreCategory | undefined = storeCategories?.find((ca) =>
//                 category?.parentCategoryID?.includes(ca.categoryID)
//             );

//             while (currentCategory) {
//                 name = `${currentCategory.categoryName} -> ${name}`;

//                 currentCategory = storeCategories?.find((ca) =>
//                     currentCategory?.parentCategoryID?.includes(ca.categoryID)
//                 );
//             }

//             return { label: name, value: category.categoryID };
//         })
//         .sort((a, b) => (a.label > b.label ? 1 : -1));
// };

export const getRecursiveCategories = (storeCategories: StoreCategory[]) => {
    return storeCategories
        ?.map<SelectOption>((category) => {
            let name = category.categoryName;
            let currentCategory: StoreCategory | undefined = storeCategories?.find((ca) =>
                category?.parentCategoryID?.includes(ca.categoryID)
            );

            const visitedCategoryIDs = new Set<string>();

            while (currentCategory) {
                if (visitedCategoryIDs.has(currentCategory.categoryID)) {
                    break;
                }

                visitedCategoryIDs.add(currentCategory.categoryID);
                name = `${currentCategory.categoryName} -> ${name}`;

                currentCategory = storeCategories?.find((ca) =>
                    currentCategory?.parentCategoryID?.includes(ca.categoryID)
                );
            }

            return { label: name, value: category.categoryID };
        })
        .sort((a, b) => (a.label > b.label ? 1 : -1));
};

export const categoriesLoadOptions = async (
    recursiveCategories: SelectOption[] | undefined,
    inputValue: string
) => {
    if (!recursiveCategories) return [];

    const filteredCategories = recursiveCategories.filter((rc) =>
        rc.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
    );

    return filteredCategories.map((category) => ({
        label: category.label,
        value: category.value,
    }));
};

export const exportCategories = async (formValues: CategoriesForm) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');
    worksheet.views = [{ rightToLeft: true }];

    const { name, supplier, parentCategory, childrenCategory } = formValues;

    const categories = await getCategoriesAPI<StoreCategory>({
        name: name ?? undefined,
        supplierID: supplier?.label,
        parentCategoryID: parentCategory?.value,
        childrenCategoryID: childrenCategory?.value,
        all: false,
    });
    const adjustedCategories = categories?.data?.map((c) => {
        return {
            Category_Name: c.categoryName,
            Supplier_Category_Name: c.supplierCategoryName,
            Supplier: c.supplierID,
            Parent_Categories: c.parentCategories?.map((c) => c.label)?.join(', '),
            Children_Categories: c.childrenCategories?.map((c) => c.label)?.join(', '),
        };
    });

    if (adjustedCategories?.[0]) {
        worksheet.addRow(Object.keys(adjustedCategories[0]));
    }

    adjustedCategories.forEach((item) => worksheet.addRow(Object.values(item)));

    worksheet.columns.forEach((column) => {
        column.width = 20;
    });

    const csvData = await workbook.csv.writeBuffer();
    const csvBlob = new Blob(['\uFEFF' + csvData], {
        type: 'text/csv; charset=utf-8',
    });
    saveAs(csvBlob, `categories.csv`);
};

export const DEFAULT_FILE_SIZE = 10; // MB
export const DEFAULT_ACCEPTED_FILES_TYPES = '.csv';

export type DigitalInventoryUploadProps = {
    onChange: (url: string) => void;
    label?: string;
    onUpload?: (file: File) => void;
    direction?: 'row' | 'column';
    disabled?: boolean;
};

export const DigitalInventoryUpload: FC<DigitalInventoryUploadProps> = ({
    onChange,
    label,
    onUpload,
    disabled,
    direction = 'row',
}) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [key, setKey] = useState(0);
    const { t } = useTranslation();

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const imageSizeIsValid = (selectedFileSize: number) => {
        return selectedFileSize <= DEFAULT_FILE_SIZE;
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setKey(key + 1);
        const file = event?.target?.files?.[0];
        const fileName = file?.name.toLowerCase();

        const selectedFileSize = (file?.size ?? 0) / 1024 / 1024; // MB
        if (
            !!file && [DEFAULT_ACCEPTED_FILES_TYPES].some((format) => fileName?.endsWith(format))
        ) {
            onUpload?.(file);
        } else {
            ErrorToast(
                `There was an error uploading. Make sure it's of the right format`
            );
        }
    };

    return (
        <div className={'file-upload-section'}>
            <div className={'file-upload'}>
                <div className={'upload-buttons'}>
                    {label ?? ''}
                    <input
                        disabled={disabled}
                        key={key}
                        type="file"
                        accept={DEFAULT_ACCEPTED_FILES_TYPES}
                        onChange={(e) => handleChange(e)}
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                    />
                    <span
                        style={{
                            display: 'flex',
                            flexDirection: direction,
                            gap: '8px',
                            alignItems: 'center',
                        }}>
                        <span style={{ display: 'flex', gap: '16px' }}>
                            <Button
                                disabled={disabled}
                                theme={'primary'}
                                onClick={handleUploadClick}
                                className={'mini'}>
                                {t('addDigitalProductInventory')}
                            </Button>
                        </span>
                        {`${t('filesInFormat')} ${DEFAULT_ACCEPTED_FILES_TYPES}`}
                    </span>
                </div>
            </div>
        </div>
    );
};

export const parseInventory = async <T,>(file: File): Promise<any[]> => {
    return new Promise<any[]>((resolve, reject) => {
        const updateTime = Date.now();
        var uploadedInventoryItems: any[] = [];
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target?.result as ArrayBuffer;
            const text = new TextDecoder('utf-8').decode(data);
            
            const workbook = XLSX.read(text, {
                type: 'buffer',
                cellText: true,
                raw: true,
                codepage: 65001,
            });
            
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            
            const range = XLSX.utils.decode_range(worksheet['!ref'] as string);
            for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
                for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
                    const cellAddress = { r: rowNum, c: colNum };
                    const cell = worksheet[XLSX.utils.encode_cell(cellAddress)];
                    
                    if (cell && cell.t === 's') {
                        // Directly access the cell value and apply fixQuotes
                        cell.v = fixQuotes(cell.v);
                    }
                }
            }
            let result = XLSX.utils.sheet_to_json(worksheet);
            for (let index = 0; index < result.length; index++) {
                let data: any = result[index];
                let item = {link: data[" SLINK URL"],
                            number: data["Card Number"],
                            updateTime: updateTime,
                            isActive: true};
                uploadedInventoryItems.push(item);
            } 
            resolve(uploadedInventoryItems);
        };
        reader.readAsArrayBuffer(file);
    })
};
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyShort } from '../../models/login.models';
import { CompanyUniqueField } from '../../models/shared.models';

interface CompaniesState {
    companies: CompanyShort[];
    editedCompanyUniqueFields: CompanyUniqueField[];
}

const initialState: CompaniesState = {
    companies: [],
    editedCompanyUniqueFields: [],
};

export const CompaniesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        setCompanies: (state: CompaniesState, action: PayloadAction<CompanyShort[]>) => {
            state.companies = action.payload;
        },
        setEditedCompanyUniqueFields: (
            state: CompaniesState,
            action: PayloadAction<CompanyUniqueField[]>
        ) => {
            state.editedCompanyUniqueFields = action.payload;
        },
    },
});

import { FC, ReactElement } from 'react';
import { InnerModal } from '../modal/InnerModal/InnerModal';
import { Modal } from './Modal';
import { useTranslation } from 'react-i18next';

type AreYouSureModalProps = {
    title: string;
    text: string;
    onConfirm: () => void;
    closeModal: () => void;
    isOpen: boolean;
    icon?: ReactElement | null;
};

export const AreYouSureModal: FC<AreYouSureModalProps> = ({
    closeModal,
    onConfirm,
    text,
    icon,
    title,
    isOpen,
}) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} onClose={closeModal}>
            <InnerModal
                content={text}
                header={{ title, icon }}
                footer={{
                    buttons: [
                        {
                            label: t('confirm'),
                            theme: 'danger',
                            callback: onConfirm,
                        },
                        {
                            label: t('cancel'),
                            theme: 'grey',
                            callback: closeModal,
                        },
                    ],
                }}
            />
        </Modal>
    );
};

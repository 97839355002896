import './post.scss';
import { Post as PostModel } from '../home/home.models';
import { PostHeader } from './PostHeader';
import { Heart, MessageSquare, MoreVertical, Calendar } from 'react-feather';
import { DropdownButton } from '../form/buttons/DropdownButton';
import { getImageURL } from '../../utils/utils';

type PostProps = {
    post: PostModel;
    deletePost: () => void;
};

export const Post = ({ post, deletePost }: PostProps) => {
    return (
        <div className={'post-container'}>
            <PostHeader
                imageURL={post.publisher?.publisherImageURL}
                fullName={post.publisher?.publisherFullName}
                userRole={post.publisher?.publisherRole}
                timestamp={post.timestamp}
                postType={post.type}
                giftDetails={post.giftDetails}
                target={post.targetUserFullName}
                publisherPronoun={post.publisher?.publisherPronoun}
            />
            <div className={'post-content'}>
                <div className={'text-content'} dangerouslySetInnerHTML={{ __html: post.content }}></div>
                <div className={'post-assets'}>
                    {post.postAssetsURLs?.map((asset, key) => (
                        <img
                            src={getImageURL(asset.url)}
                            height={asset.height}
                            className={'post-asset'}
                            alt={''}
                            key={key}
                        />
                    ))}
                </div>
                <div className={'post-details-and-settings'}>
                    <span className={'post-detail-big-gap'}>{post.eventID && <Calendar />}</span>
                    <span className={'post-detail'}>
                        {post.repliesAmount}
                        <MessageSquare />
                    </span>
                    <span className={'post-detail'}>
                        {post.likes}
                        <Heart />
                    </span>
                    <span className={'post-detail detail-cursor'}>
                        <DropdownButton
                            options={[{ label: 'מחק פוסט', onClick: deletePost }]}
                            buttonLabel={<MoreVertical color={'black'} width={'24px'} height={'24px'} />}
                        />
                    </span>
                </div>
            </div>
        </div>
    );
};

import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { AutoCompleteValue } from '../form/select/auto-complete';
import { ReactElement } from 'react';
import { ButtonTheme } from '../form/buttons/Button';

export type Filter =
    | InputFilter
    | SelectFilter
    | CheckboxFilter
    | DateFilter
    | MultiSelectFilter
    | ButtonFilter
    | AutoComplete
    | Separator
    | Custom;

interface baseForm {
    name: string;
    placeholder?: string;
    isDisabled?: boolean;
}

export interface Separator {
    type: FiltersModels.SEPARATOR;
}

export interface Custom {
    type: FiltersModels.CUSTOM;
    component?: ReactElement;
}

export interface InputFilter extends baseForm {
    type: FiltersModels.INPUT;
    onChange?: (value: string) => void;
    label?: string;
    placeholder?: string;
    fullRowSize?: boolean;
    icon?: any;
    onKeyDown?: (e: KeyboardEvent) => void;
}

export interface CheckboxFilter extends baseForm {
    type: FiltersModels.CHECK_BOX;
    onChange?: (value: string) => void;
    label?: string;
    fullRowSize?: boolean;
}

export interface AutoComplete extends baseForm {
    type: FiltersModels.AUTO_COMPLETE;
    onChange?: (value: string) => void;
    getLoadOptionsData: (inputValue: string) => Promise<AutoCompleteValue[]>;
    label: string;
    isMulti?: boolean;
    placeholder?: string;
    onKeyDown?: (e: KeyboardEvent) => void;
}

export interface SelectFilter extends baseForm {
    type: FiltersModels.SELECT;
    onChange?: (value: { value: string; label: string; filter: string }) => void;
    label: string;
    options?: { value: string | boolean; label: string }[];
    fullRowSize?: boolean;
    onKeyDown?: (e: KeyboardEvent) => void;
    filterOption?:
        | ((
              option: FilterOptionOption<{
                  value: string;
                  label: string;
              }>,
              inputValue: string
          ) => boolean)
        | null;
}

export interface MultiSelectFilter extends baseForm {
    type: FiltersModels.MULTI_SELECT;
    onChange?: (value: { value: string; label: string }[]) => void;
    label: string;
    options?: { value: string | boolean; label: string }[];
    fullRowSize?: boolean;
    onKeyDown?: (e: KeyboardEvent) => void;
    filterOption?:
        | ((
              option: FilterOptionOption<{
                  value: string;
                  label: string;
              }>,
              inputValue: string
          ) => boolean)
        | null;
}

export interface DateFilter extends baseForm {
    type: FiltersModels.DATE;
    onChange?: (value: Date[]) => void;
    placeholder?: string;
    label?: string;
    fullRowSize?: boolean;
    onKeyDown?: (e: KeyboardEvent) => void;
}

export interface ButtonFilter {
    type: FiltersModels.BUTTON;
    onClick: () => void;
    label: string;
    fullRowSize?: boolean;
    sizeBootstrap?: number;
    icon?: ReactElement;
    //theme?: ButtonTheme;
}

export enum FiltersModels {
    INPUT = 'INPUT',
    SELECT = 'SELECT',
    CHECK_BOX = 'CHECK_BOX',
    DATE = 'DATE',
    MULTI_SELECT = 'MULTI_SELECT',
    BUTTON = 'BUTTON',
    AUTO_COMPLETE = 'AUTO_COMPLETE',
    SEPARATOR = 'SEPARATOR',
    CUSTOM = 'CUSTOM',
}

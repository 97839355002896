import { Button as ButtonStrap } from 'reactstrap';
import { CSSProperties, FC, ReactElement, ReactNode } from 'react';
import './button.scss';
import { Position } from '../../modal/Modal.utils';

export type ButtonTheme =
    | 'primary'
    | 'secondary'
    | 'none'
    | 'danger'
    | 'grey'
    | 'outline-danger'
    | 'outline-primary';

type ButtonProps = {
    theme: ButtonTheme;
    className?: string;
    children?: ReactNode;
    onClick: () => void;
    style?: CSSProperties;
    disabled?: boolean;
    icon?: ReactElement;
    direction?: Position;
};

export const Button: FC<ButtonProps> = ({
    theme = 'primary',
    disabled,
    icon,
    className,
    children,
    onClick,
    style,
    direction,
}) => {
    const buttonIconClassName = direction === 'left' ? 'modal-button-icon-left' : 'modal-button-icon-right';

    return (
        <ButtonStrap
            className={`button-general ${className} ${theme} ${buttonIconClassName}`}
            style={{ ...style, padding: icon ? 0 : '7px 22px' }}
            color={theme}
            disabled={disabled}
            onClick={onClick}>
            <span className={`${icon ? 'button-and-icon' : ''} ${buttonIconClassName}`}>
                {icon}
                {children}
            </span>
        </ButtonStrap>
    );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { InnerModal } from '../modal/InnerModal/InnerModal';
import { Modal } from './Modal';
import { useHandleBack } from '../../hooks/useHandleBack';
import { CreateListItem } from './CreateVariant';

type CreateListItemModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    onConfirm: (name: string) => void;
    label: string;
};

export const CreateListItemModal: FC<CreateListItemModalProps> = ({
    isOpen,
    closeModal,
    onConfirm,
    label,
}) => {
    const createListItemForm = useForm<{ createdListItemName: string }>({
        mode: 'onTouched',
        defaultValues: { createdListItemName: '' },
    });
    const { isDirty } = createListItemForm.formState;
    const handleBack = useHandleBack(isDirty);
    const { t } = useTranslation();

    const handleClose = () => {
        handleBack(onClose, onClose);
    };

    const onClose = () => {
        closeModal();
        createListItemForm.reset({ createdListItemName: '' });
    };

    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleConfirm();
        }
    };

    const handleConfirm = () => {
        onConfirm(createListItemForm.getValues('createdListItemName'));
        createListItemForm.reset();
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <InnerModal
                header={{ title: t(label) }}
                content={
                    <CreateListItem
                        form={createListItemForm}
                        name={'createdListItemName'}
                        onKeyDown={onKeyDown}
                    />
                }
                footer={{
                    position: 'left',
                    buttons: [
                        {
                            label: t('close'),
                            theme: 'grey',
                            callback: handleClose,
                        },
                        {
                            label: t('save'),
                            callback: handleConfirm,
                            theme: 'primary',
                        },
                    ],
                }}
            />
        </Modal>
    );
};

import classNames from 'classnames';
import Select from 'react-select';
import { useController, useFormContext } from 'react-hook-form';
import { FC, useState } from 'react';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import './select.scss';
import { Label } from 'reactstrap';
import { customStyles } from './select';

type SmartSelectProps = StateManagerProps & {
    name: string;
    containerClass?: string;
    label: string;
    hideError?: boolean;
};

export const SmartSelect: FC<
    Omit<SmartSelectProps, 'onChange' | 'onKeyDown'> & {
        onChange?: (value: string) => void;
        onKeyDown?: (e: KeyboardEvent) => void;
    }
> = ({ name, containerClass, label, hideError, onKeyDown, ...rest }) => {
    const { control } = useFormContext();
    const { field, fieldState } = useController({ name, control });
    const [searchValue, setSearchValue] = useState('');

    const handleInputChange = (newValue: string) => {
        setSearchValue(newValue);
    };

    const filteredOptions = rest.options?.filter((option) =>
        String((option as { label: string }).label)
            ?.toLowerCase()
            .includes(searchValue.toLowerCase())
    );

    return (
        <div className={`container-field ${containerClass}`}>
            {label && (
                <Label for={`${label}-input`} className="form-label">
                    {label}
                    {rest.required && <span className={'required'}>*</span>}
                </Label>
            )}
            <Select
                {...rest}
                styles={customStyles}
                value={field.value}
                className={classNames(
                    'react-select',
                    { 'react-select-disabled': rest.isDisabled },
                    `${fieldState.error ? 'control error' : ''}`
                )}
                onChange={(e) => {
                    rest.onChange?.(e?.value);
                    field.onChange(e);
                }}
                menuPortalTarget={document.body}
                onInputChange={handleInputChange}
                filterOption={() => true}
                isSearchable={true}
                options={filteredOptions}
                menuPosition={'fixed'}
                onKeyDown={(e) => onKeyDown?.(e as unknown as KeyboardEvent)}
            />
            {!hideError && (
                <label className="error-label">
                    {/*@ts-ignore*/}
                    {fieldState?.error?.message || fieldState?.error?.value?.message}
                </label>
            )}
        </div>
    );
};

import { createSlice } from '@reduxjs/toolkit';

interface LoadingState {
    numActiveRequests: number;
}

const initialState: LoadingState = {
    numActiveRequests: 0,
};

export const LoadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        incrementActiveRequests(state) {
            state.numActiveRequests = state.numActiveRequests + 1;
            return state;
        },
        decrementActiveRequests(state) {
            state.numActiveRequests = state.numActiveRequests - 1;
            return state;
        },
    },
});

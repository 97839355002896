import * as Yup from 'yup';
import { ERROR_MESSAGES } from '../../utils/validations';

export const categorySchema = Yup.object().shape({
    categoryID: Yup.string().required(ERROR_MESSAGES.required).nullable(),
    categoryName: Yup.string().required(ERROR_MESSAGES.required).nullable(),
    categoryImageURL: Yup.string().nullable(),
    supplierID: Yup.string().nullable(), //.required(ERROR_MESSAGES.required)
    supplierCategoryID: Yup.string().nullable(), //.required(ERROR_MESSAGES.required)
    supplierCategoryName: Yup.string().nullable(),
    parentCategoryID: Yup.array().nullable(),
});

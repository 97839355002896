import { FormProvider, useForm, useWatch } from 'react-hook-form';
import {
    CustomTimelineItemData,
    HistoryUpdate,
    OrderForm,
    orderFormInitialValue,
    Purchase,
    PurchaseStatus,
    UpdatePurchaseStatus,
} from './order.models';
import { useQuery } from 'react-query';
import { getPurchaseByID, updatePurchaseStatusAPI } from '../../api/store.api';
import { FC } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { SmartSelect } from '../form/select/smart-select';
import { formatTimestamp, getImageURL } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import { SuccessToast } from '../toaster/Toast';
import { Button } from '../form/buttons/Button';
import { Save } from 'react-feather';
import { useHandleBack } from '../../hooks/useHandleBack';
import { Input } from '../form/input-and-checkbox/Input';
import { useParams } from 'react-router';
import CustomTimeline from '../timeline/timeline';

import './orderPage.scss';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { useSelector } from 'react-redux';

const getTimelineItems = (
    purchase: Purchase,
    t: (key: string) => string,
    orderStatus?: PurchaseStatus[],
    internalOrderStatuses?: PurchaseStatus[]
) => {
    let timelineItems: CustomTimelineItemData[] = [];

    if (!purchase.purchaseHistory || purchase.purchaseHistory.length === 0) {
        timelineItems.push({
            status: t(purchase.purchaseStatus),
            timestamp: +purchase.statusUpdateTimeStamp,
            userFullName: purchase.userFullName || '',
            note: '',
            type: 'regular',
            position: 'left' as 'right' | 'left',
        });
    } else {
        timelineItems = purchase.purchaseHistory.map((item) => ({
            status: t(item.status),
            timestamp: item.timestamp,
            userFullName: item.userFullName,
            note: item.note,
            type: item.type,
            position: item.type === 'regular' ? 'right' : ('left' as 'right' | 'left'),
        }));
    }

    return timelineItems;
};

const renderContent = (item: CustomTimelineItemData) => (
    <div>
        <div className="status-header">{item.status}</div>
        <div className="status-body">{item.note}</div>
        <div className="status-body">{item.userFullName}</div>
        <div className="status-body"> {formatTimestamp(item.timestamp)}</div>
    </div>
);

// const renderOppositeContent = (item: { timestamp: number; userID: string }) => (
//     <div>
//         <p>{new Date(item.timestamp * 1000).toLocaleTimeString()}</p>
//         <p>{item.userID}</p>
//     </div>
// );

type OrderPageProps = {
    onStageReset: () => void;
    orderStatuses?: PurchaseStatus[];
    internalOrderStatuses?: PurchaseStatus[];
};

export const OrderPage: FC<OrderPageProps> = ({ onStageReset, orderStatuses, internalOrderStatuses }) => {
    const form = useForm<OrderForm>({
        defaultValues: orderFormInitialValue,
        mode: 'onTouched',
    });
    const { control, formState, getValues, reset, getFieldState } = form;
    const { isDirty } = form.formState;
    const handleBack = useHandleBack(isDirty);
    const { t } = useTranslation();
    const productImageURL = useWatch({ control: form.control, name: 'productImageURL' });
    const summary = useWatch({ control: form.control, name: 'summary' });
    const hasBeenRefunded = useWatch({ control: form.control, name: 'hasBeenRefunded' });
    const variants = useWatch({ control: form.control, name: 'variants' });
    const { order: purchaseID } = useParams();
    const currentUser = useSelector(getCurrentUser);

    const {
        data: purchase,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ['purchaseID', purchaseID],
        queryFn: async () => {
            if (!purchaseID) return;

            const purchase = await getPurchaseByID(purchaseID);

            if (!purchase) {
                handleBack(onStageReset, onStageReset);
                return;
            }

            const formValues: OrderForm = {
                ...purchase,
                ...purchase?.product,
                description: purchase?.product?.description,
                originalPrice: +purchase?.product?.originalPrice,
                price: +purchase?.product?.price,
                productID: purchase?.product?.productID,
                subtitle: purchase?.product?.subtitle ?? '',
                summary: purchase?.product?.summary,
                supplier: purchase?.product?.supplierID,
                title: purchase?.product?.summary,
                productImageURL: purchase?.product.mainImage,
                variants:
                    purchase?.product?.variant?.variants?.map((variant) => {
                        return {
                            label: t(variant.type),
                            value: variant.value,
                        };
                    }) ?? [],
                productName: purchase?.product?.productName,
                statusUpdateTimeStamp: purchase.statusUpdateTimeStamp.toString(),
                timestamp: purchase.timestamp.toString(),
                purchaseStatus: { label: t(purchase.purchaseStatus), value: t(purchase.purchaseStatus) },
                internalPurchaseStatus: {
                    label: t(purchase.internalPurchaseStatus),
                    value: purchase.internalPurchaseStatus,
                },
                userFullName: purchase?.userFullName ?? '',
                userPhone: purchase?.userPhone ?? '',
                note: '',
                purchaseHistory: [],
            };

            if (purchase.moneyAmount) {
                if (purchase.appName === 'apple') {
                    formValues.paymentMethod = 'Apple pay';
                } else {
                    formValues.paymentMethod = `${purchase.creditCardCompany} ${purchase.creditCardLastDigits}`;
                }
            }

            formValues.timestamp = formatTimestamp(purchase.timestamp);
            formValues.statusUpdateTimeStamp = formatTimestamp(purchase.statusUpdateTimeStamp);
            form.reset(formValues);

            return purchase;
        },
        enabled: true,
    });

    const confirmSave = (): void => {
        const purchaseForm = form.getValues();
        const { purchaseStatus, internalPurchaseStatus, note } = purchaseForm;

        const isPurchaseStatusDirty = getFieldState('purchaseStatus').isDirty;
        const isInternalPurchaseStatusDirty = getFieldState('internalPurchaseStatus').isDirty;
        const isNoteDirty = getFieldState('note').isDirty;

        if (!purchaseStatus?.value || !internalPurchaseStatus?.value) return;

        const historyUpdates: HistoryUpdate[] = [];

        const addHistoryUpdate = (status: string, type: 'regular' | 'internal'): void => {
            historyUpdates.push({
                status,
                timestamp: Date.now(),
                userFullName: `${currentUser?.firstName} ${currentUser?.lastName}`,
                note: note ?? '',
                type,
            });
        };

        if (!purchase?.purchaseHistory || purchase?.purchaseHistory.length === 0) {
            historyUpdates.push({
                status: purchase?.purchaseStatus!,
                timestamp: +purchase?.statusUpdateTimeStamp!,
                userFullName: purchase?.userFullName || '',
                note: '',
                type: 'regular',
            });
        }

        if (isPurchaseStatusDirty) {
            addHistoryUpdate(purchaseStatus.value, 'regular');
        }

        if (isInternalPurchaseStatusDirty) {
            addHistoryUpdate(internalPurchaseStatus.value, 'internal');
        }

        if (!isPurchaseStatusDirty && !isInternalPurchaseStatusDirty && isNoteDirty) {
            addHistoryUpdate(purchaseStatus.value, 'regular');
        }

        if (historyUpdates.length === 0) return;

        const updatePurchaseStatus: UpdatePurchaseStatus = {
            purchaseID: purchaseForm.purchaseID,
            purchaseStatus: purchaseStatus.value,
            internalPurchaseStatus: internalPurchaseStatus.value,
            historyUpdates,
        };

        updatePurchaseStatusAPI(updatePurchaseStatus).then(() => {
            SuccessToast(t('actionSucceeded'));
            form.reset({
                ...purchaseForm,
                note: '',
            });
            refetch();
        });
    };

    return (
        <FormProvider {...form}>
            <Container fluid>
                <Row>
                    <Col sm="12" md="8" lg="6" className="column-content">
                        <div className={'step'}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-evenly',
                                }}>
                                <div>
                                    <SmartInput label={t('productName')} name={'productName'} disabled />
                                    <div className={'step-row'}>
                                        {variants?.map((v, i) => {
                                            return (
                                                <div className={'variant'} key={i}>
                                                    <Input
                                                        required
                                                        value={t(v.label)}
                                                        label={t('variantType')}
                                                        disabled
                                                    />
                                                    <SmartInput
                                                        required
                                                        name={`variant.variants.${i}.value`}
                                                        label={t('variantDescription')}
                                                        disabled
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={'variant'}>
                                        <SmartInput
                                            label={t('originalPrice')}
                                            name={'originalPrice'}
                                            disabled
                                        />
                                        <SmartInput label={t('price')} name={'price'} disabled />
                                    </div>
                                </div>
                            </div>
                            <img
                                src={getImageURL(productImageURL)}
                                alt=""
                                width={'100%'}
                                height={'250px'}
                                style={{ borderRadius: '20px' }}
                            />
                            <div className={'step-row'}>{summary}</div>

                            <div className={'step-title'}>{t('orderDetails')}</div>

                            <SmartInput label={t('subName')} name={'subtitle'} disabled />
                            <SmartInput label={t('supplier')} name={'supplier'} disabled />
                            <SmartInput
                                label={t('catalogNumberID')}
                                name={'product.catalogNumberID'}
                                disabled
                            />
                            <SmartInput label={t('wayOfPayment')} name={'paymentMethod'} disabled />
                            <SmartInput label={t('amountPayedInCreditCard')} name={'moneyAmount'} disabled />
                            <SmartInput label={t('amountPayedInCredits')} name={'creditsAmount'} disabled />
                            <SmartInput label={t('shippingAddress')} name={'address'} disabled />
                            <SmartInput label={t('userFullName')} name={'userFullName'} disabled />
                            <SmartInput label={t('userPhone')} name={'userPhone'} disabled />

                            <div className={'step-title'}>{t('orderStatus')}</div>

                            <SmartInput label={t('orderNumber')} name={'purchaseID'} disabled />
                            <SmartInput label={t('orderTime')} name={'timestamp'} disabled />
                            <SmartSelect
                                label={t('status')}
                                name={'purchaseStatus'}
                                options={orderStatuses?.map((ps) => ({
                                    label: t(ps?.name),
                                    value: ps?.name,
                                }))}
                                isDisabled={hasBeenRefunded}
                            />
                            <SmartSelect
                                label={t('internalStatus')}
                                name={'internalPurchaseStatus'}
                                options={internalOrderStatuses?.map((ps) => ({
                                    label: t(ps?.name),
                                    value: ps?.name,
                                }))}
                                isDisabled={hasBeenRefunded}
                            />
                            <SmartInput label={t('note')} name={'note'} />
                        </div>
                        <div className={'step-row'}>
                            <div className={'step-footer'}>
                                <div className={'inputs-separator'} />
                                <div className={'save-and-cancel'}>
                                    <Button
                                        theme={'grey'}
                                        onClick={() => handleBack(onStageReset, onStageReset)}>
                                        {t('cancel')}
                                    </Button>
                                    <Button theme={'primary'} onClick={confirmSave} icon={<Save size={14} />}>
                                        {t('save')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm="12" md="4" lg="6" className="column-content">
                        <CustomTimeline
                            items={
                                purchase
                                    ? getTimelineItems(purchase, t, orderStatuses, internalOrderStatuses)
                                    : []
                            }
                            renderContent={renderContent}
                            //renderOppositeContent={renderOppositeContent}
                        />
                    </Col>
                </Row>
            </Container>
        </FormProvider>
    );
};

import { FC } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { Company } from './companies.models';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { ImageUpload } from '../image-upload/ImageUpload';
import { SmartColorInput } from '../smart-color-input/SmartColorInput';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { getCompanyImagePath, uploadFile } from '../../api/storage.api';
import { ErrorToast } from '../toaster/Toast';
import { useTranslation } from 'react-i18next';
import { SmartPlacesAutoComplete } from '../form/select/places-auto-complete';

type CompaniesStep1Props = {
    form: UseFormReturn<Company>;
};

export const CompaniesStep1: FC<CompaniesStep1Props> = ({ form }) => {
    const logoUrl = useWatch({ control: form.control, name: 'logoUrl' });
    const whiteLogoUrl = useWatch({ control: form.control, name: 'whiteLogoUrl' });
    const squareLogoUrl = useWatch({ control: form.control, name: 'squareLogoUrl' });
    const city = useWatch({ control: form.control, name: 'address.city' });
    const currentUser = useSelector(getCurrentUser);
    const { t } = useTranslation();

    const uploadPicLogo = async (file: File) => {
        const companyID = form.getValues('companyID');
        try {
            const key = Date.now();
            const signedURL = await getCompanyImagePath(companyID, key);
            const path = `${companyID}/company/${key}`;

            await uploadFile(signedURL, file);
            form.setValue('logo', path);
            form.setValue('logoUrl', signedURL);
        } catch (e) {
            ErrorToast(t('errorUploadingFile'));
        }
    };

    const uploadPicWhiteLogo = async (file: File) => {
        const companyID = form.getValues('companyID');
        try {
            const key = Date.now();
            const signedURL = await getCompanyImagePath(companyID, key);
            const path = `${companyID}/company/${key}`;

            await uploadFile(signedURL, file);
            form.setValue('whiteLogo', path);
            form.setValue('whiteLogoUrl', signedURL);
        } catch (e) {
            ErrorToast(t('errorUploadingFile'));
        }
    };

    const uploadPicSquareLogo = async (file: File) => {
        const companyID = form.getValues('companyID');
        try {
            const key = Date.now();
            const signedURL = await getCompanyImagePath(companyID, key);
            const path = `${companyID}/company/${key}`;

            await uploadFile(signedURL, file);
            form.setValue('squareLogo', path);
            form.setValue('squareLogoUrl', signedURL);
        } catch (e) {
            ErrorToast(t('errorUploadingFile'));
        }
    };

    return (
        <div className={'step'}>
            <div className={'step-title'}>{t('companyDetails')}</div>

            <SmartInput required name={'name'} label={t('companyName')} />
            <SmartInput enforceNumbers required name={'registrarCompaniesID'} label={t('h.p')} />

            <SmartPlacesAutoComplete
                label={t('companyAddress')}
                name={'address.city'}
                placeholder={t('choose')}
                type={'(cities)'}
                required
            />
            <SmartPlacesAutoComplete
                label={t('streetNumber')}
                name={'address.street'}
                placeholder={t('choose')}
                type={'address'}
                isDisabled={!city}
                prefix={city}
                required
            />
            <div className={'step-title'}>{t('logoAndColors')}</div>

            <div className={'step-row'}>
                <ImageUpload
                    required
                    label={t('logo')}
                    imageURL={logoUrl}
                    name={'logoUrl'}
                    onChange={(value) => {
                        form.setValue('logo', value);
                        form.setValue('logoUrl', value);
                    }}
                    onUpload={uploadPicLogo}
                />
            </div>

            <div className={'step-row'}>
                <ImageUpload
                    style={{ backgroundColor: 'grey' }}
                    required
                    label={t('whiteLogo')}
                    imageURL={whiteLogoUrl}
                    name={'whiteLogoUrl'}
                    onChange={(value) => {
                        form.setValue('whiteLogo', value);
                        form.setValue('whiteLogoUrl', value);
                    }}
                    onUpload={uploadPicWhiteLogo}
                />
            </div>

            <div className={'step-row'}>
                <ImageUpload
                    required
                    label={t('squareLogo')}
                    imageURL={squareLogoUrl}
                    name={'squareLogoUrl'}
                    onChange={(value) => {
                        form.setValue('squareLogo', value);
                        form.setValue('squareLogoUrl', value);
                    }}
                    onUpload={uploadPicSquareLogo}
                />
            </div>

            <SmartColorInput
                required
                className={'filler'}
                label={t('mainColor')}
                placeholder={'#000000'}
                name={'mainColor'}
            />
            <SmartColorInput
                required
                className={'filler'}
                label={t('secondaryColor')}
                placeholder={'#000000'}
                name={'secondaryColor'}
            />

            <div className={'step-title'}>{t('contactHR')}</div>

            <SmartInput required name={'companyContact.HRName'} label={t('contactHR')} />
            <SmartInput required name={'companyContact.HRPhone'} label={t('phoneHR')} />
            <SmartInput required name={'companyContact.HREmail'} label={t('emailHR')} />

            <div className={'step-title'}>{t('accountingName')}</div>

            <SmartInput name={'companyContact.accountingName'} label={t('accountingName')} />
            <SmartInput name={'companyContact.accountingPhone'} label={t('accountingPhone')} />
            <SmartInput name={'companyContact.accountingEmail'} label={t('accountingEmail')} />

            <div className={'checkbox-with-label'}>
                <SmartInput
                    name={'budgetApproved'}
                    type={'checkbox'}
                    disabled={currentUser?.userType !== 'admin'}
                />
                <span className={'checkbox-label'}>{t('budgetApproved')}</span>
            </div>
        </div>
    );
};

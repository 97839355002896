import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { defaultCSuppliersValues, Supplier, SuppliersFilterForm } from './store.models';
import { useTranslation } from 'react-i18next';
import { Table } from '../table/Table';
import { populateSuppliersData, suppliersTableColumns } from './store.utils';
import { Column } from '../table/Tables.types';
import { Order } from '../../hooks/useSort';
import { useQuery } from 'react-query';
import { deleteSupplierAPI, getSuppliersAPI, toggleSupplierActiveAPI } from '../../api/store.api';
import { useUpdateEffect } from '../../hooks/useUpdateEffect';
import { TableHeader } from '../table/TableHeader';
import { Button } from '../form/buttons/Button';
import { UserPlus } from 'react-feather';
import { SuccessToast } from '../toaster/Toast';

type StoreSuppliersProps = {
    onCreateSupplier: () => void;
    onEditSupplier: (supplierID: string) => void;
};

export const StoreSuppliers: FC<StoreSuppliersProps> = ({ onCreateSupplier, onEditSupplier }) => {
    const currentUser = useSelector(getCurrentUser);
    const dispatch = useDispatch();
    const form = useForm<SuppliersFilterForm>({
        defaultValues: defaultCSuppliersValues,
        mode: 'onTouched',
    });
    const orderBy = useWatch({ name: 'orderBy', control: form.control });
    const rowsPerPage = useWatch({ name: 'rowsPerPage', control: form.control });
    const page = useWatch({ name: 'page', control: form.control });
    const { t } = useTranslation();

    const { refetch, data: { data: suppliers = [], total = 0 } = {} } = useQuery({
        queryKey: ['getSuppliers'],
        queryFn: async () => {
            return getSuppliersAPI<Supplier>({
                offset: page * (rowsPerPage?.value ?? 0),
                count: rowsPerPage?.value,
                sortBy: orderBy?.direction,
                sortField: orderBy?.sortedColumn,
            });
        },
        enabled: true,
    });

    useUpdateEffect(() => {
        if (page !== 0) {
            form.setValue('page', 0);
        } else {
            refetch();
        }
    }, [rowsPerPage?.value]);

    useUpdateEffect(() => {
        refetch();
    }, [dispatch, form, page, orderBy]);

    const CustomHeader = () => {
        return (
            <TableHeader
                rowsPerPage={rowsPerPage?.value}
                leftSideComponents={[
                    currentUser?.userType === 'admin' && (
                        <Button
                            theme={'primary'}
                            onClick={() => onCreateSupplier()}
                            icon={<UserPlus width={14} />}>
                            {t('addNewSupplier')}
                        </Button>
                    ),
                ]}
            />
        );
    };

    const onActiveClick = (supplierID: string) => {
        toggleSupplierActiveAPI(supplierID).then(() => {
            SuccessToast(t('operationSuccess'));
            refetch();
        });
    };

    const onDeleteClick = (supplierID: string) => {
        deleteSupplierAPI(supplierID).then(() => {
            SuccessToast(t('operationSuccess'));
            refetch();
        });
    };

    return (
        <FormProvider {...form}>
            <div className={'management'}>
                <Table
                    columns={suppliersTableColumns(t, onDeleteClick)}
                    data={populateSuppliersData(suppliers, onActiveClick)}
                    onRowClicked={(row) => onEditSupplier(row.supplierID)}
                    header={CustomHeader()}
                    pagination={true}
                    rowsPerPage={rowsPerPage?.value}
                    total={total}
                    selectableRows={false}
                    onSort={(column: Column<Supplier>, direction: Order) => {
                        form.setValue('orderBy', { direction, sortedColumn: column.field });
                    }}
                />
            </div>
        </FormProvider>
    );
};

import { TableRow } from 'react-data-table-component';
import { Edit2, Trash } from 'react-feather';
import {
    Address,
    ExcelUser,
    Gender,
    GenderLabel,
    InvalidUserDetailsDto,
    PostsTableData,
    PurchasesHistoryTableData,
    SaveUserRequest,
    User,
    UserDetailsForm,
    UsersTableData,
    UserType,
    ValidUserDetailsDto,
} from './users.models';
import { v4 } from 'uuid';
import { SmartSwitch } from '../form/switch/SmartSwitch';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { UniqueFieldType } from '../../models/shared.models';
import { ChangeEvent, Dispatch, ReactElement } from 'react';
import { createOrEditUsers, getUsersAPI } from '../../api/users.api';
import { formatTimestamp, formatUnixTimestamp, getImageURL } from '../../utils/utils';
import { closeModal, raiseModal } from '../../store/modal/modal.actions';
import { AnyAction } from '@reduxjs/toolkit';
import { ReportModal } from '../modal/report-modal/ReportModal';
import { ErrorToast } from '../toaster/Toast';
import { Purchase } from '../orders/order.models';
import { TFunction } from 'i18next';
import { Chip } from '../chip/Chip';
import { statusVariant } from '../orders/orders.utils';
import { BoostType, Post } from '../home/home.models';
import { ReactComponent as ComplimentSVG } from '../../assets/boost/icon-boost-compliment.svg';
import { ReactComponent as GiftSVG } from '../../assets/boost/icon-boost-gift.svg';
import { ReactComponent as CreditSVG } from '../../assets/boost/icon-boost-credit.svg';

export const usersManagementTableColumns = (
    userType: UserType | undefined,
    handleEditUser: (userID: UsersTableData) => void,
    handleDeleteUser: (u: UsersTableData) => void
) =>
    [
        {
            name: 'תמונה',
            selector: (row: TableRow) => row.image,
            sortable: false,
            field: 'image',
            width: '5%',
        },
        userType === 'admin' && {
            name: 'חברה',
            selector: (row: TableRow) => row.company,
            sortable: true,
            field: 'companyName',
        },
        {
            name: 'שם מלא',
            selector: (row: TableRow) => row.fullName,
            sortable: true,
            field: 'firstName',
        },
        {
            name: 'טלפון נייד',
            selector: (row: TableRow) => row.phone,
            sortable: false,
            field: 'phone',
        },
        {
            name: 'מנהל',
            selector: (row: TableRow) => row.manager,
            sortable: true,
            field: 'managerID.value',
        },
        {
            name: 'קבוצות ארגוניות',
            selector: (row: TableRow) => row.groups,
            sortable: false,
        },
        {
            name: 'קרדיטים אישיים/להוקרה',
            selector: (row: TableRow) => row.credits,
            sortable: true,
            field: 'credits',
        },
        {
            name: 'פעיל',
            selector: (row: TableRow) => row.freeze,
            sortable: true,
            field: 'isFrozen',
        },
        {
            name: '',
            selector: (row: TableRow) => row.editDelete,
            sortable: false,
            cell: (row: UsersTableData) => (
                <span className="text-capitalize table-text">
                    <span className={'table-icon'}>
                        <Trash onClick={() => handleDeleteUser(row)} width={20} />
                    </span>
                    <span className={'table-icon'}>
                        <Edit2 onClick={() => handleEditUser(row)} width={20} />
                    </span>
                </span>
            ),
        },
    ].filter((a) => !!a);

export const purchasesHistoryTableColumns = () => [
    {
        name: 'מזהה הזמנה',
        width: '20%',
        selector: (row: TableRow) => row.purchaseID,
        field: 'purchaseID',
        sortable: false,
    },
    {
        name: 'תמונה',
        width: '5%',
        selector: (row: TableRow) => row.image,
        field: 'image',
        sortable: false,
    },
    {
        name: 'שם המוצר',
        width: '20%',
        selector: (row: TableRow) => row.productName,
        field: 'productName',
        sortable: false,
    },
    {
        name: 'סכום',
        width: '12%',
        selector: (row: TableRow) => row.totalAmount,
        field: 'totalAmount',
        sortable: false,
    },
    {
        name: 'תאריך הזמנה',
        width: '7%',
        selector: (row: TableRow) => row.purchaseDate,
        field: 'purchaseDate',
        sortable: false,
    },
    {
        name: 'תאריך שינוי סטטוס הזמנה',
        width: '10%',
        selector: (row: TableRow) => row.statusChangeDate,
        field: 'statusChangeDate',
        sortable: false,
    },
    {
        name: 'סטטוס הזמנה',
        width: '10%',
        selector: (row: TableRow) => row.purchaseStatus,
        field: 'purchaseStatus',
        sortable: false,
    },
    {
        name: '',
        width: '',
        selector: (row: TableRow) => row.a,
        sortable: false,
    },
];

export const genderTranslate = (gender: Gender | undefined): GenderLabel => {
    return gender === 'male' ? 'זכר' : 'נקבה';
};

export const calcUniqueFields = (
    uniqueFields: UniqueFieldType[],
    isCompanyField: boolean
): User['uniqueFields'] => {
    return uniqueFields.map((f) => {
        return {
            isCompanyField,
            isPublic: f.isPublic,
            id: f.id,
            value: f.value,
            label: f.label,
        };
    });
};

export const buildFormUser = (userForm: UserDetailsForm, editedUser?: User): SaveUserRequest => {
    const addresses: Address[] = [];
    const homeAddress = addresses.find((a) => a.isMain && a.type === 'home');
    const officeAddress = addresses.find((a) => a.isMain && a.type === 'office');

    if (homeAddress) {
        homeAddress.city = userForm.address?.city;
        homeAddress.isCityPublic = !!userForm.address?.isCityPublic;
        homeAddress.street = userForm.address?.street;
        homeAddress.isStreetPublic = !!userForm.address?.isStreetPublic;
    } else {
        addresses.push({
            type: 'home',
            city: userForm.address?.city,
            isCityPublic: !!userForm.address?.isCityPublic,
            street: userForm.address?.street,
            isStreetPublic: !!userForm.address?.isStreetPublic,
            isMain: true,
            addressID: v4(),
            isActive: true,
        });
    }

    if (officeAddress) {
        officeAddress.city = userForm.officeAddress?.city;
        officeAddress.isCityPublic = !!userForm.officeAddress?.isCityPublic;
        officeAddress.street = userForm.officeAddress?.street;
        officeAddress.isStreetPublic = !!userForm.officeAddress?.isStreetPublic;
    } else {
        addresses.push({
            type: 'office',
            city: userForm.officeAddress?.city,
            isCityPublic: !!userForm.officeAddress?.isCityPublic,
            street: userForm.officeAddress?.street,
            isStreetPublic: !!userForm.officeAddress?.isStreetPublic,
            isMain: true,
            addressID: v4(),
            isActive: true,
        });
    }

    return {
        ...{ ...(editedUser ?? {}) },
        userID: userForm.userID ?? v4(),
        firstName: userForm.firstName,
        lastName: userForm.lastName,
        uniqueFields: [
            ...calcUniqueFields(userForm.uniqueFields, false),
            ...calcUniqueFields(userForm.companyUniqueFields, true),
        ],
        groupsIDs: userForm.companyGroups.map((g) => g.value.groupID) || [],
        companyName: userForm.company?.label,
        companyID: userForm.company?.value,
        addresses: addresses,
        pronoun: {
            value: userForm.pronoun?.value?.value as 'he' | 'she',
            isPublic: userForm.pronoun.isPublic,
        },
        managerID: userForm.managerID?.value?.value
            ? {
                  value: userForm.managerID.value?.value,
                  isPublic: !!userForm.managerID?.isPublic,
              }
            : undefined,
        children: userForm.children?.map((c) => {
            return {
                name: c.name,
                gender: c.gender?.value
                    ? { value: c.gender?.value?.value, isPublic: c.gender?.isPublic }
                    : undefined,
                id: c.id ?? v4(),
                birthDate: {
                    value: c.birthDate?.value ? new Date(c.birthDate?.value).getTime().toString() : '0',
                    isPublic: !!c.birthDate?.isPublic,
                },
            };
        }),
        //@ts-ignore
        spouse:
            userForm.spouse?.gender?.value !== undefined && userForm.spouse?.gender?.value !== 'none'
                ? {
                      name: userForm.spouse?.name,
                      id: userForm.spouse?.id ?? v4(),
                      gender: userForm.spouse?.gender?.value
                          ? {
                                value: userForm.spouse?.gender?.value,
                                isPublic: userForm.spouse?.gender?.isPublic,
                            }
                          : undefined,
                      birthDate: userForm?.spouse?.birthDate?.value
                          ? {
                                value: new Date(userForm?.spouse?.birthDate?.value).getTime().toString(),
                                isPublic: !!userForm.spouse?.birthDate?.isPublic,
                            }
                          : undefined,
                  }
                : null,
        birthDate: userForm?.birthDate?.value
            ? {
                  value: new Date(userForm?.birthDate?.value)?.getTime().toString(),
                  isPublic: userForm?.birthDate?.isPublic,
              }
            : undefined,
        birthYear: userForm?.birthYear?.value
            ? {
                  value: new Date(userForm?.birthYear?.value)?.getTime().toString(),
                  isPublic: userForm?.birthYear?.isPublic,
              }
            : undefined,
        marriageDate: userForm?.marriageDate?.value
            ? {
                  value: new Date(userForm?.marriageDate?.value).getTime().toString(),
                  isPublic: userForm?.marriageDate?.isPublic,
              }
            : undefined,
        roleStartDate: userForm?.roleStartDate?.value
            ? {
                  value: new Date(userForm?.roleStartDate?.value).getTime().toString(),
                  isPublic: userForm?.roleStartDate?.isPublic,
              }
            : undefined,
        jobStartDate: userForm?.jobStartDate?.value
            ? {
                  value: new Date(userForm?.jobStartDate?.value).getTime().toString(),
                  isPublic: userForm?.jobStartDate?.isPublic,
              }
            : undefined,
        maritalStatus: {
            value: userForm.maritalStatus?.value?.value,
            isPublic: !!userForm.maritalStatus?.isPublic,
        },
        email: userForm.email?.value ? userForm.email : undefined,
        department: userForm.department,
        role: userForm.role,
        userType: userForm.userType?.value,
        officePhone: userForm.officePhone,
        phone: userForm.phone,
        imageURL: userForm.imageURL,
        isFrozen: userForm.isFrozen,
    };
};

export const getFormData = (file: any) => {
    const formData = new FormData();
    formData.append('file', file);
    return formData;
};

export const populateUsersData = (
    users: User[],
    handleFreezeUser: (userID: string) => void
): UsersTableData[] => {
    return users.map<UsersTableData>((u) => {
        const {
            managerName,
            socialCredits,
            personalCredits,
            isFrozen,
            phone,
            userID,
            imageURL,
            companyName,
            firstName,
            userType,
            lastName,
            organizationalGroups,
        } = u;

        return {
            image: imageURL ? <img src={getImageURL(imageURL)} width={32} alt={''} /> : '',
            company: companyName ?? '',
            freeze: (
                <SmartSwitch
                    type="switch"
                    name={`switch-${userID}`}
                    checked={!isFrozen}
                    onClick={() => handleFreezeUser(userID)}
                    disabled={userType === 'admin'}
                />
            ),
            phone: phone?.value,
            credits: userType !== 'admin' ? `${personalCredits}/${socialCredits}` : '-',
            fullName: `${firstName} ${lastName}`,
            userType: userType,
            manager: managerName ?? '',
            groups: organizationalGroups?.join(', ') ?? '',
            id: userID,
        };
    });
};

export const convertExcelToJson = async <T,>(file: File): Promise<T[]> => {
    return new Promise<T[]>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = e.target?.result as ArrayBuffer;
            const text = new TextDecoder('utf-8').decode(data);

            const workbook = XLSX.read(text, {
                type: 'buffer',
                cellText: true,
                raw: true,
                codepage: 65001,
            });

            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];

            const range = XLSX.utils.decode_range(worksheet['!ref'] as string);

            for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
                for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
                    const cellAddress = { r: rowNum, c: colNum };
                    const cell = worksheet[XLSX.utils.encode_cell(cellAddress)];

                    if (cell && cell.t === 's') {
                        // Directly access the cell value and apply fixQuotes
                        cell.v = fixQuotes(cell.v);
                    }
                }
            }

            resolve(XLSX.utils.sheet_to_json<T>(worksheet));
        };

        reader.onerror = (e) => {
            reject(e);
        };

        reader.readAsArrayBuffer(file);
    });
};

// export const convertExcelToJson = async <T,>(file: File) => {
//     return new Promise<T[]>((resolve, reject) => {
//         const reader = new FileReader();
//
//         reader.onload = (e) => {
//             try {
//                 const data = e.target?.result as ArrayBuffer;
//                 // Convert ArrayBuffer to string
//                 const textBeforeFixing = new TextDecoder('utf-8').decode(new Uint8Array(data));
//
//                 // Log the length of the text before fixing quotes
//                 console.log('Text length before fixing quotes:', textBeforeFixing.length);
//
//                 // Fix quotes for each line independently
//                 const fixedText = fixQuotes(textBeforeFixing);
//
//                 // Log the length of the text after fixing quotes
//                 console.log('Text length after fixing quotes:', fixedText.length);
//
//                 const fixedData = new TextEncoder().encode(fixedText).buffer;
//
//                 const workbook = XLSX.read(new Uint8Array(fixedData), {
//                     type: 'array',
//                     cellText: true,
//                     raw: true,
//                     codepage: 65001,
//                 });
//                 const firstSheetName = workbook.SheetNames[0];
//                 const worksheet = workbook.Sheets[firstSheetName];
//
//                 resolve(XLSX.utils.sheet_to_json<T>(worksheet));
//             } catch (error) {
//                 console.error('Error converting Excel to JSON:', error);
//                 reject(error);
//             }
//         };
//
//         reader.onerror = (e) => {
//             reject(e);
//         };
//
//         reader.readAsArrayBuffer(file);
//     });
// };

export const transformUsersToExcelUsers = (users: User[]): Partial<ExcelUser>[] => {
    return users.map<Partial<ExcelUser>>((user) => {
        const homeAddress = user.addresses.find((a) => a.type === 'home' && a.isMain);
        // const officeAddress = user.addresses.find((a) => a.type === 'office' && a.isMain);

        return {
            Company_Name: user.companyName ?? '',
            User_Type: user.userType ?? '',
            First_Name: user.firstName ?? '',
            Last_Name: user.lastName ?? '',
            Phone: `${user.phone?.value?.slice(0, 3)}-${user.phone?.value?.slice(3, 10)}` ?? '',
            Office_Phone: user.officePhone?.value
                ? `${user.officePhone?.value?.slice(0, 2)}-${user.officePhone?.value?.slice(2, 10)}` ?? ''
                : '',
            Email: user.email?.value ?? '',
            Role: user.role?.value ?? '',
            Department: user.department?.value ?? '',
            Birth_Date:
                user.birthDate?.value &&
                `${new Date(user.birthDate?.value).getDate()}/${
                    new Date(user.birthDate?.value).getMonth() + 1
                }/${(user.birthYear?.value && new Date(user.birthYear?.value)?.getFullYear()) ?? ''}`,
            Pronoun: user.pronoun?.value ?? '',
            Manager_Phone: user.managerPhone
                ? `${user.managerPhone?.slice(0, 3)}-${user.managerPhone?.slice(3, 10)}`
                : '',
            Home_City: homeAddress?.city ?? '',
            Home_Street: homeAddress?.street ?? '',
            // Unique_Fields: user.uniqueFields?.map((f) => ({ [f.label]: f.value })),
            Marital_Status: user.maritalStatus?.value ?? undefined,
            Job_Start_Date: formatUnixTimestamp(user.jobStartDate?.value),
            Role_Start_Date: formatUnixTimestamp(user.roleStartDate?.value),
            Spouse:
                user.spouse?.gender?.value !== 'none' && user.spouse
                    ? {
                          name: user.spouse?.name?.value ?? '',
                          gender: user.spouse?.gender?.value ?? '',
                          birthDate: formatUnixTimestamp(user.spouse?.birthDate?.value),
                      }
                    : '',
            Children: user.children?.map((c) => ({
                name: c.name?.value ?? '',
                gender: c.gender?.value ?? '',
                birthDate: formatUnixTimestamp(c.birthDate?.value),
            })),
            Organizational_Groups: user.organizationalGroups,
            Image_URL: '',
            Has_Image: user.imageURL ? 'TRUE' : '',
        };
    });
};

export const exportUsers = async (companiesIDs: string[] | undefined, name: string) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');
    worksheet.views = [{ rightToLeft: true }];

    const users = await getUsersAPI({ companiesIDs, all: true, isExtended: true });
    const excelUsers: Partial<ExcelUser>[] = transformUsersToExcelUsers(users?.data);

    if (excelUsers?.[0]) {
        worksheet.addRow(Object.keys(excelUsers[0]));
    }

    excelUsers.forEach((item) => worksheet.addRow(Object.values(item)));

    worksheet.columns.forEach((column) => {
        column.width = 20;
    });

    const csvData = await workbook.csv.writeBuffer();
    const csvBlob = new Blob(['\uFEFF' + csvData], {
        type: 'text/csv; charset=utf-8',
    });
    saveAs(csvBlob, `${name}.csv`);

    // const excelData = await workbook.xlsx.writeBuffer();
    // const blob = new Blob([excelData], {
    //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // });
    // saveAs(blob, `${name}.xlsx`);
};

export const fixQuotes = (text: string): string => {
    const key = '%$#%^&@#$%&)%#%&($';

    return text
        .replace('""', key)
        .replace(/[\u201C\u201D]/g, '""')
        .replace(/""+/g, '"')
        .replace(`/${key}+/g`, '""');
};

const hasDuplicatesPhoneNumber = (users: ExcelUser[]): boolean => {
    const uniquePhoneNumbers: Set<string> = new Set();

    return users.some((user) => {
        const phoneNumber = user.Phone?.replaceAll(/[-\s]/g, '');
        if (uniquePhoneNumbers.has(phoneNumber)) {
            return true;
        } else {
            uniquePhoneNumbers.add(phoneNumber);
            return false;
        }
    });
};

export const importUsers = async (
    e: ChangeEvent<HTMLInputElement>,
    dispatch: Dispatch<AnyAction>,
    onClose: () => void,
    chosenCompanyID: string | undefined,
    setUploadFileKey: () => void,
    t: TFunction<'translation'>
): Promise<User | void> => {
    setUploadFileKey();
    const file = e?.target?.files?.[0];

    if (!file || !chosenCompanyID) {
        ErrorToast(`There was an error uploading the file`);
        return;
    }

    const fileType = file.name.split('.').pop()?.toLowerCase();

    if (fileType !== 'csv') {
        ErrorToast(t('selectValidCSV'));
        return;
    }

    const uploadedUsers: ExcelUser[] = await convertExcelToJson<ExcelUser>(file);

    if (hasDuplicatesPhoneNumber(uploadedUsers)) {
        ErrorToast(t('containingDuplicatePhoneNumber'));
        return;
    }

    uploadedUsers.forEach((eu) => {
        try {
            eu.Phone = eu.Phone?.replaceAll(/[-\s]/g, '');
            eu.Manager_Phone = eu.Manager_Phone?.replaceAll(/[-\s]/g, '');
            eu.Office_Phone = eu.Office_Phone?.replaceAll(/[-\s]/g, '');
            // @ts-ignore
            eu.Children = eu.Children ? JSON.parse(eu.Children) ?? undefined : undefined;
            // @ts-ignore
            eu.Spouse = eu.Spouse ? JSON.parse(eu.Spouse) ?? undefined : undefined;
            eu.Organizational_Groups = eu.Organizational_Groups
                ? // @ts-ignore
                  JSON.parse(eu.Organizational_Groups) ?? undefined
                : undefined;
            eu.Company_Unique_Fields = eu.Company_Unique_Fields
                ? // @ts-ignore
                  JSON.parse(eu.Company_Unique_Fields) ?? undefined
                : undefined;
            // @ts-ignore
            eu.Unique_Fields = eu.Unique_Fields ? JSON.parse(eu.Unique_Fields) ?? undefined : undefined;
        } catch (e) {
            console.log(e);
        }
    });

    const result: {
        failed: InvalidUserDetailsDto[];
        newUsers: string[];
        unchanged: string[];
        edited: string[];
    } = await createOrEditUsers(uploadedUsers, chosenCompanyID).catch(() => {
        throw ErrorToast(t('errorUploadingFile'));
    });

    const edited: ValidUserDetailsDto[] = result.edited.map((e) => {
        const foundRow = uploadedUsers.find((uu) => uu.Phone === e);

        return {
            firstName: foundRow?.First_Name ?? '',
            lastName: foundRow?.Last_Name ?? '',
            phone: foundRow?.Phone ?? '',
        };
    });

    const newUsers: ValidUserDetailsDto[] = result.newUsers.map((e) => {
        const foundRow = uploadedUsers.find((uu) => uu.Phone === e);

        return {
            firstName: foundRow?.First_Name ?? '',
            lastName: foundRow?.Last_Name ?? '',
            phone: foundRow?.Phone ?? '',
        };
    });

    const unchanged: ValidUserDetailsDto[] = result.unchanged.map((e) => {
        const foundRow = uploadedUsers.find((uu) => uu.Phone === e);

        return {
            firstName: foundRow?.First_Name ?? '',
            lastName: foundRow?.Last_Name ?? '',
            phone: foundRow?.Phone ?? '',
        };
    });

    dispatch(
        raiseModal({
            isOpen: true,
            content: (
                <ReportModal
                    unchanged={unchanged}
                    failed={result.failed}
                    edited={edited}
                    newUsers={newUsers}
                />
            ),
            header: {
                title: 'דו״ח העלאת משתמשים',
            },
            footer: {
                buttons: [
                    {
                        label: 'אישור',
                        theme: 'primary',
                        callback: async () => {
                            dispatch(closeModal());
                            onClose();
                        },
                    },
                ],
            },
        })
    );
};

export const populateUserPurchasesData = (
    purchases: Purchase[],
    t: TFunction<'translation'>
): PurchasesHistoryTableData[] => {
    return purchases?.map<PurchasesHistoryTableData>((purchase) => {
        const {
            purchaseID,
            moneyAmount,
            creditsAmount,
            timestamp,
            statusUpdateTimeStamp,
            purchaseStatus,
            product,
        } = purchase;

        return {
            purchaseID: purchaseID,
            image: <img src={getImageURL(product?.mainImage)} height={32} width={32} alt={''} />,
            totalAmount: `${moneyAmount ?? 0} ${t('ILS')}, ${creditsAmount ?? 0} ${t('points')}`,
            productName: product?.productName ?? '',
            purchaseDate: new Date(timestamp).toLocaleDateString(),
            statusChangeDate: new Date(statusUpdateTimeStamp).toLocaleDateString(),
            purchaseStatus: <Chip variant={statusVariant[purchaseStatus]} label={t(purchaseStatus)} />,
        };
    });
};

export const boostsTableColumns = (t: TFunction<'translation'>) => [
    {
        name: t('from'),
        selector: (row: TableRow) => row.publisher,
        sortable: false,
        field: 'publisher',
        width: '10%',
    },
    {
        name: t('to'),
        selector: (row: TableRow) => row.targetUserID,
        sortable: false,
        field: 'targetUserID',
        width: '10%',
    },
    {
        name: t('dateOfSend'),
        selector: (row: TableRow) => row.timestamp,
        sortable: false,
        width: '10%',
    },
    {
        name: t('boostType'),
        selector: (row: TableRow) => row.boostType,
        sortable: false,
        field: 'boostType',
        width: '5%',
    },
    {
        name: t('productName'),
        selector: (row: TableRow) => row.productName,
        sortable: false,
        field: 'productName',
        width: '15%',
    },
    {
        name: t('boostAmount'),
        selector: (row: TableRow) => row.creditsAmount,
        sortable: false,
        width: '6%',
    },
    {
        name: t('text'),
        selector: (row: TableRow) => row.content,
        sortable: false,
        field: 'content',
        maxWidth: '25%',
    },
    {
        name: t('groups'),
        selector: (row: TableRow) => row.groups,
        sortable: false,
        width: '10%',
    },
    {
        name: t('hashtags'),
        selector: (row: TableRow) => row.hashtags,
        sortable: false,
        width: '10%',
    },
    {
        name: '',
        sortable: false,
        width: '0%',
    },
];

export const populateBoostsData = (posts: Post[]): PostsTableData[] => {
    return posts.map<PostsTableData>((p) => {
        const {
            boostType,
            presentedGroup,
            relatedGroupsIDs,
            content,
            timestamp,
            hashTags,
            publisher,
            giftDetails,
            targetUserFullName,
            targetUserImageURL,
        } = p;

        const moreGroups = !relatedGroupsIDs?.length
            ? ''
            : relatedGroupsIDs?.length === 1
            ? `+ ${relatedGroupsIDs?.length} נוספת `
            : `+ ${relatedGroupsIDs?.length} נוספות `;

        const boostTypeSVG: Record<BoostType, ReactElement> = {
            compliment: <ComplimentSVG />,
            gift: <GiftSVG />,
            credit: <CreditSVG />,
        };

        return {
            boostType: boostType ? boostTypeSVG[boostType] : '',
            hashTags,
            content,
            timestamp: formatTimestamp(timestamp),
            creditsAmount: giftDetails?.creditsAmount ?? '',
            groups: `${presentedGroup?.groupName} ${moreGroups}`,
            productName: giftDetails?.productDetails?.productName ?? '',
            publisher: (
                <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                    <img src={getImageURL(publisher.publisherImageURL)} height={36} width={36} alt={''} />
                    {publisher?.publisherFullName}
                </div>
            ),
            targetUserID: (
                <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                    <img src={getImageURL(targetUserImageURL)} height={36} width={36} alt={''} />
                    {targetUserFullName}
                </div>
            ),
            hashtags: hashTags.map((h) => `#${h}`)?.join(' '),
        };
    });
};

import { useDispatch } from 'react-redux';
import { closeModal, raiseModal } from '../store/modal/modal.actions';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { unstable_usePrompt } from 'react-router-dom';

export function useHandleBack(isDirty: boolean) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // For route changes
    unstable_usePrompt({
        message: t('changesNotSaved'),
        when: isDirty,
    });

    // For Refresh/Exit page
    useEffect(() => {
        function handleOnBeforeUnload(event: BeforeUnloadEvent) {
            if (isDirty) {
                const location = window.document.location;
                const originalHashValue = location.hash;

                window.setTimeout(function () {
                    location.hash = 'preventNavigation' + ~~(9999 * Math.random());
                    location.hash = originalHashValue;
                }, 0);

                const message = t('changesNotSaved');
                event.returnValue = message;
                return message;
            }
        }

        window.addEventListener('beforeunload', handleOnBeforeUnload, { capture: true });

        return () => {
            window.removeEventListener('beforeunload', handleOnBeforeUnload, { capture: true });
        };
    }, [isDirty]);

    // For leaving form
    return useCallback(
        (onBack: () => void, onStageReset: () => void) => {
            if (isDirty) {
                dispatch(
                    raiseModal({
                        isOpen: true,
                        header: { title: t('back') },
                        content: t('areYouSureChangesWillNotBeSaved'),
                        footer: {
                            buttons: [
                                {
                                    label: t('close'),
                                    theme: 'secondary',
                                    callback: () => dispatch(closeModal()),
                                },
                                {
                                    label: t('continueWithoutSave'),
                                    theme: 'primary',
                                    callback: () => {
                                        onBack();
                                        dispatch(closeModal());
                                    },
                                },
                            ],
                        },
                    })
                );
            } else {
                onStageReset();
            }
        },
        [dispatch]
    );
}

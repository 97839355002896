import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalProps } from '../../components/modal/Modal.utils';

type ModalState = ModalProps;

const initialState: ModalState = {
    isOpen: false,
    header: {
        title: '',
        icon: undefined,
    },
    content: '',
    footer: {
        buttons: [],
        position: undefined,
    },
};

export const ModalSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        raiseModal: (state: ModalState, action: PayloadAction<ModalState | undefined>): ModalState => {
            const { payload } = action;
            return { ...state, ...payload };
        },
        closeModal: (state: ModalState): ModalState => {
            return { ...state, ...initialState };
        },
    },
});

import { Button } from '../form/buttons/Button';
import welcome from './welcome-feed.png';
import './feed.scss';
import { Gift, PlusCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';

type WelcomeFeedProps = {
    onGift: () => void;
    onPost: () => void;
};

export const WelcomeFeed = ({ onGift, onPost }: WelcomeFeedProps) => {
    const { t } = useTranslation();

    return (
        <div className={'welcome-feed'}>
            <div className={'main-content'}>
                <div className={'greetings'}>
                    <h2 className={'welcome'}>{t('welcomeBack')}</h2>
                    <h3 className={'what-to-do'}>{t('whatDoYouWantToDoToday')}</h3>
                </div>
                <div className={'welcome-buttons'}>
                    <Button theme={'primary'} onClick={onGift} icon={<Gift size={14} />}>
                        {t('sendGift')}
                    </Button>
                    <Button theme={'primary'} onClick={onPost} icon={<PlusCircle size={14} />}>
                        {t('newPost')}
                    </Button>
                </div>
            </div>

            <img className={'feed-welcome-image'} src={welcome} alt={''} />
        </div>
    );
};

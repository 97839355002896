import { FC, useEffect, useState } from 'react';
import { InnerModal } from '../modal/InnerModal/InnerModal';
import { Modal } from './Modal';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { getCompanies } from '../../store/companies/companies.selectors';
import { Gift } from '../home/home.models';
import { CreateGift } from '../send-gift/CreateGift';
import { createPostFormDefaultValues, SendGiftForm } from '../send-gift/create-gift.models';
import { getToBePublishedAtTimestamp } from '../../utils/utils';
import { v4 } from 'uuid';
import { getCompaniesAPI } from '../../api/companies.api';
import { Company } from '../companies/companies.models';
import { useUpdateEffect } from '../../hooks/useUpdateEffect';
import { yupResolver } from '@hookform/resolvers/yup';
import { GiftSchema } from '../send-gift/gift-validation';
import { AreYouSureModal } from './AreYouSure.modal';
import { ModalIcon } from '../modal/ModalIcon';
import { useHandleBack } from '../../hooks/useHandleBack';

type SendGiftModalProps = {
    onConfirm: (post: Gift) => Promise<boolean>;
    closeModal: () => void;
    isOpen: boolean;
};

export const SendGiftModal: FC<SendGiftModalProps> = ({ closeModal, onConfirm, isOpen }) => {
    const currentUser = useSelector(getCurrentUser);
    const sendGiftForm = useForm<SendGiftForm>({
        mode: 'onTouched',
        defaultValues: createPostFormDefaultValues,
        resolver: yupResolver(GiftSchema),
    });
    const company = useWatch({ control: sendGiftForm.control, name: 'company' });
    const companies = useSelector(getCompanies);
    const { t } = useTranslation();
    const [closeModalOpen, setCloseModalOpen] = useState(false);
    const { isDirty } = sendGiftForm.formState;
    useHandleBack(isDirty);

    useEffect(() => {
        getCompanyBudget();
        const currentCompany = companies.find((c) => c.companyID === currentUser?.companyID);

        if (currentCompany?.name) {
            sendGiftForm.setValue('company', {
                label: currentCompany.name,
                value: currentCompany.companyID,
            });
        }
    }, [companies, currentUser?.companyID, currentUser?.userType]);

    useUpdateEffect(() => {
        getCompanyBudget();
    }, [company?.value]);

    useEffect(() => {
        if (isOpen) {
            sendGiftForm.setValue('postID', v4());
        }

        if (currentUser?.userType === 'HR') {
            sendGiftForm.setValue('behalfOf', {
                label: `${currentUser.firstName} ${currentUser.lastName}`,
                value: currentUser.userID,
            });
        }
    }, [isOpen]);

    const getCompanyBudget = async () => {
        const companies = await getCompaniesAPI<Company>({
            companyID: company?.value,
            isExtended: true,
        });
        const currentCompany = companies?.data?.[0];

        if (company) {
            sendGiftForm.setValue('companyBudget', currentCompany.budget);
        }
    };

    const onGiftCreate = async () => {
        const giftForm = sendGiftForm.getValues();
        const toBePublishedAt = getToBePublishedAtTimestamp(giftForm.publishAtDate, giftForm.publishAtTime);

        const gift: Gift = {
            companyID: giftForm.company?.value,
            behalfOfUserID: giftForm.behalfOf?.value,
            postID: giftForm.postID,
            campaign: giftForm.campaign,
            content: giftForm.content,
            relatedGroupsIDs: giftForm.groups.map((g) => g.value?.groupID),
            relatedUsersIDs: giftForm.users?.map((u) => u.value),
            type: 'gift',
            toBePublishedAt,
            productID: giftForm.product?.value,
            creditsAmount: +(giftForm.creditsAmount ?? 0),
            hashTags: [],
            postAssetsURLs: [],
            isEvent: giftForm.isEvent,
            eventName: giftForm.eventName,
        };

        if (await onConfirm(gift)) {
            sendGiftForm.reset(createPostFormDefaultValues);
        }
    };

    const handleClose = () => {
        closeModal();
        sendGiftForm.reset(createPostFormDefaultValues);
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                if (isDirty) {
                    setCloseModalOpen(true);
                } else {
                    handleClose();
                }
            }}>
            <FormProvider {...sendGiftForm}>
                <InnerModal
                    content={<CreateGift onConfirm={onGiftCreate} />}
                    header={{ title: t('giftSend') }}
                    footer={{ buttons: [] }}
                />
            </FormProvider>
            <AreYouSureModal
                onConfirm={handleClose}
                icon={<ModalIcon theme={'ALERT'} />}
                closeModal={() => setCloseModalOpen(false)}
                title={t('leaveWithoutSave')}
                text={t('changesNotSaved')}
                isOpen={closeModalOpen}
            />
        </Modal>
    );
};

import { FC } from 'react';
import { InvalidUserDetailsDto, ValidUserDetailsDto } from '../../users/users.models';
import { UploadUserReportTable } from './UploadUserReportTable';

export type Report = {
    failed: InvalidUserDetailsDto[];
    newUsers: ValidUserDetailsDto[];
    unchanged: ValidUserDetailsDto[];
    edited: ValidUserDetailsDto[];
};

export const ReportModal: FC<Report> = ({ failed, edited, unchanged, newUsers }) => {
    return (
        <div className={'report-content'}>
            <span className={'report-title'} style={{ display: 'flex', justifyContent: 'center' }}>
                לבירורים ופרטים נוספים ניתן לפנות לתמיכה.
            </span>
            {failed && failed.length > 0 && (
                <span style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                    <span className={'report-title'}> שורות שגויות:</span>
                    {failed.length > 0 && <UploadUserReportTable data={failed} />}
                </span>
            )}
            {edited && edited.length > 0 && (
                <span style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                    <span className={'report-title'}> משתמשים מעודכנים:</span>
                    {edited.length > 0 && <UploadUserReportTable data={edited} />}
                </span>
            )}
            {newUsers && newUsers.length > 0 && (
                <span style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                    <span className={'report-title'}> משתמשים חדשים:</span>
                    {newUsers.length > 0 && <UploadUserReportTable data={newUsers} />}
                </span>
            )}
            {unchanged && unchanged.length > 0 && (
                <span style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                    <span className={'report-title'}> שורות ללא שינוי:</span>
                    {unchanged.length > 0 && <UploadUserReportTable data={unchanged} />}
                </span>
            )}
        </div>
    );
};

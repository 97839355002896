import { FC, useMemo } from 'react';
import { Avatar } from './Avatar';
import './avatar.scss';

type AvatarGroupProps = {
    data: { imageURL?: string; initials: string }[];
    max?: number;
    emptyDataLabel?: string;
    onEmptyDataClick?: () => void;
};

export const AvatarGroup: FC<AvatarGroupProps> = ({ data, onEmptyDataClick, emptyDataLabel, max = 5 }) => {
    const rest = useMemo(() => {
        return data?.length - max > 0 ? data?.length - max : 0;
    }, [data, max]);

    if (!data || data?.length === 0) {
        return (
            <div className={'avatar-group'} style={{ cursor: 'pointer' }} onClick={onEmptyDataClick}>
                {emptyDataLabel}
            </div>
        );
    }

    return (
        <div className={'avatar-group'}>
            {data.slice(0, max).map((item, index) => {
                return <Avatar key={index} right={16 + 24 * index} src={item} />;
            })}
            {rest > 0 && <div style={{ position: 'absolute', right: 24 * max + 40 }}>{`+${rest}`}</div>}
        </div>
    );
};

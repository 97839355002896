import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { SuccessToast } from '../toaster/Toast';
import { InnerModal } from '../modal/InnerModal/InnerModal';
import { Modal } from './Modal';
import Group from '../group-modal/Group';
import { defaultGroupValues, GroupForm, GroupWithMembers } from '../../models/groups.models';
import { yupResolver } from '@hookform/resolvers/yup';
import { groupSchema } from '../group-modal/group-validation';
import { buildFormGroup } from '../companies/companies.utils';
import { createCompanyGroupAPI, editCompanyGroupAPI } from '../../api/groups.api';
import { Save } from 'react-feather';
import { useHandleBack } from '../../hooks/useHandleBack';
import { v4 } from 'uuid';

type CreateGroupModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    companyID: string;
    refetch: () => void;
    group: GroupWithMembers | undefined;
};

export const CreateGroupModal: FC<CreateGroupModalProps> = ({
    isOpen,
    closeModal,
    companyID,
    refetch,
    group,
}) => {
    const createGroupForm = useForm<GroupForm>({
        mode: 'onTouched',
        defaultValues: { ...defaultGroupValues, groupID: v4() },
        resolver: yupResolver(groupSchema),
    });
    const { t } = useTranslation();
    const { isDirty } = createGroupForm.formState;
    const handleBack = useHandleBack(isDirty);

    const handleGroupSave = async (editedGroup?: GroupWithMembers): Promise<void> => {
        await createGroupForm.trigger().then((value) => {
            if (value) {
                createGroupForm.setValue('companyID', companyID);
                createGroupForm.setValue('ownerID', null);

                const members = createGroupForm.getValues('members').map((member) => member.value);
                const group = buildFormGroup(createGroupForm.getValues());

                const saveHandler = editedGroup ? editCompanyGroupAPI : createCompanyGroupAPI;
                saveHandler(group, members).then(() => {
                    editedGroup
                        ? SuccessToast(t('groupUpdatedSuccessfully'))
                        : SuccessToast(t('groupCreatedSuccessfully'));
                    onClose();
                    refetch();
                });
            }
        });
    };

    const handleClose = () => {
        handleBack(onClose, onClose);
    };

    const onClose = () => {
        closeModal();
        createGroupForm.reset({ ...defaultGroupValues, groupID: v4() });
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <InnerModal
                header={{ title: group ? t('editGroup') : t('addGroup') }}
                content={<Group form={createGroupForm} companyID={companyID} group={group} />}
                footer={{
                    position: 'left',
                    buttons: [
                        { label: t('close'), theme: 'grey', callback: handleClose },
                        {
                            label: t('save'),
                            icon: <Save />,
                            theme: 'primary',
                            callback: () => handleGroupSave(group),
                        },
                    ],
                }}
            />
        </Modal>
    );
};

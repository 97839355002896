import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { TableHeader } from '../table/TableHeader';
import { Button } from '../form/buttons/Button';
import { PlusCircle } from 'react-feather';
import { SuccessToast } from '../toaster/Toast';
import { closeModal, raiseModal } from '../../store/modal/modal.actions';
import { ModalIcon } from '../modal/ModalIcon';
import { Table } from '../table/Table';
import { Column } from '../table/Tables.types';
import { User } from '../users/users.models';
import { Order } from '../../hooks/useSort';
import { createOrderStatusAPI, deleteOrderStatusAPI, getInternalOrderStatusesAPI } from '../../api/store.api';
import { populateListData, listsTableColumns } from './settings.utils';
import { defaultSettingsValues, SettingsFilterForm } from './settings.models';
import { CreateListItemModal } from '../modals/CreateListItemModal';
import { PurchaseStatus } from '../orders/order.models';
import { v4 } from 'uuid';

export const InternalOrderStatuses: FC<{}> = ({}) => {
    const [isCreateInternalOrderStatusOpen, setCreateInternalOrderStatusOpen] = useState(false);
    const filterForm = useForm<SettingsFilterForm>({
        defaultValues: defaultSettingsValues,
        mode: 'onTouched',
    });
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { data: internalOrderStatuses, refetch } = useQuery({
        queryKey: ['internalOrderStatusesOptions'],
        queryFn: getInternalOrderStatusesAPI,
    });

    const CustomHeader = () => {
        return (
            <TableHeader
                leftSideComponents={[
                    <Button
                        theme={'primary'}
                        onClick={() => setCreateInternalOrderStatusOpen(true)}
                        icon={<PlusCircle width={13} />}>
                        {t('createInternalOrderStatus')}
                    </Button>,
                ]}
                rightSideComponents={[]}
            />
        );
    };

    const confirmDeleteInternalOrderStatus = async (internalOrderStatusID: string): Promise<void> => {
        deleteOrderStatusAPI(internalOrderStatusID).then(() => {
            SuccessToast(t('internalOrderStatusSuccessfullyDeleted'));
            refetch();
        });
    };

    const onConfirm = async (name: string): Promise<void> => {
        if (!name) return;

        const internalOrderStatus: PurchaseStatus = {
            name: name.trim(),
            statusID: v4(),
            statusType: 'internal',
        };

        await createOrderStatusAPI(internalOrderStatus).then(() => {
            SuccessToast(t('statusSuccessfullyCreated'));
            setCreateInternalOrderStatusOpen(false);
            refetch();
        });
    };

    const handleDeleteInternalOrderStatus = (internalOrderStatusID: string) => {
        dispatch(
            raiseModal({
                isOpen: true,
                content: t('areYouSureYouWantToDeleteInternalOrderStatus'),
                header: {
                    title: t('deleteInternalOrderStatus'),
                    icon: <ModalIcon theme={'ALERT'} />,
                },
                footer: {
                    buttons: [
                        {
                            label: t('yesDelete'),
                            theme: 'danger',
                            callback: () =>
                                confirmDeleteInternalOrderStatus(internalOrderStatusID).then(() =>
                                    dispatch(closeModal())
                                ),
                        },
                        { label: t('cancel'), theme: 'grey', callback: () => dispatch(closeModal()) },
                    ],
                },
            })
        );
    };

    return (
        <FormProvider {...filterForm}>
            <Table
                columns={listsTableColumns(handleDeleteInternalOrderStatus, t)}
                data={populateListData(
                    (internalOrderStatuses ?? [])?.map((v) => ({ name: v.name, id: v.statusID })) ?? [],
                    t
                )}
                header={CustomHeader()}
                pagination={false}
                selectableRows={false}
                onSort={(column: Column<User>, direction: Order) => {
                    filterForm.setValue('orderBy', {
                        direction,
                    });
                }}
                rowsPerPage={internalOrderStatuses?.length ?? 0}
                total={internalOrderStatuses?.length ?? 0}
            />
            <CreateListItemModal
                isOpen={isCreateInternalOrderStatusOpen}
                onConfirm={onConfirm}
                closeModal={() => setCreateInternalOrderStatusOpen(false)}
                label={'creationOfNewListItem'}
            />
        </FormProvider>
    );
};

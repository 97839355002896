import { BoostType, PostType } from '../home/home.models';
import { SelectOption, SelectOptionT, TransactionType } from '../../models/shared.models';
import { Group } from '../../models/groups.models';
import { Address, MaritalStatus } from '../users/users.models';

export type ReportsForm = {
    companyID: SelectOption | null;
    fromTimestamp: number | null;
    toTimestamp: number | null;
    redeemedFromTimestamp: number | null;
    redeemedToTimestamp: number | null;
    ageFromTimestamp: number | null;
    ageToTimestamp: number | null;
    userID: SelectOption | null;
    targetID: SelectOption | null;
    managerID: SelectOption | null;
    gender: SelectOptionT<'male'> | SelectOptionT<'female'> | null;
    postType: SelectOptionT<PostType> | null;
    isStyled: SelectOptionT<'yes'> | SelectOptionT<'no'> | null;
    boostType: SelectOptionT<'gift'> | SelectOptionT<'boost'> | null;
    giftOrBoostType: SelectOptionT<'product'> | SelectOptionT<'credits'> | SelectOptionT<'compliment'> | null;
    redeemedGiftOrBoostType:
        | SelectOptionT<'product'>
        | SelectOptionT<'credits'>
        | SelectOptionT<'compliment'>
        | null;
};

export const reportsDefaultValues: ReportsForm = {
    companyID: null,
    fromTimestamp: null,
    toTimestamp: null,
    redeemedFromTimestamp: null,
    redeemedToTimestamp: null,
    ageFromTimestamp: null,
    ageToTimestamp: null,
    gender: null,
    userID: null,
    targetID: null,
    managerID: null,
    isStyled: null,
    postType: null,
    boostType: null,
    giftOrBoostType: null,
    redeemedGiftOrBoostType: null,
};

export type ExcelPost = {
    Company: string;
    Publisher: string;
    ManagerName: string;
    BirthDate: string;
    Gender: string;
    PostDate: string;
    PostType: PostType;
    isStyled: boolean;
    Groups: string;
};

export type ExcelBoost = {
    Company?: string;
    PostDate: string;
    PostType: 'בוסט' | 'מתנה' | string;
    Type: 'מוצר' | 'קרדיטים' | 'מילה טובה' | string;
    PublisherName: string;
    PublisherManagerName?: string;
    PublisherBirthDate: string;
    PublisherGender: string;
    TargetName: string;
    TargetManagerName: string;
    TargetBirthDate: string;
    TargetGender: string;
    PublisherAccountType: string;
    PublisherBalanceAfterAction: number;
    RedeemDate: string;
    Hashtags: string;
    RedeemType: string;
};

export type ProductAction = {
    ProductName: string;
    Company: string;
    Name: string;
    ManagerName: string;
    BirthDate: string;
    Gender: string;
    EventType: 'Add to Wishlist' | 'Purchase';
    ProductSize: 'small' | 'medium' | 'large';
    ActionTime: string;
    CreditCardAmount: number | '';
    CreditsAmount: number | '';
    Status: string;
};

export type ExcelTransaction = {
    TransactionType: string;
    ConnectorID: string;
    Company: string;
    From: string;
    FromOwnerType: string;
    FromManagerName: string;
    FromBirthDate: string;
    FromGender: string;
    To: string;
    ToManagerName: string;
    ToBirthDate: string;
    ToGender: string;
    TransactionDate: string;
    Amount: number;
    FromAccountType: string;
    BalanceAfterTransaction: string;
};

export type AccountType = 'social' | 'personal' | 'general' | 'transactions';
export type AccountOwner = 'user' | 'company' | 'admin';

export type Account = {
    accountID: string;
    userID: string | null;
    companyID: string;
    balance: number;
    isDeleted: boolean;
    accountType: AccountType;
    ownerType: AccountOwner;
    user?: SQLUser;
    company?: { name: string; companyID: string };
};

export type SQLUser = {
    userID: string;
    companyID: string | null;
    companyName: string;
    firstName: string;
    lastName: string;
    isFirstLogin: boolean;
    fcmToken?: string;
    userType: string;
    isDeleted: boolean;
    isFrozen: boolean;
    isCompanyFrozen: boolean;
    OTP: number;
    pronoun: 'he' | 'she';
    phone: string;
    imageURL: string;
    birthDate: number;
    birthYear: number;
    addresses?: Address[];
    role: string;
    jobStartDate: number | undefined;
    roleStartDate: number | undefined;
    department: string;
    managerID?: string;
    managerName?: string;
    isManager: boolean;
    officePhone: string;
    email?: string;
    maritalStatus?: MaritalStatus;
    marriageDate?: number;
    spouse?: SQLRelative;
    children?: SQLRelative[];
    deviceKey?: string;
    accounts?: Account[];
};

type SQLRelative = {
    id: string;
    userID: string;
    parent?: SQLUser;
    spouse?: SQLUser;
    type: 'spouse' | 'children';
    gender: string;
    name: string;
    birthDate?: number;
};

export type BoostsAndGiftsReport = {
    postID: string;
    companyID?: string;
    publisherID: string;
    publisherName: string;
    user?: SQLUser & { accounts: Account[] };
    targetUser?: SQLUser & { accounts: Account[] };
    groups?: Group[];
    targetUserID?: string;
    type: PostType;
    campaign?: string;
    views: number;
    timestamp: number;
    lastUpdatedAtTimestamp: number;
    toBePublishedAt?: number;
    relatedGroupsIDs: string[];
    hiddenFromUsersID: string[];
    hashTags: string[];
    boostType?: BoostType;
    boostToBePublishedAtRelatedGroupsIDs?: string[];
    isStyled?: boolean;
    complimentID?: string;
    giftDetails?: GiftDetailsReport;
    giftDetailsID?: string;
    cms?: boolean;
};

type GiftDetailsReport = {
    giftDetailsID: string;
    postID: string;
    post?: BoostsAndGiftsReport;
    productID: string | undefined;
    creditsAmount: number | undefined;
    fromUserID: string;
    toUserID: string;
    isRedeemed: boolean;
    redeemedType: BoostType;
    boostType: BoostType | undefined;
    timestamp: number;
    redeemedAt?: number;
    boostAndGiftLog?: any;
};

export type Transaction = {
    transactionID: string;
    companyID: string;
    fromAccountID?: string;
    toAccountID?: string;
    fromAccount?: Account;
    toAccount?: Account;
    refID: string;
    type: TransactionType;
    timestamp: number;
    amount: number;
    senderNewBalance?: number;
    receiverNewBalance?: number;
    text: string;
    boostAndGiftLog?: any;
};

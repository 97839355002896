import ReactDOM from 'react-dom/client';
import './index.scss';
import { App } from './App';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { ModalContainer } from './components/modal/ModalContainer';
import { LoadingContainer } from './components/loading/LoadingContainer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import './translate/i18n';
import { StrictMode } from 'react';
import { ROUTES } from './app-routes';

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const currentUser = store.getState()?.users?.currentUser;

const router = createBrowserRouter(
    createRoutesFromElements([
        ...ROUTES.filter((r) => (currentUser ? r.roles?.includes(currentUser.userType) : true)).map(
            (route) => (
                <Route path={route.path} element={<App key={route.path}>{route.element}</App>}>
                    {route.children?.map((child) => {
                        return <Route key={child.path} path={`${child.path}`} element={child.element} />;
                    })}
                </Route>
            )
        ),
    ])
);

root.render(
    <StrictMode>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} />
                <ModalContainer />
                <LoadingContainer />
                <ToastContainer newestOnTop />
            </QueryClientProvider>
        </Provider>
    </StrictMode>
);

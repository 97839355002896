export const timeAgo = (timestamp: number) => {
    const now = Date.now();
    const diff = now - timestamp;
    const day = 24 * 60 * 60 * 1000; // One day in milliseconds
    const daysAgo = Math.floor(diff / day);
    const eventDate = new Date(timestamp);
    const currentHours = eventDate.getHours();
    const currentMinutes = eventDate.getMinutes();
    const currentDate = new Date();
    const isSameDay =
        eventDate.getFullYear() === currentDate.getFullYear() &&
        eventDate.getMonth() === currentDate.getMonth() &&
        eventDate.getDate() === currentDate.getDate();
    const isYesterday =
        eventDate.getFullYear() === currentDate.getFullYear() &&
        eventDate.getMonth() === currentDate.getMonth() &&
        eventDate.getDate() === currentDate.getDate() - 1;

    if (isSameDay) {
        return `היום, ${formatTime(currentHours, currentMinutes)}`;
    } else if ((isYesterday && currentHours >= 12) || daysAgo === 1) {
        return `אתמול, ${formatTime(currentHours, currentMinutes)}`;
    } else {
        const formattedDate = formatDate(eventDate, now);
        return `${formattedDate}, ${formatTime(currentHours, currentMinutes)}`;
    }
};

const formatTime = (hours: number, minutes: number) => {
    const formattedHours = hours === 0 ? '12' : hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    return `${formattedHours}:${formattedMinutes}`;
};

const formatDate = (date: Date, currentDate: number) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const currentYear = new Date(currentDate).getFullYear();
    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;

    return year === currentYear
        ? `${formattedDay}/${formattedMonth}`
        : `${formattedDay}/${formattedMonth}/${year}`;
};

import { Card } from 'reactstrap';
import './table.scss';
import { ChevronDown } from 'react-feather';
import DataTable from 'react-data-table-component';
import { FC, ReactNode } from 'react';
import Pagination from './pagination/Pagination';
import EmptyState from '../empty-state/EmptyState';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface TableProps {
    data: any[];
    columns: any[];
    rowsPerPage: number;
    pagination: boolean;
    header?: ReactNode;
    onSort?: (column: any, direction: any) => void;
    total: number;
    page?: number;
    selectableRows?: boolean;
    onRowSelect?: (row: any) => void;
    onRowClicked?: (row: any) => void;
}

export const Table: FC<TableProps> = ({
    data,
    total,
    header,
    page,
    rowsPerPage,
    columns,
    onSort,
    onRowSelect,
    onRowClicked,
    selectableRows = true,
}) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    const { field } = useController({ name: 'page', control });

    const CustomPagination = () => {
        return (
            <Pagination
                pagesCount={Math.ceil(total / rowsPerPage)}
                name={'page'}
                total={total}
                rowsPerPage={rowsPerPage}
                page={page ?? field.value}
            />
        );
    };

    const handleSort = (column: any, direction: any) => {
        if (onSort) {
            const upperCaseDirection = direction ? direction.toUpperCase() : undefined;
            onSort(column, upperCaseDirection);
        }
    };

    return (
        <Card className="data-table overflow-hidden d-flex">
            <DataTable
                noHeader
                subHeader
                highlightOnHover
                pagination
                paginationPerPage={rowsPerPage}
                paginationServer={true}
                responsive
                columns={columns}
                sortIcon={<ChevronDown />}
                className={`react-dataTable ${onRowClicked ? 'pointer' : ''}`}
                paginationComponent={CustomPagination}
                data={data}
                subHeaderComponent={header ?? undefined}
                onSort={handleSort}
                noDataComponent={<EmptyState title={t('noResults')} />}
                selectableRows={selectableRows}
                onSelectedRowsChange={onRowSelect}
                sortServer={true}
                onRowClicked={onRowClicked}
            />
        </Card>
    );
};

import { FC } from 'react';
import { useFormContext, UseFormReturn, useWatch } from 'react-hook-form';
import { UserDetailsForm } from './users.models';
import { RadioOption } from '../form/radio/smart-radio-button';
import { SmartSelect } from '../form/select/smart-select';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { SmartDatePicker } from '../form/date-picker/smart-date-picker';
import { UniqueField } from '../unique-field/unique-field';
import { DropdownSplitButton } from '../form/buttons/SplitButton';
import { v4 } from 'uuid';
import { SmartPlacesAutoComplete } from '../form/select/places-auto-complete';
import { useSelector } from 'react-redux';
import { getEditedCompanyUniqueFields } from '../../store/companies/companies.selectors';
import { useTranslation } from 'react-i18next';

type UsersStep2Props = {};

export const UsersStep2: FC<UsersStep2Props> = ({}) => {
    const form: UseFormReturn<UserDetailsForm> = useFormContext();
    const uniqueFields = useWatch({ control: form.control, name: 'uniqueFields' });
    const city = useWatch({ control: form.control, name: 'address.city' });
    const companyOptions = useSelector(getEditedCompanyUniqueFields);
    const { t } = useTranslation();

    const onSplitButtonClick = (id: string | undefined) => {
        const chosen = companyOptions.find((o) => o.id === id);
        const newFields = [...uniqueFields];

        if (chosen) {
            newFields.push({
                label: chosen.label,
                value: '',
                id: chosen.id,
                isCompanyField: chosen.isCompanyField,
                isPublic: true,
            });
            form.setValue('uniqueFields', newFields);
        } else {
            newFields.push({
                label: '',
                value: '',
                id: v4(),
                isCompanyField: false,
                isPublic: true,
            });
            form.setValue('uniqueFields', newFields);
        }
    };

    return (
        <div className={'step'}>
            <div className={'field-with-publicity'}>
                <SmartSelect
                    required
                    label={t('pronoun')}
                    name={'pronoun.value'}
                    options={[
                        { label: t('she'), value: 'she' },
                        { label: t('he'), value: 'he' },
                    ]}
                    placeholder={t('pronoun')}
                />
                <RadioOption name={'pronoun.isPublic'} option1={t('private')} option2={t('public')} />
            </div>
            <div className={'field-with-publicity'}>
                <SmartInput
                    required
                    type={'tel'}
                    label={t('cellphone')}
                    name={'phone.value'}
                    placeholder={t('cellphone')}
                    enforceNumbers
                />
                <RadioOption name={'phone.isPublic'} option1={t('private')} option2={t('public')} />
            </div>

            <div className={'field-with-publicity'}>
                <SmartDatePicker
                    label={t('birthday')}
                    dateFormat={'dd/MM'}
                    showYearDropdown={false}
                    name={'birthDate.value'}
                    placeholder="DD/MM"
                    onlyMonthHeader
                />
                <RadioOption name={'birthDate.isPublic'} option1={t('private')} option2={t('public')} />
            </div>

            <div className={'field-with-publicity'}>
                <SmartDatePicker
                    label={t('birthYear')}
                    dateFormat={'yyyy'}
                    name={'birthYear.value'}
                    placeholder="YYYY"
                    showYearPicker
                />
                <RadioOption name={'birthYear.isPublic'} option1={t('private')} option2={t('public')} />
            </div>

            <div className={'field-with-publicity'}>
                <SmartPlacesAutoComplete
                    label={t('city')}
                    name={'address.city'}
                    placeholder={t('choose')}
                    type={'(cities)'}
                />
                <RadioOption name={'address.isCityPublic'} option1={t('private')} option2={t('public')} />
            </div>
            <div className={'field-with-publicity'}>
                <SmartPlacesAutoComplete
                    label={t('streetNumber')}
                    name={'address.street'}
                    placeholder={t('choose')}
                    type={'address'}
                    isDisabled={!city}
                    prefix={city}
                />
                <RadioOption name={'address.isStreetPublic'} option1={t('private')} option2={t('public')} />
            </div>

            <div className={'inputs-separator step-row'} />

            {uniqueFields?.map((field, i) => {
                return (
                    <div className={'step-row'} key={i}>
                        <UniqueField
                            fieldNames={[
                                {
                                    fieldName: `uniqueFields.${i}.label`,
                                    label: t('fieldName'),
                                    isDisabled: !!companyOptions.find((o) => o.id === field.id),
                                },
                                {
                                    fieldName: `uniqueFields.${i}.value`,
                                    label: t('fieldValue'),
                                },
                            ]}
                            publicityName={`uniqueFields.${i}.isPublic`}
                            onRemove={() => {
                                const newFields = [...uniqueFields].filter((f) => f.id !== field.id);
                                form.setValue('uniqueFields', newFields);
                            }}
                        />
                    </div>
                );
            })}

            {uniqueFields.length > 0 && <div className={'inputs-separator step-row'} />}

            <DropdownSplitButton
                onClick={onSplitButtonClick}
                options={companyOptions
                    .filter(
                        (co) =>
                            !co.isCompanyField &&
                            !uniqueFields.find((o) => o.id === co.id || o.label === co.label)
                    )
                    .map((co) => ({ label: co.label, value: co.id, id: co.id }))}
                separatorLabel={t('addNewField')}
                buttonLabel={t('addUniqueField')}
            />
        </div>
    );
};

import { FC } from 'react';
import { RadioOption } from '../form/radio/smart-radio-button';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { Button } from '../form/buttons/Button';
import { Trash } from 'react-feather';
import './unique-fields.scss';
import { SmartSelect } from '../form/select/smart-select';
import { SmartDatePicker } from '../form/date-picker/smart-date-picker';
import { useTranslation } from 'react-i18next';

type ChildFieldProps = {
    name: string;
    genderName: string;
    birthDateName: string;
    onRemove: () => void;
};

export const ChildField: FC<ChildFieldProps> = ({ name, genderName, birthDateName, onRemove }) => {
    const { t } = useTranslation();

    return (
        <div className={'users-child'} style={{ position: 'relative' }}>
            <div className={'field-with-publicity'}>
                <SmartInput label={t('childName')} name={`${name}.value`} />
                <RadioOption
                    name={`${name}.isPublic`}
                    option1={t('private')}
                    option2={t('public')}
                    disabled
                />
            </div>

            <div className={'field-with-publicity'}>
                <SmartSelect
                    label={t('childGender')}
                    name={`${genderName}.value`}
                    options={[
                        { label: t('male'), value: 'male' },
                        { label: t('female'), value: 'female' },
                    ]}
                />
                <RadioOption
                    name={`${genderName}.isPublic`}
                    option1={t('private')}
                    option2={t('public')}
                    disabled
                />
            </div>

            <div className={'field-with-publicity'}>
                <SmartDatePicker
                    dateFormat={'dd/MM/yyyy'}
                    placeholder={'DD/MM/YYYY'}
                    label={t('childBirthDate')}
                    name={`${birthDateName}.value`}
                />
                <RadioOption
                    name={`${birthDateName}.isPublic`}
                    option1={t('private')}
                    option2={t('public')}
                    disabled
                />
            </div>
            <Button theme={'secondary'} onClick={onRemove} className={'delete-field'}>
                <Trash width={16} height={16} />
            </Button>
        </div>
    );
};

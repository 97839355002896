import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Input, InputProps } from 'reactstrap';
import './switch.scss';

type FormInputProps = Omit<InputProps, 'value' | 'ref' | 'type'> & {
    name: string;
};

export const SmartSwitch: FC<FormInputProps> = ({ name, ...rest }) => {
    const { control } = useFormContext();
    const { field } = useController({ name, control });

    return (
        <div className="form-switch form-check-success">
            <Input {...rest} {...field} type={'switch'} className={'form-switch input'} />
        </div>
    );
};

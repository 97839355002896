import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { useNavigate } from 'react-router-dom';
import { Variants } from './Variants';
import './settings.scss';
import { Tabs } from '../tabs/Tabs';
import { settingsTabs } from './settings.models';
import { OrderStatuses } from './OrderStatuses';
import { InternalOrderStatuses } from './InternalOrderStatuses';

export const Settings: FC = () => {
    const currentUser = useSelector(getCurrentUser);
    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser?.userType !== 'admin') {
            navigate('/');
        }
    }, [currentUser?.userType, navigate]);

    const renderStep = () => {
        switch (currentStep) {
            case 0:
                return <Variants />;
            case 1:
                return <OrderStatuses />;
            case 2:
                return <InternalOrderStatuses />;
        }
    };

    return (
        <div className={'settings'}>
            <div className={'steps-tabs'}>
                <Tabs
                    activeTab={currentStep + 1}
                    toggleTab={(step) => setCurrentStep(step - 1)}
                    tabs={settingsTabs}
                />
            </div>
            {renderStep()}
        </div>
    );
};

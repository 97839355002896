import { TFunction } from 'i18next';
import { Filter, FiltersModels } from '../filters/filters.models';
import { companyLoadOptions, userLoadOptions } from '../../utils/loadOptions';
import { Download } from 'react-feather';
import {
    getBoostsAndGiftsReportAPI,
    getPostsReportAPI,
    getPurchasesReportAPI,
    getTransactionsReportAPI,
} from '../../api/reports.api';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { Post } from '../home/home.models';
import {
    BoostsAndGiftsReport,
    ExcelBoost,
    ExcelPost,
    ProductAction,
    ExcelTransaction,
    Transaction,
} from './reports.models';
import { Group, GroupType } from '../../models/groups.models';
import { formatTimestamp } from '../../utils/utils';
import { Purchase } from '../orders/order.models';

type FilterWithSteps = Filter & { steps?: number[] };

export const getReportsFilters = (
    onFilter: () => void,
    step: number,
    t: TFunction<'translation'>
): FilterWithSteps[] => {
    const filters: FilterWithSteps[] = [
        {
            steps: [0, 1, 2, 3],
            type: FiltersModels.AUTO_COMPLETE,
            name: 'companyID',
            placeholder: t('company'),
            label: t('company'),
            getLoadOptionsData: companyLoadOptions,
        },
        {
            type: FiltersModels.DATE,
            name: 'fromTimestamp',
            label: t('fromDate'),
            placeholder: t('fromDate'),
            steps: [0, 1, 2],
        },
        {
            type: FiltersModels.DATE,
            name: 'toTimestamp',
            label: t('toDate'),
            placeholder: t('toDate'),
            steps: [0, 1, 2],
        },
        {
            type: FiltersModels.DATE,
            name: 'redeemedFromTimestamp',
            label: t('redeemedFromDate'),
            placeholder: t('fromDate'),
            steps: [1],
        },
        {
            type: FiltersModels.DATE,
            name: 'redeemedToTimestamp',
            label: t('redeemedToDate'),
            placeholder: t('toDate'),
            steps: [1],
        },
        {
            type: FiltersModels.AUTO_COMPLETE,
            name: 'userID',
            placeholder: t('showAll'),
            getLoadOptionsData: (inputValue: string) => userLoadOptions({ inputValue }),
            label: t('user'),
            steps: [0, 1, 2],
        },
        {
            type: FiltersModels.AUTO_COMPLETE,
            name: 'targetID',
            placeholder: t('showAll'),
            getLoadOptionsData: (inputValue: string) => userLoadOptions({ inputValue }),
            label: t('targetID'),
            steps: [1],
        },
        {
            type: FiltersModels.AUTO_COMPLETE,
            name: 'managerID',
            placeholder: t('showAll'),
            getLoadOptionsData: (inputValue: string) => userLoadOptions({ inputValue }),
            label: t('manager'),
            steps: [0, 1, 2, 3],
        },
        {
            type: FiltersModels.SELECT,
            name: 'gender',
            placeholder: t('gender'),
            label: t('gender'),
            options: [
                { label: t('male'), value: 'male' },
                { label: t('female'), value: 'female' },
            ],
            steps: [0, 1, 2, 3],
        },
        {
            type: FiltersModels.DATE,
            name: 'ageFromTimestamp',
            label: t('ageFromDate'),
            placeholder: t('fromDate'),
            steps: [0, 1, 2, 3],
        },
        {
            type: FiltersModels.DATE,
            name: 'ageToTimestamp',
            label: t('ageToDate'),
            placeholder: t('toDate'),
            steps: [0, 1, 2, 3],
        },
        {
            type: FiltersModels.SELECT,
            name: 'postType',
            label: t('giftOrBoost'),
            placeholder: t('giftOrBoost'),
            steps: [1],
            options: [
                { label: t('boost'), value: 'boost' },
                { label: t('gift'), value: 'gift' },
            ],
        },
        {
            type: FiltersModels.SELECT,
            name: 'boostType',
            label: t('giftOrBoostType'),
            placeholder: t('giftOrBoostType'),
            steps: [1],
            options: [
                { label: t('product'), value: 'gift' },
                { label: t('credits'), value: 'credit' },
                { label: t('compliment'), value: 'compliment' },
            ],
        },
        {
            type: FiltersModels.SELECT,
            name: 'redeemedGiftOrBoostType',
            label: t('redeemedGiftOrBoostType'),
            placeholder: t('redeemedGiftOrBoostType'),
            steps: [1],
            options: [
                { label: t('product'), value: 'gift' },
                { label: t('credits'), value: 'credit' },
                { label: t('compliment'), value: 'compliment' },
            ],
        },
        {
            type: FiltersModels.BUTTON,
            label: t('exportToExcel'),
            onClick: onFilter,
            icon: <Download width={14} />,
            steps: [0, 1, 2, 3],
        },
    ];

    return filters.filter((a) => (a.steps ?? [])?.includes(step)) ?? [];
};

export const getApiByStep = (step: number) => {
    switch (step) {
        case 0: {
            return getPostsReportAPI;
        }
        case 1: {
            return getBoostsAndGiftsReportAPI;
        }
        case 2: {
            return getPurchasesReportAPI;
        }
        case 3: {
            return getTransactionsReportAPI;
        }
    }
};

export const exportToExcelByStep = (step: number) => {
    switch (step) {
        case 0: {
            return exportPosts;
        }
        case 1: {
            return exportBoostsAndGifts;
        }
        case 2: {
            return exportProductActions;
        }
        case 3: {
            return exportTransactions;
        }
        default: {
            return exportPosts;
        }
    }
};

export const exportPosts = async (posts: Post[]) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');
    worksheet.views = [{ rightToLeft: true }];

    const excelPosts: Partial<ExcelPost>[] = transformPostsToExcelPosts(posts);

    if (excelPosts?.[0]) {
        worksheet.addRow(Object.keys(excelPosts[0]));
    }

    excelPosts.forEach((item) => worksheet.addRow(Object.values(item)));

    worksheet?.columns?.forEach((column) => {
        column.width = 20;
    });

    const csvData = await workbook.csv.writeBuffer();
    const csvBlob = new Blob(['\uFEFF' + csvData], {
        type: 'text/csv; charset=utf-8',
    });
    saveAs(csvBlob, `posts-${formatTimestamp(new Date().getTime())}.csv`);
};

export const exportTransactions = async (transactions: Transaction[]) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');
    worksheet.views = [{ rightToLeft: true }];

    const excelTransactions: Partial<ExcelTransaction>[] =
        transformTransactionsToExcelTransactions(transactions);

    if (excelTransactions?.[0]) {
        worksheet.addRow(Object.keys(excelTransactions[0]));
    }

    excelTransactions.forEach((item) => worksheet.addRow(Object.values(item)));

    worksheet?.columns?.forEach((column) => {
        column.width = 20;
    });

    const csvData = await workbook.csv.writeBuffer();
    const csvBlob = new Blob(['\uFEFF' + csvData], {
        type: 'text/csv; charset=utf-8',
    });
    saveAs(csvBlob, `Transactions-${formatTimestamp(new Date().getTime())}.csv`);
};

export const exportProductActions = async (productActions: any[]) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');
    worksheet.views = [{ rightToLeft: true }];

    const excelProductActions: Partial<ProductAction>[] =
        transformProductsActionsToExcelProductActions(productActions);

    if (excelProductActions?.[0]) {
        worksheet.addRow(Object.keys(excelProductActions[0]));
    }

    excelProductActions.forEach((item) => worksheet.addRow(Object.values(item)));

    worksheet?.columns?.forEach((column) => {
        column.width = 20;
    });

    const csvData = await workbook.csv.writeBuffer();
    const csvBlob = new Blob(['\uFEFF' + csvData], { type: 'text/csv; charset=utf-8' });
    saveAs(csvBlob, `ProductsActions-${formatTimestamp(new Date().getTime())}.csv`);
};

export const exportBoostsAndGifts = async (boostsAndGifts: BoostsAndGiftsReport[]) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');
    worksheet.views = [{ rightToLeft: true }];

    const excelBoosts: Partial<ExcelBoost>[] = transformPostsToExcelBoostsAndGifts(boostsAndGifts);

    if (excelBoosts?.[0]) {
        worksheet.addRow(Object.keys(excelBoosts[0]));
    }

    excelBoosts?.forEach((item) => worksheet.addRow(Object.values(item)));

    worksheet?.columns?.forEach((column) => {
        column.width = 20;
    });

    const csvData = await workbook.csv.writeBuffer();
    const csvBlob = new Blob(['\uFEFF' + csvData], {
        type: 'text/csv; charset=utf-8',
    });
    saveAs(csvBlob, `boosts-and-gifts-${formatTimestamp(new Date().getTime())}.csv`);
};

const transformPostsToExcelPosts = (posts: any[]): ExcelPost[] => {
    return posts.map((post) => {
        const userBirthDate = +post.user?.birthDate;
        const userBirthYear = +post.user?.birthYear;
        const postTimestamp = +post.timestamp;

        return {
            Company: post.user?.companyName,
            Publisher: `${post.publisherName}-${post.user?.phone}`,
            ManagerName: post.user?.managerName,
            BirthDate: userBirthDate
                ? `${new Date(userBirthDate).getDate()}/${new Date(userBirthDate).getMonth() + 1}/${
                      (userBirthYear && new Date(userBirthYear)?.getFullYear()) ?? ''
                  }`
                : '',
            Gender: post.user?.pronoun === 'female' ? 'Female' : 'Male',
            PostDate: `${new Date(postTimestamp).getDate()}/${new Date(postTimestamp).getMonth() + 1}/${
                new Date(postTimestamp)?.getFullYear() ?? ''
            }`,
            isStyled: !!post?.isStyled,
            Groups: post?.groups?.map((g: Group) => {
                return `${groupTypes[g.type]}~~${g.name}`;
            }),
            PostType: post.type,
        };
    });
};

const transformTransactionsToExcelTransactions = (transactions: Transaction[]): ExcelTransaction[] => {
    return transactions.map((transaction) => {
        const fromUser = transaction?.fromAccount?.user;
        const toUser = transaction?.toAccount?.user;
        const userBirthDate = fromUser?.birthDate;
        const userBirthYear = fromUser?.birthYear;

        const targetBirthDate = toUser?.birthDate;
        const targetBirthYear = toUser?.birthYear;

        const birthdate = userBirthDate
            ? `${new Date(+userBirthDate).getDate()}/${new Date(+userBirthDate).getMonth() + 1}/${
                  (userBirthYear && new Date(+userBirthYear)?.getFullYear()) ?? ''
              }`
            : '';
        const targetBirthdate = targetBirthDate
            ? `${new Date(+targetBirthDate).getDate()}/${new Date(+targetBirthDate).getMonth() + 1}/${
                  (targetBirthYear && new Date(+targetBirthYear)?.getFullYear()) ?? ''
              }`
            : '';

        const fromUserName = fromUser
            ? `${fromUser?.firstName ?? ''} ${fromUser?.lastName ?? ''} - ${fromUser?.phone}`
            : '';
        const toUserName = toUser
            ? `${toUser?.firstName ?? ''} ${toUser?.lastName ?? ''} - ${toUser?.phone}`
            : '';

        return {
            TransactionType: transactionTypeDict[transaction.type] ?? '',
            ConnectorID: transaction?.boostAndGiftLog?.[0]?.giftDetailsID ?? '',
            Company: transaction.fromAccount?.company?.name ?? '',
            From:
                transaction?.fromAccount?.ownerType === 'user'
                    ? fromUserName ?? ''
                    : transaction?.fromAccount?.accountType === 'transactions'
                    ? 'Transactions'
                    : 'Company',
            FromOwnerType: transaction?.fromAccount?.ownerType ?? '',
            FromBirthDate: birthdate ?? '',
            FromManagerName: fromUser?.managerName ?? '',
            FromGender: fromUser?.pronoun ? (fromUser?.pronoun === 'she' ? 'נקבה' : 'זכר') : '',
            To:
                transaction?.toAccount?.ownerType === 'user'
                    ? toUserName ?? ''
                    : transaction?.toAccount?.accountType === 'transactions'
                    ? 'Transactions'
                    : 'Company',
            ToManagerName: toUser?.managerName ?? '',
            ToBirthDate: targetBirthdate,
            ToGender: toUser?.pronoun === 'she' ? 'נקבה' : 'זכר',
            TransactionDate: formatTimestamp(+transaction?.timestamp) ?? '',
            Amount: transaction.amount ?? '',
            FromAccountType: transaction.fromAccount?.accountType
                ? accountTypeDict[transaction.fromAccount?.accountType] ?? ''
                : '',
            BalanceAfterTransaction:
                transaction.fromAccount?.ownerType !== 'admin'
                    ? transaction.senderNewBalance?.toString() ?? ''
                    : '',
        };
    });
};

const accountTypeDict = {
    social: 'הוקרה',
    personal: 'אישי',
    general: 'חברה',
    transactions: 'העברות',
};

const transactionTypeDict = {
    moneyTransfer: 'העברת תקציב',
    gift: 'מתנה',
    boost: 'בוסט',
    creditCardDeposit: 'הפקדת כסף',
    purchaseProduct: 'רכישת מוצר',
};

const transformPostsToExcelBoostsAndGifts = (posts: BoostsAndGiftsReport[]): ExcelBoost[] => {
    return posts.map<ExcelBoost>((post) => {
        const publisherBirthDate = post.user?.birthDate;
        const publisherBirthYear = post.user?.birthYear;
        const targetBirthDate = post.targetUser?.birthDate;
        const targetBirthYear = post.targetUser?.birthYear;

        const postTimestamp = +post.timestamp;
        const targetGender = post.targetUser?.pronoun
            ? post.targetUser?.pronoun === 'she'
                ? 'נקבה'
                : 'זכר'
            : '';
        const giftRedeemed = post.giftDetails?.isRedeemed;
        const log = giftRedeemed
            ? post.giftDetails?.boostAndGiftLog?.find(
                  (b: any) => b?.fromAccount?.accountType === 'transactions'
              )
            : post.giftDetails?.boostAndGiftLog?.find(
                  (b: any) => b?.fromAccount?.accountType !== 'transactions'
              );

        return {
            Company: post.user?.companyName ?? '',
            PostDate: `${new Date(postTimestamp).getDate()}/${new Date(postTimestamp).getMonth() + 1}/${
                new Date(postTimestamp)?.getFullYear() ?? ''
            }`,
            PostType: post.type,
            Type: post.complimentID ? 'מילה טובה' : post.giftDetails?.productID ? 'מוצר' : 'קרדיטים',
            PublisherName: `${post.publisherName} - ${post.user?.phone}`,
            PublisherManagerName: post.user?.managerName ?? '',
            PublisherBirthDate:
                publisherBirthDate && publisherBirthYear
                    ? `${new Date(+publisherBirthDate).getDate()}/${
                          new Date(+publisherBirthDate).getMonth() + 1
                      }/${new Date(+publisherBirthYear)?.getFullYear() ?? ''}`
                    : '',

            PublisherGender: post.user?.pronoun === 'she' ? 'נקבה' : 'זכר',
            TargetName: `${post.targetUser?.firstName ?? ''} ${post.targetUser?.lastName ?? ''} - ${
                post.targetUser?.phone
            }`,
            TargetManagerName: post.targetUser?.managerName ?? '',
            TargetBirthDate:
                targetBirthDate && targetBirthYear
                    ? `${new Date(+targetBirthDate).getDate()}/${new Date(+targetBirthDate).getMonth() + 1}/${
                          new Date(+targetBirthYear)?.getFullYear() ?? ''
                      }`
                    : '',
            TargetGender: targetGender,
            PublisherAccountType: log?.fromAccount?.accountType ?? '',
            PublisherBalanceAfterAction: log?.transaction?.senderNewBalance ?? '',
            Hashtags: post.hashTags.join(', '),
            RedeemDate: post.giftDetails?.redeemedAt
                ? `${new Date(+post.giftDetails?.redeemedAt).getDate()}/${
                      new Date(+post.giftDetails?.redeemedAt).getMonth() + 1
                  }/${new Date(+post.giftDetails?.redeemedAt)?.getFullYear() ?? ''}`
                : '',
            RedeemType: post.giftDetails?.redeemedType ?? '',
        };
    });
};

const groupTypes: Record<GroupType | 'HR', string> = {
    company: 'א',
    core: 'ח',
    invitation: 'ה',
    private: 'פ',
    public: 'צ',
    HR: 'HR',
};

export const transformProductsActionsToExcelProductActions = (
    productActions: any[]
): Partial<ProductAction>[] => {
    return productActions.map<any>((productAction) => {
        const userBirthDate = +productAction.user?.birthDate;
        const userBirthYear = +productAction.user?.birthYear;

        const userFullName = `${productAction.user?.firstName ?? ''} ${productAction.user?.lastName ?? ''} ${
            productAction.user?.phone ?? ''
        }`;
        const userManagerFullName = `${productAction.user?.managerName ?? ''}`;
        const birthDate = userBirthDate
            ? `${new Date(userBirthDate).getDate()}/${new Date(userBirthDate).getMonth() + 1}/${
                  (userBirthYear && new Date(userBirthYear)?.getFullYear()) ?? ''
              }`
            : '';

        const gender = productAction.user?.pronoun === 'female' ? 'Female' : 'Male';
        const product = productAction.purchaseID ? productAction.purchasedProduct : productAction.product;

        return {
            ProductName: product?.productName ?? product?.title ?? '',
            Company: productAction.user?.companyName ?? '',
            Name: userFullName,
            ManagerName: userManagerFullName,
            BirthDate: birthDate,
            Gender: gender,
            EventType: productAction.purchaseID
                ? 'Purchase'
                : productAction.wishListID
                ? 'Add to Wishlist'
                : '',
            ProductSize: productAction.productSize,
            ActionTime: formatTimestamp(+productAction?.timestamp) ?? '',
            CreditCardAmount: productAction.moneyAmount ?? '',
            CreditsAmount: productAction.creditsAmount ?? '',
            Status: productAction.purchaseStatus ?? '',
        };
    });
};

import { FC } from 'react';
import { Modal } from './Modal';
import { InnerModal } from '../modal/InnerModal/InnerModal';
import { useTranslation } from 'react-i18next';

type ImportInfoModalProps = {
    isOpen: boolean;
    closeModal: () => void;
};

export const ImportInfoModal: FC<ImportInfoModalProps> = ({ closeModal, isOpen }) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} onClose={closeModal}>
            <InnerModal
                content={<div>הוראות הוראות הוראות</div>}
                header={{ title: t('instructions') }}
                footer={{
                    buttons: [
                        {
                            label: t('confirm'),
                            theme: 'primary',
                            callback: closeModal,
                        },
                    ],
                }}
            />
        </Modal>
    );
};

import { FC } from 'react';
import { useFormContext, UseFormReturn, useWatch } from 'react-hook-form';
import { UserDetailsForm } from './users.models';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { SmartDatePicker } from '../form/date-picker/smart-date-picker';
import { SmartAutoComplete } from '../form/select/auto-complete';
import { UniqueField } from '../unique-field/unique-field';
import { DropdownSplitButton } from '../form/buttons/SplitButton';
import { RadioOption } from '../form/radio/smart-radio-button';
import { v4 } from 'uuid';
import { SmartPlacesAutoComplete } from '../form/select/places-auto-complete';
import { useSelector } from 'react-redux';
import { getEditedCompanyUniqueFields } from '../../store/companies/companies.selectors';
import { managerLoadOptions } from '../../utils/loadOptions';
import { useTranslation } from 'react-i18next';

type UsersStep3Props = {};

export const UsersStep3: FC<UsersStep3Props> = ({}) => {
    const form: UseFormReturn<UserDetailsForm> = useFormContext();
    const companyUniqueFields = useWatch({ control: form.control, name: 'companyUniqueFields' });
    const company = useWatch({ control: form.control, name: 'company' });
    const editedUserID = useWatch({ control: form.control, name: 'userID' });
    const companyOptions = useSelector(getEditedCompanyUniqueFields);
    const city = useWatch({ control: form.control, name: 'officeAddress.city' });
    const { t } = useTranslation();

    const onSplitButtonClick = (id: string | undefined) => {
        const chosen = companyOptions.find((o) => o.id === id);
        const newFields = [...companyUniqueFields];

        if (chosen) {
            newFields.push({
                label: chosen.label,
                value: '',
                id: chosen.id,
                isCompanyField: chosen.isCompanyField,
                isPublic: true,
            });
            form.setValue('companyUniqueFields', newFields);
        } else {
            newFields.push({
                label: '',
                value: '',
                id: v4(),
                isCompanyField: false,
                isPublic: true,
            });
            form.setValue('companyUniqueFields', newFields);
        }
    };

    return (
        <div className={'step'}>
            <div className={'field-with-publicity'}>
                <SmartInput required name={'role.value'} label={'תפקיד'} placeholder={'תפקיד'} />
                <RadioOption name={'role.isPublic'} option1={t('private')} option2={t('public')} disabled />
            </div>

            <div className={'field-with-publicity'}>
                <SmartDatePicker
                    label={'תאריך תחילת תפקיד'}
                    dateFormat={'dd/MM/yyyy'}
                    name={'roleStartDate.value'}
                    placeholder="DD/MM/YYYY"
                />
                <RadioOption
                    name={'roleStartDate.isPublic'}
                    option1={t('private')}
                    option2={t('public')}
                    disabled
                />
            </div>

            <div className={'field-with-publicity'}>
                <SmartInput
                    required
                    name={'department.value'}
                    label={'מדור/מחלקה'}
                    placeholder={'מדור/מחלקה'}
                />
                <RadioOption
                    name={'department.isPublic'}
                    option1={t('private')}
                    option2={t('public')}
                    disabled
                />
            </div>

            <div className={'field-with-publicity'}>
                <SmartDatePicker
                    label={'תאריך תחילת עבודה'}
                    dateFormat={'dd/MM/yyyy'}
                    name={'jobStartDate.value'}
                    placeholder="DD/MM/YYYY"
                />
                <RadioOption name={'jobStartDate.isPublic'} option1={t('private')} option2={t('public')} />
            </div>

            <div className={'field-with-publicity'}>
                <SmartAutoComplete
                    name={'managerID.value'}
                    label={'מנהל ישיר'}
                    placeholder={'בחר'}
                    getLoadOptionsData={(value: string) =>
                        managerLoadOptions(company?.value, value, editedUserID)
                    }
                    isDisabled={!company?.value}
                />
                <RadioOption
                    name={'managerID.isPublic'}
                    option1={t('private')}
                    option2={t('public')}
                    disabled
                />
            </div>

            <div className={'field-with-publicity'}>
                <SmartInput
                    label={'טלפון במשרד'}
                    name={'officePhone.value'}
                    placeholder={'טלפון במשרד'}
                    enforceNumbers
                />
                <RadioOption
                    name={'officePhone.isPublic'}
                    option1={t('private')}
                    option2={t('public')}
                    disabled
                />
            </div>

            <div className={'field-with-publicity'}>
                <SmartPlacesAutoComplete
                    label={'עיר כתובת משרד'}
                    name={'officeAddress.city'}
                    placeholder={'בחר'}
                    type={'(cities)'}
                />
                <RadioOption
                    name={'officeAddress.isCityPublic'}
                    option1={t('private')}
                    option2={t('public')}
                />
            </div>
            <div className={'field-with-publicity'}>
                <SmartPlacesAutoComplete
                    label={'רחוב ומספר - משרד'}
                    name={'officeAddress.street'}
                    placeholder={'בחר'}
                    type={'address'}
                    isDisabled={!city}
                    prefix={city}
                />
                <RadioOption
                    name={'officeAddress.isStreetPublic'}
                    option1={t('private')}
                    option2={t('public')}
                />
            </div>

            <div className={'field-with-publicity'}>
                <SmartInput label={'אימייל'} name={'email.value'} placeholder={'אימייל'} />
                <RadioOption name={'email.isPublic'} option1={t('private')} option2={t('public')} disabled />
            </div>

            <div className={'step-row inputs-separator'} />

            {companyUniqueFields?.map((field, i) => {
                return (
                    <div className={'step-row'} key={i}>
                        <UniqueField
                            fieldNames={[
                                {
                                    fieldName: `companyUniqueFields.${i}.label`,
                                    isDisabled: field.isCompanyField,
                                },
                                {
                                    fieldName: `companyUniqueFields.${i}.value`,
                                    label: 'תוכן השדה',
                                },
                            ]}
                            publicityName={`companyUniqueFields.${i}.isPublic`}
                            onRemove={() => {
                                const newFields = [...companyUniqueFields].filter((f) => f.id !== field.id);
                                form.setValue('companyUniqueFields', newFields);
                            }}
                        />
                    </div>
                );
            })}

            {companyUniqueFields.length > 0 && <div className={'step-row inputs-separator'} />}

            <DropdownSplitButton
                onClick={onSplitButtonClick}
                options={companyOptions
                    .filter(
                        (co) =>
                            co.isCompanyField &&
                            !companyUniqueFields.find((o) => o.id === co.id || o.label === co.label)
                    )
                    .map((co) => ({ label: co.label, value: co.id, id: co.id }))}
                separatorLabel={'הוספת שדה חדש'}
                buttonLabel={`הוספת שדה ייחודי`}
            />
        </div>
    );
};

import './CodeVerification.scss';
import { useEffect, useState, FC, CSSProperties } from 'react';
import '../../scss/page-auth.scss';
import { Button } from '../form/buttons/Button';
import { CodeInsertion } from './CodeInsertion';
import { useTranslation } from 'react-i18next';

type CodeVerificationProps = {
    digits?: number;
    sendAgain: () => void;
    verify: (value: string) => void;
    back: () => void;
    style?: CSSProperties;
};

export const CodeVerification: FC<CodeVerificationProps> = ({ digits = 6, verify, back, sendAgain }) => {
    const [isResendDisabled, setResendDisabled] = useState(true);
    const [values, setValues] = useState<string[]>(new Array(digits).fill(''));
    const { t } = useTranslation();

    useEffect(() => {
        if (isResendDisabled) {
            const timeout = setTimeout(() => {
                setResendDisabled(false);
            }, 30000);

            return () => clearTimeout(timeout);
        }
    }, [isResendDisabled]);

    const onClickVerify = (updatedValues?: string[]) => {
        const codeValue = (updatedValues ?? values).toString().replaceAll(',', '');

        if (codeValue.length === digits) {
            verify?.(codeValue);
        }
    };

    return (
        <div className={'validation'}>
            <CodeInsertion
                digits={digits}
                values={values}
                setValues={setValues}
                onClickVerify={onClickVerify}
            />
            <div className={'buttons-footer'}>
                <Button style={{ width: '100%' }} onClick={() => onClickVerify()} theme="primary">
                    {t('login')}
                </Button>
                <Button style={{ width: '100%' }} onClick={back} theme="secondary">
                    {t('back')}
                </Button>
                <Button
                    theme={'none'}
                    onClick={() => {
                        setResendDisabled(true);
                        sendAgain();
                    }}
                    disabled={isResendDisabled}>
                    {t('resendCode')}
                </Button>
            </div>
        </div>
    );
};

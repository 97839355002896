import { FC } from 'react';
import { ChooseCompany } from '../choose-company/ChooseCompany';
import { Modal } from './Modal';
import { InnerModal } from '../modal/InnerModal/InnerModal';
import { SelectOption } from '../../models/shared.models';
import { useTranslation } from 'react-i18next';

type ChooseCompanyModalProps = {
    chosenUploadCompany?: SelectOption | null;
    name: string;
    disabled?: boolean;
    isOpen: boolean;
    closeModal: () => void;
    onTrigger: () => void;
};

export const ChooseCompanyModal: FC<ChooseCompanyModalProps> = ({
    name,
    disabled,
    closeModal,
    onTrigger,
    chosenUploadCompany,
    isOpen,
}) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} onClose={closeModal}>
            <InnerModal
                content={<ChooseCompany name={name} isDisabled={disabled} />}
                header={{ title: t('chooseCompany') }}
                footer={{
                    buttons: [
                        {
                            label: t('continue'),
                            theme: 'primary',
                            isDisabled: !chosenUploadCompany,
                            callback: onTrigger,
                        },
                    ],
                }}
            />
        </Modal>
    );
};

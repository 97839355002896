import { FC, useMemo } from 'react';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { Save } from 'react-feather';
import { InnerModalFooter } from '../modal/InnerModal/InnerModalFooter';
import { ModalButton } from '../modal/Modal.utils';
import { useTranslation } from 'react-i18next';

type AddSideBudgetProps = {
    requestBudget: () => void;
    closeModal: () => void;
};

export const AddSideBudget: FC<AddSideBudgetProps> = ({ requestBudget, closeModal }) => {
    const { t } = useTranslation();

    const buttons: ModalButton[] = useMemo(() => {
        return [
            {
                label: t('close'),
                theme: 'grey',
                callback: closeModal,
            },
            {
                label: t('save'),
                callback: requestBudget,
                icon: <Save />,
                iconPosition: 'right',
                theme: 'primary',
            },
        ];
    }, [requestBudget, closeModal]);

    return (
        <div className={'add-side-budget-step-2'}>
            <SmartInput name={'budget'} label={t('addToCompanyBudget')} required enforceNumbers />
            <InnerModalFooter buttons={buttons} position="left" />
        </div>
    );
};

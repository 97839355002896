import { User } from '../components/users/users.models';
import { FilterForm } from '../components/table/Tables.types';
import { ReactElement, ReactNode } from 'react';
import { SelectOption } from './shared.models';
import { v4 } from 'uuid';

export type GroupType = 'public' | 'private' | 'company' | 'invitation' | 'core';

export type Group = {
    groupID: string;
    companyID: string;
    ownerID?: string | null;
    name: string;
    type: GroupType;
    imageURL?: string;
};

export type GroupWithMembers = Group & {
    members: User[];
};

export type GroupForm = {
    groupID: string;
    companyID: string;
    imageURL?: string;
    url?: string;
    name: string;
    ownerID?: string | null;
    members: SelectOption[];
};

export const defaultGroupValues: GroupForm = {
    groupID: v4(),
    companyID: '',
    imageURL: '',
    name: '',
    members: [],
};

export type GroupsFilterForm = FilterForm<GroupWithMembers> & {};

export const defaultGroupsTableValues: GroupsFilterForm = {
    rowsPerPage: { label: 25, value: 25 },
    page: 0,
    orderBy: {
        direction: 'DESC',
        sortedColumn: undefined,
    },
};

export type GroupsTableData = {
    name: ReactNode;
    groupName: string;
    image: ReactElement;
    members: ReactElement;
};

export type GroupOption = { label: string; value: { groupID: string } };

export type MemberGroup = Group & {
    groupID: string;
    companyID: string;
    userID: string;
    isActive: boolean;
    invitationTime?: number;
};

export type GroupMemberStatus = Omit<Group & MemberGroup, 'isActive'>;

import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { SmartSelect } from '../form/select/smart-select';
import { SmartAutoComplete } from '../form/select/auto-complete';
import { getProductsAPI } from '../../api/store.api';
import { Product } from '../store/store.models';
import './send-gift.scss';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { useTranslation } from 'react-i18next';
import { getImageURL } from '../../utils/utils';

export const ChooseGift: FC = () => {
    const { setValue, control } = useFormContext();
    const giftType = useWatch({ control, name: 'giftType' });
    const company = useWatch({ control: control, name: 'company' });
    const { t } = useTranslation();

    const renderGiftOptions = () => {
        switch (giftType.value) {
            case 'product': {
                return (
                    <SmartAutoComplete
                        getLoadOptionsData={(e) => productsLoadOptions(e, company?.value)}
                        label={t('chooseProduct')}
                        name={'product'}
                        required
                    />
                );
            }
            case 'credit': {
                return (
                    <SmartInput enforceNumbers name={'creditsAmount'} label={t('creditsAmount')} required />
                );
            }
        }
    };

    const productsLoadOptions = async (inputValue: string, companyID?: string) => {
        const results = await getProductsAPI<Product>({
            productName: inputValue,
            companyID,
            availabilitySupplier: true,
        });
        if (!results?.data) return [];

        return results.data.map((c) => {
            return {
                label: (
                    <div className={'product-desc'} key={c.productID}>
                        <img src={getImageURL(c.mainImage)} alt={''} width={32} height={32} />
                        <div className={'title-and-catalog-number'}>
                            <div className={'title'}>{c.title}</div>
                            <div className={'subtitle'}>{`${t('makat')} ${c.catalogNumberID}`}</div>
                        </div>
                    </div>
                ),
                value: c.productID,
                key: c.productID,
            };
        });
    };

    return (
        <div className={'step'} style={{ width: '412px' }}>
            <div className={'step-row'}>
                <SmartSelect
                    label={t('giftType')}
                    name={'giftType'}
                    options={[
                        { value: 'credit', label: t('points') },
                        { value: 'product', label: t('product') },
                    ]}
                    required
                    onChange={(value: string) => {
                        if (value === 'credit') {
                            setValue('product', undefined);
                        } else {
                            setValue('creditsAmount', undefined);
                        }
                    }}
                />
            </div>
            <div className={'step-row'}>{renderGiftOptions()}</div>
        </div>
    );
};

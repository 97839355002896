import { FC } from 'react';
import { useFormContext, UseFormReturn, useWatch } from 'react-hook-form';
import { MaritalStatus, maritalStatuses, openCoupleStatuses, UserDetailsForm } from './users.models';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { SmartDatePicker } from '../form/date-picker/smart-date-picker';
import { RadioOption } from '../form/radio/smart-radio-button';
import { SmartSelect } from '../form/select/smart-select';
import { v4 } from 'uuid';
import { Button } from '../form/buttons/Button';
import { Plus } from 'react-feather';
import { ChildField } from '../unique-field/child-field';
import { SpouseGender } from './SpouseGender';
import { useTranslation } from 'react-i18next';

type UsersStep4Props = {};

export const UsersStep4: FC<UsersStep4Props> = ({}) => {
    const form: UseFormReturn<UserDetailsForm> = useFormContext();
    const maritalStatus = useWatch({ name: 'maritalStatus', control: form.control });
    const spouse = useWatch({ name: 'spouse', control: form.control });
    const children = useWatch({ control: form.control, name: 'children' });
    const { t } = useTranslation();

    return (
        <div className={'step'}>
            <div className={'field-with-publicity'}>
                <SmartSelect
                    name={'maritalStatus.value'}
                    label={'סטטוס'}
                    placeholder={'סטטוס'}
                    options={['', ...maritalStatuses].map((s: MaritalStatus | string) => ({
                        label: t(s),
                        value: s,
                    }))}
                    onChange={(value) => {
                        if (
                            openCoupleStatuses.includes(value as MaritalStatus) &&
                            form.getValues('spouse.gender.value') === 'none'
                        ) {
                            const pronoun = form.getValues('pronoun');
                            form.setValue(
                                'spouse.gender.value',
                                pronoun?.value?.value === 'he' ? 'female' : 'male'
                            );
                        } else {
                            // @ts-ignore
                            form.setValue('spouse', {
                                gender: { value: 'none', isPublic: false },
                                name: { value: '', isPublic: false },
                                birthDate: { value: '', isPublic: false },
                            });
                        }
                    }}
                />
                <RadioOption name={'maritalStatus.isPublic'} option1={t('private')} option2={t('public')} />
            </div>
            {maritalStatus?.value?.value === 'married' && (
                <div className={'field-with-publicity'}>
                    <SmartDatePicker
                        name={'marriageDate.value'}
                        label={'יום נישואין'}
                        placeholder={'DD/MM/YYYY'}
                        dateFormat={'dd/MM/yyyy'}
                        disabled={maritalStatus?.value?.value !== 'married'}
                    />
                    <RadioOption
                        name={'marriageDate.isPublic'}
                        option1={t('private')}
                        option2={t('public')}
                    />
                </div>
            )}

            <div className={'step-row inputs-separator'} />

            <div className={'step-row'}>
                <SpouseGender
                    name={'spouse.gender.value'}
                    option1={'בת זוג'}
                    option2={'בן זוג'}
                    option3={'ללא'}
                />
            </div>

            {spouse?.gender?.value !== 'none' && (
                <div className={'step'}>
                    <div className={'field-with-publicity'}>
                        <SmartInput
                            name={'spouse.name.value'}
                            label={'שם בן/בת זוג'}
                            placeholder={'שם בן/בת זוג'}
                        />
                        <RadioOption
                            name={'spouse.name.isPublic'}
                            option1={t('private')}
                            option2={t('public')}
                        />
                    </div>

                    <div className={'field-with-publicity'}>
                        <SmartDatePicker
                            dateFormat={'dd/MM/yyyy'}
                            placeholder="DD/MM/YYYY"
                            name={'spouse.birthDate.value'}
                            label={'תאריך לידה'}
                        />
                        <RadioOption
                            name={'spouse.birthDate.isPublic'}
                            option1={t('private')}
                            option2={t('public')}
                        />
                    </div>
                </div>
            )}

            {spouse?.gender?.value !== 'none' && <div className={'step-row inputs-separator'} />}

            {children?.map((field, i) => (
                <div className={'step-row'}>
                    <ChildField
                        key={i}
                        name={`children.${i}.name`}
                        genderName={`children.${i}.gender`}
                        birthDateName={`children.${i}.birthDate`}
                        onRemove={() => {
                            const newFields = [...children].filter((f) => f.id !== field.id);
                            form.setValue('children', newFields);
                        }}
                    />
                </div>
            ))}

            {children.length > 0 && <div className={'step-row inputs-separator'} />}

            <Button
                onClick={() => {
                    const newFields = [...children];
                    newFields.push({
                        name: { value: '', isPublic: false },
                        birthDate: { value: null, isPublic: false },
                        gender: { value: { label: 'זכר', value: 'male' }, isPublic: false },
                        id: v4(),
                    });
                    form.setValue('children', newFields);
                }}
                style={{ width: '146px' }}
                theme={'secondary'}>
                <span className={'button-center'}>
                    <Plus width={10} height={10} />
                    הוספת ילד
                </span>
            </Button>
        </div>
    );
};

import { FC, useState } from 'react';
import { FormProvider, useForm, UseFormReturn, useWatch } from 'react-hook-form';
import {
    Company,
    defaultHashtagsValues,
    HashtagsFilterForm,
    hashtagsTableColumns,
    HashtagsTableData,
} from './companies.models';
import { useUpdateEffect } from '../../hooks/useUpdateEffect';
import { TableHeader } from '../table/TableHeader';
import { Table } from '../table/Table';
import { Button } from '../form/buttons/Button';
import { PlusCircle, Trash } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { closeModal, raiseModal } from '../../store/modal/modal.actions';
import { ModalIcon } from '../modal/ModalIcon';
import { SuccessToast } from '../toaster/Toast';
import { Column } from '../table/Tables.types';
import { User } from '../users/users.models';
import { Order } from '../../hooks/useSort';
import { useTranslation } from 'react-i18next';
import { CreateHashtagModal } from '../modals/CreateHashtag.modal';
import { deleteHashtagsAPI, getHashtagsAPI } from '../../api/hashtags.api';

type CompaniesStep4Props = {
    form: UseFormReturn<Company>;
};

export const CompaniesStep4: FC<CompaniesStep4Props> = ({ form }) => {
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [isCreateHashtagOpen, setCreateHashtagOpen] = useState(false);
    const filterForm = useForm<HashtagsFilterForm>({
        defaultValues: defaultHashtagsValues,
        mode: 'onTouched',
    });
    const { t } = useTranslation();
    const orderBy = useWatch({ name: 'orderBy', control: filterForm.control });
    const companyID = useWatch({ name: 'companyID', control: form.control });
    const rowsPerPage = useWatch({ name: 'rowsPerPage', control: filterForm.control });
    const page = useWatch({ name: 'page', control: filterForm.control });
    const dispatch = useDispatch();
    const { refetch, data: { data: hashtags = [], total = 0 } = {} } = useQuery({
        queryKey: ['getHashtags'],
        queryFn: async () => {
            const companyID = form.getValues('companyID');
            return getHashtagsAPI({
                companyID,
                offset: page * rowsPerPage.value,
                count: rowsPerPage.value,
                sortBy: orderBy.direction,
                sortField: 'name',
            });
        },
        enabled: true,
    });

    useUpdateEffect(() => {
        if (page !== 0) {
            filterForm.setValue('page', 0);
        } else {
            refetch();
        }
    }, [rowsPerPage.value]);

    useUpdateEffect(() => {
        refetch();
    }, [dispatch, form, page, orderBy]);

    const CustomHeader = () => {
        return (
            <TableHeader
                rowsPerPage={rowsPerPage.value}
                leftSideComponents={[
                    selectedRows.length > 0 && (
                        <Button
                            theme={'outline-danger'}
                            onClick={() => handleDeleteHashtag()}
                            icon={<Trash width={13} />}>
                            {t('deleteHashtags')}
                        </Button>
                    ),
                    <Button
                        theme={'primary'}
                        onClick={() => setCreateHashtagOpen(true)}
                        icon={<PlusCircle width={13} />}>
                        {t('createNewHashtag')}
                    </Button>,
                ]}
                rightSideComponents={[]}
            />
        );
    };

    const populateHashtagsData = (): HashtagsTableData[] => {
        return hashtags.map((h) => ({ name: `#${h}`, id: h }));
    };

    const onRowSelect = (row: { selectedRows: HashtagsTableData[] }) => {
        setSelectedRows(row.selectedRows?.map((r) => r.id));
    };

    const confirmDeleteHashtag = async (companyID: string, deletedHashtags: string[]): Promise<void> => {
        deleteHashtagsAPI(companyID, deletedHashtags).then(() => {
            SuccessToast(t('hashtagSuccessfullyDeleted'));
            setSelectedRows([]);
            refetch();
        });
    };

    const handleDeleteHashtag = (name?: string) => {
        const deletedHashtags = name ? [name] : selectedRows;
        const companyID = form.getValues('companyID');

        dispatch(
            raiseModal({
                isOpen: true,
                content: `${t('areYouSureYouWantToDeleteFollowingHashtags')}:\n${deletedHashtags
                    .map((n) => `#${n}`)
                    .join(', ')}`,
                header: {
                    title: t('deleteHashtag'),
                    icon: <ModalIcon theme={'ALERT'} />,
                },
                footer: {
                    buttons: [
                        {
                            label: t('yesDelete'),
                            theme: 'danger',
                            callback: () => {
                                confirmDeleteHashtag(companyID, deletedHashtags).then(() =>
                                    dispatch(closeModal())
                                );
                            },
                        },
                        { label: t('cancel'), theme: 'grey', callback: () => dispatch(closeModal()) },
                    ],
                },
            })
        );
    };

    return (
        <FormProvider {...filterForm}>
            <Table
                columns={hashtagsTableColumns(handleDeleteHashtag, t)}
                data={populateHashtagsData()}
                header={CustomHeader()}
                pagination={true}
                rowsPerPage={rowsPerPage.value}
                total={total}
                selectableRows={true}
                onRowSelect={onRowSelect}
                onSort={(column: Column<User>, direction: Order) => {
                    filterForm.setValue('orderBy', {
                        direction,
                    });
                }}
            />
            <CreateHashtagModal
                companyID={companyID}
                isOpen={isCreateHashtagOpen}
                refetchHashtags={refetch}
                closeModal={() => setCreateHashtagOpen(false)}
            />
        </FormProvider>
    );
};

import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { FC, ReactElement } from 'react';
import './BreadCrumbs.scss';

type BreadCrumbsProps = {
    breadCrumbTitle: string;
    breadCrumbs: string[];
    icon: ReactElement;
    onBack?: () => void;
};

export const BreadCrumbs: FC<BreadCrumbsProps> = ({ breadCrumbTitle, breadCrumbs, icon, onBack }) => {
    const cursorStyle = { cursor: onBack ? 'pointer' : 'auto' };

    return (
        <div className="content-header">
            {breadCrumbTitle && (
                <Breadcrumb className="content-header-title" onClick={onBack} style={cursorStyle}>
                    {breadCrumbTitle}
                </Breadcrumb>
            )}
            <div className={'line'} />
            <Breadcrumb
                className={'breadcrumb-wrapper breadcrumb-chevron'}
                listClassName={'breadcrumb-chevron'}>
                <div onClick={onBack} style={{ ...cursorStyle, display: 'flex' }}>
                    {icon}
                </div>
                <BreadcrumbItem tag="li" className="text-primary" />
                {breadCrumbs.map((crumb, i) => {
                    return (
                        <BreadcrumbItem
                            key={i}
                            tag="li"
                            className="text-primary"
                            style={{ cursor: onBack && i === 0 ? 'pointer' : 'auto' }}
                            onClick={() => {
                                if (i === 0) {
                                    return onBack?.();
                                }
                            }}>
                            {crumb}
                        </BreadcrumbItem>
                    );
                })}
            </Breadcrumb>
        </div>
    );
};

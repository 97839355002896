import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';
import { ArrowLeft, ArrowRight, Home, Save } from 'react-feather';
import { Tabs } from '../tabs/Tabs';
import { Button } from '../form/buttons/Button';
import './companies.scss';
import { CompaniesManagement } from './CompaniesManagement';
import { Company, companyDetailsFormInitialValues } from './companies.models';
import { CompaniesStep1 } from './CompaniesStep1';
import { v4 } from 'uuid';
import { CreateOrEditCompanyAPI, getCompaniesAPI } from '../../api/companies.api';
import { closeModal, raiseModal } from '../../store/modal/modal.actions';
import { companiesSchema } from './companies-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { CompaniesStep2 } from './CompaniesStep2';
import { CompaniesStep3 } from './CompaniesStep3';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { CompaniesStep4 } from './CompaniesStep4';
import { ModalIcon } from '../modal/ModalIcon';
import { SuccessToast } from '../toaster/Toast';
import { useHandleBack } from '../../hooks/useHandleBack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

type CompaniesProps = {};

const companyTabs = ['general', 'budget', 'companyGroups', 'hashtagsManagement'];

export const Companies: FC<CompaniesProps> = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector(getCurrentUser);
    const [currentStep, setCurrentStep] = useState(0);
    const [tabs, setTabs] = useState(companyTabs);
    const form = useForm<Company>({
        defaultValues: companyDetailsFormInitialValues,
        mode: 'onTouched',
        resolver: yupResolver(companiesSchema),
    });
    const {
        formState: { isDirty },
    } = form;
    const { t } = useTranslation();
    const handleBack = useHandleBack(isDirty);
    const [breadCrumbs, setBreadCrumbs] = useState([t('companiesManagement')]);
    const { companyID } = useParams();
    const navigate = useNavigate();

    const onCreateOrEdit = useCallback(
        async (chosenCompanyID: string | undefined) => {
            const companies = await getCompaniesAPI<Company>({
                companyID: chosenCompanyID,
                isExtended: true,
            });
            const company = companies?.data?.[0];

            setTabs(companyTabs);
            setCurrentStep(1);
            const breadcrumbs = [
                t('companiesManagement'),
                chosenCompanyID ? t('editCompany') : t('createCompany'),
            ];
            if (chosenCompanyID) {
                breadcrumbs.push(company?.name);
            }
            setBreadCrumbs(breadcrumbs);

            if (chosenCompanyID && company) {
                const companyToEdit: Company = { ...company };
                companyToEdit.mainColor = `#${companyToEdit.mainColor}`;
                companyToEdit.secondaryColor = `#${companyToEdit.secondaryColor}`;
                companyToEdit.logoUrl = companyToEdit.logo;
                companyToEdit.whiteLogoUrl = companyToEdit.whiteLogo;
                companyToEdit.squareLogoUrl = companyToEdit.squareLogo;

                form.reset(companyToEdit, { keepDirty: false });
            } else {
                form.reset(companyDetailsFormInitialValues);
                form.setValue('companyID', v4());
            }
        },
        [dispatch, form]
    );

    useEffect(() => {
        if (companyID) {
            onCreateOrEdit(companyID);
        } else {
            setCurrentStep(0);
            setBreadCrumbs([t('companiesManagement')]);
        }
    }, [companyID, navigate]);

    useEffect(() => {
        if (currentUser?.userType !== 'admin' && currentUser?.companyID) {
            onCreateOrEdit(currentUser.companyID);
        }
    }, [currentUser?.userType, currentUser?.companyID, onCreateOrEdit]);

    const renderNextButton = () => {
        return (
            currentStep < tabs.length && (
                <Button
                    style={{ maxWidth: '100px' }}
                    theme={'primary'}
                    onClick={() => setCurrentStep(currentStep + 1)}
                    icon={<ArrowLeft size={14} />}
                    direction={'left'}>
                    {t('next')}
                </Button>
            )
        );
    };

    const saveButton = () => {
        return (
            <Button theme={'primary'} onClick={handleSave} icon={<Save size={14} />}>
                {t('save')}
            </Button>
        );
    };

    const renderStep = () => {
        switch (currentStep) {
            case 0:
                return <CompaniesManagement onCreate0rEdit={onCreateOrEdit} />;
            case 1:
                return <CompaniesStep1 form={form} />;
            case 2:
                return <CompaniesStep2 form={form} />;
            case 3:
                return <CompaniesStep3 form={form} />;
            case 4:
                return <CompaniesStep4 form={form} />;
        }

        return null;
    };

    const handleSave = async () => {
        await form.trigger().then((value) => {
            if (!value) {
                dispatch(
                    raiseModal({
                        isOpen: true,
                        content: t('mustFillMandatoryFields'),
                        header: {
                            icon: <ModalIcon theme={'ALERT'} />,
                            title: t('save'),
                        },
                        footer: {
                            buttons: [
                                { label: t('close'), theme: 'grey', callback: () => dispatch(closeModal()) },
                            ],
                        },
                    })
                );
            } else {
                dispatch(
                    raiseModal({
                        isOpen: true,
                        content: t('areYouSureSaveChanges'),
                        header: {
                            title: t('save'),
                            icon: <ModalIcon theme={'ALERT'} />,
                        },
                        footer: {
                            buttons: [
                                { label: t('close'), theme: 'grey', callback: () => dispatch(closeModal()) },
                                {
                                    label: t('save'),
                                    theme: 'primary',
                                    callback: () => {
                                        confirmSave();
                                        dispatch(closeModal());
                                    },
                                },
                            ],
                        },
                    })
                );
            }
        });
    };

    const confirmSave = () => {
        const companyForm = form.getValues();
        const { companyContact } = companyForm;
        const company: Company = {
            ...companyForm,
            logo: companyForm.logo,
            companyContact: {
                HRName: companyContact.HRName,
                HREmail: companyContact.HREmail,
                HRPhone: companyContact.HRPhone,
                accountingEmail: companyContact.accountingEmail || undefined,
                accountingName: companyContact.accountingName || undefined,
                accountingPhone: companyContact.accountingPhone || undefined,
            },
            usersUniqueFields: companyForm?.usersUniqueFields ?? [],
            companyUniqueFields: companyForm?.companyUniqueFields ?? [],
            mainColor: companyForm.mainColor.replace('#', ''),
            secondaryColor: companyForm.secondaryColor.replace('#', ''),
        };

        CreateOrEditCompanyAPI(company)
            .then(() => {
                SuccessToast(t('companySavedSuccessfully'));
                onCreateOrEdit(company.companyID);
                form.reset(company);
            })
            .catch((e) => console.log(e.response?.data?.message?.join()));
    };

    const onStageReset = () => {
        setCurrentStep(currentUser?.userType === 'admin' ? 0 : 1);
        setBreadCrumbs([t('companiesManagement')]);
        navigate('/companies');
    };

    return (
        <div className={'companies'}>
            <FormProvider {...form}>
                <BreadCrumbs
                    breadCrumbTitle={
                        currentUser?.userType !== 'admin' ? t('editCompany') : t('companiesManagement')
                    }
                    icon={<Home width={13} height={13} color={'var(--primary)'} />}
                    breadCrumbs={currentUser?.userType !== 'admin' ? [t('editCompany')] : breadCrumbs}
                    onBack={
                        currentUser?.userType === 'admin'
                            ? () =>
                                  handleBack(() => {
                                      onStageReset();
                                      navigate('/companies');
                                  }, onStageReset)
                            : undefined
                    }
                />
                {currentStep !== 0 && (
                    <div className={'steps-tabs'}>
                        <Tabs activeTab={currentStep} toggleTab={setCurrentStep} tabs={tabs} />
                        <span style={{ display: 'flex', gap: '12px' }}>
                            {currentUser?.userType === 'admin' && (
                                <Button
                                    theme={'outline-primary'}
                                    onClick={() => {
                                        navigate('/companies');
                                        handleBack(() => {
                                            onStageReset();
                                            navigate('/companies');
                                        }, onStageReset);
                                    }}
                                    icon={<ArrowRight size={14} />}>
                                    {t('back')}
                                </Button>
                            )}
                            {saveButton()}
                        </span>
                    </div>
                )}
                {renderStep()}
                {currentStep !== 0 && renderNextButton()}
            </FormProvider>
        </div>
    );
};

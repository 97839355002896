import * as Yup from 'yup';
import { ERROR_MESSAGES } from '../../utils/validations';

export const GiftSchema = Yup.object().shape({
    behalfOf: Yup.object({
        label: Yup.string().nullable().required(ERROR_MESSAGES.required),
        value: Yup.string().nullable().required(ERROR_MESSAGES.required),
    })
        .nullable()
        .required(ERROR_MESSAGES.required),
    content: Yup.string().min(1, ERROR_MESSAGES.required).required(ERROR_MESSAGES.required),
    publishAtDate: Yup.lazy(() =>
        Yup.date()
            .nullable()
            .when(['publishAtDate', 'publishAtTime'], {
                is: (publishAtDate: number | null | undefined, publishAtTime: number | null | undefined) =>
                    !!publishAtDate || !!publishAtTime,
                then: Yup.date().nullable().required(ERROR_MESSAGES.required),
            })
    ),
    publishAtTime: Yup.lazy(() =>
        Yup.string()
            .nullable()
            .when(['publishAtDate', 'publishAtTime'], {
                is: (publishAtDate: number | null | undefined, publishAtTime: number | null | undefined) =>
                    !!publishAtDate || !!publishAtTime,
                then: Yup.string().required(ERROR_MESSAGES.required),
            })
    ),
});

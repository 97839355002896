import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { UseFormReturn } from 'react-hook-form';
import { useUpdateEffect } from './useUpdateEffect';

export interface FormData<T> {
    [key: string]: T;
}

export const useFormWithSession = <T extends { [key: string]: any }>(
    form: UseFormReturn<T>,
    refetch?: (formValues: T) => void
) => {
    const location = useLocation();

    // useEffect(() => {
    //     return form.watch((values) => {
    //         let savedFormDataString = sessionStorage.getItem('formData');
    //
    //         if (savedFormDataString) {
    //             const savedFormData: FormData<T> = JSON.parse(savedFormDataString);
    //
    //             form.reset(savedFormData as T);
    //             refetch?.(savedFormData as T);
    //         }
    //     }).unsubscribe;
    // }, [location.key]);

    useEffect(() => {
        let savedFormDataString = sessionStorage.getItem('formData');

        if (savedFormDataString) {
            const savedFormData: FormData<T> = JSON.parse(savedFormDataString);

            form.reset(savedFormData as T);
            refetch?.(savedFormData as T);
        }
    }, [location.key]);

    useUpdateEffect(() => {
        const formValues = form.watch();
        if (formValues.users) {
            delete formValues.users;
        }
        delete formValues.total;

        sessionStorage.setItem('formData', JSON.stringify(formValues));
    }, [form.watch()]);
};

import * as Yup from 'yup';
import { ERROR_MESSAGES } from '../../utils/validations';

export const supplierSchema = Yup.object().shape({
    supplierName: Yup.string().required(ERROR_MESSAGES.required).nullable(),
    APILink: Yup.string().required(ERROR_MESSAGES.required).nullable(),
    APIService: Yup.object({
        label: Yup.string().nullable(),
        value: Yup.string().required(ERROR_MESSAGES.required).nullable(),
    })
        .required(ERROR_MESSAGES.required)
        .nullable(),
    contactName: Yup.string().required(ERROR_MESSAGES.required).nullable(),
    contactPhone: Yup.string()
        .min(9, 'טלפון לא תקין')
        .max(10, 'טלפון לא תקין')
        .required(ERROR_MESSAGES.required)
        .nullable(),
    contactEmail: Yup.string().email('אימייל לא תקין').required(ERROR_MESSAGES.required).nullable(),
});

import './CodeVerification.scss';
import {
    useEffect,
    useRef,
    KeyboardEvent,
    ChangeEvent,
    ClipboardEvent,
    FC,
    createRef,
    RefObject,
    Dispatch,
    SetStateAction,
} from 'react';
import './CodeInsertion.scss';
import { Input } from '../form/input-and-checkbox/Input';
import { useTranslation } from 'react-i18next';

type CodeInsertionProps = {
    digits: number;
    values: string[];
    onClickVerify: (values: string[]) => void;
    setValues: Dispatch<SetStateAction<string[]>>;
    onError?: string;
};

export const CodeInsertion: FC<CodeInsertionProps> = ({
    digits,
    values,
    setValues,
    onClickVerify,
    onError,
}) => {
    const myRefs = useRef<RefObject<HTMLInputElement>[]>(Array.from({ length: digits }, () => createRef()));
    const { t } = useTranslation();

    const onKeyUp = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
        e.preventDefault();

        if (e.key === 'Backspace') {
            onBackspaceClicked(index);
        }
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>, index: number, current: HTMLInputElement | null) => {
        e.preventDefault();

        if (!Number.isInteger(+e.target.value) || e.target?.value?.length > 1) {
            return;
        }
        const newValues = [...values];

        if (index < digits - 1) {
            current?.focus();
            newValues[index + 1] = '';
        }

        newValues[index] = e.target.value;
        setValues(newValues);

        if (index === digits - 1 && newValues.every((v) => v !== '')) {
            onClickVerify(newValues);
            return;
        }
    };

    const onBackspaceClicked = (index: number) => {
        if (index === digits - 1 && values[digits - 1]) {
            const newValues = [...values];
            newValues[digits - 1] = '';
            setValues(newValues);
            return;
        }

        if (index) {
            const newValues = [...values];
            newValues[index - 1] = '';
            setValues(newValues);
            myRefs.current[index - 1]?.current?.focus();
        }
    };

    const onPaste = (e: ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const pasteValue = e.clipboardData.getData('Text');
        if (Number.isInteger(+pasteValue) && pasteValue.length === digits) {
            onPasteCode(pasteValue);
        }
    };

    const onPasteCode = (code: string) => {
        setValues(Array.from(String(code)));
        myRefs.current[digits - 1]?.current?.focus();
    };

    const onClickInput = (index: number) => {
        const newValues = [...values];
        newValues[index] = '';
        setValues(newValues);
    };

    useEffect(() => {
        myRefs.current[0]?.current?.focus();
    }, [myRefs]);

    return (
        <div className={'insert-verification-code'}>
            <h3 className={'welcome-title'}>{t('enterCode')}</h3>
            <div className={'welcome-description'}>{t('typeCodeThatWasSentToYou')}</div>
            <div className={'validation-inputs'}>
                {values.map((value, index) => (
                    <Input
                        key={index}
                        type="text"
                        value={value}
                        inputRef={myRefs.current[index]}
                        className={'square'}
                        onClick={() => onClickInput(index)}
                        onChange={(e) => onChange(e, index, myRefs.current[index + 1]?.current)}
                        onKeyUp={(e) => onKeyUp(e, index)}
                        onPaste={onPaste}
                    />
                ))}
            </div>
            <div style={{ color: 'red', marginTop: '-10px' }}>{onError}</div>
        </div>
    );
};

import { FC, useState } from 'react';
import { Filters } from '../filters/Filters';
import { Filter, FiltersModels } from '../filters/filters.models';
import './orders.scss';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Table } from '../table/Table';
import { useQuery } from 'react-query';
import { Column } from '../table/Tables.types';
import { Order } from '../../hooks/useSort';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from '../../hooks/useUpdateEffect';
import { TableHeader } from '../table/TableHeader';
import {
    defaultOpenTransactionsFilterForm,
    defaultPurchaseFilterForm,
    OpenTransactionsFilterForm,
    openTransactionsManagementTableColumns,
    OrderTableData,
    Purchase,
} from './order.models';
import { populateOpenTransactionsData } from './orders.utils';
import { companyLoadOptions } from '../../utils/loadOptions';
import { Button } from '../form/buttons/Button';
import { closeModal, raiseModal } from '../../store/modal/modal.actions';
import { ModalIcon } from '../modal/ModalIcon';
import { SuccessToast } from '../toaster/Toast';
import { cancelBoostOrGift, getOpenTransactions } from '../../api/posts.api';

export const OpenTransactions: FC = () => {
    const dispatch = useDispatch();
    const form = useForm<OpenTransactionsFilterForm>({
        defaultValues: defaultOpenTransactionsFilterForm,
        mode: 'onTouched',
    });
    const company = useWatch({ name: 'company', control: form.control });
    const name = useWatch({ name: 'name', control: form.control });
    const fromTimestamp = useWatch({ name: 'fromTimestamp', control: form.control });
    const toTimestamp = useWatch({ name: 'toTimestamp', control: form.control });
    const orderBy = useWatch({ name: 'orderBy', control: form.control });
    const rowsPerPage = useWatch({ name: 'rowsPerPage', control: form.control });
    const page = useWatch({ name: 'page', control: form.control });
    const { t } = useTranslation();
    const [selectedRows, setSelectedRows] = useState<string[]>([]);

    const { refetch, data: { data: openTransactions = [], total = 0 } = {} } = useQuery({
        queryKey: ['getOpenTransactions'],
        queryFn: async () => {
            return getOpenTransactions({
                companyID: company?.value,
                name,
                fromTimestamp: fromTimestamp ? new Date(fromTimestamp).getTime() : undefined,
                toTimestamp: toTimestamp ? new Date(toTimestamp).getTime() : undefined,
                offset: page * (rowsPerPage?.value ?? 0),
                count: rowsPerPage?.value,
                sortBy: orderBy?.direction,
                sortField: orderBy?.sortedColumn,
            });
        },
        enabled: true,
    });

    useUpdateEffect(() => {
        if (page !== 0) {
            form.setValue('page', 0);
        } else {
            refetch();
        }
    }, [rowsPerPage?.value]);

    useUpdateEffect(() => {
        refetch();
    }, [dispatch, form, page, orderBy]);

    const cancelBoostOrGifts = async () => {
        handleCancelBoostOrGift(selectedRows);
    };

    const CustomHeader = () => {
        return (
            <TableHeader
                rowsPerPage={rowsPerPage?.value}
                leftSideComponents={[
                    selectedRows.length > 0 && (
                        <Button key={1} theme={'primary'} onClick={cancelBoostOrGifts}>
                            {t('cancelOpenTransactions')}
                        </Button>
                    ),
                ]}
            />
        );
    };

    const onRowSelect = (row: { selectedRows: OrderTableData[] }) => {
        setSelectedRows(row.selectedRows?.map((r) => r.id));
    };

    const handleCancelBoostOrGift = (postsIDs: string[]) => {
        dispatch(
            raiseModal({
                isOpen: true,
                content: t('areYouSureCancelBoostOrGift'),
                header: {
                    title: t('confirmCancel'),
                    icon: <ModalIcon theme={'ALERT'} />,
                },
                footer: {
                    buttons: [
                        {
                            label: t('confirm'),
                            theme: 'danger',
                            callback: async () => {
                                cancelBoostOrGift(postsIDs)
                                    .then(() => {
                                        SuccessToast(t('operationSuccess'));
                                    })
                                    .catch()
                                    .finally(() => {
                                        setSelectedRows([]);
                                        refetch();
                                        dispatch(closeModal());
                                    });
                            },
                        },
                        {
                            label: t('cancel'),
                            theme: 'grey',
                            callback: async () => dispatch(closeModal()),
                        },
                    ],
                },
            })
        );
    };

    const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            refetch();
        }
    };

    const getFilters = (): Filter[] => {
        return [
            {
                type: FiltersModels.AUTO_COMPLETE,
                getLoadOptionsData: companyLoadOptions,
                name: 'company',
                placeholder: t('showAll'),
                label: t('company'),
                onKeyDown,
            },
            {
                type: FiltersModels.INPUT,
                name: 'name',
                onKeyDown,
                placeholder: t('search...'),
                label: t('searchByNamePhoneEmail'),
            },
            {
                type: FiltersModels.DATE,
                onKeyDown,
                name: 'fromTimestamp',
                label: t('fromDate'),
                placeholder: t('fromDate'),
            },
            {
                type: FiltersModels.DATE,
                onKeyDown,
                name: 'toTimestamp',
                label: t('toDate'),
                placeholder: t('toDate'),
            },
            {
                type: FiltersModels.BUTTON,
                sizeBootstrap: 2,
                label: t('filter'),
                onClick: () => refetch(),
            },
        ];
    };

    return (
        <FormProvider {...form}>
            <div className={'management'}>
                <Filters
                    clearFilters={() => form.reset(defaultPurchaseFilterForm)}
                    filters={getFilters()}
                    title={t('filterBy')}
                />
                <Table
                    columns={openTransactionsManagementTableColumns()}
                    data={populateOpenTransactionsData(openTransactions, handleCancelBoostOrGift, t)}
                    header={CustomHeader()}
                    pagination={true}
                    rowsPerPage={rowsPerPage?.value}
                    total={total}
                    selectableRows={true}
                    onSort={(column: Column<Purchase>, direction: Order) => {
                        form.setValue('orderBy', { direction, sortedColumn: column.field });
                    }}
                    onRowSelect={onRowSelect}
                />
            </div>
            {/*<ChangeStatusModal*/}
            {/*    isOpen={!!changeStatus}*/}
            {/*    refetchPurchases={refetch}*/}
            {/*    closeModal={() => setChangeStatusOpen(undefined)}*/}
            {/*    options={*/}
            {/*        changeStatus === 'regular'*/}
            {/*            ? orderStatuses?.map((os) => os?.name)*/}
            {/*            : internalOrderStatuses?.map((os) => os?.name)*/}
            {/*    }*/}
            {/*    statusType={changeStatus}*/}
            {/*    purchasesIDs={selectedRows}*/}
            {/*/>*/}
        </FormProvider>
    );
};

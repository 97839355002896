import { toast } from 'react-toastify';
import { CheckCircle } from 'react-feather';

const autoClose = 3000;

export const ErrorToast = (message: string | string[]) => {
    return toast.error(Array.isArray(message) ? message.join(' ') : message, { autoClose, rtl: true });
};

export const SuccessToast = (message: string) => {
    return toast.success(message, { icon: <CheckCircle color={'var(--primary)'} />, autoClose });
};

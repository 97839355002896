import axios from 'axios';
import { ErrorToast } from '../components/toaster/Toast';
import { TFunction } from 'i18next';
import { LocalStorage } from '../utils/LocalStorage.utils';
import { store } from '../store/store';
import { setCurrentUser, setToken } from '../store/users/UsersActions';
import { incrementActiveRequests } from '../store/loading/LoadingActions';
import i18next from 'i18next';
import { debounce } from 'lodash';

export const axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_DEVELOPMENT}/communi-system/api`,
});

const t: TFunction<'translation'> = i18next.t;

export const setup = async () => {
    const debounceAxiosToast = debounce((text: string) => {
        ErrorToast(text);
    }, 1000);

    axiosClient.interceptors.response.use(
        (response) => response,
        async (error) => {
            const token = LocalStorage.getItem('token');

            if (error?.response?.status === 401) {
                await LocalStorage.removeItem('token');
                store.dispatch(setCurrentUser(undefined));
                store.dispatch(setToken(null));
                return;
            } else {
                if (!token) {
                    store.dispatch(setCurrentUser(undefined));
                }
            }
            if (error.response?.data?.errorCode?.code && token) {
                debounceAxiosToast(t(error?.response?.data?.errorCode?.code));
            } else if (error?.response?.data?.message) {
                if (Array.isArray(error?.response?.data?.message)) {
                    debounceAxiosToast(error?.response?.data?.message?.join());
                }
                // else {
                //     debounceAxiosToast(error?.response?.data?.message);
                // }
            } else if (error?.response?.status && token) {
                debounceAxiosToast('שגיאה');
                console.log(error?.message);
            }

            return Promise.reject(error);
        }
    );

    axiosClient.interceptors.request.use(
        (config) => {
            store.dispatch(incrementActiveRequests());
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
};

import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext, UseFormReturn, useWatch } from 'react-hook-form';
import { BoostsFilterForm, defaultBoostsValues, HISTORY_DAYS_TO_SHOW, UserDetailsForm } from './users.models';
import { Input } from '../form/input-and-checkbox/Input';
import { Button } from '../form/buttons/Button';
import { Plus } from 'react-feather';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { AddCreditsModal } from '../modals/add-credits/AddCredits.modal';
import { getCompaniesAPI } from '../../api/companies.api';
import { Company } from '../companies/companies.models';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getBoostsStats, getPostsAPI } from '../../api/posts.api';
import { Post } from '../home/home.models';
import { useUpdateEffect } from '../../hooks/useUpdateEffect';
import { Table } from '../table/Table';
import { boostsTableColumns, populateBoostsData } from './users.utils';
import { Column } from '../table/Tables.types';
import { Order } from '../../hooks/useSort';

type UsersStep5Props = {};

export const UsersStep5: FC<UsersStep5Props> = ({}) => {
    const form: UseFormReturn<UserDetailsForm> = useFormContext();
    const [isAddCreditsOpen, setAddCreditsOpen] = useState(false);
    const [companyDetails, setCompanyDetails] = useState<Company>();
    const userID = useWatch({ control: form.control, name: 'userID' });
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const boostsForm = useForm<BoostsFilterForm>({
        defaultValues: defaultBoostsValues,
        mode: 'onTouched',
    });
    const rowsPerPage = useWatch({ control: boostsForm.control, name: 'rowsPerPage' });
    const page = useWatch({ control: boostsForm.control, name: 'page' });
    const orderBy = useWatch({ control: boostsForm.control, name: 'orderBy' });
    const stats = useWatch({ control: boostsForm.control, name: 'stats' });
    useQuery({
        queryKey: ['boostsStats'],
        queryFn: async () => {
            return getBoostsStats(
                userID,
                new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000).getTime()
            ).then((res) => boostsForm.setValue('stats', res));
        },
    });
    const { refetch, data: { data: posts = [], total = 0 } = {} } = useQuery({
        queryKey: ['getPosts'],
        queryFn: async () => {
            return getPostsAPI<Post>({
                boostByOrToUserID: userID,
                userID: userID,
                postType: 'boost',
                // fromTimestamp: fromTimestamp ? new Date(fromTimestamp).getTime() : undefined,
                // toTimestamp: toTimestamp ? new Date(toTimestamp).getTime() : undefined,
                offset: page * (rowsPerPage?.value ?? 0),
                count: rowsPerPage?.value ?? 0,
                sortBy: orderBy?.direction,
                sortField: orderBy?.sortedColumn,
            });
        },
        enabled: true,
    });

    useUpdateEffect(() => {
        if (page !== 0) {
            boostsForm.setValue('page', 0);
        } else {
            refetch();
        }
    }, [rowsPerPage.value]);

    useUpdateEffect(() => {
        refetch();
    }, [dispatch, boostsForm, page, orderBy]);

    useEffect(() => {
        const company = form.getValues('company');
        if (!company?.value) return;

        getCompaniesAPI<Company>({ companyID: company.value, isExtended: true }).then((res) => {
            if (res?.data?.[0]) {
                setCompanyDetails(res?.data?.[0]);
            }
        });
    }, [dispatch, form, t]);

    // if (!companyDetails) return null;

    return (
        <div className={'management'}>
            <div className={'step'}>
                <div
                    className={
                        'step-row step-title'
                    }>{`נתונים כלליים (${HISTORY_DAYS_TO_SHOW} יום אחרונים)`}</div>
                <Input value={stats.boostsReceived} disabled label={'כמה פעמים קיבל'} />
                <Input value={stats.boostsGiven} disabled label={'כמה פעמים נתן'} />
                <Input value={stats.creditsReceived} disabled label={'סך השווי קיבל'} />
                <Input value={stats.creditsGiven} disabled label={'סך השווי נתן'} />

                <div className={'step-title'}>{t('creditsStatus')}</div>

                <SmartInput name={'personalCredits'} disabled label={t('personalCreditsAmount')} />
                <SmartInput name={'socialCredits'} disabled label={t('socialCreditsAmount')} />

                <Button
                    onClick={() => setAddCreditsOpen(true)}
                    style={{ width: '200px' }}
                    theme={'secondary'}>
                    <span className={'button-center'}>
                        <Plus width={10} height={10} />
                        {t('addCredits')}
                    </span>
                </Button>

                <div className={'step-title'} style={{ paddingTop: '24px' }}>
                    {t('creditsHistory')}
                </div>
            </div>

            <FormProvider {...boostsForm}>
                <Table
                    columns={boostsTableColumns(t)}
                    data={populateBoostsData(posts)}
                    pagination={true}
                    rowsPerPage={rowsPerPage.value}
                    total={total}
                    onSort={(column: Column<Post>, direction: Order) => {
                        boostsForm.setValue('orderBy', {
                            direction,
                            sortedColumn: column.field,
                        });
                    }}
                />
            </FormProvider>
            {companyDetails && (
                <AddCreditsModal
                    isOpen={isAddCreditsOpen}
                    closeModal={() => setAddCreditsOpen(false)}
                    company={companyDetails}
                    userID={userID}
                    form={form}
                    setCompanyDetails={(company: Company) => setCompanyDetails(company)}
                />
            )}
        </div>
    );
};

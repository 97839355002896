import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Dropdown } from 'reactstrap';
import { FC, ReactNode, useState } from 'react';
import './button.scss';

type DropdownSplitButtonProps = {
    options: { label: string; onClick: () => void }[];
    buttonLabel: ReactNode;
};

export const DropdownButton: FC<DropdownSplitButtonProps> = ({ buttonLabel, options }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen((prevState) => !prevState);
    };

    return (
        <UncontrolledButtonDropdown>
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle className={'dropdown-label'}>{buttonLabel}</DropdownToggle>
                <DropdownMenu className="dropdown-menu-bottom" style={{ textAlign: 'right' }}>
                    {options.map((o, key) => {
                        return (
                            <DropdownItem key={key} className={'dropdown-item'} onClick={o.onClick}>
                                {o.label}
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </Dropdown>
        </UncontrolledButtonDropdown>
    );
};

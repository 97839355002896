import { FC } from 'react';
import './Modal.scss';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../store/modal/modal.actions';
import { X as CloseIcon } from 'react-feather';
import { InnerModal } from './InnerModal/InnerModal';
import { ModalProps } from './Modal.utils';
import { Button } from '../form/buttons/Button';

export const Modal: FC<ModalProps> = ({ isOpen, ...rest }) => {
    const dispatch = useDispatch();

    const handleCloseModal = () => {
        dispatch(closeModal());
    };

    if (!isOpen) return null;

    return (
        <div className={'fixed-container'}>
            <div className={'routes-modal'}>
                <Button
                    theme={'grey'}
                    icon={<CloseIcon color={'var(--title-grey-color)'} />}
                    className={'close-icon'}
                    onClick={handleCloseModal}
                />
                <InnerModal isOpen={isOpen} {...rest} />
            </div>
        </div>
    );
};

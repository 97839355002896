import { FC, useState } from 'react';
import { Filters } from '../filters/Filters';
import { Filter, FiltersModels } from '../filters/filters.models';
import './orders.scss';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Table } from '../table/Table';
import { useQuery } from 'react-query';
import { Column } from '../table/Tables.types';
import { Order } from '../../hooks/useSort';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from '../../hooks/useUpdateEffect';
import { TableHeader } from '../table/TableHeader';
import {
    defaultPurchaseFilterForm,
    OrderTableData,
    Purchase,
    PurchaseFilterForm,
    purchasesManagementTableColumns,
    PurchaseStatus,
} from './order.models';
import { getPurchasesAPI, getSuppliersAPI, refundPurchase } from '../../api/store.api';
import { exportOrders, populatePurchasesData } from './orders.utils';
import { companyLoadOptions, productsIDLoadOptions, userLoadOptions } from '../../utils/loadOptions';
import { Button } from '../form/buttons/Button';
import { Download } from 'react-feather';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { ChangeStatusModal } from '../modals/ChangeStatus.modal';
import { closeModal, raiseModal } from '../../store/modal/modal.actions';
import { ModalIcon } from '../modal/ModalIcon';
import { SuccessToast } from '../toaster/Toast';
import { GetInstances } from '../../api/api.models';
import { useFormWithSession } from '../../hooks/useFormSession';
import { useNavigate } from 'react-router-dom';

type OrderManagementProps = {
    orderStatuses?: PurchaseStatus[];
    internalOrderStatuses?: PurchaseStatus[];
};

export const OrdersManagement: FC<OrderManagementProps> = ({ orderStatuses, internalOrderStatuses }) => {
    const dispatch = useDispatch();
    const form = useForm<PurchaseFilterForm>({
        defaultValues: defaultPurchaseFilterForm,
        mode: 'onTouched',
    });
    const [changeStatus, setChangeStatusOpen] = useState<'regular' | 'internal' | undefined>(undefined);
    const currentUser = useSelector(getCurrentUser);
    const companiesIDs = useWatch({ name: 'companiesIDs', control: form.control });
    const userID = useWatch({ name: 'userID', control: form.control });
    const productID = useWatch({ name: 'productID', control: form.control });
    const supplierIDs = useWatch({ name: 'supplierIDs', control: form.control });
    const purchaseStatus = useWatch({ name: 'purchaseStatus', control: form.control });
    const internalPurchaseStatus = useWatch({ name: 'internalPurchaseStatus', control: form.control });
    const fromTimestamp = useWatch({ name: 'fromTimestamp', control: form.control });
    const toTimestamp = useWatch({ name: 'toTimestamp', control: form.control });
    const orderBy = useWatch({ name: 'orderBy', control: form.control });
    const rowsPerPage = useWatch({ name: 'rowsPerPage', control: form.control });
    const page = useWatch({ name: 'page', control: form.control });
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [filters, setFilters] = useState<GetInstances<Purchase>>(buildFiltersFromForm());

    const { data: suppliers } = useQuery({
        queryKey: ['getOrdersSuppliers'],
        queryFn: async () => {
            return getSuppliersAPI().then((res) => res.data);
        },
        enabled: true,
    });
    useFormWithSession<PurchaseFilterForm>(form, (values: PurchaseFilterForm) =>
        setFilters(buildFiltersFromSession(values))
    );

    function buildFiltersFromForm() {
        return {
            companiesIDs: companiesIDs?.map((c) => c?.value),
            userID: userID?.value,
            productID: productID?.value,
            supplierIDs: supplierIDs?.map((sid) => sid?.value),
            purchaseStatus: purchaseStatus
                ?.map((ps) => orderStatuses?.find((o) => o.statusID === ps?.value)?.name ?? '')
                ?.filter(Boolean),
            internalPurchaseStatus: internalPurchaseStatus
                ?.map((is) => internalOrderStatuses?.find((o) => o.statusID === is?.value)?.name ?? '')
                ?.filter(Boolean),
            fromTimestamp: fromTimestamp ? new Date(fromTimestamp)?.getTime() : undefined,
            toTimestamp: toTimestamp ? new Date(toTimestamp)?.getTime() : undefined,
            offset: page * (rowsPerPage?.value ?? 0),
            count: rowsPerPage?.value,
            sortBy: orderBy?.direction,
            sortField: orderBy?.sortedColumn,
        };
    }

    function buildFiltersFromSession(values: PurchaseFilterForm): GetInstances<Purchase> {
        const {
            companiesIDs,
            userID,
            productID,
            supplierIDs,
            purchaseStatus,
            internalPurchaseStatus,
            fromTimestamp,
            toTimestamp,
            page,
            rowsPerPage,
            orderBy,
        } = values;

        return {
            companiesIDs: companiesIDs?.map((c) => c?.value),
            userID: userID?.value,
            productID: productID?.value,
            supplierIDs: supplierIDs?.map((sid) => sid?.value),
            purchaseStatus: purchaseStatus
                ?.map((ps) => orderStatuses?.find((o) => o.statusID === ps?.value)?.name ?? '')
                ?.filter(Boolean),
            internalPurchaseStatus: internalPurchaseStatus
                ?.map((is) => internalOrderStatuses?.find((o) => o.statusID === is?.value)?.name ?? '')
                ?.filter(Boolean),
            fromTimestamp: fromTimestamp ? new Date(fromTimestamp)?.getTime() : undefined,
            toTimestamp: toTimestamp ? new Date(toTimestamp)?.getTime() : undefined,
            offset: page * (rowsPerPage?.value ?? 0),
            count: rowsPerPage?.value,
            sortBy: orderBy?.direction,
            sortField: orderBy?.sortedColumn,
        };
    }

    const { data: { data: purchases = [], total = 0 } = {}, refetch: forceRefetch } = useQuery({
        queryKey: ['getOrders', filters],
        queryFn: async () => {
            return getPurchasesAPI<Purchase>(filters);
        },
        enabled: true,
    });

    const refetch = () => {
        setFilters(buildFiltersFromForm());
    };

    const refetchOrResetPage = () => {
        if (page !== 0) {
            form.setValue('page', 0);
        } else {
            refetch();
        }
    };

    useUpdateEffect(() => {
        refetchOrResetPage();
    }, [rowsPerPage.value, orderBy]);

    useUpdateEffect(() => {
        refetch();
    }, [page]);

    const CustomHeader = () => {
        return (
            <TableHeader
                rowsPerPage={rowsPerPage?.value}
                leftSideComponents={[
                    currentUser?.userType === 'admin' && (
                        <Button
                            key={0}
                            theme={'secondary'}
                            onClick={() => exportOrders({ ...filters, count: 100000 })}
                            icon={<Download width={14} />}>
                            {t('exportToExcel')}
                        </Button>
                    ),
                    selectedRows.length > 0 && (
                        <Button key={1} theme={'primary'} onClick={() => setChangeStatusOpen('regular')}>
                            {t('changeStatus')}
                        </Button>
                    ),
                    selectedRows.length > 0 && (
                        <Button key={2} theme={'primary'} onClick={() => setChangeStatusOpen('internal')}>
                            {t('changeInternalStatus')}
                        </Button>
                    ),
                    // <Button
                    //     theme={'primary'}
                    //     onClick={sendEmailToSuppliersTestAPI}
                    //     icon={<UserPlus width={14} />}>
                    //     {t('sendEmailToSuppliers')}
                    // </Button>,
                ]}
            />
        );
    };

    const onRowSelect = (row: { selectedRows: OrderTableData[] }) => {
        setSelectedRows(row.selectedRows?.map((r) => r.id));
    };

    const handleRefundPurchase = (purchaseID: string) => {
        dispatch(
            raiseModal({
                isOpen: true,
                content: t('areYouSureRefund'),
                header: {
                    title: t('confirmRefund'),
                    icon: <ModalIcon theme={'ALERT'} />,
                },
                footer: {
                    buttons: [
                        {
                            label: t('confirm'),
                            theme: 'danger',
                            callback: async () => {
                                refundPurchase(purchaseID)
                                    .then(() => {
                                        SuccessToast(t('operationSuccess'));
                                    })
                                    .catch()
                                    .finally(() => {
                                        dispatch(closeModal());
                                        forceRefetch();
                                    });
                            },
                        },
                        {
                            label: t('cancel'),
                            theme: 'grey',
                            callback: async () => dispatch(closeModal()),
                        },
                    ],
                },
            })
        );
    };

    const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            refetchOrResetPage();
        }
    };

    const getFilters = (): Filter[] => {
        return [
            {
                type: FiltersModels.AUTO_COMPLETE,
                getLoadOptionsData: companyLoadOptions,
                name: 'companiesIDs',
                placeholder: t('showAll'),
                label: t('company'),
                onKeyDown,
                isMulti: true,
            },
            {
                type: FiltersModels.AUTO_COMPLETE,
                getLoadOptionsData: (value: string) =>
                    userLoadOptions({
                        inputValue: value,
                        companiesIDs: companiesIDs?.map((c) => c.value),
                    }),
                name: 'userID',
                onKeyDown,
                placeholder: t('showAll'),
                label: t('user'),
            },
            {
                type: FiltersModels.MULTI_SELECT,
                name: 'supplierIDs',
                onKeyDown,
                placeholder: t('showAll'),
                options: suppliers?.map((st) => ({
                    label: st.supplierName,
                    value: st.supplierName,
                })),
                label: t('supplier'),
            },
            {
                type: FiltersModels.MULTI_SELECT,
                name: 'purchaseStatus',
                onKeyDown,
                placeholder: t('showAll'),
                options: orderStatuses?.map((ps) => ({
                    label: t(ps?.name),
                    value: ps?.statusID,
                })),
                label: t('orderStatus'),
            },
            {
                type: FiltersModels.MULTI_SELECT,
                name: 'internalPurchaseStatus',
                onKeyDown,
                placeholder: t('showAll'),
                options: internalOrderStatuses?.map((ps) => ({
                    label: t(ps?.name),
                    value: ps?.statusID,
                })),
                label: t('internalStatus'),
            },
            {
                type: FiltersModels.AUTO_COMPLETE,
                getLoadOptionsData: productsIDLoadOptions,
                onKeyDown,
                name: 'productID',
                placeholder: t('showAll'),
                label: t('internalCatalogID'),
            },
            {
                type: FiltersModels.DATE,
                onKeyDown,
                name: 'fromTimestamp',
                label: t('fromDate'),
                placeholder: t('fromDate'),
            },
            {
                type: FiltersModels.DATE,
                onKeyDown,
                name: 'toTimestamp',
                label: t('toDate'),
                placeholder: t('toDate'),
            },
            {
                type: FiltersModels.BUTTON,
                sizeBootstrap: 2,
                label: t('filter'),
                onClick: () => refetchOrResetPage(),
            },
        ];
    };

    return (
        <FormProvider {...form}>
            <div className={'management'}>
                <Filters
                    clearFilters={() => form.reset(defaultPurchaseFilterForm)}
                    filters={getFilters()}
                    title={t('filterBy')}
                />
                <Table
                    columns={purchasesManagementTableColumns()}
                    data={populatePurchasesData(purchases, handleRefundPurchase, t)}
                    // onRowClicked={(row) => onEditOrder(row.id)}
                    onRowClicked={(row) => navigate(`order/${row.id}`)}
                    header={CustomHeader()}
                    pagination={true}
                    rowsPerPage={rowsPerPage?.value}
                    total={total}
                    selectableRows={true}
                    onSort={(column: Column<Purchase>, direction: Order) => {
                        form.setValue('orderBy', { direction, sortedColumn: column.field });
                    }}
                    onRowSelect={onRowSelect}
                />
            </div>
            <ChangeStatusModal
                isOpen={!!changeStatus}
                refetchPurchases={refetchOrResetPage}
                closeModal={() => setChangeStatusOpen(undefined)}
                options={
                    changeStatus === 'regular'
                        ? (orderStatuses ?? [])?.map((os) => os?.name)
                        : (internalOrderStatuses ?? [])?.map((os) => os?.name)
                }
                statusType={changeStatus}
                purchasesIDs={selectedRows}
            />
        </FormProvider>
    );
};

import { FC, KeyboardEvent } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { useTranslation } from 'react-i18next';

type CreateHashtagProps = {
    form: UseFormReturn<{ createdHashTagName: string }>;
    name: string;
    onKeyDown?: (event: KeyboardEvent) => void;
};

export const CreateHashtag: FC<CreateHashtagProps> = ({ form, name, onKeyDown }) => {
    const { t } = useTranslation();

    return (
        <FormProvider {...form}>
            <SmartInput
                required
                label={t('hashtagName')}
                placeholder={t('hashtagName')}
                name={name}
                containerClass={`position-relative size-default container-input`}
                onKeyDown={onKeyDown}
            />
        </FormProvider>
    );
};

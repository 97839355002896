import { API } from './api';
import { LoginData } from '../models/login.models';

export const getOnLoginData = async (): Promise<LoginData> => {
    return API.get({ url: `login/cms` });
};

export const sendOTPByPhone = async (phone: string): Promise<LoginData> => {
    return API.post({ url: `login/OTP`, body: { phone: phone.trim() } });
};

export const sendOTPByCurrentUser = async (): Promise<LoginData> => {
    return API.post({ url: `login/OTP/validateAuth` });
};

export const verifyOTP = async (phone: string, code: string): Promise<string> => {
    return API.post({ url: `login/OTP/code`, body: { phone, code } });
};

export const verifyOTPByAuth = async (code: string): Promise<boolean> => {
    return API.post({ url: `login/OTP/code/auth`, body: { code } });
};

export const healthCheckAPI = async (): Promise<boolean> => {
    return API.get({ url: `health` });
};

import './create-post.scss';
import { FC, useCallback, useState } from 'react';
import { ArrowLeft, MessageSquare, Users } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { CreatePostContent } from './CreatePostContent';
import { Button } from '../form/buttons/Button';
import { CreatePostGroupsAndUsers } from './CreatePostGroupsAndUsers';
import { useFormContext } from 'react-hook-form';

type CreatePostProps = {
    onConfirm: () => void;
};

export const CreatePost: FC<CreatePostProps> = ({ onConfirm }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const { t } = useTranslation();
    const { trigger, formState } = useFormContext();
    const { isValid, errors } = formState;

    const renderStepIcon = useCallback(
        (step: 0 | 1) => {
            const icon =
                step === 0 ? <MessageSquare height={15} width={15} /> : <Users height={15} width={15} />;

            const style =
                currentStep === step
                    ? {
                          boxShadow: ' 0 3px 6px 0 rgba(104, 224, 240, 0.4)',
                          backgroundColor: 'var(--color-chosen)',
                      }
                    : {};

            return (
                <div className={'step-icon'} style={{ ...style }}>
                    {icon}
                </div>
            );
        },
        [currentStep]
    );

    const renderStep = useCallback(
        (title: string, subtitle: string, step: 0 | 1) => {
            return (
                <div className={'create-post-step'} onClick={() => setCurrentStep(step)}>
                    {renderStepIcon(step)}
                    <div className={'create-form-step-title'}>
                        <h5 className={'step-main-title'}>{title}</h5>
                        <h6 className={'step-sub-title'}>{subtitle}</h6>
                    </div>
                </div>
            );
        },
        [currentStep]
    );

    const handleNext = () => {
        if (currentStep === 0) {
            setCurrentStep(1);
        } else {
            trigger().then((res) => {
                if (res) {
                    onConfirm();
                }
            });
        }
    };

    return (
        <div className={'create-post'}>
            <div className={'current-step-container'}>
                <div className={'post-steps-tabs'}>
                    {renderStep(t('post'), t('whatToSay'), 0)}
                    {renderStep(t('groupsAndUsers'), t('whereToPublish'), 1)}
                </div>
                <div className={'current-step-step-content'}>
                    {currentStep === 0 ? <CreatePostContent /> : <CreatePostGroupsAndUsers />}
                </div>
            </div>
            {!isValid && Object.keys(errors)?.length > 0 && (
                <span className={'not-enough-budget'}>{t('notAllDataFilled')}</span>
            )}
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Button
                    theme={'primary'}
                    onClick={handleNext}
                    icon={<ArrowLeft />}
                    direction={'left'}
                    disabled={currentStep !== 0 && !isValid && Object.values(errors)?.length !== 0}>
                    {currentStep === 0 ? t('continue') : t('save')}
                </Button>
            </div>
        </div>
    );
};

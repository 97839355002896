import * as Yup from 'yup';
import { ERROR_MESSAGES } from '../../utils/validations';

export const companiesSchema = Yup.object().shape({
    name: Yup.string().nullable().required(ERROR_MESSAGES.required),
    registrarCompaniesID: Yup.number()
        .nullable()
        .min(1, ERROR_MESSAGES.required)
        .transform((_, val) => (+val === Number(val) ? +val : null))
        .required(ERROR_MESSAGES.required),
    address: Yup.object({
        city: Yup.string().nullable().required(ERROR_MESSAGES.required),
        street: Yup.string().nullable().required(ERROR_MESSAGES.required),
    }),
    logo: Yup.string().nullable().required(ERROR_MESSAGES.required),
    logoUrl: Yup.string().nullable().required(ERROR_MESSAGES.required),
    whiteLogo: Yup.string().min(1, ERROR_MESSAGES.required).nullable().required(ERROR_MESSAGES.required),
    whiteLogoUrl: Yup.string().min(1, ERROR_MESSAGES.required).nullable().required(ERROR_MESSAGES.required),
    squareLogoUrl: Yup.string().min(1, ERROR_MESSAGES.required).nullable().required(ERROR_MESSAGES.required),
    mainColor: Yup.string()
        .min(3, 'מינימום 3 תווים')
        .max(7, 'מקסימום 6 תווים')
        .nullable()
        .test('valid-color', 'צבע לא תקין', function (value) {
            if (!value) {
                return true;
            }

            const rgbPattern = /^[0-9a-fA-F#]+$/;

            // Test the input string against the pattern
            return rgbPattern.test(value);
        })
        .required(ERROR_MESSAGES.required),
    secondaryColor: Yup.string()
        .min(3, 'מינימום 3 תווים')
        .max(7, 'מקסימום 6 תווים')
        .nullable()
        .test('valid-color', 'צבע לא תקין', function (value) {
            if (!value) {
                return true;
            }

            const rgbPattern = /^[0-9a-fA-F#]+$/;
            return rgbPattern.test(value);
        })
        .required(ERROR_MESSAGES.required),
    companyContact: Yup.object().shape({
        HRName: Yup.string().nullable().required(ERROR_MESSAGES.required),
        HRPhone: Yup.string()
            .nullable()
            .test('min-length', 'מינימום 9 תווים', function (value) {
                if (!value) {
                    return true;
                }

                return value.length >= 9;
            })
            .max(10, 'מקסימום 10 תווים')
            .required(ERROR_MESSAGES.required),
        HREmail: Yup.string().email('עליך להכניס אימייל תקין').nullable().required(ERROR_MESSAGES.required),
        accountingName: Yup.string().nullable(),
        accountingPhone: Yup.string()
            .nullable()
            .test('min-length', 'מינימום 9 תווים', function (value) {
                if (!value) {
                    return true;
                }

                return value.length >= 9;
            })
            .max(10, 'מקסימום 10 תווים'),
        accountingEmail: Yup.string().email('עליך להכניס אימייל תקין').nullable(),
    }),
    budgetApproved: Yup.boolean(),
    fixedCostPerMonth: Yup.number()
        .nullable()
        .transform((_, val) => (+val === Number(val) ? +val : null))
        .required(ERROR_MESSAGES.required),
    costPerUser: Yup.number()
        .nullable()
        .transform((_, val) => (+val === Number(val) ? +val : null))
        .required(ERROR_MESSAGES.required),
    socialCeilingRatio: Yup.number()
        .nullable()
        .transform((_, val) => (+val === Number(val) ? +val : null))
        .min(1, 'חייב להיות גדול או שווה ל-1')
        .required(ERROR_MESSAGES.required),
    defaultGiftGroupAmount: Yup.number()
        .nullable()
        .transform((_, val) => (+val === Number(val) ? +val : null))
        .required(ERROR_MESSAGES.required),
    smallAmountMax: Yup.number()
        .nullable()
        .transform((_, val) => (+val === Number(val) ? +val : null))
        .test('smallAmountMax', 'ערך טווח מתנות קטן צריך להיות קטן מערך טווח מתנות בינוני', function (value) {
            const smallAmountMax: number = value ?? 0;
            const current = this.resolve(Yup.ref('mediumAmountMax')) as number;
            return smallAmountMax < +current;
        })
        .required(ERROR_MESSAGES.required),
    mediumAmountMax: Yup.number()
        .nullable()
        .transform((_, val) => (+val === Number(val) ? +val : null))
        .test(
            'mediumAmountMax',
            'ערך טווח מתנות בינוני צריך להיות גדול מערך טווח מתנות קטן',
            function (value) {
                const mediumAmountMax: number = value ?? 0;
                const current = this.resolve(Yup.ref('smallAmountMax')) as number;
                return mediumAmountMax > +current;
            }
        )
        .required(ERROR_MESSAGES.required),
    loginBackgroundURL: Yup.string().nullable(),
    specialEventBackgroundURL: Yup.string().nullable(),
    monthlyBudget: Yup.object().shape({
        personal: Yup.number()
            .nullable()
            .transform((_, val) => (+val === Number(val) ? +val : null))
            .required(ERROR_MESSAGES.required),
        social: Yup.number()
            .nullable()
            .transform((_, val) => (+val === Number(val) ? +val : null))
            .required(ERROR_MESSAGES.required),
        perSubordinate: Yup.number()
            .nullable()
            .transform((_, val) => (+val === Number(val) ? +val : null))
            .min(0, 'חייב להיות גדול או שווה ל-0')
            .required(ERROR_MESSAGES.required),
        other: Yup.number()
            .nullable()
            .transform((_, val) => (+val === Number(val) ? +val : null)),
    }),
});

import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Dropdown } from 'reactstrap';
import { FC, ReactNode, useState } from 'react';
import './button.scss';
import { Plus } from 'react-feather';

type DropdownSplitButtonProps = {
    options: { label: string; value: string; id: string }[];
    onClick: (id: string | undefined) => void;
    separatorLabel: string;
    buttonLabel: ReactNode;
};

export const DropdownSplitButton: FC<DropdownSplitButtonProps> = ({
    separatorLabel,
    buttonLabel,
    onClick,
    options,
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen((prevState) => !prevState);
    };

    return (
        <UncontrolledButtonDropdown>
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle className={'button-general split-button'}>
                    <Plus width={10} height={10} />
                    {buttonLabel}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-bottom" style={{ textAlign: 'right' }}>
                    {options.map((o) => {
                        return (
                            <DropdownItem
                                key={o.id}
                                className={'dropdown-item'}
                                onClick={() => onClick(o.id)}>
                                {o.label}
                            </DropdownItem>
                        );
                    })}
                    {options.length > 0 && <DropdownItem divider />}
                    <DropdownItem
                        onClick={() => onClick(undefined)}
                        style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                        <Plus width={10} height={10} />
                        {separatorLabel}
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </UncontrolledButtonDropdown>
    );
};

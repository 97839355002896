import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../components/users/users.models';

export interface UsersState {
    currentUser: User | undefined;
    token: string | null;
    logo: string | undefined;
}

const initialState: UsersState = {
    currentUser: undefined,
    token: null,
    logo: undefined,
};

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setCurrentUser: (state: UsersState, action: PayloadAction<User | undefined>) => {
            state.currentUser = action.payload;
        },
        setLogo: (state: UsersState, action: PayloadAction<string>) => {
            state.logo = action.payload;
        },
        setToken: (state: UsersState, action: PayloadAction<string | null>) => {
            state.token = action.payload;
        },
    },
});

export default usersSlice.reducer;

import { Filter, FiltersModels } from './filters.models';
import { Accordion, AccordionItem, Card, Collapse } from 'reactstrap';
import { SmartSelect } from '../form/select/smart-select';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { FC, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Button } from '../form/buttons/Button';
import './filters.scss';
import { SmartAutoComplete } from '../form/select/auto-complete';
import { SmartDatePicker } from '../form/date-picker/smart-date-picker';

export interface FiltersProps {
    title?: string;
    filters: Filter[];
    clearFilters: () => void;
    initialOpen?: boolean;
}

export const Filters: FC<FiltersProps> = ({ clearFilters, filters, title, initialOpen = false }) => {
    const [isOpen, setIsOpen] = useState(initialOpen);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Accordion open={String(isOpen)}>
            <AccordionItem style={{ backgroundColor: 'var(--white)' }}>
                <div className="filter-title">
                    {title}
                    <Button onClick={toggle} theme={'none'}>
                        {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                    </Button>
                </div>
                <Collapse isOpen={isOpen}>
                    <Card className="data-filter">
                        <div className={'filter-header'}>
                            <Button theme={'none'} onClick={clearFilters}>
                                נקה מסננים
                            </Button>
                        </div>
                        <div className="filter-body">
                            <div className={'filters'}>
                                {filters.map((filter, index) => {
                                    return filter.type === FiltersModels.INPUT ? (
                                        <div className={`position-relative size-default`} key={index}>
                                            <SmartInput
                                                name={filter.name}
                                                label={filter.label}
                                                placeholder={filter.placeholder}
                                                onKeyDown={filter.onKeyDown}
                                            />
                                        </div>
                                    ) : filter.type === FiltersModels.DATE ? (
                                        <div className={`position-relative size-default`} key={index}>
                                            <SmartDatePicker
                                                name={filter.name}
                                                label={filter.label}
                                                placeholder={'DD/MM/YYYY'}
                                                dateFormat={'dd/MM/yyyy'}
                                                onKeyDown={filter.onKeyDown}
                                            />
                                        </div>
                                    ) : filter.type === FiltersModels.MULTI_SELECT ? (
                                        <div className={`position-relative size-default`} key={index}>
                                            <SmartSelect
                                                key={index}
                                                isClearable={true}
                                                isDisabled={filter.isDisabled}
                                                name={filter.name}
                                                placeholder={filter.placeholder}
                                                isMulti
                                                options={filter.options}
                                                hideSelectedOptions={true}
                                                label={filter.label}
                                                onKeyDown={filter.onKeyDown}
                                                containerClass={`position-relative size-default`}
                                            />
                                        </div>
                                    ) : filter.type === FiltersModels.BUTTON ? (
                                        <div key={index} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                            <Button
                                                onClick={filter.onClick}
                                                theme={'primary'}
                                                icon={filter?.icon}>
                                                {filter.label}
                                            </Button>
                                        </div>
                                    ) : filter.type === FiltersModels.AUTO_COMPLETE ? (
                                        <div key={index} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                            <SmartAutoComplete
                                                key={index}
                                                getLoadOptionsData={filter.getLoadOptionsData}
                                                isClearable={true}
                                                name={filter.name}
                                                isDisabled={filter.isDisabled}
                                                placeholder={filter.placeholder}
                                                isMulti={filter.isMulti}
                                                label={filter.label}
                                                onKeyDown={filter.onKeyDown}
                                                containerClass={`position-relative size-default`}
                                            />
                                        </div>
                                    ) : filter.type === FiltersModels.CHECK_BOX ? (
                                        <div
                                            key={index}
                                            style={{ display: 'flex' }}
                                            className={'checkbox-with-label'}>
                                            <SmartInput key={index} name={filter.name} type={'checkbox'} />
                                            <span className={'checkbox-label'}>{filter.label}</span>
                                        </div>
                                    ) : filter.type === FiltersModels.SEPARATOR ? (
                                        <div key={`separator-${index}`} className="filter-separator"></div>
                                    ) : filter.type === FiltersModels.CUSTOM ? (
                                        <div key={`custom-${index}`} className="filter-custom">
                                            {filter.component ? filter.component : null}
                                        </div>
                                    ) : (
                                        <div className={`position-relative size-default`} key={index}>
                                            <SmartSelect
                                                key={index}
                                                isClearable={true}
                                                isDisabled={filter.isDisabled}
                                                name={filter.name}
                                                placeholder={filter.placeholder}
                                                options={filter.options}
                                                hideSelectedOptions={true}
                                                label={filter.label}
                                                onKeyDown={filter.onKeyDown}
                                                containerClass={`position-relative size-default`}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Card>
                </Collapse>
            </AccordionItem>
        </Accordion>
    );
};

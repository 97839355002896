import { FC, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import 'draft-js/dist/Draft.css';
import './editor.scss';
import { Editor } from 'react-draft-wysiwyg';
import { Label } from 'reactstrap';
import { ContentState, EditorState } from 'draft-js';

type SmartRichTextProps = {
    name: string;
    label?: string;
    disabled?: boolean;
    required?: boolean;
    maxCharacters?: number;
};

export const SmartRichText: FC<SmartRichTextProps> = ({ name, label, maxCharacters, required, disabled }) => {
    const { control } = useFormContext();
    const { field } = useController({ name, control });
    const [characterCount, setCharacterCount] = useState(0);

    const handleEditorChange = (newEditorState: EditorState) => {
        field.onChange(newEditorState);

        const contentState: ContentState = newEditorState.getCurrentContent();
        const contentText: string = contentState.getPlainText('');
        const count: number = contentText.length;
        setCharacterCount(count);
    };

    return (
        <>
            {label && (
                <Label className="form-label" for="basicInput">
                    {label}
                    {required && <span className={'required'}>*</span>}
                </Label>
            )}
            <Editor
                readOnly={disabled}
                editorState={field?.value}
                onEditorStateChange={handleEditorChange}
                editorClassName={disabled ? 'richtext-disabled' : ''}
            />
            {maxCharacters && characterCount > maxCharacters && (
                <label className="error-label">{`מקסימום ${maxCharacters} תווים`}</label>
            )}
        </>
    );
};

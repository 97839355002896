import { API } from './api';
import { GetInstances, GetResponse } from './api.models';
import { ExcelUser, InvalidUserDetailsDto, User } from '../components/users/users.models';

export const getUsersAPI = async <T>(getBy: GetInstances<T>): Promise<GetResponse & { data: User[] }> => {
    return API.get({ params: getBy, url: `users` });
};

export const getUsersByPhonesAPI = async (phones: string[]): Promise<User[]> => {
    return API.post({ url: `users/phones`, body: { phones } });
};

export const createOrEditUser = async (user: User): Promise<void> => {
    return API.post({ url: `users`, body: user });
};

export const createOrEditUsers = async (
    users: ExcelUser[],
    companyID: string
): Promise<{
    failed: InvalidUserDetailsDto[];
    newUsers: string[];
    unchanged: string[];
    edited: string[];
}> => {
    return API.post({ url: `users/many`, body: { users, companyID } });
};

export const freezeUser = async (userID: string): Promise<void> => {
    return API.put({ url: `users/freeze/${userID}` });
};

export const deleteUser = async (userID: string): Promise<void> => {
    return API.delete({ url: `users/${userID}` });
};

export const deleteUsersAPI = async (usersIDs: string[]): Promise<void> => {
    return API.post({ url: `users/delete`, body: { usersIDs } });
};

export const updateUserProfilePic = async (url: string, userID: string): Promise<string> => {
    return API.put({ url: `users/profile-picture/${userID}`, body: { url } });
};

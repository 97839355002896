import { FC, useState } from 'react';
import { Filters } from '../filters/Filters';
import { Filter, FiltersModels } from '../filters/filters.models';
import './store.scss';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, UseFormReturn, useWatch } from 'react-hook-form';
import { Table } from '../table/Table';
import { useQuery } from 'react-query';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { Column } from '../table/Tables.types';
import { Order } from '../../hooks/useSort';
import { useTranslation } from 'react-i18next';
import {
    deleteProductAPI,
    getProductsAPI,
    toggleForSaleProductAPI,
    toggleHotSaleAPI,
    toggleShowOrHideProductAPI,
} from '../../api/store.api';
import { Product, StoreCategory, StoreFilterForm, Supplier } from './store.models';
import { populateProductsData, storeManagementTableColumns } from './store.utils';
import { useUpdateEffect } from '../../hooks/useUpdateEffect';
import { TableHeader } from '../table/TableHeader';
import { Button } from '../form/buttons/Button';
import { UserPlus } from 'react-feather';
import { SuccessToast } from '../toaster/Toast';
import { productsIDLoadOptions, productsNameLoadOptions } from '../../utils/loadOptions';
import { closeModal, raiseModal } from '../../store/modal/modal.actions';
import { ModalIcon } from '../modal/ModalIcon';
import { useNavigate } from 'react-router-dom';
import { useFormWithSession } from '../../hooks/useFormSession';
import { GetInstances } from '../../api/api.models';
import { getLastJobRunTime } from '../../api/schedule.api';
import { formatTimestamp } from '../../utils/utils';

type StoreManagementProps = {
    productManagementForm: UseFormReturn<StoreFilterForm>;
    duplicateProduct: (product: Product) => Promise<void>;
    onCreateOrEdit: (productID: string | undefined) => void;
    storeCategories: StoreCategory[] | undefined;
    suppliers: Supplier[] | undefined;
};

export const StoreManagement: FC<StoreManagementProps> = ({
    storeCategories,
    duplicateProduct,
    productManagementForm,
    suppliers,
}) => {
    const currentUser = useSelector(getCurrentUser);
    const dispatch = useDispatch();
    const orderBy = useWatch({ name: 'orderBy', control: productManagementForm.control });
    const rowsPerPage = useWatch({ name: 'rowsPerPage', control: productManagementForm.control });
    const page = useWatch({ name: 'page', control: productManagementForm.control });
    const categories = useWatch({ name: 'categories', control: productManagementForm.control });
    const chosenSuppliers = useWatch({ name: 'suppliers', control: productManagementForm.control });
    const variantSearch = useWatch({ name: 'variantSearch', control: productManagementForm.control });
    const productID = useWatch({ name: 'productID', control: productManagementForm.control });
    const productName = useWatch({ name: 'productName', control: productManagementForm.control });
    const availabilitySupplier = useWatch({
        name: 'availabilitySupplier',
        control: productManagementForm.control,
    });
    const isShown = useWatch({ name: 'isShown', control: productManagementForm.control });
    const hotSale = useWatch({ name: 'hotSale', control: productManagementForm.control });
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [productFilters, setProductFilters] = useState<GetInstances<Product>>(buildFiltersFromForm());

    const { data: { data: products = [], total = 0 } = {}, refetch: forceRefetch } = useQuery({
        queryKey: ['getProducts', productFilters],
        queryFn: async () => {
            return getProductsAPI<Product>(productFilters);
        },
        enabled: true,
    });

    const { data: lastRunTimeData } = useQuery(['getLastRunTime', 'store-products'], async () =>
        getLastJobRunTime('store-products')
    );

    const refetch = () => {
        setProductFilters(buildFiltersFromForm());
    };

    useFormWithSession<StoreFilterForm>(productManagementForm, (values: StoreFilterForm) =>
        setProductFilters(buildFiltersFromSession(values))
    );

    function buildFiltersFromForm() {
        return {
            companyID: currentUser?.companyID,
            categoryIDs: categories?.map((category) => category.value) ?? undefined,
            productID: productID?.value,
            productName: productName?.label,
            variantSearch: variantSearch ?? '',
            hotSale: hotSale?.value,
            shownByCompany: isShown?.value,
            supplierIDs: chosenSuppliers?.map((s) => s?.label),
            availabilitySupplier: availabilitySupplier?.value,
            offset: page * (rowsPerPage?.value ?? 0),
            count: rowsPerPage?.value,
            sortBy: orderBy?.direction,
            sortField: orderBy?.sortedColumn,
            all: isShown?.value === undefined,
        };
    }

    function buildFiltersFromSession(values: StoreFilterForm): GetInstances<Product> {
        const {
            categories,
            productID,
            productName,
            variantSearch,
            hotSale,
            isShown,
            suppliers: chosenSuppliers,
            availabilitySupplier,
            page,
            rowsPerPage,
            orderBy,
        } = values;

        return {
            companyID: currentUser?.companyID,
            categoryIDs: categories?.map((category) => category.value) ?? undefined,
            productID: productID?.value,
            productName: productName?.label,
            variantSearch: variantSearch ?? '',
            hotSale: hotSale?.value,
            shownByCompany: isShown?.value,
            supplierIDs: chosenSuppliers?.map((s) => s?.label),
            availabilitySupplier: availabilitySupplier?.value,
            offset: page * (rowsPerPage?.value ?? 0),
            count: rowsPerPage?.value,
            sortBy: orderBy?.direction,
            sortField: orderBy?.sortedColumn,
            all: isShown?.value === undefined,
        };
    }

    const refetchOrResetPage = () => {
        if (page !== 0) {
            productManagementForm.setValue('page', 0);
        } else {
            refetch();
        }
    };

    useUpdateEffect(() => {
        refetchOrResetPage();
    }, [rowsPerPage.value, orderBy]);

    useUpdateEffect(() => {
        refetch();
    }, [page]);

    const handleDeleteProduct = async (productID: string) => {
        dispatch(
            raiseModal({
                isOpen: true,
                content: t(`areYouSureDeleteProduct`),
                header: {
                    title: t('deleteProduct'),
                    icon: <ModalIcon theme={'ALERT'} />,
                },
                footer: {
                    buttons: [
                        {
                            label: 'כן, מחק',
                            theme: 'danger',
                            callback: async () => {
                                deleteProductAPI(productID).then(() => {
                                    refetch();
                                    SuccessToast(t('productSuccessfullyDeleted'));
                                    dispatch(closeModal());
                                });
                            },
                        },
                        { label: t('cancel'), theme: 'grey', callback: () => dispatch(closeModal()) },
                    ],
                },
            })
        );
    };

    const CustomHeader = () => {
        return (
            <TableHeader
                rowsPerPage={rowsPerPage?.value}
                leftSideComponents={[
                    currentUser?.userType === 'admin' && (
                        <Button
                            theme={'primary'}
                            onClick={() => navigate('product/new')}
                            icon={<UserPlus width={14} />}>
                            {t('addNewProduct')}
                        </Button>
                    ),
                ]}
                rightSideComponents={[
                    <div>
                        {lastRunTimeData?.lastRunTime
                            ? `${t('lastUpdatedAt')} ${formatTimestamp(lastRunTimeData?.lastRunTime)}`
                            : `${t('lastUpdatedAt')}`}
                    </div>,
                ]}
            />
        );
    };

    const handleMakeHotSale = (productID: string) => {
        toggleHotSaleAPI(productID).then(() => {
            forceRefetch();
            SuccessToast(t('operationSuccess'));
        });
    };

    const handleToggleHideOrShow = (productID: string) => {
        toggleShowOrHideProductAPI(productID).then(() => {
            SuccessToast(t('operationSuccess'));
            forceRefetch();
        });
    };

    const handleToggleForSale = (productID: string) => {
        toggleForSaleProductAPI(productID).then(() => {
            SuccessToast(t('operationSuccess'));
            forceRefetch();
        });
    };

    const handleDuplicateProduct = async (productID: string) => {
        const originalProduct = products.find((p) => p.productID === productID);
        if (originalProduct) {
            duplicateProduct(originalProduct);
        }
    };

    const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            refetchOrResetPage();
        }
    };

    const getFilters = (): Filter[] => {
        return [
            {
                type: FiltersModels.AUTO_COMPLETE,
                getLoadOptionsData: (e) => productsNameLoadOptions(e, !!currentUser?.companyID),
                name: 'productName',
                onKeyDown,
                placeholder: t('showAll'),
                label: t('productName'),
            },
            {
                type: FiltersModels.MULTI_SELECT,
                name: 'categories',
                onKeyDown,
                fullRowSize: false,
                placeholder: t('showAll'),
                label: t('category'),
                options: (storeCategories || [])?.map((st) => ({
                    label: st.categoryName,
                    value: st.categoryID,
                })),
            },
            {
                type: FiltersModels.MULTI_SELECT,
                name: 'suppliers',
                onKeyDown,
                fullRowSize: false,
                placeholder: t('showAll'),
                options: suppliers?.map((st) => ({
                    label: st.supplierName,
                    value: st.supplierID,
                })),
                label: t('suppliers'),
            },
            {
                type: FiltersModels.AUTO_COMPLETE,
                getLoadOptionsData: productsIDLoadOptions,
                name: 'productID',
                onKeyDown,
                placeholder: t('showAll'),
                label: t('internalCatalogID'),
            },
            {
                type: FiltersModels.SELECT,
                name: 'availabilitySupplier',
                placeholder: t('showAll'),
                label: t('stockAvailability'),
                onKeyDown,
                options: [
                    { label: t('available'), value: true },
                    { label: t('notAvailable'), value: false },
                ],
            },
            {
                type: FiltersModels.SELECT,
                name: 'isShown',
                placeholder: t('showAll'),
                label: t('displayedInStore'),
                onKeyDown,
                options: [
                    { label: t('displayed'), value: true },
                    { label: t('notDisplayed'), value: false },
                ],
            },
            {
                type: FiltersModels.SELECT,
                name: 'hotSale',
                label: t('hotSale'),
                placeholder: t('hotSale'),
                onKeyDown,
                options: [
                    { label: t('yes'), value: true },
                    { label: t('no'), value: false },
                ],
            },
            {
                type: FiltersModels.INPUT,
                name: 'variantSearch',
                onKeyDown,
                label: t('variant'),
                placeholder: t('searchVariant'),
            },
            {
                type: FiltersModels.BUTTON,
                sizeBootstrap: 2,
                label: t('filter'),
                onClick: () => refetchOrResetPage(),
            },
        ];
    };

    return (
        <FormProvider {...productManagementForm}>
            <div className={'management'}>
                <Filters
                    clearFilters={() => {
                        productManagementForm.resetField('categories');
                        productManagementForm.resetField('suppliers');
                        productManagementForm.resetField('productName');
                        productManagementForm.resetField('productID');
                        productManagementForm.resetField('variantSearch');
                        productManagementForm.resetField('availabilitySupplier');
                        productManagementForm.resetField('isShown');
                        productManagementForm.resetField('hotSale');
                    }}
                    filters={getFilters()}
                    title={t('filterBy')}
                    initialOpen={true}
                />
                <Table
                    columns={storeManagementTableColumns(t, currentUser?.userType ?? 'HR')}
                    data={populateProductsData(
                        t,
                        currentUser?.userType ?? 'HR',
                        products,
                        storeCategories ?? [],
                        handleMakeHotSale,
                        handleToggleHideOrShow,
                        handleToggleForSale,
                        handleDuplicateProduct,
                        handleDeleteProduct
                    )}
                    onRowClicked={(row) => navigate(`product/${row.productID}`)}
                    header={CustomHeader()}
                    pagination={true}
                    rowsPerPage={rowsPerPage?.value}
                    total={total}
                    selectableRows={false}
                    onSort={(column: Column<Product>, direction: Order) => {
                        productManagementForm.setValue('orderBy', { direction, sortedColumn: column.field });
                    }}
                />
            </div>
        </FormProvider>
    );
};

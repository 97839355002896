import { PreloaderType } from './Preloader.models';
import { CSSProperties } from 'react';

const overlayStyling: CSSProperties = {
    background: 'white',
    top: 0,
    left: 0,
};

export const MAP_TYPE_TO_STYLE: { [key in PreloaderType]: CSSProperties } = {
    inline: { display: 'inline-block', background: 'rgba(255, 255, 255, 0)', width: 'fit-content' },
    container: {},
    paddedTop: {
        paddingTop: '50%',
    },
    fullWidthCenter: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    overlay: {
        position: 'absolute',
        ...overlayStyling,
    },
    overlayUpper: {
        position: 'absolute',
        zIndex: 1,
        ...overlayStyling,
    },
    fullscreen: {
        position: 'fixed',
        ...overlayStyling,
    },
    transparentOverlay: {
        ...overlayStyling,
        position: 'absolute',
        background: 'rgba(255, 255, 255, 0.5)',
        color: 'gray',
        zIndex: 5,
    },
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { InnerModal } from '../modal/InnerModal/InnerModal';
import { Modal } from './Modal';
import { ChangeStatus } from './ChangeStatus';
import { SuccessToast } from '../toaster/Toast';
import { changePurchaseStatusAPI } from '../../api/store.api';

type ChangeStatusModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    options: string[];
    refetchPurchases: () => void;
    statusType: 'regular' | 'internal' | undefined;
    purchasesIDs: string[];
};

export const ChangeStatusModal: FC<ChangeStatusModalProps> = ({
    isOpen,
    closeModal,
    options,
    refetchPurchases,
    purchasesIDs,
    statusType,
}) => {
    const form = useForm<{ status: { label: string; value: string } }>({
        mode: 'onTouched',
        defaultValues: { status: { value: '', label: '' } },
    });
    const { t } = useTranslation();

    const changeStatus = async (): Promise<void> => {
        const newStatus = form.getValues('status');

        if (!newStatus?.value || !statusType) return;

        await changePurchaseStatusAPI(purchasesIDs, newStatus?.value, statusType).then(() => {
            SuccessToast(t('statusSuccessfullyChanged'));
            closeModal();
            refetchPurchases();
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={closeModal}>
            <InnerModal
                header={{ title: t('changeStatus') }}
                content={
                    <ChangeStatus
                        form={form}
                        name={'status'}
                        options={options.map((o) => ({ value: o, label: t(o) }))}
                    />
                }
                footer={{
                    position: 'left',
                    buttons: [
                        {
                            label: t('close'),
                            theme: 'grey',
                            callback: () => {
                                closeModal();
                                form.reset();
                            },
                        },
                        {
                            label: t('save'),
                            callback: () => {
                                changeStatus();
                                form.reset();
                            },
                            theme: 'primary',
                        },
                    ],
                }}
            />
        </Modal>
    );
};

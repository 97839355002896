import { FC, useEffect, useState } from 'react';
import { Tabs } from '../tabs/Tabs';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { useNavigate } from 'react-router-dom';
import './reports.scss';
import { Filters } from '../filters/Filters';
import { useTranslation } from 'react-i18next';
import { exportToExcelByStep, getApiByStep, getReportsFilters } from './reports.utils';
import { FormProvider, useForm } from 'react-hook-form';
import { reportsDefaultValues, ReportsForm } from './reports.models';

export const reportsTabs = ['פוסטים', 'בוסטים ומתנות', 'מוצרים', 'ניצול תקציב'];

export const Reports: FC = () => {
    const currentUser = useSelector(getCurrentUser);
    const form = useForm<ReportsForm>({
        defaultValues: reportsDefaultValues,
        mode: 'onTouched',
    });
    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onFilter = async () => {
        const filters = form.getValues();

        const queries = {
            companyID: filters.companyID?.value,
            fromTimestamp: filters.fromTimestamp ? new Date(filters.fromTimestamp).getTime() : null,
            toTimestamp: filters.toTimestamp ? new Date(filters.toTimestamp).getTime() : null,
            redeemedFromTimestamp: filters.redeemedFromTimestamp
                ? new Date(filters.redeemedFromTimestamp).getTime()
                : null,
            redeemedToTimestamp: filters.redeemedToTimestamp
                ? new Date(filters.redeemedToTimestamp).getTime()
                : null,
            ageFromTimestamp: filters.ageFromTimestamp ? new Date(filters.ageFromTimestamp).getTime() : null,
            ageToTimestamp: filters.ageToTimestamp ? new Date(filters.ageToTimestamp).getTime() : null,
            userID: filters.userID?.value,
            targetID: filters.targetID?.value,
            managerID: filters.managerID?.value,
            gender: filters.gender?.value,
            postType: filters.postType?.value,
            isStyled: filters.isStyled?.value === undefined ? null : filters.isStyled?.value === 'yes',
            boostType: filters.boostType?.value,
            redeemedGiftOrBoostType: filters.redeemedGiftOrBoostType?.value,
        };

        const api = getApiByStep(currentStep);
        const report = await api?.(queries);

        // @ts-ignore
        await exportToExcelByStep(currentStep)(report);
    };

    useEffect(() => {
        if (currentUser?.userType !== 'admin') {
            navigate('/');
        }
    }, [currentUser?.userType, navigate]);

    return (
        <div className={'reports'}>
            <FormProvider {...form}>
                <div className={'steps-tabs'}>
                    <div className={'management'}>
                        <Tabs
                            activeTab={currentStep + 1}
                            toggleTab={(step) => setCurrentStep(step - 1)}
                            tabs={reportsTabs}
                            onClick={() => form.reset(reportsDefaultValues)}
                        />
                        <Filters
                            clearFilters={() => form.reset()}
                            filters={getReportsFilters(onFilter, currentStep, t)}
                            title={t('filterBy')}
                            initialOpen
                        />
                    </div>
                </div>
            </FormProvider>
        </div>
    );
};

import { FC, useState } from 'react';
import './SideBudgetModal.scss';
import { SideBudgetCodeVerification } from './SideBudgetCodeVerification';
import { AddSideBudget } from './AddSideBudget';
import { FormProvider, useFormContext, UseFormReturn, useWatch } from 'react-hook-form';
import { AddBudgetForm } from './side-budget.models';
import { verifyOTPByAuth } from '../../api/login.api';
import { addBudgetAPI, getCompaniesAPI } from '../../api/companies.api';
import { SuccessToast } from '../toaster/Toast';
import { useTranslation } from 'react-i18next';
import { Company } from '../companies/companies.models';

type SideBudgetModalProps = {
    form: UseFormReturn<AddBudgetForm>;
    sendCode: () => void;
    companyID?: string;
    closeModal: () => void;
};

export const SideBudgetModal: FC<SideBudgetModalProps> = ({ form, companyID, closeModal, sendCode }) => {
    const [step, setStep] = useState<1 | 2>(1);
    const [error, setError] = useState<string>();
    const budget = useWatch({ control: form.control, name: 'budget' });
    const { t } = useTranslation();
    const { setValue } = useFormContext();

    const onError = () => {
        setError(t('codeEnteredInvalid'));
    };

    const verifyCode = async (code: string) => {
        await verifyOTPByAuth(code)
            .then((res) => {
                if (res) {
                    setStep(2);
                } else {
                    onError();
                }
            })
            .catch(onError);
    };

    const onAmountSave = async () => {
        addBudgetAPI({ companyID, amount: budget }).then(async () => {
            SuccessToast(t('budgetTransferredSuccessfully'));
            const companies = await getCompaniesAPI<Company>({ companyID, isExtended: true });
            const company = companies?.data?.[0];

            if (company?.budget) {
                setValue('budget', company.budget);
            }

            closeModal();
        });
    };

    return (
        <FormProvider {...form}>
            <div className={'add-side-budget-modal'}>
                {step === 1 ? (
                    <SideBudgetCodeVerification
                        sendAgain={sendCode}
                        verify={verifyCode}
                        onError={error}
                        closeModal={closeModal}
                    />
                ) : (
                    <AddSideBudget requestBudget={onAmountSave} closeModal={closeModal} />
                )}
            </div>
        </FormProvider>
    );
};

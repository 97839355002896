import React from 'react';

interface ChartData {
    name: string;
    amount: number;
    mainImage?: string;
}

interface CustomTickProps {
    x?: number;
    y?: number;
    payload?: {
        value?: string;
        mainImage?: string;
        index?: number;
    };
    visibleTicksCount?: any;
    width?: number;
    extraData?: any;
    formatTickFunction?: any;
}

const CustomTick: React.FC<CustomTickProps> = ({
    x,
    y,
    payload,
    visibleTicksCount,
    width,
    extraData,
    formatTickFunction,
}) => {
    const imgUrl = payload && extraData[payload!.index!]?.mainImage;
    const text = payload?.value;

    return (
        <g transform={`translate(${x!}, ${y!})`}>
            {' '}
            {/* Adjust the translate to center the image or text */}
            {/* {imgUrl ? (
                <image
                    href={imgUrl} // Ensure you use 'href' or 'xlinkHref' depending on your React version
                    x={0} // Center the image based on the tick position
                    y={10} // Move it up by half the image's height plus some padding
                    width={50}
                    height={50}
                />
            ) : ( */}
            <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                {formatTickFunction ? formatTickFunction(text) : text}
            </text>
        </g>
    );
};

export default CustomTick;

import {
    Request,
    GetRequest,
    PostRequest,
    METHOD,
    PutRequest,
    PatchRequest,
    DeleteRequest,
    DEFAULT_HEADERS,
} from './api.models';
import { LocalStorage } from '../utils/LocalStorage.utils';
import { store } from '../store/store';
import { axiosClient, setup } from './Setup';
import { decrementActiveRequests } from '../store/loading/LoadingActions';

type FetchOptions = {
    method: METHOD;
    headers: Record<string, string>;
    body?: any;
    params: Record<string, unknown>;
};

setup();

export abstract class API {
    static headers: null | Record<string, string> = {};

    static async request<T>({ url, method = 'GET', body, params, headers = {} }: Request): Promise<T> {
        const token = await LocalStorage.getItem('token');

        if (token) {
            headers.authorization = `Bearer ${token}`;
        }

        const fetchOptions: FetchOptions = {
            method,
            headers: { ...DEFAULT_HEADERS, ...headers },
            params: { ...params, noCache: true, fromCMS: true },
        };

        const response = await axiosClient({
            ...fetchOptions,
            url,
            data: body,
        }).finally(() => store.dispatch(decrementActiveRequests()));

        return response?.data;
    }

    static get<T>({ url, headers, params }: GetRequest): Promise<T> {
        return this.request({ url, headers, params });
    }

    static post<T>({ url, body, headers, params }: PostRequest): Promise<T> {
        return this.request({ url, method: 'POST', body, headers, params });
    }

    static put<T>({ url, body, headers, params }: PutRequest): Promise<T> {
        return this.request({ url, method: 'PUT', body, headers, params });
    }

    static patch<T>({ url, body, headers, params }: PatchRequest): Promise<T> {
        return this.request({ url, method: 'PATCH', body, headers, params });
    }

    static delete<T>({ url, headers, params }: DeleteRequest): Promise<T> {
        return this.request({ url, method: 'DELETE', headers, params });
    }
}

import ReactPaginate from 'react-paginate';
import { useFormContext } from 'react-hook-form';
import { FC, useMemo } from 'react';
import './Pagination.scss';

interface PaginationProps {
    pagesCount: number;
    name: string;
    rowsPerPage: number;
    total: number;
    page: number;
}

const Pagination: FC<PaginationProps> = ({ pagesCount, name, rowsPerPage, total, page = 0 }) => {
    const { setValue } = useFormContext();

    const firstElement = useMemo(() => {
        return page * rowsPerPage + 1;
    }, [page, rowsPerPage]);

    const lastElement = useMemo(() => {
        return Math.min((page + 1) * rowsPerPage, total);
    }, [page, rowsPerPage, total]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '25px',
                color: 'var(--color-placeholder-text)',
                fontSize: '14px',
                lineHeight: 1.5,
            }}>
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                pageCount={pagesCount}
                activeClassName="active"
                forcePage={page}
                onPageChange={(page) => setValue(name, page.selected)}
                pageClassName={'page-item'}
                nextLinkClassName={'page-link'}
                nextClassName={'page-item next'}
                previousClassName={'page-item prev'}
                previousLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
                containerClassName={'pagination pagination-container react-paginate'}
                marginPagesDisplayed={-10}
                pageRangeDisplayed={7}
            />
            {`מציג ${firstElement} עד ${lastElement} מתוך ${total}`}
        </div>
    );
};

export default Pagination;

import { FC } from 'react';
import './avatar.scss';
import { getImageURL } from '../../utils/utils';

export const Avatar: FC<{ right: number; src: { imageURL?: string; initials: string } }> = ({
    src,
    right = 0,
}) => {
    if (src.imageURL)
        return <img className={'avatar'} src={getImageURL(src.imageURL)} style={{ right: right }} alt={''} />;
    return (
        <div className="user-icon initials-avatar" style={{ right }}>
            {src.initials}
        </div>
    );
};

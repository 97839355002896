import * as Yup from 'yup';
import { ERROR_MESSAGES } from '../../utils/validations';

export const productSchema = Yup.object().shape({
    catalogNumberID: Yup.string().required(ERROR_MESSAGES.required).nullable(),
    productID: Yup.string().required(ERROR_MESSAGES.required).nullable(),
    title: Yup.string().max(100, 'מקסימום 100 תווים').required(ERROR_MESSAGES.required).nullable(),
    category: Yup.object({
        label: Yup.string(),
        value: Yup.string().required(ERROR_MESSAGES.required).nullable(),
    })
        .required(ERROR_MESSAGES.required)
        .nullable(),
    brand_he: Yup.string().max(30, 'מקסימום 30 תווים').required(ERROR_MESSAGES.required).nullable(),
    subtitle: Yup.string().max(50, 'מקסימום 50 תווים').required(ERROR_MESSAGES.required).nullable(),
    summary: Yup.string().max(1000, 'מקסימום 1000 תווים').required(ERROR_MESSAGES.required).nullable(),
    description: Yup.mixed().nullable().required(ERROR_MESSAGES.required).nullable(),
    specifications: Yup.mixed().required(ERROR_MESSAGES.required).nullable(),
    warranty: Yup.mixed().required(ERROR_MESSAGES.required).nullable(),
    mainImage: Yup.string().required(ERROR_MESSAGES.required).nullable(),
    mainImageURL: Yup.string().required(ERROR_MESSAGES.required).nullable(),
    youtube: Yup.string().nullable(),
    price: Yup.string().min(1, 'יש להגדיר מחיר תקין').required(ERROR_MESSAGES.required).nullable(),
    originalPrice: Yup.string().nullable(),
    delivery_price: Yup.string().required(ERROR_MESSAGES.required).nullable(),
    variant: Yup.object({
        id: Yup.string().nullable(),
        isMain: Yup.boolean().nullable(),
        variants: Yup.array(
            Yup.object({
                value: Yup.string().nullable().required(ERROR_MESSAGES.required),
                type: Yup.object({
                    label: Yup.string().nullable(),
                    value: Yup.string().nullable().required(ERROR_MESSAGES.required),
                }).required(ERROR_MESSAGES.required),
            })
        ),
    }).nullable(),
    rating: Yup.string().nullable(),
    ratingCount: Yup.string().nullable(),
});

import { TFunction } from 'i18next';
import { TableRow } from 'react-data-table-component';
import { Trash } from 'react-feather';

export type ListTableData = {
    name: string;
    id: string;
};

export const listsTableColumns = (handleDelete: (id: string) => void, t: TFunction<'translation'>) => [
    {
        name: t('key'),
        selector: (row: TableRow) => row.key,
        width: '20%',
        sortable: false,
    },
    {
        name: t('presentationName'),
        selector: (row: TableRow) => row.name,
        width: '20%',
        sortable: false,
    },
    {
        name: '',
        selector: (row: TableRow) => row.editDelete,
        sortable: false,
        cell: (row: ListTableData) => (
            <span className="text-capitalize table-text">
                <span className={'table-icon'}>
                    <Trash onClick={() => handleDelete(row.id)} width={20} />
                </span>
            </span>
        ),
    },
];

export const populateListData = (data: ListTableData[], t: TFunction<'translation'>): ListTableData[] => {
    return data?.map<ListTableData>((row) => {
        const { name, id } = row;

        return {
            key: name,
            name: t(name),
            id,
        };
    });
};

import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    CartesianGrid,
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell,
    Rectangle,
} from 'recharts';
import CustomTick from '../dashboard/ticks';
import { productsColumns } from '../dashboard/dashboard.models';
import { Table } from '../table/Table';
import { RectRadius } from 'recharts/types/shape/Rectangle';
import { t } from 'i18next';
import './chart.scss';
import { ChartProps, COLORS } from './chart.models';

const renderLegend = (props: any) => {
    const { payload } = props;
    return (
        <ul
            className="recharts-default-legend scrollable"
            style={{ display: 'flex', flexWrap: 'wrap', padding: 0 }}>
            {payload.map((entry: any, index: any) => (
                <li
                    key={`item-${index}`}
                    className="recharts-legend-item"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '15px',
                    }}>
                    <svg
                        className="recharts-surface"
                        width="14"
                        height="14"
                        style={{ display: 'block', marginRight: '8px' }}
                        viewBox="0 0 32 32"
                        version="1.1">
                        <path
                            fill={entry.color}
                            d={
                                entry.type === 'square'
                                    ? 'M0,0h32v32H0z'
                                    : 'M16,0 C24.837,0 32,7.163 32,16 C32,24.837 24.837,32 16,32 C7.163,32 0,24.837 0,16 C0,7.163 7.163,0 16,0 Z'
                            }
                            stroke="none"
                        />
                    </svg>
                    <span
                        className="legend-item-text"
                        style={{
                            color: entry.color,
                        }}>
                        {t(entry.value)}
                    </span>
                </li>
            ))}
        </ul>
    );
};

interface CustomBarShapeProps {
    fill?: string;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    payload?: any;
    index?: number;
    dataKey?: string;
}

const CustomBarShape = (props: CustomBarShapeProps) => {
    const { fill, x, y, width, height, payload, dataKey } = props;

    const dataKeys = ['posts', 'replies', 'likes'];
    let radius: RectRadius = [0, 0, 0, 0]; // Default to no rounded corners

    const nonZeroKeys = dataKeys.filter((key) => payload[key] > 0);

    // If this is the only bar greater than zero, or this is the topmost non-zero bar
    if (nonZeroKeys.length === 1 || nonZeroKeys[nonZeroKeys.length - 1] === dataKey) {
        radius = [10, 10, 0, 0]; // Apply rounded corners to the top
    }

    return <Rectangle fill={fill} x={x} y={y} width={width} height={height} radius={radius} />;
};

const ChartComponent: React.FC<ChartProps> = ({
    data,
    width = '100%',
    height = 300,
    type,
    xAxisProps = [],
    yAxisProps = [],
    formatTickFunction,
}) => {
    switch (type) {
        case 'bar':
            return (
                <ResponsiveContainer width={width} height={height}>
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis
                            dataKey={xAxisProps[0]?.dataKey}
                            tick={(props) => (
                                <CustomTick
                                    {...props}
                                    extraData={data}
                                    formatTickFunction={formatTickFunction}
                                />
                            )}
                            reversed={xAxisProps[0].reversed}
                        />
                        <YAxis tickMargin={30} axisLine={false} />

                        <Tooltip />
                        <Legend iconType={'circle'} content={renderLegend} />
                        {yAxisProps.map((prop, index) => (
                            <Bar key={index} {...prop} shape={<CustomBarShape />} />
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            );
        case 'pie':
            return (
                <ResponsiveContainer width={width} height={height}>
                    <PieChart width={800} height={800}>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            outerRadius={100}
                            fill="#8884d8"
                            dataKey="value"
                            label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                                const RADIAN = Math.PI / 180;

                                const radius = 25 + innerRadius + (outerRadius - innerRadius);

                                const x = cx + radius * Math.cos(-midAngle * RADIAN);

                                const y = cy + radius * Math.sin(-midAngle * RADIAN);

                                return (
                                    <text
                                        x={x}
                                        y={y}
                                        fill="#8884d8"
                                        textAnchor={x <= cx ? 'start' : 'end'}
                                        dominantBaseline="central">
                                        {value}
                                    </text>
                                );
                            }}>
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend content={renderLegend} />
                    </PieChart>
                </ResponsiveContainer>
            );
        case 'table':
            return (
                <ResponsiveContainer width={width} height={height}>
                    <Table
                        columns={productsColumns}
                        data={data}
                        //header={}
                        // onRowClicked={(row) => {
                        //     setEditedGroupID(row.groupID);
                        //     setGroupModalOpen(true);
                        // }}
                        pagination={false}
                        rowsPerPage={10}
                        total={data.length}
                        //page={page}
                        selectableRows={false}
                        // onSort={(column: Column<GroupWithMembers>, direction: Order) => {
                        //     filterForm.setValue('orderBy', {
                        //         direction,
                        //         sortedColumn: column.field,
                        //     });
                        // }}
                    />
                </ResponsiveContainer>
            );
        default:
            return <div>No chart type specified</div>;
    }
};

export default ChartComponent;

import { FC, useEffect, useMemo, useState } from 'react';
import { CodeInsertion } from '../code-verification/CodeInsertion';
import { Button } from '../form/buttons/Button';
import { ModalIcon } from '../modal/ModalIcon';
import { useTranslation } from 'react-i18next';

type SideBudgetCodeVerificationProps = {
    sendAgain: () => void;
    verify: (value: string) => Promise<void>;
    digits?: number;
    onError: string | undefined;
    closeModal: () => void;
};

export const SideBudgetCodeVerification: FC<SideBudgetCodeVerificationProps> = ({
    digits = 6,
    sendAgain,
    verify,
    closeModal,
    onError,
}) => {
    const [isResendDisabled, setResendDisabled] = useState(true);
    const [values, setValues] = useState(new Array(digits).fill(''));
    const { t } = useTranslation();

    useEffect(() => {
        const timeout = setTimeout(() => {
            setResendDisabled(false);
        }, 30000);

        return () => clearTimeout(timeout);
    }, [isResendDisabled]);

    const onClickVerify = async () => {
        const codeValue = values.toString().replaceAll(',', '');
        if (codeValue.length !== digits) return;

        await verify?.(codeValue);
    };

    const isDisabled = useMemo(() => {
        return values.some((value) => value === '');
    }, [values]);

    return (
        <div className={'add-side-budget-step-1'}>
            <CodeInsertion
                digits={digits}
                values={values}
                setValues={setValues}
                onError={onError}
                onClickVerify={onClickVerify}
            />
            <div className={'add-budget-footer'}>
                <Button
                    theme={'none'}
                    onClick={() => {
                        setResendDisabled(true);
                        sendAgain();
                    }}
                    disabled={isResendDisabled}>
                    {t('didntReceiveCodeSendAgain')}
                </Button>

                <div className={'buttons-wrapper'}>
                    <Button onClick={closeModal} theme="secondary">
                        {t('cancel')}
                    </Button>
                    <Button
                        onClick={onClickVerify}
                        theme={'primary'}
                        disabled={isDisabled}
                        className="flex-row-reverse"
                        icon={<ModalIcon theme={'ARROW_LEFT'} />}>
                        {t('save')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

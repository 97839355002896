import { GetInstances, GetResponse } from './api.models';
import { API } from './api';
import { Gift, GiftDetails, Post, PostCreate } from '../components/home/home.models';
import { GiftValidation } from '../components/send-gift/create-gift.models';
import { BoostsStatsDto } from '../components/users/users.models';

export const getPostsAPI = async <T>(getBy: GetInstances<T>): Promise<GetResponse & { data: Post[] }> => {
    return API.get({ url: `posts/cms`, params: getBy });
};

export const getBoostsStats = async (userID: string, fromTimestamp: number): Promise<BoostsStatsDto> => {
    return API.get({ url: `posts/stats`, params: { userID, fromTimestamp } });
};

export const createPostAPI = async (post: PostCreate | Gift): Promise<void> => {
    return API.post({ url: `posts/cms`, body: post });
};

export const deletePostAPI = async (postID: string): Promise<void> => {
    return API.delete({ url: `posts/${postID}` });
};

export const validateBudgetEnoughAPI = async (giftValidation: GiftValidation): Promise<number> => {
    return API.post({ url: 'posts/gift-budget-validate', body: giftValidation });
};

export const cancelBoostOrGift = async (postsIDs: string[]): Promise<number> => {
    return API.put({ url: `posts/cancel`, body: { postsIDs } });
};

export const getOpenTransactions = async <T>(
    getBy: GetInstances<T>
): Promise<GetResponse & { data: GiftDetails[] }> => {
    return API.get({ url: 'posts/boosts-and-gifts', params: getBy });
};

import { FC, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Table } from '../table/Table';
import {
    categoriesLoadOptions,
    categoriesTableColumns,
    exportCategories,
    getRecursiveCategories,
    populateCategoriesData,
} from './store.utils';
import { Column } from '../table/Tables.types';
import { CategoriesForm, initialCategories, StoreCategory, Supplier } from './store.models';
import { Order } from '../../hooks/useSort';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TableHeader } from '../table/TableHeader';
import { Button } from '../form/buttons/Button';
import { FileText, Plus } from 'react-feather';
import { useQuery } from 'react-query';
import { deleteCategoryAPI, getCategoriesAPI } from '../../api/store.api';
import { useUpdateEffect } from '../../hooks/useUpdateEffect';
import { Filters } from '../filters/Filters';
import { Filter, FiltersModels } from '../filters/filters.models';
import { SuccessToast } from '../toaster/Toast';
import { closeModal, raiseModal } from '../../store/modal/modal.actions';
import { ModalIcon } from '../modal/ModalIcon';
import { GetInstances } from '../../api/api.models';
import { useFormWithSession } from '../../hooks/useFormSession';

type CategoryManagementProps = {
    onCreateOrEditCategory: (categoryID?: string) => void;
    suppliers: Supplier[] | undefined;
    allStoreCategories: StoreCategory[] | undefined;
};

export const CategoryManagement: FC<CategoryManagementProps> = ({
    suppliers,
    onCreateOrEditCategory,
    allStoreCategories,
}) => {
    const dispatch = useDispatch();
    const form = useForm<CategoriesForm>({
        defaultValues: initialCategories,
        mode: 'onTouched',
    });
    const orderBy = useWatch({ name: 'orderBy', control: form.control });
    const rowsPerPage = useWatch({ name: 'rowsPerPage', control: form.control });
    const page = useWatch({ name: 'page', control: form.control });
    const name = useWatch({ name: 'name', control: form.control });
    const supplier = useWatch({ name: 'supplier', control: form.control });
    const parentCategory = useWatch({ name: 'parentCategory', control: form.control });
    const childrenCategory = useWatch({ name: 'childrenCategory', control: form.control });
    const { t } = useTranslation();
    const [filters, setFilters] = useState<GetInstances<StoreCategory>>(buildFiltersFromForm());

    const { data: { data: categories = [], total = 0 } = {} } = useQuery({
        queryKey: ['getCategories', filters],
        queryFn: async () => {
            return getCategoriesAPI<StoreCategory>(filters);
        },
        enabled: true,
    });
    useFormWithSession<CategoriesForm>(form, (values: CategoriesForm) =>
        setFilters(buildFiltersFromSession(values))
    );

    const recursiveCategories = useMemo(() => {
        return getRecursiveCategories(allStoreCategories ?? []);
    }, [allStoreCategories]);

    function buildFiltersFromForm() {
        return {
            name: name ?? undefined,
            supplierID: supplier?.label,
            parentCategoryID: parentCategory?.value,
            childrenCategoryID: childrenCategory?.value,
            offset: page * rowsPerPage.value,
            count: rowsPerPage.value,
            sortBy: orderBy.direction,
            sortField: orderBy.sortedColumn,
            all: false,
        };
    }

    function buildFiltersFromSession(values: CategoriesForm): GetInstances<StoreCategory> {
        const { name, supplier, parentCategory, childrenCategory, page, rowsPerPage, orderBy } = values;

        return {
            name: name ?? undefined,
            supplierID: supplier?.label,
            parentCategoryID: parentCategory?.value,
            childrenCategoryID: childrenCategory?.value,
            offset: page * rowsPerPage.value,
            count: rowsPerPage.value,
            sortBy: orderBy.direction,
            sortField: orderBy.sortedColumn,
            all: false,
        };
    }

    const refetch = () => {
        setFilters(buildFiltersFromForm());
    };

    const refetchOrResetPage = () => {
        if (page !== 0) {
            form.setValue('page', 0);
        } else {
            refetch();
        }
    };

    useUpdateEffect(() => {
        refetchOrResetPage();
    }, [rowsPerPage.value, orderBy]);

    useUpdateEffect(() => {
        refetch();
    }, [page]);

    const CustomHeader = () => {
        return (
            <TableHeader
                rowsPerPage={rowsPerPage?.value}
                leftSideComponents={[
                    <Button
                        theme={'outline-primary'}
                        onClick={() => exportCategories(form.getValues())}
                        icon={<FileText width={14} />}>
                        {t('exportToExcel')}
                    </Button>,
                    <Button
                        theme={'primary'}
                        onClick={() => onCreateOrEditCategory(undefined)}
                        icon={<Plus width={14} />}>
                        {t('addNewCategory')}
                    </Button>,
                ]}
            />
        );
    };

    const handleDeleteCategory = (categoryID: string) => {
        dispatch(
            raiseModal({
                isOpen: true,
                content: `${t('areYouSureYouWantToDeleteCategory')}`,
                header: {
                    title: t('deleteCategory'),
                    icon: <ModalIcon theme={'ALERT'} />,
                },
                footer: {
                    buttons: [
                        {
                            label: t('yesDelete'),
                            theme: 'danger',
                            callback: async () => {
                                deleteCategoryAPI(categoryID).then(() => {
                                    SuccessToast(t('categoryDeletedSuccessfully'));
                                    dispatch(closeModal());
                                    refetch();
                                });
                            },
                        },
                    ],
                },
            })
        );
    };

    const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            refetchOrResetPage();
        }
    };

    const getFilters = (): Filter[] => {
        return [
            {
                type: FiltersModels.INPUT,
                name: 'name',
                onKeyDown,
                placeholder: t('search...'),
                label: t('searchByNameOrID'),
            },
            {
                type: FiltersModels.SELECT,
                name: 'supplier',
                onKeyDown,
                fullRowSize: false,
                placeholder: t('showAll'),
                options: suppliers?.map((st) => ({
                    label: st.supplierName,
                    value: st.supplierID,
                })),
                label: t('suppliers'),
            },
            {
                type: FiltersModels.AUTO_COMPLETE,
                getLoadOptionsData: (input) => categoriesLoadOptions(recursiveCategories, input),
                name: 'parentCategory',
                onKeyDown,
                placeholder: t('showAll'),
                label: t('parentCategories'),
            },
            {
                type: FiltersModels.AUTO_COMPLETE,
                getLoadOptionsData: (input) => categoriesLoadOptions(recursiveCategories, input),
                name: 'childrenCategory',
                onKeyDown,
                placeholder: t('showAll'),
                label: t('childrenCategories'),
            },
            {
                type: FiltersModels.BUTTON,
                sizeBootstrap: 2,
                label: t('filter'),
                onClick: () => {
                    refetchOrResetPage();
                },
            },
        ];
    };

    return (
        <FormProvider {...form}>
            <div className={'management'}>
                <Filters
                    clearFilters={() => {
                        form.setValue('name', '');
                        form.setValue('supplier', null);
                        form.setValue('parentCategory', null);
                        form.setValue('childrenCategory', null);
                    }}
                    filters={getFilters()}
                    title={t('filterBy')}
                    initialOpen={true}
                />
                <Table
                    columns={categoriesTableColumns(t, handleDeleteCategory)}
                    data={populateCategoriesData(categories)}
                    onRowClicked={(row) => onCreateOrEditCategory(row.categoryID)}
                    header={CustomHeader()}
                    pagination={true}
                    rowsPerPage={rowsPerPage?.value}
                    total={total}
                    selectableRows={false}
                    onSort={(column: Column<StoreCategory>, direction: Order) => {
                        form.setValue('orderBy', { direction, sortedColumn: column.field });
                    }}
                />
            </div>
        </FormProvider>
    );
};

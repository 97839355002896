import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { ArrowLeft, Gift, Heart, UserPlus } from 'react-feather';
import { Button } from '../form/buttons/Button';
import { chosenStepStyles, GiftValidation, SendGiftForm } from './create-gift.models';
import { ChooseGift } from './ChooseGift';
import '../create-post/create-post.scss';
import { validateBudgetEnoughAPI } from '../../api/posts.api';
import { CreatePostGroupsAndUsers } from '../create-post/CreatePostGroupsAndUsers';
import { formatter } from '../../utils/utils';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';

type CreateGiftProps = {
    onConfirm: () => void;
};

export const CreateGift: FC<CreateGiftProps> = ({ onConfirm }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const { t } = useTranslation();
    const {
        trigger,
        control,
        formState: { isValid, errors },
    } = useFormContext();
    const [budgetCost, setBudgetCost] = useState(0);
    const companyBudget = useWatch({ control, name: 'companyBudget' });
    const { getValues } = useFormContext();
    const creditsAmount = useWatch({ control, name: 'creditsAmount' });
    const product = useWatch({ control, name: 'product' });
    const groups = useWatch({ control, name: 'groups' });
    const users = useWatch({ control, name: 'users' });

    useEffect(() => {
        calcBudgetAmount();
    }, [currentStep, product?.value, creditsAmount, groups, users]);

    const renderStepIcon = useCallback(
        (step: 0 | 1 | 2) => {
            const icon =
                step === 0 ? (
                    <UserPlus height={15} width={15} />
                ) : step === 1 ? (
                    <Gift height={15} width={15} />
                ) : (
                    <Heart height={15} width={15} />
                );

            const style = currentStep === step && chosenStepStyles;

            return (
                <div className={'step-icon'} style={{ ...(style ?? {}) }}>
                    {icon}
                </div>
            );
        },
        [currentStep]
    );

    const renderStepTitle = useCallback(
        (title: string, subtitle: string, step: 0 | 1 | 2) => {
            return (
                <div className={'create-post-step'} onClick={() => setCurrentStep(step)}>
                    {renderStepIcon(step)}
                    <div className={'create-form-step-title'}>
                        <h5 className={'step-main-title'}>{title}</h5>
                        <h6 className={'step-sub-title'}>{subtitle}</h6>
                    </div>
                </div>
            );
        },
        [currentStep]
    );

    const handleNext = async () => {
        if (currentStep === 2) {
            trigger().then((res) => {
                if (res) {
                    onConfirm();
                }
            });
        } else {
            setCurrentStep(currentStep + 1);
        }
    };

    const calcBudgetAmount = async () => {
        const formValues: Partial<SendGiftForm> = getValues();
        const giftValidation: GiftValidation = {
            groups: formValues.groups?.map((g) => g.value.groupID),
            creditsAmount: +(formValues.creditsAmount ?? 0),
            productID: formValues.product?.value,
            users: formValues.users?.map((u) => u.value) ?? [],
        };

        validateBudgetEnoughAPI(giftValidation).then((res) => setBudgetCost(res));
    };

    const renderStep = () => {
        switch (currentStep) {
            case 0: {
                return <CreatePostGroupsAndUsers isEventable={true} isGift={true} />;
            }
            case 1: {
                return <ChooseGift />;
            }
            default: {
                return (
                    <div className={'step'} style={{ width: '412px' }}>
                        <div className={'step-row'}>
                            <SmartInput
                                type={'textarea'}
                                placeholder={t('giftContentText')}
                                required
                                name={'content'}
                                label={t('giftContentText')}
                            />
                        </div>
                    </div>
                );
            }
        }
    };

    const isNextDisabled = useCallback(() => {
        if (currentStep === 0) {
            return users?.length === 0 && groups.length === 0;
        }

        const { giftType, product } = getValues();

        return (
            (users?.length === 0 && groups.length === 0) ||
            (giftType?.value === 'credit' && !+(creditsAmount ?? 0)) ||
            (giftType?.value === 'product' && !product?.value)
        );
    }, [product?.value, creditsAmount, currentStep, groups?.length, users?.length]);

    return (
        <div className={'create-post'}>
            <div className={'current-step-container'}>
                <div className={'post-steps-tabs'}>
                    {renderStepTitle(t('whoToSend'), t('whoToSendAndWhen'), 0)}
                    {renderStepTitle(t('chooseGift'), t('outOfCatalog'), 1)}
                    {renderStepTitle(t('greeting'), t('fewGoodWords'), 2)}
                </div>
                <div className={'current-step-step-content'}>{renderStep()}</div>
            </div>
            <div className={'bottom-messages'}>
                {currentStep > 0 && (
                    <span className={'budget-amount'}>
                        {`${t('totalCost')} ${formatter.format(budgetCost)} ${t('outOf')} ${formatter.format(
                            companyBudget
                        )} ${t('inCompanyBudget')} `}
                    </span>
                )}
                {users?.length === 0 && groups.length === 0 ? (
                    <span className={'not-enough-budget'}>{t('chooseAtLeastOneGroupOrUser')}</span>
                ) : (
                    budgetCost >= companyBudget && (
                        <span className={'not-enough-budget'}>{t('noBudgetToCompany')}</span>
                    )
                )}
            </div>
            {!isValid && Object.keys(errors)?.length > 0 && (
                <span className={'not-enough-budget'}>{t('notAllDataFilled')}</span>
            )}

            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Button
                    theme={'primary'}
                    onClick={handleNext}
                    icon={<ArrowLeft />}
                    direction={'left'}
                    disabled={isNextDisabled()}>
                    {currentStep < 2 ? t('continue') : t('confirm')}
                </Button>
            </div>
        </div>
    );
};

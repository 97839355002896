import { FC, useState } from 'react';
import { VerifyPhone } from '../verify-phone/VerifyPhone';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { CodeVerification } from '../code-verification/CodeVerification';
import { useDispatch } from 'react-redux';
import { setCurrentUser, setLogo } from '../../store/users/UsersActions';
import { getOnLoginData, sendOTPByPhone, verifyOTP } from '../../api/login.api';
import { closeModal, raiseModal } from '../../store/modal/modal.actions';
import { LocalStorage } from '../../utils/LocalStorage.utils';
import { setCompanies } from '../../store/companies/companies.actions';
import { ModalIcon } from '../modal/ModalIcon';
import { useTranslation } from 'react-i18next';

export type LoginForm = { phone: string; code: number[] };
const LoginFormDefaultValues = { phone: '', code: new Array(6).fill('') };

export const LoginArea: FC = () => {
    const [currentStep, setStep] = useState<1 | 2>(1);
    const dispatch = useDispatch();
    const form = useForm<LoginForm>({
        defaultValues: LoginFormDefaultValues,
        mode: 'onTouched',
    });
    const phone = useWatch({ name: 'phone', control: form.control });
    const { t } = useTranslation();

    const sendCode = async () => {
        await sendOTPByPhone(phone)
            .then(() => setStep(2))
            .catch((e) => {
                dispatch(
                    raiseModal({
                        isOpen: true,
                        header: {
                            title: t(e.response?.data?.errorCode?.code),
                            icon: <ModalIcon theme={'ALERT'} />,
                        },
                        content: (
                            <>
                                <div>{t('phoneDoesntExistTalkWithHR')}</div>
                                <div>{t('orToMail')}</div>
                            </>
                        ),
                        footer: {
                            buttons: [
                                { label: 'סגור', theme: 'grey', callback: () => dispatch(closeModal()) },
                            ],
                        },
                    })
                );
            });
    };

    const verifyUser = (code: string) => {
        const phone = form.getValues('phone');

        verifyOTP(phone, code)
            .then((token) => {
                if (token) {
                    LocalStorage.setItem('token', token);
                }
            })
            .then(() => getOnLoginData())
            .then((res) => {
                if (!(res.currentUser?.userType === 'admin' || res.currentUser?.userType === 'HR')) {
                    LocalStorage.removeItem('token');
                    return;
                }

                dispatch(setCurrentUser(res.currentUser));
                dispatch(setCompanies(res.companies));
                dispatch(setLogo(res.logo));
            })
            .catch((e) => {
                dispatch(
                    raiseModal({
                        isOpen: true,
                        content: t(e.response?.data?.errorCode?.code),
                        header: {
                            title: t('error'),
                            icon: <ModalIcon theme={'ALERT'} />,
                        },
                        footer: {
                            buttons: [
                                { label: t('close'), theme: 'grey', callback: () => dispatch(closeModal()) },
                            ],
                        },
                    })
                );
            });
    };

    return (
        <FormProvider {...form}>
            <div className={'login-area'}>
                {currentStep === 1 ? (
                    <VerifyPhone sendCode={sendCode} phone={phone} />
                ) : (
                    <CodeVerification sendAgain={sendCode} verify={verifyUser} back={() => setStep(1)} />
                )}
                {/*<button onClick={healthCheckAPI}>healthcheck</button>*/}
            </div>
        </FormProvider>
    );
};

import { ChangeEvent, FC } from 'react';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { useController, useFormContext } from 'react-hook-form';
import './color-input.scss';

type SmartColorInputProps = {
    label: string;
    placeholder?: string;
    name: string;
    className?: string;
    required?: boolean;
};

export const SmartColorInput: FC<SmartColorInputProps> = ({
    name,
    placeholder,
    label,
    className,
    required,
}) => {
    const { control } = useFormContext();
    const { field } = useController({ name, control });

    return (
        <div className={`${className} color-picker`}>
            <SmartInput
                name={name}
                placeholder={placeholder}
                label={label}
                required={required}
                onChange={(v: ChangeEvent<HTMLInputElement>) => {
                    const val = v?.currentTarget?.value;
                    if (val !== undefined) {
                        const removedTagValue = String(val).replaceAll('#', '');
                        let value = '';
                        if (removedTagValue) {
                            value = `#${removedTagValue}`;
                        }

                        field.onChange(value);
                    }
                }}
            />
            <div className={'color-picked'} style={{ backgroundColor: field.value }} />
        </div>
    );
};

import { FC } from 'react';
import { Input } from 'reactstrap';
import { useController, useFormContext } from 'react-hook-form';
import '../form/radio/radio.scss';
import { RadioButtonProps } from '../form/radio/smart-radio-button';

export const SpouseGender: FC<RadioButtonProps> = ({ option1, option2, option3, name }) => {
    const { control } = useFormContext();
    const { field, fieldState } = useController({ name, control });

    return (
        <div className={`radio-buttons ${fieldState?.error && 'error-msg'}`}>
            <div className={'button-label-and-radio'}>
                <Input
                    type="radio"
                    value={option1}
                    checked={field.value === 'female'}
                    onChange={() => field.onChange('female')}
                />
                {option1}
            </div>
            <div className={'button-label-and-radio'}>
                <Input
                    type="radio"
                    value={option2}
                    checked={field.value === 'male'}
                    onChange={() => field.onChange('male')}
                />
                {option2}
            </div>
            {option3 && (
                <div className={'button-label-and-radio'}>
                    <Input
                        type="radio"
                        value={option3}
                        checked={field.value === 'none'}
                        onChange={() => field.onChange('none')}
                    />
                    {option3}
                </div>
            )}
        </div>
    );
};

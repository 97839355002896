import { WelcomeFeed } from './WelcomeFeed';
import './feed.scss';
import { Filters } from '../filters/Filters';
import { Filter } from '../filters/filters.models';
import { companyLoadOptions, userLoadOptions } from '../../utils/loadOptions';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { Group, GroupOption } from '../../models/groups.models';
import { getGroupsAPI } from '../../api/groups.api';
import { Gift, HomeForm, homeFormInitialValues, Post, PostCreate } from './home.models';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { getCompanies } from '../../store/companies/companies.selectors';
import { useQuery } from 'react-query';
import { createPostAPI, deletePostAPI, getPostsAPI } from '../../api/posts.api';
import { Post as UserPost } from '../post/Post';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AreYouSureModal } from '../modals/AreYouSure.modal';
import { SuccessToast } from '../toaster/Toast';
import { ModalIcon } from '../modal/ModalIcon';
import { CreatePostModal } from '../modals/CreatePost.modal';
import { getHomeFilters } from './home.utils';
import { SendGiftModal } from '../modals/SendGift.modal';
import { useTranslation } from 'react-i18next';

export const Home = () => {
    const form = useForm<HomeForm>({
        defaultValues: homeFormInitialValues,
        mode: 'onTouched',
    });
    const { t } = useTranslation();
    const companies = useSelector(getCompanies);
    const currentUser = useSelector(getCurrentUser);
    const [deletePostID, setDeleteModalOpen] = useState<string>();
    const [createPostModalOpen, setCreatePostModalOpen] = useState(false);
    const [sendGiftModalOpen, setSendGiftModalOpen] = useState(false);
    const { fields: posts, remove } = useFieldArray({ control: form.control, name: 'posts' });
    const {
        company,
        group,
        rowsPerPage,
        user,
        fromTimestamp,
        toTimestamp,
        futurePostsOnly,
        orderBy,
        campaign,
    } = useWatch({ control: form.control });

    const { refetch, data: { total = 0 } = {} } = useQuery({
        queryKey: ['getPosts'],
        queryFn: async () => {
            const updatedPage = form.getValues('page');
            return getPostsAPI<Post>({
                companyID: company?.value,
                groupID: group?.value?.groupID,
                userID: user?.value,
                campaign: campaign ?? null,
                futurePostsOnly: futurePostsOnly,
                includeFuturePosts: true,
                fromTimestamp: fromTimestamp ? new Date(fromTimestamp).getTime() : undefined,
                toTimestamp: toTimestamp ? new Date(toTimestamp).getTime() : undefined,
                offset: updatedPage * (rowsPerPage?.value ?? 0),
                count: rowsPerPage?.value ?? 0,
                sortBy: orderBy?.direction,
                sortField: orderBy?.sortedColumn,
            }).then((res) => {
                const updatedPosts = form.getValues('posts');
                form.setValue('page', updatedPage + 1);
                form.setValue('posts', [...updatedPosts, ...res.data]);
                return { total: res.total };
            });
        },
        enabled: true,
    });

    useEffect(() => {
        if (currentUser?.userType !== 'admin') {
            const currentCompany = companies.find((c) => c.companyID === currentUser?.companyID);
            if (currentCompany?.name) {
                form.setValue('company', { label: currentCompany.name, value: currentCompany.companyID });
            }
        }
    }, [companies, currentUser?.companyID, currentUser?.userType, form]);

    const getFilters = (): Filter[] => {
        return getHomeFilters(
            currentUser,
            companyLoadOptions,
            groupsLoadOptions,
            userLoadOptions,
            onSearch,
            (e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                    refetch();
                }
            },
            t
        );
    };

    const onSearch = () => {
        form.setValue('posts', []);
        form.setValue('page', 0);
        refetch();
    };

    const groupsLoadOptions = async (inputValue: string): Promise<GroupOption[]> => {
        const results = await getGroupsAPI<Group>({
            companyID: company?.value,
            name: inputValue,
        });

        if (!results?.data) return [];

        return results.data.map((u) => ({
            label: `${u.name}`,
            value: { groupID: u.groupID },
        }));
    };

    const handleDeletePost = (postID: string) => {
        setDeleteModalOpen(postID);
    };

    const confirmDelete = (postID: string) => {
        const fieldIndex = posts.findIndex((post) => post.postID === postID);

        deletePostAPI(postID)
            .then(() => SuccessToast(t('operationSuccess')))
            .finally(() => {
                remove(fieldIndex);
                setDeleteModalOpen(undefined);
            });
    };

    const onCreatePost = (post: PostCreate) => {
        createPostAPI(post)
            .then(() => SuccessToast(t('operationSuccess')))
            .finally(() => {
                setCreatePostModalOpen(false);
                onSearch();
            });
    };

    const onSendGift = async (gift: Gift): Promise<boolean> => {
        return createPostAPI(gift)
            .then(() => {
                SuccessToast(t('operationSuccess'));
                return true;
            })
            .catch(() => {
                return false;
            })
            .finally(() => {
                setSendGiftModalOpen(false);
                onSearch();
            });
    };

    return (
        <FormProvider {...form}>
            <div className={'home'}>
                <WelcomeFeed
                    onGift={() => setSendGiftModalOpen(true)}
                    onPost={() => setCreatePostModalOpen(true)}
                />
                <Filters
                    clearFilters={() => form.reset(homeFormInitialValues)}
                    filters={getFilters()}
                    title={'סנן לפי'}
                    initialOpen={true}
                />
                <div id="scrollableDiv" style={{ overflow: 'auto', height: '100%' }}>
                    <InfiniteScroll
                        dataLength={posts.length}
                        next={refetch}
                        hasMore={posts.length < total}
                        loader={null}
                        scrollableTarget="scrollableDiv"
                        scrollThreshold={0.5}>
                        <div className={'posts'}>
                            {posts.map((post) => {
                                return (
                                    <UserPost
                                        key={post.postID}
                                        post={post}
                                        deletePost={() => handleDeletePost(post.postID)}
                                    />
                                );
                            })}
                        </div>
                    </InfiniteScroll>
                </div>
            </div>
            <AreYouSureModal
                onConfirm={() => {
                    if (deletePostID) {
                        confirmDelete(deletePostID);
                    }
                }}
                icon={<ModalIcon theme={'ALERT'} />}
                closeModal={() => setDeleteModalOpen(undefined)}
                title={t('deletePost')}
                text={t('areYouSureYouWantToDelete')}
                isOpen={!!deletePostID}
            />
            {createPostModalOpen && (
                <CreatePostModal
                    isOpen={createPostModalOpen}
                    onConfirm={onCreatePost}
                    closeModal={() => setCreatePostModalOpen(false)}
                />
            )}
            {sendGiftModalOpen && (
                <SendGiftModal
                    isOpen={sendGiftModalOpen}
                    onConfirm={onSendGift}
                    closeModal={() => setSendGiftModalOpen(false)}
                />
            )}
        </FormProvider>
    );
};

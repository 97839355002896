import { FC } from 'react';
import './header.scss';
import { useSelector } from 'react-redux';
import { getCurrentUser, getUserLogo } from '../../store/users/UsersSelectors';
import { UserIcon } from '../user-icon/UserIcon';
import { UserTranslate } from '../users/users.models';
import { getInitials } from '../../utils/initials.utils';
import logo from '../Sidebar/elementsLogoCommuni.svg';
import { getImageURL } from '../../utils/utils';

export const Header: FC = () => {
    const currentUser = useSelector(getCurrentUser);
    const userLogo = useSelector(getUserLogo);

    return (
        <div className={'header'}>
            {currentUser && (
                <div className={'header-content'}>
                    <UserIcon
                        initials={getInitials(currentUser?.firstName, currentUser?.lastName)}
                        imageURL={currentUser.imageURL}
                    />
                    <div className={'user-name-and-type'}>
                        <div className={'user-name'}>
                            {currentUser?.firstName} {currentUser?.lastName}
                        </div>
                        <div className={'user-type'}>{UserTranslate[currentUser.userType]}</div>
                    </div>
                </div>
            )}
            {<img src={getImageURL(userLogo) ?? logo} height={300} className={'header-logo'} alt={''} />}
        </div>
    );
};

import { ChangeEvent, FC, useMemo, useState } from 'react';
import { FormProvider, UseFormReturn, useWatch } from 'react-hook-form';
import { Company } from './companies.models';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { Input } from '../form/input-and-checkbox/Input';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { Button } from '../form/buttons/Button';
import { PlusCircle } from 'react-feather';
import { RequestBudgetModal } from '../modals/RequestBudget.modal';
import { useTranslation } from 'react-i18next';

type CompaniesStep2Props = {
    form: UseFormReturn<Company>;
};

export const CompaniesStep2: FC<CompaniesStep2Props> = ({ form }) => {
    const [isBudgetModalOpen, setBudgetModalOpen] = useState(false);
    const currentUser = useSelector(getCurrentUser);
    const { t } = useTranslation();
    const companyID = useWatch({ control: form.control, name: 'companyID' });
    const monthlyBudget = useWatch({ control: form.control, name: 'monthlyBudget' });
    const employeesAmount = useWatch({ control: form.control, name: 'employeesAmount' });
    const costPerUser = useWatch({ control: form.control, name: 'costPerUser' });
    const fixedCostPerMonth = useWatch({ control: form.control, name: 'fixedCostPerMonth' });
    const perSubordinate = useWatch({ control: form.control, name: 'monthlyBudget.perSubordinate' });
    const subordinatesAmount = useWatch({ control: form.control, name: 'subordinatesAmount' });
    const budget = useWatch({ control: form.control, name: 'budget' });

    const totalCreditsAmount = useMemo(() => {
        return +monthlyBudget?.personal + +monthlyBudget?.social;
    }, [monthlyBudget]);

    const totalCost = useMemo(() => {
        return (
            +fixedCostPerMonth +
            (+costPerUser + +totalCreditsAmount) * +(employeesAmount ?? 0) +
            +(perSubordinate ?? 0) * (subordinatesAmount ?? 0)
        );
    }, [
        fixedCostPerMonth,
        costPerUser,
        employeesAmount,
        perSubordinate,
        subordinatesAmount,
        totalCreditsAmount,
    ]);

    return (
        <div className={'step'}>
            <FormProvider {...form}>
                <div className={'step-title'}>{t('creditsAndBudget')}</div>
                <SmartInput
                    required
                    enforceNumbers
                    name={'monthlyBudget.personal'}
                    label={t('personalPerMonth')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value;

                        if (!isNaN(+value)) {
                            form.setValue('monthlyBudget.personal', +value);
                        }
                    }}
                />
                <SmartInput
                    required
                    enforceNumbers
                    type={'number'}
                    name={'monthlyBudget.social'}
                    label={t('socialPerMonth')}
                />

                <SmartInput
                    required
                    enforceNumbers
                    name={'monthlyBudget.perSubordinate'}
                    label={t('socialPerEmployee')}
                />
                <SmartInput
                    required
                    enforceNumbers
                    name={'socialCeilingRatio'}
                    label={t('multiplierCreditsCeiling')}
                    disabled={currentUser?.userType !== 'admin'}
                />
                <SmartInput name={'employeesAmount'} label={t('employeeAmount')} disabled />
                <SmartInput name={'subordinatesAmount'} label={t('subordinatesAmount')} disabled />

                <SmartInput
                    enforceNumbers
                    name={'budget'}
                    label={t('companyBudget')}
                    disabled
                    errorMsg={totalCost > (budget ?? 0) ? t('budgetIsNotEnough') : ''}
                />

                <span className={'step-row'} style={{ marginTop: '-8px' }}>
                    <Button
                        onClick={() => setBudgetModalOpen(true)}
                        style={{ width: '182px' }}
                        theme={'outline-primary'}>
                        <span className={'button-center'}>
                            <PlusCircle width={13} height={13} />
                            {t('addToCompanyBudget')}
                        </span>
                    </Button>
                    <RequestBudgetModal
                        companyID={companyID}
                        isOpen={isBudgetModalOpen}
                        closeModal={() => setBudgetModalOpen(false)}
                    />
                </span>

                <div className={'step-title'}>{t('costs')}</div>

                <SmartInput
                    enforceNumbers
                    required
                    name={'fixedCostPerMonth'}
                    label={t('monthlyCost')}
                    disabled={currentUser?.userType !== 'admin'}
                />
                <SmartInput
                    enforceNumbers
                    required
                    name={'costPerUser'}
                    label={t('monthlyCostPerEmployee')}
                    disabled={currentUser?.userType !== 'admin'}
                />

                <Input label={t('totalPaymentPerMonth')} value={totalCost} disabled />

                <div className={'step-title'}>{t('giftPriceRange')}</div>
                <SmartInput required enforceNumbers name={'smallAmountMax'} label={t('small')} />
                <SmartInput required enforceNumbers name={'mediumAmountMax'} label={t('medium')} />
            </FormProvider>
        </div>
    );
};

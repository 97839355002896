import { FC, useRef } from 'react';
import { Input, InputProps } from './Input';
import { useController, useFormContext } from 'react-hook-form';

type FormInputProps = Omit<InputProps, 'value'> & {
    name: string;
    containerClass?: string;
    enforceNumbers?: boolean;
    disabled?: boolean;
    required?: boolean;
    errorMsg?: string;
};

export const SmartInput: FC<FormInputProps> = ({ name, enforceNumbers, disabled, errorMsg, ...rest }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { control } = useFormContext();
    const { field, fieldState } = useController({
        name,
        control,
    });

    return (
        <Input
            inputRef={inputRef}
            {...rest}
            {...field}
            onChange={(e) => {
                if (enforceNumbers) {
                    if (
                        e.target.value?.length > 10 ||
                        (isNaN(Number(e.target.value)) && e.target.value !== '')
                    )
                        return;
                }

                return rest.onChange ? rest.onChange?.(e) : field.onChange(e);
            }}
            checked={field.value}
            disabled={disabled}
            className={fieldState.error && 'error-msg'}
            errorMessage={errorMsg || fieldState.error?.message}
            onFocus={() => {
                if ((rest.type === 'number' || enforceNumbers) && inputRef.current) {
                    inputRef.current.select();
                }
            }}
        />
    );
};

import { FC, useMemo } from 'react';
import { FormProvider, useForm, useFormContext, UseFormReturn, useWatch } from 'react-hook-form';
import {
    defaultPurchaseFilterForm,
    HISTORY_DAYS_TO_SHOW,
    UserDetailsForm,
    UserPurchasesFilterForm,
} from './users.models';
import { PurchaseSummary } from '../purchase-summary/purchase-summary';
import { ShoppingCart } from 'react-feather';
import { ReactComponent as ils } from './ils.svg';
import { Table } from '../table/Table';
import { TableHeader } from '../table/TableHeader';
import './users.scss';
import { useUpdateEffect } from '../../hooks/useUpdateEffect';
import { populateUserPurchasesData, purchasesHistoryTableColumns } from './users.utils';
import { useQuery } from 'react-query';
import { getPurchasesAPI } from '../../api/store.api';
import { Purchase } from '../orders/order.models';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { Column } from '../table/Tables.types';
import { Order } from '../../hooks/useSort';

type UsersStep6Props = {};

const IlsIcon = ils;

export const UsersStep6: FC<UsersStep6Props> = ({}) => {
    const form: UseFormReturn<UserDetailsForm> = useFormContext();
    const dispatch = useDispatch();
    const purchasesForm = useForm<UserPurchasesFilterForm>({
        defaultValues: defaultPurchaseFilterForm,
        mode: 'onTouched',
    });
    const search = useWatch({ name: 'search', control: purchasesForm.control });
    const orderBy = useWatch({ name: 'orderBy', control: purchasesForm.control });
    const rowsPerPage = useWatch({ name: 'rowsPerPage', control: purchasesForm.control });
    const page = useWatch({ name: 'page', control: purchasesForm.control });
    const { t } = useTranslation();
    const { refetch, data: { data: purchases = [], total = 0 } = {} } = useQuery({
        queryKey: ['getOrders'],
        queryFn: async () => {
            const { userID } = form.getValues();

            const currentDate = new Date();
            const threeMonthsAgo = new Date(currentDate);
            threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

            return getPurchasesAPI<Purchase>({
                userID,
                fromTimestamp: threeMonthsAgo.getTime(),
                toTimestamp: currentDate.getTime(),
                offset: page * (rowsPerPage?.value ?? 0),
                count: rowsPerPage?.value,
                sortBy: orderBy?.direction,
                sortField: orderBy?.sortedColumn,
            });
        },
        enabled: true,
    });
    const purchasesAmount = useMemo(() => purchases?.length, [purchases]);
    const purchasesSum = useMemo(() => {
        return purchases.reduce((acc, curr) => {
            return +acc + (+curr?.moneyAmount ?? 0) + +curr?.creditsAmount ?? 0;
        }, 0);
    }, [purchases]);

    useUpdateEffect(() => {
        purchasesForm.setValue(
            'purchases',
            purchases?.filter((pd) => {
                return (
                    pd?.purchaseID?.toLowerCase().includes(search.toLowerCase()) ||
                    pd.product?.productName?.toLowerCase().includes(search.toLowerCase())
                );
            }) ?? []
        );
    }, [search]);

    useUpdateEffect(() => {
        if (page !== 0) {
            purchasesForm.setValue('page', 0);
        } else {
            refetch();
        }
    }, [rowsPerPage?.value]);

    useUpdateEffect(() => {
        refetch();
    }, [dispatch, purchasesForm, page, orderBy]);

    const CustomHeader = () => {
        return (
            <TableHeader
                rowsPerPage={rowsPerPage?.value}
                leftSideComponents={[]}
                rightSideComponents={[
                    <span style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                        חיפוש
                        <SmartInput
                            name={'search'}
                            label={''}
                            fullRowSize={false}
                            containerClass={`position-relative size-default`}
                            hideError
                        />
                    </span>,
                ]}
            />
        );
    };

    return (
        <div className={'step-6'}>
            <FormProvider {...purchasesForm}>
                <div className={'inputs'}>
                    <div className={'step-title'}>{`היסטוריה (${HISTORY_DAYS_TO_SHOW} יום אחרונים)`}</div>
                    <div className={'purchases'}>
                        <PurchaseSummary
                            label={'מספר רכישות'}
                            amount={purchasesAmount}
                            icon={<ShoppingCart width={24} height={24} color={'var(--color-success)'} />}
                            iconBackground={'rgba(38, 188, 126, 0.12)'}
                        />
                        <PurchaseSummary
                            label={'שווי רכישות'}
                            amount={purchasesSum}
                            icon={<IlsIcon width={24} height={24} />}
                            iconBackground={'rgba(104, 224, 240, 0.12)'}
                        />
                    </div>
                </div>

                <Table
                    columns={purchasesHistoryTableColumns()}
                    data={populateUserPurchasesData(purchases, t)}
                    header={CustomHeader()}
                    pagination={true}
                    rowsPerPage={rowsPerPage?.value}
                    total={total}
                    onSort={(column: Column<Purchase>, direction: Order) => {
                        purchasesForm.setValue('orderBy', { direction, sortedColumn: column.field });
                    }}
                    selectableRows={false}
                />
            </FormProvider>
        </div>
    );
};

import { FC } from 'react';
import { Input } from 'reactstrap';
import { useController, useFormContext } from 'react-hook-form';
import './radio.scss';

export type RadioButtonProps = {
    option1: string;
    option2: string;
    option3?: string;
    disabled?: boolean;
    name: string;
};

export const RadioOption: FC<RadioButtonProps> = ({ option1, option2, option3, name, disabled }) => {
    const { control } = useFormContext();
    const { field, fieldState } = useController({ name, control });

    return (
        <div className={`radio-buttons ${fieldState?.error && 'error-msg'}`}>
            <div className={'button-label-and-radio'}>
                <Input
                    type="radio"
                    value={option1}
                    checked={field.value === false}
                    onChange={() => field.onChange(false)}
                    disabled={disabled}
                />
                {option1}
            </div>
            <div className={'button-label-and-radio'}>
                <Input
                    type="radio"
                    value={option2}
                    checked={field.value === true}
                    onChange={() => field.onChange(true)}
                    disabled={disabled}
                />
                {option2}
            </div>
            {option3 && (
                <div className={'button-label-and-radio'}>
                    <Input
                        type="radio"
                        value={option3}
                        checked={field.value === 'none'}
                        onChange={() => field.onChange('none')}
                        disabled={disabled}
                    />
                    {option3}
                </div>
            )}
        </div>
    );
};

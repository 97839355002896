import { FC } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { SmartSelect } from '../form/select/smart-select';
import { SelectOption } from '../../models/shared.models';
import { useTranslation } from 'react-i18next';

type ChangeStatusProps = {
    form: UseFormReturn<{ status: { label: string; value: string } }>;
    name: string;
    options: SelectOption[];
};

export const ChangeStatus: FC<ChangeStatusProps> = ({ form, name, options }) => {
    const { t } = useTranslation();

    return (
        <FormProvider {...form}>
            <SmartSelect
                required
                label={t('newStatus')}
                placeholder={t('status')}
                name={name}
                containerClass={`position-relative size-default container-input`}
                options={options}
            />
        </FormProvider>
    );
};

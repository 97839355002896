import * as Yup from 'yup';
import { ERROR_MESSAGES } from '../../utils/validations';

export const groupSchema = Yup.object().shape({
    companyID: Yup.string(),
    ownerID: Yup.string().nullable(),
    name: Yup.string()
        .required(ERROR_MESSAGES.required)
        .nullable()
        .min(2, 'מינימום 2 תווים')
        .max(15, 'מקסימום 15 תווים'),
    // relatedCategory: Yup.object({ value: Yup.string().nullable().required(ERROR_MESSAGES.required) }),
    imageURL: Yup.string().nullable(),
    members: Yup.array().of(Yup.object({ value: Yup.string() })),
});

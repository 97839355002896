import React from 'react';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';

import ChartComponent from '../chart/chart_factory';

import { StatisticsCardProps } from './dashboard.models';

export const StatisticsCard: React.FC<StatisticsCardProps> = ({ data, title, chartType, chartProps }) => {
    return (
        <Card className="text-center my-3">
            <CardBody>
                <CardTitle tag="h5">{title}</CardTitle>
                <CardText>{/* <Icon color="red" /> */}</CardText>
                <ChartComponent
                    data={data}
                    type={chartType}
                    width={chartProps?.width}
                    height={chartProps?.height}
                    xAxisProps={chartProps?.xAxisProps}
                    yAxisProps={chartProps?.yAxisProps}
                    formatTickFunction={chartProps?.formatTickFunction}
                />
            </CardBody>
        </Card>
    );
};

export default StatisticsCard;

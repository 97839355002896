import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreCategory } from '../../components/store/store.models';

export interface StoreState {
    allCategories: StoreCategory[];
}

const initialState: StoreState = {
    allCategories: [],
};

export const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        setAllCategories: (state: StoreState, action: PayloadAction<StoreCategory[]>) => {
            state.allCategories = action.payload;
        },
    },
});

export default storeSlice.reducer;

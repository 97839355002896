import { FC, useEffect, useRef, useState } from 'react';
import './users.scss';
import { Download, Info, Trash, Upload, UserPlus } from 'react-feather';
import { Filters } from '../filters/Filters';
import { FiltersModels } from '../filters/filters.models';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanies } from '../../store/companies/companies.selectors';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Table } from '../table/Table';
import { TableHeader } from '../table/TableHeader';
import { Button } from '../form/buttons/Button';
import { exportUsers, importUsers, populateUsersData, usersManagementTableColumns } from './users.utils';
import { deleteUser, deleteUsersAPI, freezeUser, getUsersAPI } from '../../api/users.api';
import { defaultUsersValues, User, UsersFilterForm, UsersTableData, userTypeOptions } from './users.models';
import { useQuery } from 'react-query';
import { closeModal, raiseModal } from '../../store/modal/modal.actions';
import { ModalIcon } from '../modal/ModalIcon';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { yupResolver } from '@hookform/resolvers/yup';
import { chooseCompanySchema } from './user-validation';
import { ErrorToast, SuccessToast } from '../toaster/Toast';
import { Column } from '../table/Tables.types';
import { Order } from '../../hooks/useSort';
import { useTranslation } from 'react-i18next';
import { ChooseCompanyModal } from '../modals/ChooseCompanyModal';
import { ImportInfoModal } from '../modals/ImportInfoModal';
import { getCompaniesAPI } from '../../api/companies.api';
import { companyLoadOptions } from '../../utils/loadOptions';
import { useNavigate } from 'react-router-dom';
import { useFormWithSession } from '../../hooks/useFormSession';
import { useUpdateEffect } from '../../hooks/useUpdateEffect';
import { GetInstances } from '../../api/api.models';

type UsersManagementProps = {
    onCreate0rEdit: (userID: string | undefined) => void;
};

export const UsersManagement: FC<UsersManagementProps> = () => {
    const currentUser = useSelector(getCurrentUser);
    const dispatch = useDispatch();
    const [isImportInfoModalOpen, setIsImportInfoModalOpen] = useState(false);
    const form = useForm<UsersFilterForm>({
        defaultValues: defaultUsersValues,
        mode: 'onTouched',
        resolver: yupResolver(chooseCompanySchema),
    });
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [exportModalOpen, setExportModalOpen] = useState(false);
    const orderBy = useWatch({ name: 'orderBy', control: form.control });
    const rowsPerPage = useWatch({ name: 'rowsPerPage', control: form.control });
    const page = useWatch({ name: 'page', control: form.control });
    const userType = useWatch({ name: 'userType', control: form.control });
    const search = useWatch({ name: 'search', control: form.control });
    const userSpecialFields = useWatch({ name: 'userSpecialFields', control: form.control });
    const companySpecialFields = useWatch({ name: 'companySpecialFields', control: form.control });
    const chosenCompanies = useWatch({ name: 'companies', control: form.control });
    const chosenUploadCompany = useWatch({ name: 'chosenUploadCompany', control: form.control });
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [uploadFileKey, setUploadFileKey] = useState(0);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [filters, setFilters] = useState<GetInstances<User>>(buildFiltersFromForm());

    function buildFiltersFromForm() {
        return {
            companiesIDs: chosenCompanies?.map((cc) => cc.value),
            userSpecialFields: userSpecialFields,
            companySpecialFields: companySpecialFields,
            offset: page * rowsPerPage.value,
            count: rowsPerPage.value,
            sortBy: orderBy.direction,
            sortField: orderBy.sortedColumn,
            extraFilters: { userType: userType?.value || undefined, search },
            isExtended: true,
        };
    }

    function buildFiltersFromSession(values: UsersFilterForm): GetInstances<User> {
        const {
            companySpecialFields,
            companies,
            userSpecialFields,
            page,
            rowsPerPage,
            orderBy,
            userType,
            search,
        } = values;
        return {
            companiesIDs: companies?.map((cc) => cc.value),
            userSpecialFields: userSpecialFields,
            companySpecialFields: companySpecialFields,
            offset: page * rowsPerPage.value,
            count: rowsPerPage.value,
            sortBy: orderBy.direction,
            sortField: orderBy.sortedColumn,
            extraFilters: { userType: userType?.value || undefined, search },
            isExtended: true,
        };
    }

    const { data: { data: users = [], total = 0 } = {}, refetch: forceRefetch } = useQuery({
        queryKey: ['getUsers', filters],
        queryFn: async () => {
            return getUsersAPI<User>(filters);
        },
        enabled: true,
        // refetchOnWindowFocus: true,
        // staleTime: 0,
        // cacheTime: 0,
        // refetchInterval: 0,
        // suspense: false,
        // refetchOnMount: 'always',
        // optimisticResults: false,
        // keepPreviousData: true,
    });
    const refetch = () => {
        setFilters(buildFiltersFromForm());
    };

    useFormWithSession<UsersFilterForm>(form, (values: UsersFilterForm) =>
        setFilters(buildFiltersFromSession(values))
    );

    const { data: companies } = useQuery({
        queryKey: ['getCompanies'],
        queryFn: async () => {
            return (await getCompaniesAPI({}))?.data;
        },
        enabled: true,
    });

    useEffect(() => {
        if (currentUser?.userType !== 'admin') {
            const currentCompany = (companies ?? []).find((c) => c.companyID === currentUser?.companyID);
            if (currentCompany?.name) {
                form.setValue('companies', [{ label: currentCompany.name, value: currentCompany.companyID }]);
                form.setValue('chosenUploadCompany', {
                    label: currentCompany.name,
                    value: currentCompany.companyID,
                });
            }
        }
    }, [companies, currentUser?.companyID, currentUser?.userType, form]);

    const refetchOrResetPage = () => {
        if (page !== 0) {
            form.setValue('page', 0);
        } else {
            refetch();
        }
    };

    useUpdateEffect(() => {
        refetchOrResetPage();
    }, [rowsPerPage.value, orderBy]);

    useUpdateEffect(() => {
        refetch();
    }, [page]);

    const addOrEditUser = (userID: string | undefined) => {
        // onCreate0rEdit(userID);
        navigate(`${userID ?? 'new'}`);
    };

    const handleDeleteUsers = () => {
        dispatch(
            raiseModal({
                isOpen: true,
                content: `${t('areYouSureYouWantToDeleteSelectedUsers')}?`,
                header: {
                    title: t('deleteUsers'),
                    icon: <ModalIcon theme={'ALERT'} />,
                },
                footer: {
                    buttons: [
                        {
                            label: t('yesDelete'),
                            theme: 'danger',
                            callback: async () => {
                                deleteUsersAPI(selectedRows).then(() => {
                                    SuccessToast(t('usersDeletedSuccessfully'));
                                    dispatch(closeModal());
                                    refetch();
                                });
                            },
                        },
                    ],
                },
            })
        );
    };

    const handleDeleteUser = (row: UsersTableData) => {
        const user = users?.find((u) => u.phone?.value === row.phone);

        if (!user) {
            ErrorToast(t('userDeleteFailed'));
            return;
        }

        dispatch(
            raiseModal({
                isOpen: true,
                content: `${t('areYouSureYouWantToDeleteUser')} ${user.firstName} ${user.lastName}?`,
                header: {
                    title: t('deleteUser'),
                    icon: <ModalIcon theme={'ALERT'} />,
                },
                footer: {
                    buttons: [
                        {
                            label: t('yesDelete'),
                            theme: 'danger',
                            callback: async () => {
                                deleteUser(user.userID).then(() => {
                                    SuccessToast(t('userDeletedSuccessfully'));
                                    dispatch(closeModal());
                                    refetch();
                                });
                            },
                        },
                    ],
                },
            })
        );
    };

    const importExcelChooseCompany = () => {
        setExportModalOpen(true);
    };

    const onExportTrigger = async () => {
        form.trigger('chosenUploadCompany').then((value) => {
            if (value) {
                if (fileInputRef.current) {
                    fileInputRef.current.click();
                }

                dispatch(closeModal());
            }
        });
    };

    const handleFreezeUser = (userID: string) => {
        const user = users.find((u) => u.userID === userID);
        dispatch(
            raiseModal({
                isOpen: true,
                content: user?.isFrozen ? t('unfreeze') : t('confirmFreeze'),
                header: {
                    title: t('freezeUser'),
                    icon: <ModalIcon theme={'ALERT'} />,
                },
                footer: {
                    buttons: [
                        {
                            label: t('confirm'),
                            theme: 'danger',
                            callback: async () => {
                                freezeUser(userID).then(() => {
                                    SuccessToast(t('operationSuccess'));
                                    dispatch(closeModal());
                                    forceRefetch();
                                });
                            },
                        },
                        {
                            label: t('cancel'),
                            theme: 'grey',
                            callback: async () => dispatch(closeModal()),
                        },
                    ],
                },
            })
        );
    };

    const CustomHeader = () => {
        return (
            <TableHeader
                rowsPerPage={rowsPerPage.value}
                leftSideComponents={[
                    selectedRows.length > 0 && (
                        <Button
                            theme={'outline-danger'}
                            onClick={() => handleDeleteUsers()}
                            icon={<Trash width={13} />}>
                            {t('deleteUsers')}
                        </Button>
                    ),
                    <Button
                        theme={'secondary'}
                        onClick={() =>
                            exportUsers(
                                chosenCompanies?.map((cc) => cc.value),
                                chosenCompanies && chosenCompanies?.length > 1
                                    ? 'users'
                                    : chosenCompanies?.[0]?.label ?? 'users'
                            )
                        }
                        icon={<Download width={14} />}>
                        {t('exportToExcel')}
                    </Button>,
                    <Info
                        color={'var(--primary'}
                        onClick={() => setIsImportInfoModalOpen(true)}
                        style={{ cursor: 'pointer' }}
                    />,
                    <span>
                        <input
                            key={uploadFileKey}
                            type="file"
                            accept=".csv"
                            onChange={(e) =>
                                importUsers(
                                    e,
                                    dispatch,
                                    () => setExportModalOpen(false),
                                    chosenUploadCompany?.value,
                                    () => setUploadFileKey(uploadFileKey + 1),
                                    t
                                ).then(() => refetchOrResetPage())
                            }
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                        />
                        <Button
                            theme={'secondary'}
                            onClick={importExcelChooseCompany}
                            icon={<Upload width={14} />}>
                            {t('import')}
                        </Button>
                    </span>,
                    <Button
                        theme={'primary'}
                        onClick={() => addOrEditUser(undefined)}
                        icon={<UserPlus width={14} />}>
                        {t('addUser')}
                    </Button>,
                ]}
            />
        );
    };

    const onRowSelect = (row: { selectedRows: UsersTableData[] }) => {
        setSelectedRows(row.selectedRows?.map((r) => r.id));
    };

    const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            refetchOrResetPage();
        }
    };

    return (
        <FormProvider {...form}>
            <div className={'management'}>
                <Filters
                    clearFilters={() => {
                        form.setValue('companies', []);
                        form.setValue('userType', { label: '', value: '' });
                        form.setValue('search', '');
                        form.setValue('page', 0);
                        form.setValue('userSpecialFields', '');
                        form.setValue('companySpecialFields', '');
                    }}
                    filters={[
                        {
                            type: FiltersModels.AUTO_COMPLETE,
                            name: 'companies',
                            getLoadOptionsData: companyLoadOptions,
                            placeholder: t('showAll'),
                            label: t('company'),
                            onKeyDown,
                            isMulti: true,
                            isDisabled: currentUser?.userType !== 'admin',
                        },
                        {
                            type: FiltersModels.SELECT,
                            name: 'userType',
                            fullRowSize: false,
                            onKeyDown,
                            placeholder: t('showAll'),
                            label: t('userType'),
                            options: [...userTypeOptions],
                        },
                        {
                            type: FiltersModels.INPUT,
                            name: 'search',
                            fullRowSize: false,
                            onKeyDown,
                            placeholder: t('search...'),
                            label: t('searchByNamePhoneEmail'),
                        },
                        {
                            type: FiltersModels.INPUT,
                            name: 'userSpecialFields',
                            fullRowSize: false,
                            onKeyDown,
                            placeholder: t('search...'),
                            label: t('searchByUserSpecialFields'),
                        },
                        {
                            type: FiltersModels.INPUT,
                            name: 'companySpecialFields',
                            fullRowSize: false,
                            onKeyDown,
                            placeholder: t('search...'),
                            label: t('searchByCompanySpecialFields'),
                        },
                        {
                            type: FiltersModels.BUTTON,
                            sizeBootstrap: 2,
                            label: t('filter'),
                            onClick: () => refetchOrResetPage(),
                        },
                    ]}
                    title={t('filterBy')}
                    initialOpen
                />
                <Table
                    columns={usersManagementTableColumns(
                        currentUser?.userType,
                        (row: UsersTableData) =>
                            addOrEditUser(users.find((u) => u.phone?.value === row.phone)?.userID),
                        handleDeleteUser
                    )}
                    onRowClicked={(row) =>
                        addOrEditUser(users.find((u) => u.phone?.value === row.phone)?.userID)
                    }
                    data={populateUsersData(users ?? [], handleFreezeUser)}
                    header={CustomHeader()}
                    pagination={true}
                    rowsPerPage={rowsPerPage.value}
                    selectableRows={true}
                    onRowSelect={onRowSelect}
                    total={total}
                    onSort={(column: Column<User>, direction: Order) => {
                        form.setValue('orderBy', {
                            direction,
                            sortedColumn: column.field,
                        });
                    }}
                />
            </div>
            <ChooseCompanyModal
                chosenUploadCompany={chosenUploadCompany}
                name={'chosenUploadCompany'}
                disabled={currentUser?.userType !== 'admin'}
                onTrigger={onExportTrigger}
                isOpen={exportModalOpen}
                closeModal={() => setExportModalOpen(false)}
            />
            <ImportInfoModal
                isOpen={isImportInfoModalOpen}
                closeModal={() => setIsImportInfoModalOpen(false)}
            />
        </FormProvider>
    );
};

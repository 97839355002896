import { FC, ReactElement } from 'react';
import './purchase-summary.scss';

type PurchaseSummaryProps = {
    amount: number;
    label: string;
    icon: ReactElement;
    iconBackground?: string;
};

export const PurchaseSummary: FC<PurchaseSummaryProps> = ({ icon, amount, label, iconBackground }) => {
    return (
        <div className={'purchase-summary'}>
            <div className={'summary'}>
                <div className={'summary-amount'}>{amount.toLocaleString()}</div>
                <div className={'summary-label'}>{label}</div>
            </div>
            <span
                className={'purchase-icon'}
                style={{ backgroundColor: iconBackground, padding: '11px', borderRadius: '50%' }}>
                {icon}
            </span>
        </div>
    );
};

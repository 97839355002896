import { FC } from 'react';
import { AddCreditsForm } from './AddCredits.modal';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import './add-credits.scss';
import { SmartInput } from '../../form/input-and-checkbox/SmartInput';
import { RadioOption } from '../../form/radio/smart-radio-button';
import { useTranslation } from 'react-i18next';

type AddCreditsProps = {
    form: UseFormReturn<AddCreditsForm>;
};

export const AddCredits: FC<AddCreditsProps> = ({ form }) => {
    const { t } = useTranslation();

    return (
        <FormProvider {...form}>
            <div className={'add-credits'}>
                <SmartInput name={'budget'} disabled label={t('remainingBudget')} />
                <SmartInput name={'creditsToAdd'} label={t('amountCreditsToAdd')} enforceNumbers />
                <RadioOption option1={t('personal')} option2={t('social')} name={'isSocial'} />
            </div>
        </FormProvider>
    );
};

import { FC, useEffect } from 'react';
import { useFormContext, UseFormReturn, useWatch } from 'react-hook-form';
import { allUserTypeOptions, systemAccess, User, UserDetailsForm, userTypeOptions } from './users.models';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { SmartSelect } from '../form/select/smart-select';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { Input } from '../form/input-and-checkbox/Input';
import shadow from './person-placeholder.png';
import { ImageUpload } from '../image-upload/ImageUpload';
import { getCompaniesAPI } from '../../api/companies.api';
import { Company } from '../companies/companies.models';
import { setEditedCompanyUniqueFields } from '../../store/companies/companies.actions';
import { ChooseCompany } from '../choose-company/ChooseCompany';
import { SmartAutoComplete } from '../form/select/auto-complete';
import { getGroupsAPI } from '../../api/groups.api';
import { getUserImagePath, uploadFile } from '../../api/storage.api';
import { Group, GroupOption } from '../../models/groups.models';
import { ErrorToast } from '../toaster/Toast';
import { useTranslation } from 'react-i18next';

type UsersStepOneProps = {
    editedUser: User | undefined;
};

export const UsersStep1: FC<UsersStepOneProps> = ({ editedUser }) => {
    const currentUser = useSelector(getCurrentUser);
    const form: UseFormReturn<UserDetailsForm> = useFormContext();
    const userType = useWatch({ name: 'userType', control: form.control });
    const address = useWatch({ name: 'address', control: form.control });
    const url = useWatch({ name: 'url', control: form.control });
    const company = useWatch({ name: 'company', control: form.control });
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (company?.value) {
            onCompanyChange(company?.value);
        }
    }, []);

    const onCompanyChange = async (compID: string) => {
        const company = await getCompaniesAPI<Company>({ companyID: compID });
        const userCompany = company.data?.[0];

        dispatch(
            setEditedCompanyUniqueFields([
                ...(userCompany?.companyUniqueFields?.map((c) => ({ ...c, isCompanyField: true })) ?? []),
                ...(userCompany?.usersUniqueFields ?? []),
            ])
        );

        if (!address?.city) {
            if (userCompany?.address?.city) form.setValue('officeAddress.city', userCompany?.address?.city);
            if (userCompany?.address?.street)
                form.setValue('officeAddress.street', userCompany?.address?.street);
        }

    };

    const groupsLoadOptions = async (inputValue: string): Promise<GroupOption[]> => {
        const results = await getGroupsAPI<Group>({
            companyID: company?.value,
            groupType: 'company',
            name: inputValue,
        });

        if (!results?.data) return [];

        return results.data.map((u) => ({
            label: `${u.name}`,
            value: { groupID: u.groupID },
        }));
    };

    const uploadPic = async (file: File) => {
        try {
            const userID = form.getValues('userID');
            const key = Date.now();
            const signedURL = await getUserImagePath(userID, key);
            const path = `public/users/${userID}/${key}`;

            await uploadFile(signedURL, file).then(() => {
                form.setValue('imageURL', path);
                form.setValue('url', signedURL);
            });
        } catch (e) {
            ErrorToast(t('errorUploadingFile'));
        }
    };

    const getUserOptions = () => {
        if (!!editedUser && editedUser.userType !== 'admin') {
            return userTypeOptions;
        } else {
            return currentUser?.userType === 'admin' ? allUserTypeOptions : userTypeOptions;
        }
    };

    return (
        <div className={'step'}>
            <div className={'step-row'}>
                <ImageUpload
                    imageURL={url ?? ''}
                    name={'url'}
                    onChange={(value) => {
                        form.setValue('imageURL', value);
                        form.setValue('url', value);
                    }}
                    defaultImage={shadow}
                    onUpload={uploadPic}
                />
            </div>

            <SmartInput required name={'firstName'} label={t('firstName')} />
            <SmartInput required name={'lastName'} label={t('lastName')} />
            <SmartSelect
                required
                name={'userType'}
                label={t('userType')}
                options={getUserOptions()}
                onChange={(value: string) => {
                    if (value === 'admin') {
                        form.setValue('company', { label: '', value: undefined });
                    }
                }}
                isDisabled={editedUser?.userType === 'admin'}
            />
            <Input label={'גישה למערכת'} value={userType ? systemAccess[userType.value] : ''} disabled />

            <ChooseCompany
                required
                name={'company'}
                isDisabled={currentUser?.userType !== 'admin' || userType?.value === 'admin' || !!editedUser}
                onChange={onCompanyChange}
            />

            {userType.value !== 'admin' && (
                <SmartAutoComplete
                    name={'companyGroups'}
                    label={t('companyGroups')}
                    placeholder={t('choose')}
                    getLoadOptionsData={groupsLoadOptions}
                    isDisabled={!company}
                    isMulti={true}
                    isClearable={false}
                    keyValue={company?.value}
                />
            )}
        </div>
    );
};

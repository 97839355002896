import './empty-state.scss';
import { Search } from 'react-feather';

interface EmptyStateProps {
    title: string;
}

const EmptyState = ({ title }: EmptyStateProps) => {
    return (
        <div className="empty-state-wrapper">
            <Search width={67} height={67} color={'var(--primary)'} />
            {title && <div className="empty-state-wrapper-title">{title}</div>}
        </div>
    );
};

export default EmptyState;

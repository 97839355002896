import { FC } from 'react';
import { Button } from '../../form/buttons/Button';
import { ModalButton, Position } from '../Modal.utils';
import './InnerModal.scss';

type InnerModalFooterProps = {
    buttons: ModalButton[];
    position?: Position;
};

export const InnerModalFooter: FC<InnerModalFooterProps> = ({ buttons, position }) => {
    const footerClassName = position === 'left' ? 'modal-footer-align-left' : 'modal-footer-align-center';
    if (!buttons.length) return null;

    return (
        <div className={`dynamic-modal-footer ${footerClassName}`}>
            {buttons.map((btn, i) => {
                return (
                    <Button
                        className={'footer-button'}
                        key={i}
                        theme={btn.theme}
                        onClick={btn.callback}
                        icon={btn.icon}
                        direction={btn.iconPosition}>
                        {btn.label}
                    </Button>
                );
            })}
        </div>
    );
};

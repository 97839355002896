import { SmartAutoComplete } from '../form/select/auto-complete';
import { FC } from 'react';
import './choose-company.scss';
import { useTranslation } from 'react-i18next';
import { companyLoadOptions } from '../../utils/loadOptions';

type ChooseCompanyProps = {
    onChange?: (compID: string) => void;
    isDisabled?: boolean;
    name: string;
    required?: boolean;
};

export const ChooseCompany: FC<ChooseCompanyProps> = ({ name, required, onChange, isDisabled }) => {
    const { t } = useTranslation();

    return (
        <SmartAutoComplete
            getLoadOptionsData={companyLoadOptions}
            name={name}
            containerClass={'complete-input'}
            label={t('company')}
            isDisabled={isDisabled}
            onChange={onChange}
            placeholder={`${t('typeCompanyName')}...`}
            required={required}
        />
    );
};

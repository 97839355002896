import { configureStore } from '@reduxjs/toolkit';
import UsersSlice from './users/UsersSlice';
import { ModalSlice } from './modal/Modal.slice';
import { LoadingSlice } from './loading/LoadingSlice';
import { CompaniesSlice } from './companies/CompanesSlice';
import StoreSlice from './store/StoreSlice';

export const store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
    reducer: {
        users: UsersSlice,
        modals: ModalSlice.reducer,
        loading: LoadingSlice.reducer,
        companies: CompaniesSlice.reducer,
        store: StoreSlice,
    },
});

export type RootState = ReturnType<typeof store.getState>;

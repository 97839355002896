import { FC } from 'react';
import { ModalProps } from '../Modal.utils';
import './InnerModal.scss';
import '../Modal.scss';
import { InnerModalFooter } from './InnerModalFooter';

export const InnerModal: FC<ModalProps> = ({ header, content, footer }) => {
    const headerIcon = header?.icon;
    const modelClassName = `${headerIcon ? 'modal-with-icon' : 'modal-without-icon'}`;

    return (
        <div className={`modal-wrapper ${modelClassName}`}>
            <div className={'dynamic-modal-header'}>
                {headerIcon}
                {header?.title}
            </div>

            <div className={'dynamic-modal-content'}>{content}</div>
            <InnerModalFooter buttons={footer.buttons} position={footer.position} />
        </div>
    );
};

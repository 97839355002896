import { FC, useEffect } from 'react';
import { SmartInput } from '../form/input-and-checkbox/SmartInput';
import { FormProvider, UseFormReturn, useWatch } from 'react-hook-form';
import { GroupForm, GroupWithMembers } from '../../models/groups.models';
import { SmartAutoComplete } from '../form/select/auto-complete';
import { ImageUpload } from '../image-upload/ImageUpload';
import { getGroupImagePath, uploadFile } from '../../api/storage.api';
import { ErrorToast } from '../toaster/Toast';
import { v4 } from 'uuid';
import { membersLoadOptions } from '../../utils/loadOptions';
import { useTranslation } from 'react-i18next';

type GroupProps = {
    form: UseFormReturn<GroupForm>;
    companyID: string;
    group?: GroupWithMembers;
};

const Group: FC<GroupProps> = ({ form, companyID, group }) => {
    const url = useWatch({ control: form.control, name: 'url' });
    const { t } = useTranslation();

    useEffect(() => {
        if (group) {
            form.reset({
                companyID: group.companyID,
                groupID: group.groupID || v4(),
                imageURL: group.imageURL || '',
                url: group.imageURL || '',
                name: group.name,
                ownerID: group.ownerID,
                members: group.members.map((user) => ({
                    label: `${user.firstName} ${user.lastName} - ${user.phone.value}`,
                    value: user.userID,
                })),
            });
        }
    }, [form, group]);

    const uploadPic = async (file: File) => {
        try {
            const key = Date.now();
            const groupID = form.getValues('groupID');
            const signedURL = await getGroupImagePath(groupID, companyID, key);
            const path = `${companyID}/groups/${groupID}/${key}`;

            await uploadFile(signedURL, file);
            form.setValue('imageURL', path);
            form.setValue('url', signedURL);
        } catch (e) {
            ErrorToast(t('errorUploadingFile'));
        }
    };

    return (
        <FormProvider {...form}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <ImageUpload
                    imageURL={url ?? ''}
                    name={'url'}
                    onChange={(value) => {
                        form.setValue('imageURL', value);
                        form.setValue('url', value);
                    }}
                    onUpload={uploadPic}
                    direction={'column'}
                />

                <SmartInput
                    required
                    label={t('groupName')}
                    placeholder={t('groupName')}
                    name={'name'}
                    containerClass={'container-input'}
                />

                {/*<SmartSelect*/}
                {/*    required*/}
                {/*    label={'קטגוריה'}*/}
                {/*    name={'relatedCategory'}*/}
                {/*    containerClass={'text-align-right'}*/}
                {/*    options={[{ label: t('companyGroup'), value: t('companyGroup') }]}*/}
                {/*    hideError={false}*/}
                {/*    isDisabled*/}
                {/*/>*/}

                <SmartAutoComplete
                    name={'members'}
                    label={t('groupMembers')}
                    containerClass={'container-input'}
                    placeholder={t('choose')}
                    getLoadOptionsData={(value: string) => membersLoadOptions(companyID, value)}
                    isMulti={true}
                />
            </div>
        </FormProvider>
    );
};

export default Group;
